<template>
  <div class="how-use-container">
    <p class="instructionalVideosInfo" v-if="instructionalVideosInfo">
      {{ instructionalVideosInfo }}
    </p>

    <instructional-video
      :instructionalVideo="instructionalVideo"
      v-for="instructionalVideo in instructionalVideos"
      :key="instructionalVideo.id"
    />
  </div>
</template>

<script>
import axios from "../axios";
import InstructionalVideo from "../components/InstructionalVideo.vue";
export default {
  components: { InstructionalVideo },
  name: "HowUseAppPage",
  data() {
    return {
      instructionalVideosInfo: "",
    };
  },
  async created() {
    const response = await axios.get(`custom-text/instructionalVideosInfo`);
    this.instructionalVideosInfo = response.data;

    this.$store.dispatch("instructionalVideo/fetchInstructionalVideos");
  },
  computed: {
    instructionalVideos() {
      return this.$store.getters["instructionalVideo/getInstructionalVideos"];
    },
  },
};
</script>

<style scoped lang="scss">
.how-use-container {
  margin: 0 auto;
  max-width: 1024px;
  margin-top: 2rem;
  text-align: justify;
}
.instructionalVideosInfo {
  font-weight: 500;
  margin-bottom: 3rem;
  font-size: 1.2rem;
}
</style>
