<template>
  <v-row style="max-width: 796px; margin: auto" justify="center" dense>
    <v-col cols="12" class="text-center">
      <v-img
        @click="redirectToGadgetLink"
        class="black--text align-end gadget-image my-8"
        :src="getPhoto()"
        :class="{ 'cursor-pointer': gadget.link }"
      >
      </v-img>
      <h1
        @click="redirectToGadgetLink"
        class="my-4 name"
        :class="{ 'cursor-pointer': gadget.link }"
      >
        {{ gadget.name }}
      </h1>
      <p
        v-html="formatQuillText(gadget.description)"
        class="text-justify my-8"
      ></p>
      <p class="text-justify my-8">{{ gadget.note }}</p>
    </v-col>
  </v-row>
</template>

<script>
import { URL } from "../config";
import router from "../router";
import shared from "../shared";
export default {
  name: "GadgetDetails",
  components: {},
  data: () => ({
    id: "",
  }),
  created() {
    const gadgetId = router.currentRoute.params.id;
    if (gadgetId) {
      this.$store.dispatch("gadgets/fetchGadget", gadgetId);
    } else {
      router.push({ name: "gadgets" });
    }
  },
  methods: {
    formatQuillText(text) {
      return shared.formatQuillText(text);
    },
    redirectToGadgetLink() {
      if (this.gadget.link) {
        window.open(this.gadget.link, "_blank");
      }
    },
    getImageUrl() {
      return URL;
    },
    getPhoto() {
      let path = "/uploads/";
      if (this.src && this.src === "no-photo.png") {
        path = "/assets/";
      }
      return this.getImageUrl() + path + this.gadget.imgPath;
    },
  },

  computed: {
    gadget() {
      return this.$store.getters["gadgets/getGadget"];
    },
  },
};
</script>

<style scoped>
.column-margin {
  margin-right: 50% !important;
}
.gadget-image {
  margin: 0 auto;
  width: 100%;
  max-width: 512px;
  max-height: 512px;
  object-fit: cover;
}
.cursor-pointer {
  cursor: pointer;
}
.name {
  width: fit-content;
  margin: 0 auto;
}
</style>
