<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="600px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title v-if="!isEdit">Dodaj kod rabatowy</v-toolbar-title>
          <v-toolbar-title v-else>Edytuj kod rabatowy</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    rules="required"
                    v-model="code"
                    label="Kod promocyjny"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextAreaWithValidation
                    rules="required|max:3000"
                    label="Opis"
                    style="height: 300px"
                    v-model="description"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    rules="required|min_value:1|max_value:99"
                    v-model="discountInPercent"
                    label="Wartość kodu rabatowego w procentach"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" class="mx-2">
                  <div class="checkboxes">
                    <v-checkbox
                      color="#fbc501"
                      label="Aktywny"
                      v-model="isActive"
                    ></v-checkbox>
                    <v-checkbox
                      color="#fbc501"
                      label="Nieaktywny"
                      v-model="isNotActive"
                    ></v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              v-if="!isEdit"
              color="success"
              text
              :disabled="invalid"
              v-on:click="addPromotionalCode()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Dodaj kod
            </v-btn>
            <v-btn
              v-else
              color="success"
              text
              :disabled="invalid"
              v-on:click="editPromotionalCode()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz kod
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation";
import BlackButton from "../buttons/BlackButton";

export default {
  name: "PromotionalCodeModal",
  props: ["isVisible", "isEdit", "item"],

  components: {
    VTextFieldWithValidation,
    VTextAreaWithValidation,
    ValidationObserver,
    BlackButton,
  },
  created() {},

  data: () => ({
    VCheckboxWithValidationname: "",
    description: "",
    discountInPercent: "",
    inputKey: 0,
    isActive: true,
    isNotActive: false,
  }),
  computed: {
    promotionalCode() {
      return this.$store.getters["promotionalCodes/getPromotionalCode"];
    },
  },
  watch: {
    isActive() {
      if (this.isActive) {
        this.isNotActive = false;
      }
    },
    isNotActive() {
      if (this.isNotActive) {
        this.isActive = false;
      }
    },
    item: function () {
      this.code = this.item.code;
      this.description = this.item.description;
      this.discountInPercent = this.item.discountInPercent;
      this.isActive = this.item.isActive;
      if (!this.isActive) {
        this.isNotActive = true;
      }
    },
  },
  methods: {
    closeModal() {
      this.code = "";
      this.description = "";
      this.discountInPercent = "";
      this.isActive = true;
      this.$refs.obs.reset();
      this.$emit("closeModal");
    },
    async addPromotionalCode() {
      const body = {
        code: this.code,
        description: this.description,
        discountInPercent: this.discountInPercent,
        isActive: this.isActive,
      };
      await this.$store.dispatch("promotionalCodes/addPromotionalCode", body);
      this.closeModal();
    },
    async editPromotionalCode() {
      const body = {
        id: this.item.id,
        code: this.code,
        description: this.description,
        discountInPercent: this.discountInPercent,
        isActive: this.isActive,
      };
      await this.$store.dispatch("promotionalCodes/editPromotionalCode", body);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 1%;
  right: 3%;
  z-index: 10000000;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.checkboxes {
  display: flex;
  gap: 1rem;
}
</style>
