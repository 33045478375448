import axios from "../../axios";
import errorHandler from "../../errorHandler";
import { showErrorAlert, showSuccessAlert } from "../../utils/alert";

const payment = {
  namespaced: true,
  state: () => ({
    transaction_message: null,
    transaction_error: null,
    transaction_info: {
      p24_merchant_id: "",
      p24_pos_id: "",
      p24_session_id: "",
      p24_amount: "",
      p24_currency: "",
      p24_description: "",
      p24_email: "",
      p24_country: "",
      p24_url_return: "",
      p24_url_status: "",
      p24_api_version: "",
      p24_sign: "",
    },
    transaction_show: false,
    paymentOptions: [],
    orders: null,
  }),

  mutations: {
    SET_TRANS_INFO(state, response) {
      const { message, error, data, show } = response;
      state.transaction_message = message;
      state.transaction_error = error;
      state.transaction_info = data;
      state.transaction_show = show;
    },
    SET_PAYMENT_OPTIONS(state, response) {
      state.paymentOptions = response;
    },
    SET_ADMIN_ORDERS(state, response) {
      state.orders = response;
    },
  },

  actions: {
    async fetchPaymentOptions({ commit }) {
      return axios
        .get(`payment/subscription`)
        .then(function (response) {
          const { data } = response.data;
          commit("SET_PAYMENT_OPTIONS", data);
        })
        .catch(function (error) {
          commit("SET_PAYMENT_OPTIONS", []);
          console.log(error.response);
        });
    },
    async createOrder({ commit, dispatch }, data) {
      return axios
        .post(`payment/order`, data)
        .then(function (response) {
          const { data } = response;
          dispatch("startTransaction", data.id);
          commit();
        })
        .catch(function (error) {
          console.log(error.response);
          if (error?.response?.data) {
            dispatch(
              "snackbar/setSnackbar",
              {
                color: "red",
                icon: "exclamation-triangle",
                message: errorHandler(
                  error.response.data.message,
                  error.response.data.code
                ),
              },
              { root: true }
            );
            dispatch("snackbar/toggleSnackbar", true, { root: true });
          }
        });
    },
    async startTransaction({ commit }, orderId) {
      return axios
        .post(`payment/order/${orderId}`)
        .then(function (response) {
          const { data } = response;
          commit("SET_TRANS_INFO", {
            data,
            error: false,
            message: null,
            show: true,
          });
        })
        .catch(function (error) {
          commit("SET_TRANS_INFO", {
            data: null,
            error: true,
            message: error.response,
            show: false,
          });
        });
    },
    async updatePaymentOptions({ dispatch }, data) {
      return axios
        .put(`admin/subscription`, data)
        .then(() => {
          showSuccessAlert(dispatch, "Pomyślnie zaktualizowano");
        })
        .catch(function (error) {
          console.log(error);
          showErrorAlert(dispatch, "Błąd podczas aktualizacji");
        });
    },
    async getAllOrders({ commit, dispatch }, data) {
      const { paymentStatus } = data;
      const url = paymentStatus ? `admin/orders?paymentStatus=${paymentStatus}` : `admin/orders`
      return axios
        .get(url)
        .then((response) => {
          commit("SET_ADMIN_ORDERS", response.data);
        })
        .catch((error) => {
          console.log(error);
          showErrorAlert(dispatch, "Błąd podczas pobierania danych");
        });
    },
    async editInvoice({ dispatch }, data) {
      return axios
        .put(`admin/invoice/${data.id}`, data.data)
        .then(() => {
          showSuccessAlert(dispatch, "Pomyślnie zaktualizowano");
        })
        .catch((error) => {
          console.log(error);
          showErrorAlert(dispatch, "Błąd podczas pobierania danych");
        });
    },
    async sendInvoiceAgain({ dispatch }, orderId) {
      return axios
        .post(`admin/invoice/${orderId}/send`)
        .then(() => {
          showSuccessAlert(dispatch, "Pomyślnie wysłano");
        })
        .catch((error) => {
          const { message, code } = error.response.data;
          if (message && code) {
            showErrorAlert(dispatch, message, code);
          } else {
            showErrorAlert(dispatch, "Błąd podczas wysyłania");
          }
        });
    },
  },
  getters: {
    getTransInfo: (state) => {
      return {
        message: state.transaction_message,
        error: state.transaction_error,
        data: state.transaction_info,
        show: state.transaction_show,
      };
    },
    getPaymentOptions: (state) => {
      return state.paymentOptions;
    },
    getAdminOrders: (state) => {
      return state.orders;
    },
  },
};
export default payment;
