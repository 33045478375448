<template>
  <div class="calendar-container" @click="handleClickOutside">
    <div class="date-container">
      <div class="date-view">
        <div style="width: 220px" v-if="selectedDate">
          <div class="date-view-year">
            {{ selectedYearFormatted }}
          </div>
          <div class="date-view-day">
            {{ selectedDateFormatted }}
          </div>
          <div class="today" :style="!isSelectedDateToday ? 'opacity: 0' : ''">
            Dziś
          </div>
        </div>
        <div class="date-view-icons" v-if="selectedDate">
          <YellowButton
            icon="calendar-alt"
            xsmall
            text="Zaplanuj datę"
            @click.native="planNewContact()"
          />

          <font-awesome-icon
            color="#fff"
            icon="calendar-alt"
            style="cursor: pointer"
            @click="datepickerVisible = !datepickerVisible"
            ref="calendarIcon"
          />
          <div class="arrows">
            <font-awesome-icon
              color="#fff"
              icon="chevron-left"
              style="cursor: pointer"
              @click="previousDay()"
            />
            <font-awesome-icon
              color="#fff"
              icon="chevron-right"
              style="cursor: pointer"
              @click="nextDay()"
            />
          </div>
        </div>
      </div>
      <v-date-picker
        v-if="datepickerVisible"
        no-title
        v-model="selectedDate"
        first-day-of-week="1"
        color="#fbc501"
        full-width
        :timezone="`UTC`"
        @input="changeDay"
        ref="datepicker"
        class="calendar-picker"
      />
    </div>

    <FullCalendar ref="fullCalendar" :options="calendarOptions" class="mt-4">
      <template v-slot:eventContent="arg">
        <div
          class="calendar-content"
          @click="
            openContactDetails(
              `contactActions_${arg.event.extendedProps.contact.id}`,
              arg.event.extendedProps.contact
            )
          "
        >
          <div class="event-left-side">
            <customer-contact-actions-icons
              :contact="arg.event.extendedProps.contact"
              @contactsUpdated="handleContactsUpdated"
              :ref="`contactActions_${arg.event.extendedProps.contact.id}`"
            />
            <div class="event-title">{{ arg.event.title }}</div>
          </div>
        </div>
      </template>
    </FullCalendar>
    <set-date-time-from-to-modal
      :isVisible="contactModal"
      @closeModal="closeSetDateTimeModal()"
      :datetimeFrom="clickedDate ? clickedDate : null"
      :datetimeTo="null"
      :contact="null"
      :customerId="''"
      @contactsUpdated="handleContactsUpdated"
    />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import plLocale from "@fullcalendar/core/locales/pl";
import moment from "moment";
import CustomerContactActionsIcons from "../components/CustomerContactActionsIcons.vue";
// import CustomerContactDots from "../components/CustomerContactDots.vue";
import YellowButton from "../components/buttons/YellowButton.vue";
import SetDateTimeFromToModal from "../components/modals/SetDateTimeFromToModal.vue";
const SWIPE_THRESHOLD = 30;
export default {
  data() {
    return {
      contactModal: false,
      datepickerVisible: false,
      selectedDate: null,
      calendarApi: null,
      calendarOptions: {
        slotEventOverlap: false,
        eventMaxStack: 3,
        locale: plLocale,
        buttonText: {
          today: "Dzisiaj",
        },
        headerToolbar: false,
        allDaySlot: false,
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: "timeGrid",
        dateClick: this.handleDateClick,
        height: "80vh",
        dayHeaders: false,
        dayHeaderFormat: {
          weekday: "long",
          month: "numeric",
          day: "numeric",
        },
        slotLabelInterval: "01:00",
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
          hour12: false,
        },
        slotDuration: "00:05:00",

        // eventClick: this.handleEventClick,
        eventColor: "#fbc501",
        eventTextColor: "#000",
        eventBorderColor: "#fff",
        editable: true,
        eventDrop: this.handleEventDrop,
        nowIndicator: true,
      },
      yDown: null,
      xDown: null,
      clickedDate: null,
    };
  },
  name: "CalendarPage",
  components: {
    FullCalendar,
    CustomerContactActionsIcons,
    // CustomerContactDots,
    YellowButton,
    SetDateTimeFromToModal,
  },

  async mounted() {
    this.calendarApi = this.$refs.fullCalendar.getApi();
    await this.handleContactsUpdated();
    this.selectedDate = moment().format("YYYY-MM-DD");
    this.scrollToFirstEventIfExistsIfNotScrollToCurrentTime(this.selectedDate);
    document.addEventListener("touchstart", this.handleTouchStart, false);
    document.addEventListener("touchend", this.handleTouchMove, false);
  },

  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("touchstart", this.handleTouchStart, false);
    document.removeEventListener("touchend", this.handleTouchMove, false);
  },

  methods: {
    getTouches(evt) {
      return (
        evt.touches || // browser API
        evt.originalEvent.touches
      );
    },

    handleTouchStart(evt) {
      const firstTouch = this.getTouches(evt)[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },

    handleTouchMove(evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }

      const xUp = evt.changedTouches[0].clientX;
      const yUp = evt.changedTouches[0].clientY;

      const xDiff = this.xDown - xUp;
      const yDiff = this.yDown - yUp;
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (Math.abs(xDiff) > SWIPE_THRESHOLD) {
          if (xDiff > 0) {
            this.nextDay();
          } else {
            this.previousDay();
          }
        }
      }
      this.xDown = null;
      this.yDown = null;
    },

    handleClickOutside(event) {
      const datepicker = this.$refs.datepicker;
      const icon = this.$refs.calendarIcon;
      if (
        datepicker &&
        !datepicker.$el.contains(event.target) &&
        icon &&
        !icon.contains(event.target)
      ) {
        this.datepickerVisible = false;
      }
    },

    planNewContact() {
      this.clickedDate = new Date();
      this.contactModal = true;
    },
    async closeSetDateTimeModal() {
      this.contactModal = false;
      this.selectedDateTimeFrom = null;
      this.selectedDateTimeTo = null;
      this.selectedContact = null;
      this.clickedDate = null;
    },
    openContactDetails(refChild, contact) {
      this.$refs[refChild].editContact(contact);
    },
    nextDay() {
      this.calendarApi.next();
      const formattedDate = moment(this.calendarApi.getDate()).format(
        "YYYY-MM-DD"
      );
      this.scrollToFirstEventIfExistsIfNotScrollToCurrentTime(formattedDate);
      this.selectedDate = formattedDate;
    },
    previousDay() {
      this.calendarApi.prev();
      const formattedDate = moment(this.calendarApi.getDate()).format(
        "YYYY-MM-DD"
      );
      this.scrollToFirstEventIfExistsIfNotScrollToCurrentTime(formattedDate);
      this.selectedDate = formattedDate;
    },
    changeDay(day) {
      this.calendarApi.gotoDate(day);
      this.scrollToFirstEventIfExistsIfNotScrollToCurrentTime(day);
    },

    scrollToFirstEventIfExistsIfNotScrollToCurrentTime(day) {
      const firstEventInGivenDay = this.calendarApi
        ?.getEvents()
        ?.find((event) => {
          return moment(event.start).format("YYYY-MM-DD") === day;
        });
      if (firstEventInGivenDay) {
        this.calendarApi.scrollToTime(
          moment(firstEventInGivenDay.start).format("HH:00:00")
        );
      } else {
        this.calendarApi.scrollToTime(moment().format("HH:00:00"));
      }
    },

    async handleContactsUpdated() {
      await this.$store.dispatch("customers/fetchAllContacts");
      this.calendarApi.removeAllEvents();
      this.allContacts.contacts.forEach((contact) => {
        const contactTopic = contact.topic;
        const endDate =
          Math.abs(
            moment(contact.date).diff(moment(contact.dateTo), "minutes")
          ) < 5
            ? moment(contact.date).add(5, "minutes").toDate()
            : contact.dateTo;
        this.calendarApi.addEvent({
          id: contact.id,
          title: contactTopic,
          start: contact.date,
          end: endDate,
          allDay: false,
          extendedProps: {
            contact,
          },
          color: contact.done
            ? "#fff6d9"
            : contact.notDone
            ? "lightgray"
            : "#f6f6f6",
        });
      });
      // this.adjustEventTitles();
    },
    async handleEventDrop(info) {
      const contact = info.event.extendedProps.contact;
      const startDate = info.event.start;
      const endDate = info.event.end;
      let object = {
        customerId: contact.customerId,
        contactId: contact.id,
        contactDateFrom: startDate,
        contactDateTo: endDate,
        topic: contact.topic,
        contactMade: contact.contactMade,
        contactNotMade: contact.contactNotMade,
        businessConversation: contact.businessConversation,
      };
      await this.$store.dispatch("customers/editContact", object);
      await this.handleContactsUpdated();
    },
    handleEventClick(arg) {
      console.log(arg.event.extendedProps.contactId);
    },
    handleDateClick(arg) {
      const date = arg.dateStr;
      this.clickedDate = date;
      this.contactModal = true;
    },
  },

  computed: {
    allContacts() {
      return this.$store.getters["customers/getAllContacts"];
    },
    selectedYearFormatted() {
      return this.selectedDate ? moment(this.selectedDate).format("YYYY") : "";
    },
    selectedDateFormatted() {
      return this.selectedDate
        ? moment(this.selectedDate).locale("pl").format("ddd., D.MM")
        : "";
    },
    isSelectedDateToday() {
      return this.selectedDate
        ? moment(this.selectedDate).isSame(moment(), "day")
        : false;
    },
  },
};
</script>

<style scoped lang="scss">
.calendar-container {
  width: 100%;
  height: 100%;
  max-width: 1366px;
  margin: 0 auto;
  padding-top: 10px;
}
.event-left-side {
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: space-between;
  font-weight: 300 !important;
  padding: 5px;
  overflow: hidden;
  font-size: 13px;
  height: 100%; /* ensures it takes the full height of the event */
  position: relative;
}

.calendar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%; /* ensure the content takes full height of the event */
  flex-wrap: wrap;
}
.edit-icon {
  cursor: pointer !important;
}
.event-title {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-weight: 400;
}
.date-view {
  display: flex;
  background-color: #fbc501;
  border-color: rgb(251, 197, 1);
  height: 80px;
  width: 100%;
  padding: 12px;
  align-items: center;
}
.date-container {
  position: sticky;
  top: 63px;
  z-index: 9;
}
.date-view-year {
  font-size: 14px;
  opacity: 0.6;
  color: #fff;
}
.date-view-day {
  font-size: 25px;
  color: #fff;
  font-weight: 500;
}
.today {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}
.date-view-icons {
  display: flex;
  gap: 1.4rem;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.arrows {
  display: flex;
  gap: 2rem;
}

@media (max-width: 768px) {
  .event-title {
    font-size: 9px;
  }
  .date-view-day {
    font-size: 16px;
  }
  .date-container {
    top: 55px;
  }
}
</style>
