<template>
  <v-row justify="center">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="500px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Informator o produktach</v-toolbar-title>
        </v-toolbar>
        <v-card-actions class="bold pt-5 d-flex flex-column">
          <YellowButton
            @click.native="downloadInformant()"
            class="mb-5"
            width="60%"
            text="Pobierz"
          />
          <YellowButton
            class="mb-5"
            width="60%"
            text="Wyślij"
            @click.native="sendInformantModal = true"
          />
          <YellowButton
            :href="getURL()"
            target="_blank"
            class="mb-5"
            text="Podgląd"
            width="60%"
          />
          <YellowButton
            text="Udostępnij"
            :disabled="!webShareApiSupported"
            icon="share"
            iconColor="white"
            width="60%"
            class="mb-5"
            @click.native="share()"
          />
          <small v-if="!webShareApiSupported" class="mt-2 text-center w-80">
            Przycisk udostępnij jest kompatybilny z przeglądarkami: Edge,
            Safari. Kompatybilność z przeglądarkami mobile: Chrome Andriod,
            Firefox Android, Opera Android, Safari iOS, Samsung Internet"</small
          >
        </v-card-actions>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
      </v-card>
    </v-dialog>
    <send-informant-modal
      :isVisible="sendInformantModal"
      @closeModal="sendInformantModal = false"
    />
  </v-row>
</template>

<script>
import BlackButton from "../buttons/BlackButton";
import YellowButton from "../buttons/YellowButton";
import { URL } from "../../config";
import moment from "moment";
import SendInformantModal from "./SendInformantModal.vue";
export default {
  name: "ProductInformantModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BlackButton,
    YellowButton,
    SendInformantModal,
  },
  data: () => ({
    sendInformantModal: false,
  }),
  computed: {
    webShareApiSupported() {
      console.log(navigator);
      return navigator.share;
    },
  },
  methods: {
    async share() {
      if (navigator.share) {
        const currentTimestamp = new Date().getTime();
        try {
          await navigator.share({
            title: "Informator o produktach - ForMYever Office",
            text: "Dzień dobry,\nW załączonym linku znajduje się informator o produktach.\n\nPozdrawiam",
            url:
              URL +
              `/assets/InformatorOProduktach${moment().get(
                "year"
              )}_v2.pdf?v=${currentTimestamp}`,
          });
        } catch (error) {
          // this.shareSnackbar();
        }
      } else {
        this.shareSnackbar();
      }
    },

    getURL() {
      const currentTimestamp = new Date().getTime();
      return (
        URL +
        `/assets/InformatorOProduktach${moment().get(
          "year"
        )}_v2.pdf?v=${currentTimestamp}`
      );
    },
    async downloadInformant() {
      await this.$store.dispatch("catalogue/downloadInformant");
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 1%;
  z-index: 10000000;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
</style>
