import axios from "../../axios";
import errorHandler from "../../errorHandler";

const catalogue = {
  namespaced: true,

  state: () => ({
    categories: [],
    category: {},
    products: [],
    filteredProducts: [],
    product: {},
    productPdfPath: "",
    tab: {},
    tabs: [],
    emptyCategory: {},
  }),

  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_CATEGORY(state, category) {
      state.category = category;
    },
    SET_TABS(state, tabs) {
      state.tabs = tabs;
    },
    SET_TAB(state, tab) {
      state.tab = tab;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_FILTERED_PRODUCTS(state, products) {
      state.filteredProducts = products;
    },
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_PRODUCT_PDF_PATH(state, data) {
      state.productPdfPath = data;
    },
    SET_EMPTY_CATEGORY(state, data) {
      state.emptyCategory[data.categoryId] = data.isEmpty;
    },
  },

  actions: {
    async checkCategoryDoesNotHaveProducts({ commit }, categoryId) {
      try {
        const response = await axios.get(
          `admin/categories/${categoryId}/isEmpty`
        );
        commit("SET_EMPTY_CATEGORY", {
          categoryId,
          isEmpty: response.data?.isEmpty,
        });
        return response.data?.isEmpty;
      } catch (error) {
        console.log(error.response);
        commit("SET_EMPTY_CATEGORY", { categoryId, isEmpty: false });
        return false;
      }
    },
    async fetchCategories({ dispatch, commit }, payload) {
      try {
        if (!payload) {
          payload = {};
        }
        const params = {};
        if (payload.showInvisibleCategories) {
          params.showInvisibleCategories = true;
        }
        const response = await axios.get("product/categories", { params });
        commit("SET_CATEGORIES", response.data.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchProducts({ dispatch, commit }, object) {
      let url = "product";
      if (object) {
        if (object.category) {
          url = "product?category=" + object.category;
          if (object.filter && object.category) {
            url =
              "product?category=" +
              object.category +
              "&search=" +
              object.filter;
          }
        }
        if (object.filter && !object.category && !object.showAllPrices) {
          url = "product?&search=" + object.filter;
        }
        if (object.filter && !object.category && object.showAllPrices) {
          url = "product?&search=" + object.filter + "&show_all_prices=";
        }
      }
      try {
        const response = await axios.get(url);
        commit("SET_PRODUCTS", response.data.data);
        commit("SET_CATEGORY", response.data.category);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchProductsForAdmin({ dispatch, commit }) {
      let url = "product?show_invisible=1";
      try {
        const response = await axios.get(url);
        commit("SET_PRODUCTS", response.data.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchFilteredProducts({ dispatch, commit }, object) {
      try {
        const response = await axios.get(`product?search=${object}`);
        commit("SET_FILTERED_PRODUCTS", response.data.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchProduct({ dispatch, commit }, object) {
      try {
        const response = await axios.get(`product/${object}`);
        commit("SET_PRODUCT", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchProductForAdmin({ dispatch, commit }, object) {
      try {
        const response = await axios.get(`admin/product/${object}`);
        commit("SET_PRODUCT", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async fetchCategoryForAdmin({ dispatch, commit }, categoryId) {
      try {
        const response = await axios.get(`admin/categories/${categoryId}`);
        commit("SET_CATEGORY", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async recommendProduct({ dispatch }, object) {
      let data = { customers: object.customers };
      try {
        await axios.post(`product/${object.id}/recommend`, data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie wysłano maila!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async deleteProduct({ dispatch }, object) {
      try {
        await axios.delete(`admin/product/${object}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto produkt!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchProductsForAdmin");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async addProduct({ dispatch }, object) {
      try {
        await axios.post(`admin/product`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano produkt!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchProductsForAdmin");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async addCategory({ dispatch }, object) {
      try {
        await axios.post(`admin/categories`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano kategorię!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchCategories");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async editProduct({ dispatch }, object) {
      try {
        await axios.put(`admin/product/${object.id}`, object.formData);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano produkt!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchProductsForAdmin");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async editCategory({ dispatch }, object) {
      try {
        await axios.put(`admin/categories/${object.id}`, object.formData);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano kategorię!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchCategories");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async editTab({ dispatch }, object) {
      try {
        await axios.put(`admin/home-tabs/${object.id}`, object.formData);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano zakładkę!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchTabs");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async addTab({ dispatch }, object) {
      try {
        await axios.post(`admin/home-tabs`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano zakładkę!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchTabs");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async fetchTabForAdmin({ dispatch, commit }, tabId) {
      try {
        const response = await axios.get(`admin/home-tabs/${tabId}`);
        commit("SET_TAB", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async fetchTabs({ dispatch, commit }) {
      try {
        const response = await axios.get("user/home-tabs");
        commit("SET_TABS", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async deleteTab({ dispatch }, object) {
      try {
        await axios.delete(`admin/home-tabs/${object}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto zakładkę!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchTabs");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async downloadCatalogue({ dispatch }) {
      try {
        const response = await axios.get(`product/product-catalog`, {
          responseType: "blob",
        });
        let fileName = "";
        for (const [key, value] of Object.entries(response.headers)) {
          if (key === "content-disposition") {
            const res = value.split('"');
            fileName = res[1];
            fileName = unescape(fileName);
          }
        }
        fileName = encodeURI(fileName);

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async sendCatalogue({ dispatch }, object) {
      try {
        await axios.post(`product/product-catalog`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie wysłano katalog!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async downloadInformant({ dispatch }) {
      try {
        const response = await axios.get(`product/product-informant`, {
          responseType: "blob",
        });
        let fileName = "";
        for (const [key, value] of Object.entries(response.headers)) {
          if (key === "content-disposition") {
            const res = value.split('"');
            fileName = res[1];
            fileName = unescape(fileName);
          }
        }
        fileName = encodeURI(fileName);

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async sendInformant({ dispatch }, object) {
      try {
        await axios.post(`product/product-informant`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie wysłano informator!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async downloadProductPdf({ commit, dispatch }, object) {
      try {
        if (object.onlyFilePath) {
          const response = await axios.get(
            `product/${object.id}/pdf?onlyFilePath=`
          );
          commit("SET_PRODUCT_PDF_PATH", response.data.split("/")[2]);
        } else {
          const response = await axios.get(`product/${object.id}/pdf`, {
            responseType: "blob",
          });

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", object.name.split(".")[0] + ".pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        }
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async getProductJpgPaths(_, object) {
      const response = await axios.get(`product/${object.id}/jpg`);
      return response.data;
    },

    async downloadProductImage(_, object) {
      const { productId, productName, jpgFilename } = object;
      const response = await axios.get(
        `product/${productId}/jpg/${jpgFilename}`,
        { responseType: "blob" }
      );

      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${productName}.jpg`);
      document.body.appendChild(fileLink);

      fileLink.click();
    },
  },

  getters: {
    getCategories: (state) => state.categories,
    getCategory: (state) => state.category,
    getProducts: (state) => state.products,
    getFilteredProducts: (state) => state.filteredProducts,
    getProduct: (state) => state.product,
    getProductPdfPath: (state) => state.productPdfPath,
    getTab: (state) => state.tab,
    getTabs: (state) => state.tabs,
  },
};
export default catalogue;
