import axios from "../../axios";
import errorHandler from "../../errorHandler";

const gadgets = {
  namespaced: true,

  state: () => ({
    gadgets: [],
    gadget: {},
  }),

  mutations: {
    SET_GADGETS(state, gadgets) {
      state.gadgets = gadgets;
    },
    SET_GADGET(state, gadget) {
      state.gadget = gadget;
    },
  },

  actions: {
    async fetchGadgets({ dispatch, commit }) {
      try {
        const response = await axios.get("forever-gift");
        commit("SET_GADGETS", response.data.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchGadgetsForAdmin({ dispatch, commit }) {
      let url = "forever-gift?show_invisible=1";
      try {
        const response = await axios.get(url);
        commit("SET_GADGETS", response.data.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchGadget({ dispatch, commit }, object) {
      try {
        const response = await axios.get(`forever-gift/${object}`);
        commit("SET_GADGET", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async deleteGadget({ dispatch }, object) {
      try {
        await axios.delete(`forever-gift/${object}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto gadżet!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchGadgetsForAdmin");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async addGadget({ dispatch }, object) {
      try {
        await axios.post(`forever-gift`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano gadżet!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchGadgetsForAdmin");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async editGadget({ dispatch }, object) {
      try {
        await axios.put(`forever-gift/${object.id}`, object.formData);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano gadżet!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchGadgetsForAdmin");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
  },

  getters: {
    getGadgets: (state) => state.gadgets,
    getGadget: (state) => state.gadget,
  },
};
export default gadgets;
