<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="600px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title v-if="!isEdit">Dodaj pytanie</v-toolbar-title>
          <v-toolbar-title v-else>Edytuj pytanie</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-area-with-validation
                    v-model="question"
                    label="Pytanie"
                    color="indigo"
                    outlined
                    dense
                    rules="required|max:2048"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-area-with-validation
                    v-model="answer"
                    label="Odpowiedź"
                    color="indigo"
                    outlined
                    dense
                    rules="required|max:2048"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              v-if="!isEdit"
              color="success"
              text
              :disabled="invalid"
              v-on:click="addFaq()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Dodaj pytanie
            </v-btn>
            <v-btn
              v-else
              color="success"
              text
              :disabled="invalid"
              v-on:click="editFaq()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz pytanie
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BlackButton from "../buttons/BlackButton";
import shared from "../../shared";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";

export default {
  name: "FaqModal",
  props: ["isVisible", "isEdit"],

  components: {
    ValidationObserver,
    BlackButton,
    VTextAreaWithValidation,
  },
  created() {},

  data: () => ({
    question: "",
    answer: "",
    inputKey: 0,
  }),
  computed: {
    faq() {
      return this.$store.getters["faq/getFaq"];
    },
  },
  watch: {
    faq: function () {
      this.question = this.faq.question;
      this.answer = this.faq.answer;
    },
  },
  methods: {
    formatRole(role) {
      return shared.formatRole(role);
    },
    closeModal() {
      this.question = "";
      this.answer = "";
      this.inputKey++;
      this.$refs.obs.reset();
      this.$emit("closeModal");
    },
    async addFaq() {
      await this.$store.dispatch("faq/addFaq", {
        question: this.question,
        answer: this.answer,
      });
      this.closeModal();
    },
    async editFaq() {
      await this.$store.dispatch("faq/editFaq", {
        id: this.faq.id,
        data: {
          question: this.question,
          answer: this.answer,
        },
      });
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 1%;
  right: 3%;
  z-index: 10000000;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
</style>
