<template>
  <v-card-actions class="justify-center flex-column my-8" v-if="calculation">
    <YellowButton
      text="Podgląd"
      width="80%"
      icon="search"
      iconColor="white"
      class="mb-5"
      @click.native="preview()"
      :disabled="loading"
    />
    <YellowButton
      text="Pobierz jpg"
      width="80%"
      icon="image"
      iconColor="white"
      class="mb-5"
      @click.native="downloadJpg()"
      :disabled="loading"
    />

    <YellowButton
      text="Pobierz pdf"
      width="80%"
      icon="file-pdf"
      iconColor="white"
      class="mb-5"
      @click.native="downloadPdf()"
      :disabled="loading"
    />
    <YellowButton
      v-if="calculation.customer && calculation.customer.phone"
      text="Zadzwoń"
      :disabled="!calculation.customer || loading"
      :href="getPhoneNumber()"
      icon="phone-alt"
      iconColor="white"
      width="80%"
      class="mb-5"
    />
    <YellowButton
      v-else
      text="Zadzwoń"
      :disabled="!calculation.customer || loading"
      @click.native="showPhoneMessage()"
      icon="phone-alt"
      iconColor="white"
      width="80%"
      class="mb-5"
    />
    <YellowButton
      text="Wyślij maila"
      :disabled="!calculation.customer || loading"
      icon="envelope"
      iconColor="white"
      width="80%"
      @click.native="sendEmail()"
      class="mb-5"
    />

    <YellowButton
      text="Udostępnij"
      :disabled="!webShareApiSupported || loading"
      icon="share"
      iconColor="white"
      width="80%"
      @click.native="share()"
    />
    <small v-if="!webShareApiSupported" class="mt-2 text-center w-80">
      Przycisk udostępnij jest kompatybilny z przeglądarkami: Edge, Safari.
      Kompatybilność z przeglądarkami mobile: Chrome Andriod, Firefox Android,
      Opera Android, Safari iOS, Samsung Internet"</small
    >
  </v-card-actions>
  <div v-else></div>
</template>
<script>
import { URL } from "../config";
import router from "../router";
import YellowButton from "./buttons/YellowButton.vue";
export default {
  props: {
    calculation: {
      type: Object,
      required: true,
    },
  },
  components: { YellowButton },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async preview() {
      try {
        this.loading = true;
        const fileNames = await this.$store.dispatch(
          "calculation/getCalculationJpgPaths",
          {
            id: this.calculation.id,
          }
        );

        let jpgPaths = [];
        for (const fileName of fileNames) {
          const jphPath = `${URL}/calculations/${
            this.calculation.id
          }/jpg/${fileName}?d=${new Date().getTime()}`;
          jpgPaths.push(jphPath);
        }

        this.$store.dispatch("publics/setPdfPreviewUrls", jpgPaths);
        router.push({
          name: "pdf-preview",
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async downloadJpg() {
      try {
        this.loading = true;
        const fileNames = await this.$store.dispatch(
          "calculation/getCalculationJpgPaths",
          {
            id: this.calculation.id,
          }
        );
        for await (let fileName of fileNames) {
          await this.$store.dispatch("calculation/downloadCalculationImage", {
            calculationId: this.calculation.id,
            jpgFilename: fileName,
            productName: `kalkulacja`,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async downloadPdf() {
      try {
        this.loading = true;
        await this.$store.dispatch("calculation/downloadPdf", {
          id: this.calculation.id,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    getPhoneNumber() {
      if (this.calculation.customer && this.calculation.customer.phone) {
        return "tel:" + this.calculation.customer.phone;
      } else {
        return "";
      }
    },
    showPhoneMessage() {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "red",
          icon: "exclamation-triangle",
          message: "Klient nie ma przypisanego numeru!",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    },
    async sendEmail() {
      if (!this.calculation?.customer?.email) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Klient nie ma przypisanego adresu email!",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        return;
      }
      try {
        this.loading = true;
        await this.$store.dispatch(
          "calculation/sendEmail",
          this.calculation.id
        );
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    shareSnackbar() {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "red",
          icon: "exclamation-triangle",
          message:
            "Nie udało się udostępnić danych. Kompatybilność z przeglądarkami: Chrome, Edge, Safari. Kompatybilność z przeglądarkami mobile: Chrome Andriod, Firefox Android, Opera Android, Safari iOS, Samsung Internet",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    },
    async share() {
      if (navigator.share) {
        await this.$store.dispatch("calculation/downloadPdf", {
          id: this.calculation.id,
          onlyFilePath: true,
        });
        try {
          await navigator.share({
            title: "Oferta produktów - ForMYever Office",
            text: "Dzień dobry,\nW załączonym linku znajduje się oferta.\n\nPozdrawiam",
            url: URL + "/" + this.calculationPdfPath,
          });
        } catch (error) {
          // this.shareSnackbar();
        }
      } else {
        this.shareSnackbar();
      }
    },
  },
  computed: {
    calculationPdfPath() {
      return this.$store.getters["calculation/getCalculationPdfPath"];
    },
    webShareApiSupported() {
      return navigator.share;
    },
  },
};
</script>