var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticStyle:{"z-index":"9999"},attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"320px","overlay-opacity":"1","transition":"slide-y-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModal()},"click:outside":_vm.closeModal},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-card',[_c('v-toolbar',{staticClass:"black--text d-flex justify-center",attrs:{"color":"#fbc501","tile":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.multiple && _vm.selectedDate && _vm.isEdit ? "Edytuj listę zadań" : _vm.multiple && _vm.selectedDate ? "Kopiuj listę" : _vm.multiple ? "Dodaj listę" : _vm.isEdit ? "Edytuj zadanie" : _vm.isCopying ? "Kopiuj zadanie" : "Dodaj zadanie"))])],1),_c('BlackButton',{staticClass:"close",attrs:{"fab":"","small":"","icon":"times","iconMargin":""},nativeOn:{"click":function($event){return _vm.closeModal()}}}),_c('ValidationObserver',{ref:"obs",attrs:{"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',{staticClass:"mb-0 pb-0"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-date-picker',{staticClass:"mt-0 pt-0 mb-0 pb-0",staticStyle:{"height":"260px"},attrs:{"first-day-of-week":"1","color":"yellow","full-width":"","no-title":"","disabled":_vm.task &&
                    _vm.task.tasks &&
                    _vm.task.tasks.length > 0 &&
                    _vm.isEdit &&
                    _vm.multiple,"disabled-date":_vm.disabledAfterToday},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),(!_vm.multiple)?_c('div',[_c('v-row',{staticClass:"align-baseline"},[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"11"}},[_c('v-textarea',{attrs:{"label":"Zadanie","color":"indigo","auto-grow":"","rows":"1","maxlength":"255","outlined":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('font-awesome-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"star","color":_vm.isPriority ? '#fbc501' : 'black'},on:{"click":function($event){return _vm.setTaskIsPriority()}}})],1)],1)],1):_vm._e(),(_vm.multiple)?_c('div',[_vm._l((_vm.titles),function(title,index){return _c('v-row',{key:("title_" + index),staticClass:"align-baseline"},[_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"11","sm":"11"}},[_c('v-textarea',{attrs:{"label":("Zadanie nr " + (index + 1)),"color":"indigo","auto-grow":"","rows":"1","maxlength":"255","outlined":""},model:{value:(_vm.titles[index]),callback:function ($$v) {_vm.$set(_vm.titles, index, $$v)},expression:"titles[index]"}})],1),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":"1","sm":"1"}},[_c('font-awesome-icon',{staticClass:"ml-3",staticStyle:{"cursor":"pointer"},attrs:{"icon":"star","color":_vm.priorityIndex && _vm.priorityIndex.includes(index)
                        ? '#fbc501'
                        : 'black'},on:{"click":function($event){return _vm.setPriorityIndex(index)}}})],1)],1)}),_c('v-row',[_c('yellow-button',{staticClass:"mr-4 mt-2",attrs:{"text":"Dodaj kolejne zadanie","icon":"plus","xsmall":"","width":'100%'},nativeOn:{"click":function($event){return _vm.addNextTask()}}})],1)],2):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"mt-0 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":_vm.closeModal}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"times"}}),_vm._v(" Anuluj ")],1),(!_vm.isEdit)?_c('v-btn',{attrs:{"color":"success","text":"","disabled":invalid},on:{"click":function($event){return _vm.addTask()}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"check"}}),_vm._v(" "+_vm._s(_vm.multiple && _vm.selectedDate ? "Kopiuj listę" : _vm.multiple ? "Dodaj listę" : _vm.isCopying ? "Kopiuj zadanie" : "Dodaj zadanie")+" ")],1):_c('v-btn',{attrs:{"color":"success","text":"","disabled":invalid},on:{"click":function($event){return _vm.editTask()}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"check"}}),_vm._v(" "+_vm._s(_vm.multiple && _vm.selectedDate ? "Edytuj listę" : _vm.multiple ? "Zapisz listę" : "Edytuj zadanie")+" ")],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }