<template>
  <div style="margin-top: 0.5rem; padding: 0">
    <quill-editor
      ref="myQuillEditor"
      @change="onEditorChange($event)"
      style="width: 100%; margin-bottom: 1rem; padding: 0"
      :content="content"
      :id="editorId"
    >
    </quill-editor>
  </div>
</template>
<script>
export default {
  props: ["startContent", "editorId"],
  data() {
    return {
      content: "",
    };
  },
  created() {
    if (this.startContent.length > 0) this.content = this.startContent;
  },

  watch: {
    startContent() {
      if (this.startContent.length > 0) this.content = this.startContent;
    },
  },

  methods: {
    onEditorChange({ html }) {
      if (html !== null && html !== undefined) {
        let htmlWithReplacedCharacters = html;
        if (html) {
          html.replaceAll("<p>", "").replaceAll("</p>", "");
          htmlWithReplacedCharacters = html;
        }

        this.$emit("contentchanged", htmlWithReplacedCharacters);
      }
    },
  },

  computed: {
    editor() {
      if (this.$refs.myQuillEditor) {
        return this.$refs.myQuillEditor.quill;
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
.a {
  text-align: right;
}
</style>
