<template>
  <div class="main">
    <!-- NAV -->

    <div
      class="navigation font"
      :class="showNav ? 'show' : ''"
      style="font-size: 20px"
    >
      <div class="navigation__logo">
        <img class="img" src="../assets/logo.svg" alt="Logo formyever" />
      </div>
      <div class="navigation__bars">
        <font-awesome-icon
          @click="showNavigation(), (navClosed = false)"
          :class="{ 'burger--show': navClosed }"
          class="burger"
          icon="bars"
        />
        <font-awesome-icon
          @click="showNavigation(), (navClosed = true)"
          :class="{ 'burger--show': !navClosed }"
          class="burger"
          icon="times"
        />
      </div>
      <div @click="scrollTo('#section-one')" class="navigation__item">
        <div class="item--hover">O aplikacji</div>
      </div>
      <div @click="scrollTo('#section-two')" class="navigation__item">
        <div class="item--hover">Korzyści</div>
      </div>
      <div @click="scrollTo('#section-three')" class="navigation__item">
        <div class="item--hover">Funkcjonalności</div>
      </div>
      <div
        class="navigation__item"
        :style="
          this.$vuetify.breakpoint.smAndDown
            ? 'display: none;'
            : 'display:block'
        "
      >
        <img src="../assets/logo.svg" alt="Logo formyever" />
      </div>
      <div @click="scrollTo('#section-payment')" class="navigation__item">
        <div class="item--hover">Cennik</div>
      </div>
      <div @click="scrollTo('#section-contact')" class="navigation__item">
        <div class="item--hover">Skontaktuj się z nami</div>
      </div>
      <div class="navigation__button">
        <router-link to="/entry">
          <!-- <div v-if="this.$vuetify.breakpoint.smAndDown">
            <font-awesome-icon
              style="cursor:pointer; color:#fcc502;"
              icon="sign-in-alt"
              size="2x"
            />
          </div> -->
          <button
            class="button button--outline-yellow"
            :class="{ buttonStyling: $vuetify.breakpoint.smAndDown }"
          >
            Zaloguj się
          </button>
        </router-link>
      </div>
    </div>

    <!-- BANNER -->
    <div class="banner">
      <div class="banner__content">
        <h1>organizacja biznesu forever</h1>
        <h5>w jednym miejscu</h5>
        <div class="banner__button">
          <button
            @click="$router.push({ name: 'entry', query: { register: 1 } })"
            class="button--yellow button"
          >
            Wypróbuj bezpłatnie
          </button>
        </div>
      </div>
    </div>
    <div class="text-center pt-2 banner__arrow--container">
      <img
        @click="scrollTo('#section-one')"
        class="banner__arrow--img"
        src="../assets/lp_arrow.png"
        alt=""
      />
    </div>

    <!-- SECTION I -->
    <div class="section-one" id="section-one">
      <v-row no-gutters>
        <v-col cols="12" md="6" class="section-one__phone">
          <img src="../assets/lp_phone.png" alt="Aplikacja" />
        </v-col>
        <v-col cols="12" md="6" class="section-one__desc">
          <div class="section-one__content">
            <div class="section-one__title">
              <h1>czym jest aplikacja</h1>
              <h5>ForMyEver office?</h5>
            </div>
            <p>
              To nowoczesne narzędzie wspierające budowanie Twojego biznesu
              Forever.
            </p>
            <p>
              Sprawdź funkcje aplikacji, dzięki której uporządkujesz swoje
              kontakty, przygotujesz ofertę bez potrzeby zerkania w papierowy
              cennik oraz określisz wysokość przyszłej prowizji zgodnie z obecną
              pozycją w planie marketingowym.
            </p>
            <ul>
              <li>moje biuro</li>
              <li>kalkulator ofert</li>
              <li>symulacja prowizji</li>
              <li>katalog produktów</li>
            </ul>
            <p>I to jeszcze nie wszystko!</p>
            <div class="button-center">
              <button
                @click="$router.push({ name: 'entry', query: { register: 1 } })"
                class="button button--yellow"
              >
                Wybróbuj bezpłatnie
              </button>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- SECTION II  -->
    <div class="section-two" id="section-two">
      <v-row class="responsive-4">
        <v-col cols="12" sm="6" md="6" lg="3" class="section-two__item item">
          <img
            class="item__icon"
            src="../assets/lp_icon1.png"
            alt="Łatwa obsługa"
          />
          <h2 class="item__title">łatwa obsługa</h2>
          <p class="item__desc">
            Aplikacja jest przejrzysta dzięki czemu z łatwością zbudujesz tam
            swoje wirtualne biuro a praca stanie się jeszcze przyjemniejsza.
            Funkcje aplikacji pozwolą Ci w prosty sposób zorganizować
            najważniejsze zadania biznesu Forever.
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="section-two__item item">
          <img
            class="item__icon"
            src="../assets/lp_icon2.png"
            alt="Kalendarz"
          />
          <h2 class="item__title">kalendarz</h2>
          <p class="item__desc">
            Masz do dyspozycji kalendarz, w którym możesz zaplanować umówione
            spotkania czy telefony do wykonania. Nasza aplikacja przypomni Ci o
            tym poprzez powiadomienie i porannego maila z lista kontaktów.
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="section-two__item item">
          <img
            class="item__icon"
            src="../assets/lp_icon3.png"
            alt="Zawsze przy tobie"
          />
          <h2 class="item__title">zawsze przy tobie</h2>
          <p class="item__desc">
            Zakładasz konto raz i korzystasz gdzie chcesz. Aplikacja działa na
            wszystkich urządzeniach z dostępem do przeglądarki. Korzystaj z
            ForMYever Office gdzie Ci wygodniej; na telefonie, tablecie i
            komputerze.
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="section-two__item item">
          <img
            class="item__icon"
            src="../assets/lp_icon4.png"
            alt="Lista zakupów"
          />
          <h2 class="item__title">lista zakupów</h2>
          <p class="item__desc">
            Zapomnij o papierowym cenniku i liście zakupów na stosie karteczek.
            Teraz wygodnie dodasz każdy produkt bezpośrednio do listy w Twojej
            aplikacji, żeby już więcej o niczym nie zapomnieć. Lista ma
            podsumowanie, gdzie jeszcze przed złożeniem zamówienia widzisz
            punkty CC i cenę po której kupujesz w hurtowni.
          </p>
        </v-col>
      </v-row>
    </div>
    <!-- SECTION III  -->
    <div class="section-three" id="section-three">
      <div class="section-three__title">
        <h1>funkcjonalności</h1>
        <h5>aplikacji</h5>
      </div>
      <div class="section-three__item carousel">
        <v-carousel
          :height="height"
          :dark="false"
          :light="true"
          :show-arrows="false"
        >
          <v-carousel-item v-for="(item, i) in items" :key="i">
            <div class="carousel__container">
              <v-row>
                <v-col cols="12" md="6">
                  <div class="carousel__image">
                    <img :src="item.src" alt="" />
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="carousel__content">
                    <h1>{{ item.title }}</h1>
                    <p>{{ item.desc1 }}</p>
                    <p>{{ item.desc2 }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>

    <!-- RECOMENDATION PAYMENT  -->
    <div class="section-payment" id="recommendations-payment">
      <v-container>
        <v-row class="responsive-4">
          <recommendations />
        </v-row>
      </v-container>
    </div>

    <!-- SECTION PAYMENT  -->
    <div class="section-payment grey-background" id="section-payment">
      <div class="section-payment__title">
        <h1>plany</h1>
        <h5>aplikacji</h5>
      </div>
      <v-container>
        <v-row class="responsive-4">
          <v-col
            v-for="(item, index) in payments"
            :key="index"
            cols="12"
            sm="6"
            lg="3"
            class="section-payment__item"
          >
            <div
              class="item"
              :class="item.yellow_background ? 'bg-yellow' : 'bg-white'"
            >
              <small v-if="item.discount" class="item__promotion"
                >PROMOCJA</small
              >
              <p
                :class="
                  item.yellow_background
                    ? 'item__desc--uppercase'
                    : 'item__desc--capitalize'
                "
                class="item__desc"
              >
                {{ item.desc }}
              </p>
              <div v-if="item.discount" class="item__price strikethrough">
                <span class="item__price--small" v-if="item.month"
                  >{{ item.price }} / MIESIĄC</span
                >
              </div>
              <div class="item__price">
                <span
                  :class="
                    item.yellow_background
                      ? 'item__price--black'
                      : 'item__price--yellow'
                  "
                  class="item__price--big"
                  >{{ item.discount ? item.discount : item.price }}
                </span>
                <span class="item__price--small" v-if="item.month"
                  >/ MIESIĄC</span
                >
              </div>
              <div v-if="!item.yellow_background" class="item__price--summary">
                Całkowita kwota do zapłaty
                {{ item.discount ? item.totalDiscount : item.totalPrice }} zł
                <span v-if="item.discount"
                  >/ zamiast {{ item.totalPrice }} zł</span
                >
              </div>
              <button
                @click="
                  !token
                    ? $router.push({ name: 'entry', query: { register: 1 } })
                    : $router.push('/subscription'),
                    setSubscription()
                "
                :class="
                  item.yellow_background
                    ? 'button--white-shadow'
                    : 'button--white'
                "
                class="item__button button"
              >
                {{ item.button }}
              </button>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- SECTION CONTACT -->
    <div class="section-contact" id="section-contact">
      <v-container>
        <v-row class="section-contact__row">
          <v-col cols="12" md="6">
            <div class="section-contact__desc item">
              <div class="item__title">
                <h1>masz pytania?</h1>
                <h5>Skontaktuj sie z nami!</h5>
              </div>
              <p class="item__desc">
                Tworzenie aplikacji sprawiło nam wielką radość. Wierzymy, że to
                co przekazujemy do Twojego użytku wesprze Cię i pomoże, tak jak
                pomogło nam, w planowaniu i rozwoju biznesu, snuciu marzeń i ich
                realizacji. Czego z całego serducha Ci życzymy.
              </p>
              <p class="item__desc">
                Będzie nam niezmiernie miło towarzyszyć Ci w drodze po kolejne
                awanse, czy choćby podczas odkrywania kolejnych bestsellerów
                Forever.
              </p>
              <p class="item__desc">
                Jeżeli ciekawi Cię co jeszcze mamy dla Ciebie w środku,
                zarejestruj się, bo przez pierwsze 7 dni masz pełny dostęp do
                wszystkich funkcji. Tam znajdziesz też odpowiedzi na najczęściej
                zadawane pytania.
              </p>
              <p class="item__desc">
                Jeżeli jednak w tym momencie coś spędza Ci sen z powiek po
                prostu napisz do nas za pomocą tego formularza.
              </p>
              <p class="item__desc">Pozostańmy w kontakcie!</p>
              <p class="item__desc">Zespół ForMYever Office</p>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="pt-8">
            <div class="section-contact__form item">
              <ValidationObserver ref="obs" v-slot="{ invalid, validate }">
                <div style="width: 100%">
                  <div class="mb-2">
                    <VTextFieldWithValidation
                      rules="required"
                      v-model="fullname"
                      placeholder="Twoje imię i nazwisko"
                      outlined
                      dense
                    />
                  </div>
                  <div class="mb-2">
                    <VTextFieldWithValidation
                      rules="required|email"
                      placeholder="Twój adres e-mail"
                      v-model="email"
                      outlined
                      dense
                    />
                  </div>
                  <div class="mb-2">
                    <VTextFieldWithValidation
                      rules="required|onlyNumbers"
                      placeholder="Twój numer telefonu"
                      v-model="phone"
                      outlined
                      dense
                    />
                  </div>
                  <div class="mb-2">
                    <VTextAreaWithValidation
                      rules="required|max:200"
                      placeholder="Wpisz treść wiadomości"
                      v-model="message"
                      outlined
                      dense
                    />
                  </div>
                  <div class="checkbox">
                    <VCheckboxWithValidation
                      rules="checkbox"
                      ref="input"
                      @change="
                        agreement === null
                          ? (agreement = true)
                          : (agreement = null)
                      "
                      v-model="agreement"
                    >
                      <template #label>
                        Wyrażam zgodę na przetwarzanie moich danych osobowych
                        podanych w formularzu kontaktowym i kontakt ze mną w
                        odpowiedzi na moją wiadomość, zgodnie z
                        <a
                          target="_blank"
                          href="/privacy"
                          @click.stop
                          v-on="on"
                        >
                          Polityką Prywatności
                        </a>
                        . Administratorem danych zawartych w korespondencji
                        e-mail jest NDM Concept Sp. z o.o. NIP 5252845159 -
                        właściciel aplikacji ForMyever Office.
                      </template>
                    </VCheckboxWithValidation>
                  </div>

                  <div class="text-right">
                    <!-- <YellowButton
                      class="mt-2"
                      @click.native="validate().then(sendQuestion(invalid))"
                      type="submit"
                      text="Wyślij wiadomość"
                    /> -->
                    <button
                      @click="validate().then(sendQuestion(invalid))"
                      type="submit"
                      class="button--yellow button"
                    >
                      Wyślij wiadomość
                    </button>
                  </div>
                </div>
              </ValidationObserver>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- SECTION FOOTER -->
    <!-- <footer class="footer" id="footer">
      <div class="footer--yellow">
        <v-row class="footer__first-row">
          <v-col cols="12" sm="6" md="3"
            ><img src="../assets/logowhite.svg" alt=""
          /></v-col>
          <v-col cols="12" sm="6" md="3">
            <h5>Kontakt z ForMyEverOffice:</h5>
            <span>
              <span class="white--text pr-2 "
                ><font-awesome-icon icon="phone-alt"
              /></span>
              <span class="mr-5">(+48) 22 456 43 56</span>
            </span>
            <br />
            <span>
              <span class="white--text pr-2"
                ><font-awesome-icon icon="envelope"
              /></span>
              <span>adres@forever.com</span>
            </span>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <h5>Śledź nas:</h5>
            <div class="icons">
              <font-awesome-icon class="mr-3" :icon="['fab', 'facebook']" />
              <font-awesome-icon :icon="['fab', 'instagram']" />
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <h5>Odwiedź strone:</h5>
            <a target="_blank" href="https://foreverliving.com/"
              >https://foreverliving.com/</a
            >
          </v-col>
        </v-row>
      </div>
      <v-row class="footer__second-row">
        <v-col cols="12" md="6" class="footer__second-row--left"
          ><small>Created by <u>NDM Concept</u> Sp. z o.o.</small>
        </v-col>
        <v-col cols="12" md="6" class="footer__second-row--right"
          ><small>All rights reserved</small>
        </v-col>
      </v-row>
    </footer> -->
  </div>
</template>

<script>
import VTextFieldWithValidation from "../components/inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../components/inputs/VTextAreaWithValidation";
import VCheckboxWithValidation from "../components/inputs/VCheckboxWithValidation";
import { ValidationObserver } from "vee-validate";
import Recommendations from "../components/Recommendations.vue";
// import YellowButton from "../components/buttons/YellowButton";
export default {
  components: {
    VTextFieldWithValidation,
    VTextAreaWithValidation,
    ValidationObserver,
    // YellowButton,
    VCheckboxWithValidation,
    Recommendations,
  },
  async created() {
    await this.$store.dispatch("payment/fetchPaymentOptions");
    await this.$store.getters["payment/getPaymentOptions"].forEach((item) => {
      const month =
        item.numberOfMonth === 1
          ? "miesiąc"
          : [2, 3, 4].includes(item.numberOfMonth)
          ? "miesiące"
          : "miesięcy";
      const popular = item.numberOfMonth === 6 ? true : false;
      const itemDiscount = item.discount ? Number(item.discount) : 0;
      let temp = {
        id: item.id,
        desc: `abonament na ${item.numberOfMonth} ${month}`,
        price: parseFloat(item.price / item.numberOfMonth).toFixed(2),
        totalPrice: item.price,
        totalDiscount: itemDiscount,
        discount: itemDiscount
          ? parseFloat(itemDiscount / item.numberOfMonth).toFixed(2)
          : null,
        month: true,
        button: "Kupuję",
        popular,
        yellow_background: false,
      };
      this.payments.push(temp);
    });
  },
  methods: {
    setSubscription() {
      localStorage.setItem("subscription", 1);
    },
    showNavigation() {
      this.showNav = !this.showNav;
      if (this.showNav) this.navClass = "show";
      else this.navClass = "hide";
    },
    async sendQuestion(invalid) {
      if (invalid) {
        this.ref?.input.reset();
        return;
      } else {
        let object = {
          text: this.message,
          email: this.email,
          fullname: this.fullname,
          phone: this.phone,
        };
        await this.$store.dispatch("publics/sendQuestion", object);
        this.$refs.obs.reset();
        this.message = "";
        this.email = "";
        this.fullname = "";
        this.phone = "";
        this.agreement = false;
      }
    },
    scrollTo(to) {
      this.navClosed = true;
      this.$router.replace({ hash: "" });
      this.$router.replace({ hash: to });
      if (this.showNav) this.showNav = !this.showNav;
    },
  },
  data() {
    return {
      navClosed: true,
      fullname: "",
      agreement: false,
      email: "",
      message: "",
      navClass: "",
      showNav: false,
      phone: "",
      payments: [
        {
          desc: "pierwsze 7 dni",
          price: "BEZPŁATNIE",
          month: false,
          button: "Wybróbuj bezpłatnie",
          popular: true,
          yellow_background: true,
        },
      ],
      items: [
        {
          title: "MOJE BIURO",
          desc1:
            "Wszyscy Twoi klienci w jednym miejscu! Lista klientów oraz historia ofert. Dane kontaktowe do biura Forever. Wszystko czego potrzebujesz w jednym miejscu. To tutaj stworzysz listę zakupów dla siebie i klientów.",
          desc2: "",
          src: require("../assets/my-officex2.png"),
        },
        {
          title: "KALKULATOR OFERT",
          desc1:
            "Zapomnij o papierowym cenniku i mało profesjonalnej ofercie na kartce! Elektroniczny kalkulator cen i punktów w krótkim czasie podsumuje ofertę z wybraną zniżką dla klienta, którą wyślesz jednym kliknięciem przez mail,messenger lub SMS. System wyliczy Ci też od razu Twój zysk ze sprzedaży detalicznej.",
          desc2: "",
          src: require("../assets/calculatex2.png"),
        },
        {
          title: "SYMULATOR PROWIZJI",
          desc1:
            "Odkryj plan marketingowy w nieco przyjemniejszej postaci. Twórz symulację zarobków na kolejne miesiące w różnych opcjach awansu. Zobacz te kwoty na własne oczy, nawet wtedy, gdy plan marketingowy jeszcze nie mieści się w małym palcu.  Aplikacja wyliczy Ci w przybliżeniu jaką otrzymasz prowizję przy podanych obrotach.",
          desc2: "",
          src: require("../assets/simulationx2.png"),
        },
      ],
    };
  },
  computed: {
    token() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    height() {
      let value;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          value = 600;
          break;
        case "sm":
          value = 700;
          break;
        case "md":
          value = 400;
          break;
        case "lg":
          value = 500;
          break;
        case "xl":
          value = 600;
          break;
      }
      return value;
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.buttonStyling {
  margin-right: -5px;
  padding: 8px 20px !important;
  font-size: 15px !important;
}

$navbar-height: 75px;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.responsive-4 {
  max-width: 1600px;
  margin: auto;
  padding: 0 40px;
  justify-content: center;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .responsive-4 {
    padding: 0 10px;
  }
}

.icons {
  color: white;
  font-size: 24px;
}

.navigation {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: $navbar-height;
  background-color: white;
  z-index: 1;
  &__button {
    position: absolute;
    top: 10px;
    right: 1%;
  }
  &__bars {
    display: none;
  }
  &__logo {
    display: none;
  }

  &__item {
    &:nth-last-child(2) {
      margin-right: 12%;
    }
    cursor: pointer;
    align-self: center;
    img {
      width: 140px;
    }

    .item {
      &--hover {
        &:hover {
          border-bottom: solid 2px #fcc502;
        }
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .navigation {
    background-color: rgba(255, 255, 255, 0.514);
    &__button {
      position: absolute;
      top: 12px;
      right: 75px;
    }
  }
  .show.navigation {
    flex-direction: column;
    height: 100%;
    padding-top: 80px;
    background-color: rgb(255, 255, 255);
    z-index: 100;
    .navigation__item {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 700;

      img {
        display: none;
      }
    }
  }
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $navbar-height;

    &__item {
      &:nth-last-child(2) {
        margin-right: 0;
      }
      display: none;
    }
    &__bars {
      position: absolute;
      top: 12px;
      right: 0;
      display: inline-block;
      margin: auto;
      margin-right: 20px;

      .icon {
        color: black;
        font-size: 40px;
      }
    }
    &__logo {
      position: absolute;
      top: 12px;
      left: 0;
      display: inline-block;
      margin: auto;
      margin-left: 20px;
      cursor: pointer;
      .img {
        width: 130px;
      }
    }
  }
}

.button {
  padding: 10px 28px;
  color: black;
  border-radius: 26px;
  font-weight: 700;
  transition-duration: 0.5s;
  &--outline-yellow {
    background-color: white;
    border: 2px solid #fcc502;
    &:hover {
      background-color: #fcc502;
    }
  }
  &--yellow {
    background-color: #fcc502;
    border: 2px solid #fcc502;
    -webkit-box-shadow: 5px 7px 21px -8px rgba(0, 0, 0, 0.44);
    box-shadow: 5px 7px 21px -8px rgba(0, 0, 0, 0.44);
  }
  &--white {
    background-color: white;

    border: 2px solid #fcc502;
    &:hover {
      background-color: #fcc502;
    }
  }
  &--white-shadow {
    -webkit-box-shadow: 5px 7px 21px -8px rgba(0, 0, 0, 0.44);
    box-shadow: 5px 7px 21px -8px rgba(0, 0, 0, 0.44);
    background-color: white;

    border: none;
    &:hover {
      background-color: #fcc502;
    }
  }
}

.banner {
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.844),
      rgba(167, 167, 167, 0.312),
      rgba(223, 223, 223, 0.11),
      rgba(167, 167, 167, 0.312),
      rgba(0, 0, 0, 0.844)
    ),
    url("../assets/lp_background.png");
  background-size: cover;
  background-position: 55%;
  width: 100%;
  margin: 0;
  height: calc(100vh - #{$navbar-height * 2});
  position: relative;
  &__arrow {
    &--container {
      height: $navbar-height;
    }
    &--img {
      cursor: pointer;
    }
  }
  &__content {
    position: absolute;
    top: 40%;
    left: 10%;
    max-width: 460px;

    h1 {
      font-family: "BebasNeue", sans-serif !important;
      text-align: left;
      -webkit-text-stroke: 1px black; /* width and color */
      color: white;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 78px;
      line-height: 90%;
    }
    h5 {
      text-align: right;
      font-size: 42px;
      -webkit-text-stroke: 1px black; /* width and color */
      font-weight: 400;
      color: #fcc502;
      transform: translateY(-24px);
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-top: $navbar-height;

    height: 92vh;

    &__arrow--container {
      display: none;
    }
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.844),
        rgba(107, 107, 107, 0.312),
        rgba(223, 223, 223, 0.11),
        rgba(107, 107, 107, 0.312),
        rgba(0, 0, 0, 0.844)
      ),
      url("../assets/lp_background.png");
    &__content {
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      max-width: 330px;
      h1 {
        font-size: 55px;
      }
      h5 {
        font-size: 30px;
        margin-right: 15px;
      }
    }
    &__button {
      text-align: center;
    }
  }
}

.section-one {
  position: relative;
  &__phone {
    text-align: right;
    img {
      width: 100%;
    }
  }

  &__title {
    min-width: 330px;
    margin-bottom: 28px;

    h1 {
      text-transform: uppercase;
      font-family: "BebasNeue", sans-serif !important;
      font-weight: 400;
      line-height: 12px;
      font-size: 46px;
    }
    h5 {
      color: #fcc502;
      text-align: right;
      font-size: 33px;
      font-weight: 400;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      position: absolute;
      top: 35px;
      left: 50%;
      transform: translateX(-50%);
      h1 {
        text-align: center;
        font-size: 36px;
      }
      h5 {
        text-align: center;
        font-size: 23px;
      }
    }
  }
  &__content {
    padding: 0 30px;
  }
  &__desc {
    margin-top: 10%;
    margin-left: 5%;
    max-width: 530px;

    ul {
      list-style-image: url("../assets/lp_list-image.png");
      margin: 30px 0;
    }
  }

  @media #{map-get($display-breakpoints, 'xl-only')} {
    &__desc {
      font-size: 22px;
    }
  }

  @media #{map-get($display-breakpoints, 'md-only')} {
    &__desc {
      max-width: 420px;
    }
    &__phone {
      margin-top: 60px;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    &__content {
      width: 100%;
      padding: 26px;
    }
    &__desc {
      margin: 0;
      font-size: 16px;
      max-width: 100%;

      .button-center {
        text-align: center;
      }
    }
    &__phone {
      margin-top: 20%;
    }
    &__title {
      margin-top: 9%;
      min-width: 100%;
      h1 {
        font-size: 33px;
      }
      h5 {
        font-size: 23px;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    &__phone {
      margin-top: 30%;
    }
  }
}

/* SECTION II --- START*/

.section-two {
  background-color: rgba(0, 0, 0, 0.02);

  text-align: center;
  .item {
    padding: 90px;
    &__icon {
      height: 45px;
      margin: 7px 0;
    }
    &__title {
      margin: 18px 0;
      font-family: "BebasNeue", sans-serif !important;
      text-transform: uppercase;
      font-size: 30px;
    }
    &__desc {
      font-size: 12px;
    }
  }
  @media #{map-get($display-breakpoints, 'lg-only')} {
    .item {
      padding: 50px;
    }
  }
  @media #{map-get($display-breakpoints, 'md-only')} {
    .item {
      padding: 30px;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .item {
      padding: 22px;
      &__desc {
        margin: 0 auto;
        max-width: 300px;
      }
    }
  }
}

/* SECTION II --- END*/

/* SECTION III --- START*/
.v-carousel__controls {
  background-color: transparent !important;
}
@media #{map-get($display-breakpoints, 'xl-only')} {
  .v-carousel__controls {
    left: 6% !important;
    top: 55% !important;
  }
}
@media #{map-get($display-breakpoints, 'lg-only')} {
  .v-carousel__controls {
    left: 8% !important;
    top: 60% !important;
  }
}
@media #{map-get($display-breakpoints, 'md-only')} {
  .v-carousel__controls {
    left: 10% !important;
    top: 63% !important;
  }
}
.section-three {
  &__title {
    width: 470px;
    margin: 60px auto 30px auto;
    h1 {
      font-family: "BebasNeue", sans-serif !important;
      text-align: left;
      color: black;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 78px;
      line-height: 90%;
    }
    h5 {
      text-align: right;
      font-size: 42px;
      font-weight: 400;
      color: #fcc502;
      transform: translateY(-24px);
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    &__title {
      max-width: 250px;
      h1 {
        font-size: 42px;
      }
      h5 {
        font-size: 22px;
        transform: translateY(-14px);
      }
    }
  }
  .carousel {
    &__image {
      text-align: right;
      img {
        object-fit: cover;
        max-height: 400px;
        width: 700px;
      }
      @media #{map-get($display-breakpoints, 'xl-only')} {
        img {
          margin-top: 60px;
          max-height: 300px;
          width: 550px;
        }
      }
      @media #{map-get($display-breakpoints, 'lg-only')} {
        img {
          margin-top: 60px;
          max-height: 300px;
          width: 550px;
        }
      }
      @media #{map-get($display-breakpoints, 'md-only')} {
        img {
          margin-top: 50px;
          max-height: 250px;
          width: 450px;
        }
      }
      @media #{map-get($display-breakpoints, 'sm-only')} {
        img {
          height: 400px;
          width: 100%;
        }
      }
      @media #{map-get($display-breakpoints, 'xs-only')} {
        img {
          height: 300px;
          width: 100%;
        }
      }
    }
    &__content {
      padding: 0 42px;
      color: black !important;
      h1 {
        font-family: "BebasNeue", sans-serif !important;
      }
      p {
        padding: 10px 0;
        font-size: 12px;
      }
    }
    @media #{map-get($display-breakpoints, 'xl-only')} {
      &__content {
        margin-top: 66px;
        h1 {
          font-size: 32px;
        }
        p {
          font-size: 21px;
        }
      }
    }
    @media #{map-get($display-breakpoints, 'lg-only')} {
      &__content {
        margin-top: 55px;
        h1 {
          font-size: 27px;
        }
        p {
          font-size: 19px;
        }
      }
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      &__content {
        margin-top: 65px;
        h1 {
          font-size: 22px;
        }
        p {
          font-size: 13px;
        }
      }
    }
    @media #{map-get($display-breakpoints, 'sm-only')} {
      &__content {
        h1 {
          font-size: 29px;
        }
        p {
          font-size: 19px;
        }
      }
    }
  }
}

/* SECTION III --- END*/

/* SECTION PAYMENT --- START*/
.bg-yellow {
  background-color: #fcc502;
}

.section-payment {
  padding: 80px 0;

  &__title {
    text-align: center;
    padding: 26px;
    h1 {
      font-family: "BebasNeue", sans-serif !important;

      color: black;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 78px;
      line-height: 90%;
    }
    h5 {
      font-size: 42px;
      font-weight: 400;
      color: #fcc502;
      transform: translateY(-24px);
    }
  }

  .item {
    border: 2px solid#fcc502;
    border-radius: 25px;
    height: 260px;
    max-width: 320px;
    margin: 18px auto;
    text-align: center;
    position: relative;
    &__price {
      font-family: "BebasNeue", sans-serif !important;

      &--big {
        font-size: 45px;
      }
      &--small {
        font-size: 22px;
      }
      &--black {
        color: black;
      }
      &--yellow {
        color: #fcc502;
      }
      &--summary {
        position: absolute;
        bottom: 57px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
      }
    }
    &__desc {
      margin: 50px 0 0 0;
      font-weight: 700;
      &--capitalize::first-letter {
        text-transform: capitalize;
      }
      &--uppercase {
        text-transform: uppercase;
        color: white;
      }
    }
    &__button {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
    &__promotion {
      font-weight: 700;
      color: #fcc502;
      font-size: 30px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
/* SECTION PAYMENT --- END*/

/* SECTION CONTACT --- START*/
.section-contact {
  margin: 40px auto;
  max-width: 1200px;
  .item {
    max-width: 600px;
    margin: auto;
  }
  &__desc {
    padding: 0px 10px 10px 10px;
    .item {
      &__title {
        text-align: left;

        h1 {
          font-family: "BebasNeue", sans-serif !important;
          color: black;
          text-transform: uppercase;
          font-weight: 400;
          font-size: 54px;
        }
        h5 {
          font-size: 32px;
          font-weight: 400;
          color: #fcc502;
          // transform: translateY(-24px);
        }
      }
      &__desc {
        font-size: 12px;
      }
    }
  }
  &__row {
    margin: 0;
  }
}
/* SECTION CONTACT --- END*/

// /* SECTION FOOTER --- START*/
// .footer {
//   &--yellow {
//     background-color: #fcc502;
//   }
//   &__first-row {
//     padding: 50px 0;

//     margin: auto;
//     max-width: 1200px;

//     img {
//       max-width: 160px;
//     }
//     a {
//       color: black !important;
//     }
//   }
//   &__second-row {
//     max-width: 1400px;
//     margin: auto;
//     background-color: white;
//     &--left {
//       text-align: left;
//     }
//     &--right {
//       text-align: right;
//     }
//   }
//   @media #{map-get($display-breakpoints, 'sm-and-down')} {
//     &__first-row {
//       text-align: center;
//     }
//     &__second-row {
//       &--left {
//         text-align: center;
//       }
//       &--right {
//         text-align: center;
//       }
//     }
//   }
// }
// /* SECTION FOOTER --- END*/
.icon {
  font-size: 30px;
  margin: 5px;
}

.burger {
  display: none;
  font-size: 40px;
  &--show {
    display: block;
    animation-duration: 0.8s;
    animation-name: rotate;
  }
}
@keyframes rotate {
  from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(180deg);
  }
}

.strikethrough {
  text-decoration: line-through;
}
</style>
