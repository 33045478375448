<template>
  <v-container fluid class="pa-0">
    <v-navigation-drawer
      class="font"
      :width="$vuetify.breakpoint.name === 'xs' ? '101vw' : '280'"
      color="#fbc501"
      right
      :style="
        $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'xs'
          ? 'margin-top:56px'
          : 'margin-top:64px'
      "
      v-model="drawer"
      fixed
      clipped
      :temporary="false"
      mobile-breakpoint="0"
    >
      <v-list
        nav
        class="bold"
        :class="{
          'text-center': $vuetify.breakpoint.name === 'xs',
        }"
      >
        <v-list-item-group
          active-class="black--text text--accent-4"
          :class="{ 'padding-drawer': $vuetify.breakpoint.name === 'xs' }"
        >
          <v-list-item :to="{ name: 'home' }" @click="drawer = !drawer">
            <v-list-item-title
              :class="{
                'large-font': $vuetify.breakpoint.name === 'xs',
                'normal-font': $vuetify.breakpoint.name != 'xs',
              }"
              >STRONA GŁÓWNA</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            v-if="token.roleId != 6"
            @click="drawer = !drawer"
            :to="{ name: 'recommendations' }"
          >
            <v-list-item-title
              :class="{
                'large-font': $vuetify.breakpoint.name === 'xs',
                'normal-font': $vuetify.breakpoint.name != 'xs',
              }"
              >REKOMENDACJE</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            v-if="token.roleId != 6"
            @click="drawer = !drawer"
            :to="{ name: 'subscription' }"
          >
            <v-list-item-title
              :class="{
                'large-font': $vuetify.breakpoint.name === 'xs',
                'normal-font': $vuetify.breakpoint.name != 'xs',
              }"
              >ABONAMENT</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            v-if="token.roleId != 6"
            @click="drawer = !drawer"
            :to="{ name: 'settings' }"
          >
            <v-list-item-title
              :class="{
                'large-font': $vuetify.breakpoint.name === 'xs',
                'normal-font': $vuetify.breakpoint.name != 'xs',
              }"
              >USTAWIENIA</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="drawer = !drawer" :to="{ name: 'how-use-app' }">
            <v-list-item-title
              :class="{
                'large-font': $vuetify.breakpoint.name === 'xs',
                'normal-font': $vuetify.breakpoint.name != 'xs',
              }"
              >JAK KORZYSTAĆ</v-list-item-title
            >
          </v-list-item>

          <v-list-item @click="drawer = !drawer" :to="{ name: 'help' }">
            <v-list-item-title
              :class="{
                'large-font': $vuetify.breakpoint.name === 'xs',
                'normal-font': $vuetify.breakpoint.name != 'xs',
              }"
              >FAQ</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="drawer = !drawer" :to="{ name: 'contact' }">
            <v-list-item-title
              :class="{
                'large-font': $vuetify.breakpoint.name === 'xs',
                'normal-font': $vuetify.breakpoint.name != 'xs',
              }"
              >KONTAKT</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            @click="drawer = !drawer"
            :to="{ name: 'admin' }"
            v-if="token.roleId === 6"
          >
            <v-list-item-title
              :class="{
                'large-font': $vuetify.breakpoint.name === 'xs',
                'normal-font': $vuetify.breakpoint.name != 'xs',
              }"
              class="break"
              >PANEL ADMINISTRATORA</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            @click="drawer = !drawer"
            :to="{ name: 'add-to-desktop' }"
          >
            <v-list-item-title
              :class="{
                'large-font': $vuetify.breakpoint.name === 'xs',
                'normal-font': $vuetify.breakpoint.name != 'xs',
              }"
              >DODAJ DO PULPITU</v-list-item-title
            >
          </v-list-item>
          <v-list-item :to="{ name: 'landing-page' }">
            <v-list-item-title
              :class="{
                'large-font': $vuetify.breakpoint.name === 'xs',
                'normal-font': $vuetify.breakpoint.name != 'xs',
              }"
              >STRONA STARTOWA</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-on:click="logout()">
            <v-list-item-title
              :class="{
                'large-font': $vuetify.breakpoint.name === 'xs',
                'normal-font': $vuetify.breakpoint.name != 'xs',
              }"
              >WYLOGUJ SIĘ</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
      <v-list nav class="text-center">
        <v-list-item-title
          :class="{
            'xsmall-font': $vuetify.breakpoint.name != 'xs',
          }"
        >
          Potrzebujesz pomocy? </v-list-item-title
        ><v-list-item-title
          :class="{
            'xsmall-font': $vuetify.breakpoint.name != 'xs',
          }"
          >Skontaktuj się!</v-list-item-title
        >
        <v-list-item>
          <v-list-item-title
            class="bold text-decoration-underline"
            :class="{
              'small-font': $vuetify.breakpoint.name === 'xs',
              'xsmall-font': $vuetify.breakpoint.name != 'xs',
            }"
            ><font-awesome-icon
              color="white"
              icon="envelope"
              class="mr-1"
            />kontakt@formyeveroffice.com</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="#fbc501" dark style="z-index: 99" app>
      <router-link to="/home">
        <v-img
          @click="logoClick()"
          style="cursor: pointer"
          class="mx-2 logo"
          src="../assets/logowhite.svg"
          contain
        ></v-img>
      </router-link>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon color="black" right @click="drawer = !drawer">
        <font-awesome-icon
          :class="{ 'burger--show': !drawer }"
          class="burger"
          icon="bars" />
        <font-awesome-icon
          :class="{ 'burger--show': drawer }"
          class="burger"
          icon="times"
      /></v-app-bar-nav-icon>
    </v-app-bar>
  </v-container>
</template>

<script>
export default {
  name: "NavigationDrawer",

  created() {},

  data: () => ({
    drawer: false,
  }),
  watch: {
    click() {
      this.drawer = false;
    },
  },

  computed: {
    token() {
      return this.$store.getters["auth/getToken"];
    },
    click() {
      return this.$store.getters["publics/getClick"];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    onClickOutside() {
      if (this.drawer) {
        this.drawer = false;
      }
    },
    logoClick() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss">
.v-list-item__icon,
.v-list-item__icon.v-list-group__header__prepend-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

nav:not(.v-navigation-drawer--mini-variant) .v-list .v-list-group__items {
  padding-left: 1rem;
  margin-left: 1.25rem;
  border-left: 1px solid white;
}

nav:not(.v-navigation-drawer--mini-variant)
  .v-list
  .v-list-group__items
  .v-list-item__icon {
  margin-right: 10px;
}
.bold {
  font-weight: bold;
}
.large-font {
  font-size: 40px !important;
}
.padding-drawer {
  padding-top: 50px !important;
}
.theme--light.v-navigation-drawer .v-divider {
  border: 2px white solid !important;
  margin-left: auto;
  margin-right: auto;
}
.break {
  white-space: normal !important;
}
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .theme--light.v-navigation-drawer .v-divider {
    margin-top: 30px;
    border: 2px white solid !important;
    width: 60% !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.logo {
  width: 130px;
}
.normal-font {
  font-size: 1.6rem !important;
}
.small-font {
  font-size: 1.4rem !important;
}
.xsmall-font {
  font-size: 1.2rem !important;
}

// .burger {
//   display: none;
//   font-size: 40px;
//   &--show {
//     display: block;
//     animation-duration: 0.5s;
//     animation-name: rotate;
//   }
// }
// @keyframes rotate {
//   from {
//     opacity: 0;
//     transform: rotate(0deg);
//   }
//   to {
//     opacity: 1;
//     transform: rotate(90deg);
//   }
// }
</style>
