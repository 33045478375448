<template>
  <v-row justify="center" align="center" class="flex-column">
    <v-col cols="12" v-if="verifyingProcess" class="text-center pb-0">
      <v-progress-circular
        :size="70"
        :width="7"
        color="#fbc501"
        indeterminate
      ></v-progress-circular>
    </v-col>
    <v-col
      cols="10"
      xl="3"
      lg="4"
      md="5"
      sm="7"
      v-if="!verifyingProcess && emailVerified"
    >
      <h1 class="text-center">
        Email został zatwierdzony
      </h1>
      <v-card
        elevation="0"
        class=" mt-10 flex-column d-flex align-center justify-center"
        style="background-color:#fbc501;"
      >
        <span
          class="fa-stack check-circle-size"
          style="vertical-align: top;"
        ></span>
        <font-awesome-icon
          color="black"
          icon="circle"
          class=" check-circle-size fa-stack-1x"
        />
        <font-awesome-icon
          color="green"
          class="check-circle-size2 fa-stack-1x"
          icon="check-circle"
        />
      </v-card>
      <div class="text-center">
        <WhiteButton
          text="Zaloguj się"
          to="/entry"
          class="mt-10"
          icon="unlock"
        />
      </div>
    </v-col>
    <v-col
      cols="10"
      xl="3"
      lg="4"
      md="5"
      sm="7"
      class="pb-0"
      v-if="!verifyingProcess && !emailVerified"
    >
      <h1 class="text-center">
        Nie udało się zatwierdzić emaila, spróbuj jeszcze raz
      </h1>
      <v-card
        elevation="0"
        class="mt-10 flex-column d-flex align-center justify-center"
        style="background-color:#fbc501;"
      >
        <span
          class="fa-stack check-circle-size"
          style="vertical-align: top;"
        ></span>
        <font-awesome-icon
          color="black"
          icon="circle"
          class=" check-circle-size fa-stack-1x"
        />
        <font-awesome-icon
          color="red"
          class="check-circle-size2 fa-stack-1x"
          icon="exclamation-circle"
        />
      </v-card>
      <div class="text-center">
        <WhiteButton
          text="Zaloguj się"
          to="/entry"
          class="mt-10"
          icon="unlock"
        />
      </div>
    </v-col>
    <v-col cols="12" xl="3" lg="3" md="6" sm="8">
      <v-card tile elevation="0" class="mt-10">
        <v-card-title class="text-center" style="word-break:break-word;"
          >Jak dodać aplikację do ekranu głównego?</v-card-title
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Android
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Chrome
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-img src="../assets/gif-chrome.gif"></v-img>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Mozilla
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-img src="../assets/gif-chrome.gif"></v-img>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Opera
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-img src="../assets/gif-chrome.gif"></v-img>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              IOS
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Safari
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-img src="../assets/gif-chrome.gif"></v-img>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import WhiteButton from "../components/buttons/WhiteButton";
export default {
  name: "EmailConfirmedPage",

  components: { WhiteButton },
  created() {
    this.verify();
  },
  data: function() {
    return {
      verifying: true,
      verifyingProcess: true,
    };
  },
  computed: {
    emailVerified() {
      return this.$store.getters["auth/getIsVerified"];
    },
  },
  methods: {
    async verify() {
      let body = {
        email: this.$route.query.email,
        code: this.$route.query.code,
      };
      await this.$store.dispatch("auth/verifyEmail", body);
      this.verifyingProcess = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.check-circle-size {
  font-size: 110px !important;
}
.check-circle-size2 {
  font-size: 113px !important;
}
h1 {
  font-family: "BebasNeue", sans-serif !important;
  font-size: 38px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  h1 {
    font-size: 32px;
  }
  .check-circle-size {
    font-size: 80px !important;
  }
  .check-circle-size2 {
    font-size: 83px !important;
  }
}
.fa-stack {
  height: 1em;
}
</style>
