<template>
  <v-row style="max-width: 1400px; margin: auto" justify="center" dense>
    <Localisation :product="product" :category="product.category" />
    <v-col cols="12" sm="8" md="6" lg="4" xl="4">
      <v-card tile elevation="0">
        <v-img class="mx-2" :src="getPhoto()" contain> </v-img>
        <v-card-title
          style="font-weight: bold !important"
          class="justify-center display-1"
        >
          <span class="break text-center">{{
            product.name
          }}</span></v-card-title
        >
        <v-card-text class="d-flex justify-center black--text">
          <p
            class="product-description"
            v-html="formatQuillText(product.description)"
          ></p>
          <!-- <p>{{ formatQuillText(product.description) }}</p> -->
        </v-card-text>
        <v-card-actions class="justify-space-between px-0">
          <div
            class="pt-5 bold text-center"
            style="font-size: 25px; width: 48%"
          >
            <!-- {{
              product.product_prices ? product.product_prices[0].value : ""
            }}zł -->
            <p class="price">{{ product.priceDetal }}zł</p>
            <p v-if="product.type === 'RETAIL'" class="price-description">
              Cena detaliczna
            </p>
            <p
              v-if="product.type === 'PREFERRED_CUSTOMER'"
              class="price-description"
            >
              Cena Preferred Customer
            </p>
          </div>
          <div style="width: 48%" class="text-center">
            <div style="font-size: 0.875rem" class="text-center">
              Wybierz ilość
            </div>
            <div>
              <WhiteButton
                @click.native="decreaseCounter()"
                small
                fab
                icon="minus"
                iconMargin=""
                border
                depressed
              />
              <span
                style="margin-left: 20%; margin-right: 20%"
                :class="{ 'font-size': counter > 99 }"
                >{{ counter }}</span
              >
              <WhiteButton
                @click.native="increaseCounter()"
                small
                fab
                icon="plus"
                iconMargin=""
                border
                depressed
              />
            </div>
          </div>
        </v-card-actions>
        <v-card-actions
          class="justify-center flex-column mt-5 px-0 py-0 action-buttons"
        >
          <YellowButton
            text="Podgląd"
            width="100%"
            icon="search"
            iconColor="white"
            class="mb-3"
            small
            @click.native="preview()"
            :disabled="loading"
          />

          <YellowButton
            text="Pobierz jpg"
            width="100%"
            icon="image"
            iconColor="white"
            class="mb-3"
            small
            @click.native="downloadJpg()"
            :disabled="loading"
          />
          <YellowButton
            text="Pobierz pdf"
            width="100%"
            icon="file-pdf"
            iconColor="white"
            class="mb-3"
            small
            :disabled="loading"
            @click.native="downloadPdf()"
          />
          <YellowButton
            text="Dodaj do listy zakupów"
            width="100%"
            small
            class="mb-3"
            @click.native="addToCart()"
          />
          <YellowButton
            text="Udostępnij"
            :disabled="!webShareApiSupported"
            icon="share"
            iconColor="white"
            width="100%"
            class="mb-3"
            small
            @click.native="share()"
          />
          <small v-if="!webShareApiSupported" class="mt-2 text-center w-80">
            Przycisk udostępnij jest kompatybilny z przeglądarkami: Edge,
            Safari. Kompatybilność z przeglądarkami mobile: Chrome Andriod,
            Firefox Android, Opera Android, Safari iOS, Samsung Internet"</small
          ></v-card-actions
        >
      </v-card>
      <RecommendCustomerModal
        :isVisible="isModalVisible"
        :productDescription="product.description"
        :productName="product.name"
        :user="user"
        @closeModal="isModalVisible = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import { URL } from "../config";
import WhiteButton from "../components/buttons/WhiteButton";
import YellowButton from "../components/buttons/YellowButton";
import RecommendCustomerModal from "../components/modals/RecommendCustomerModal";
import Localisation from "../components/Localisation";
import shared from "../shared";
import router from "../router";
export default {
  components: {
    WhiteButton,
    YellowButton,
    RecommendCustomerModal,
    Localisation,
  },

  name: "ProductPage",
  created() {
    this.$store.dispatch(
      "catalogue/fetchProduct",
      this.$route.params.productId
    );
  },
  data: () => ({
    counter: 1,
    isModalVisible: false,
    loading: false,
  }),
  computed: {
    product() {
      return this.$store.getters["catalogue/getProduct"];
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
    webShareApiSupported() {
      console.log({ nav: navigator.share });
      return navigator.share;
    },
    productPdfPath() {
      return this.$store.getters["catalogue/getProductPdfPath"];
    },
  },
  methods: {
    async preview() {
      try {
        this.loading = true;

        const fileNames = await this.$store.dispatch(
          "catalogue/getProductJpgPaths",
          {
            id: this.product.id,
          }
        );
        let jpgPaths = [];
        for (const fileName of fileNames) {
          const jphPath = `${URL}/products/${
            this.product.id
          }/jpg/${fileName}?d=${new Date().getTime()}`;
          jpgPaths.push(jphPath);
        }

        this.$store.dispatch("publics/setPdfPreviewUrls", jpgPaths);
        router.push({
          name: "pdf-preview",
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    formatQuillText(text) {
      return shared.formatQuillText(text);
    },
    async share() {
      if (navigator.share) {
        await this.$store.dispatch("catalogue/downloadProductPdf", {
          id: this.product.id,
          onlyFilePath: true,
        });
        if (this.productPdfPath) {
          try {
            await navigator.share({
              title: `Produkt ${this.product.name}`,
              text: "Dzień dobry,\nW załączonym linku znajduje się szczegółowy opis produktu.\n\nPozdrawiam",
              url: URL + "/products/" + this.productPdfPath,
            });
          } catch (error) {
            // this.shareSnackbar();
          }
        }
      } else {
        this.shareSnackbar();
      }
    },
    shareSnackbar() {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "red",
          icon: "exclamation-triangle",
          message:
            "Nie udało się udostępnić danych. Kompatybilność z przeglądarkami: Chrome, Edge, Safari. Kompatybilność z przeglądarkami mobile: Chrome Andriod, Firefox Android, Opera Android, Safari iOS, Samsung Internet",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    },
    async downloadPdf() {
      try {
        this.loading = true;
        await this.$store.dispatch("catalogue/downloadProductPdf", {
          id: this.product.id,
          name: this.product.name,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async downloadJpg() {
      try {
        this.loading = true;
        const fileNames = await this.$store.dispatch(
          "catalogue/getProductJpgPaths",
          {
            id: this.product.id,
          }
        );
        for await (let fileName of fileNames) {
          await this.$store.dispatch("catalogue/downloadProductImage", {
            productId: this.product.id,
            jpgFilename: fileName,
            productName: this.product.name,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    addToCart() {
      let product = {
        amount: this.counter,
        productId: this.$route.params.productId,
      };
      let products = [];
      products.push(product);

      this.$store.dispatch("shoppingCart/addToShoppingCart", products);
    },
    increaseCounter() {
      this.counter++;
    },
    decreaseCounter() {
      if (this.counter > 1) {
        this.counter--;
      } else {
        return;
      }
    },
    getImageUrl() {
      return URL;
    },
    getPhoto() {
      let path = "/uploads/";
      if (this.product && this.product.imgPath === "no-photo.png") {
        path = "/assets/";
      }
      return this.getImageUrl() + path + this.product.imgPath;
    },
  },
};
</script>

<style scoped>
.font-size {
  font-size: 0.78rem;
}
.price {
  margin: 0;
  padding: 0;
}
.price-description {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
}
::v-deep .no-margin-paragraph {
  margin-bottom: 0;
}
.action-buttons {
  margin-bottom: 30px;
}
</style>
