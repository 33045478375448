<template>
  <v-row justify="center" align="center" class="flex-column">
    <v-col cols="12" xl="3" lg="3" md="6" sm="8">
      <v-card tile elevation="0" class="mt-10">
        <v-card-title class="text-center" style="word-break:break-word;"
          >Jak dodać aplikację do ekranu głównego?</v-card-title
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Android
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Chrome
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-img src="../assets/gif-chrome.gif"></v-img>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Mozilla
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-img src="../assets/gif-chrome.gif"></v-img>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Opera
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-img src="../assets/gif-chrome.gif"></v-img>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              IOS
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Safari
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-img src="../assets/gif-chrome.gif"></v-img>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "AddToDesktopPage",
};
</script>
