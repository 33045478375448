<template>
  <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mr-auto">
    <v-card flat elevation="0" v-if="!office">
      <router-link to="/categories" class="black--text text-decoration-none"
        ><span v-if="!gadgets" :class="{ bold: !product && !category }"
          >KATALOG PRODUKTÓW</span
        >
      </router-link>
      <span v-if="gadgets">GADŻETY FOREVER GIFT</span>
      <span
        v-if="!category && !gadgets"
        :class="{ bold: !product && !category }"
      >
        / Kategorie</span
      >

      <!-- <span v-if="gadgets">{{ " / " }}</span>
      <span v-if="gadgets" :class="{ bold: gadgets }"> Gadżety</span> -->

      <span v-if="category">{{ " / " }}</span>
      <router-link
        v-if="category"
        :to="`/categories/${category.id}/`"
        class="black--text text-decoration-none"
        ><span
          style="text-transform: uppercase"
          :class="{ bold: !product && category }"
          >{{ category.name }}</span
        ></router-link
      >

      <span v-if="product">{{ " / " }}</span>
      <span v-if="product" :class="{ bold: product && category }">{{
        product.name
      }}</span>
    </v-card>
    <v-card tile elevation="0" v-else>
      <router-link to="/my-office" class="black--text text-decoration-none"
        ><span :class="{ bold: !officeType }">MOJE BIURO</span></router-link
      >
      <span v-if="officeType">{{ " / " }}</span>
      <span v-if="officeType" :class="{ bold: officeType }">{{
        officeType
      }}</span>
    </v-card>
  </v-col>
</template>

<script>
import moment from "moment";
export default {
  name: "Localisation",

  props: {
    category: {
      type: Object,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
    gadgets: {
      type: Array,
      default: null,
    },
    office: {
      type: Boolean,
      default: false,
    },
    officeType: {
      type: String,
      default: null,
    },
  },
  computed: {
    currentYear() {
      const year = moment().get("year");
      return year;
    },
  },
};
</script>

<style lang="scss" scoped></style>
