const errorHandler = (text, errorCode) => {
  let message = "";
  switch (errorCode) {
    case "001":
      message = "Adres email jest zajęty";
      break;
    case "002":
      message = "Nie znaleziono użytkownika!";
      break;
    case "003":
      message = "Nie znaleziono klienta!";
      break;
    case "004":
      message = "Nie znaleziono wyceny!";
      break;
    case "005":
      message = "Nie znaleziono produktu!";
      break;
    case "006":
      message = "Nie znaleziono kategorii!";
      break;
    case "007":
      message = "Nie znaleziono roli!";
      break;
    case "008":
      message = "Nie znaleziono języka!";
      break;
    case "009":
      message = "Nie znaleziono kategorii klienta!";
      break;
    case "010":
      message = "Brak statusu o podanym ID!";
      break;
    case "011":
      message = "Nie znaleziono gadżetu!";
      break;
    case "012":
      message = "Nie znaleziono!";
      break;
    case "013":
      message = "Kategoria została już dodana!";
      break;
    case "014":
      message = "Nie znaleziono zamówienia!";
      break;
    case "015":
      message = "Nie znaleziono subskrypcji!";
      break;
    case "016":
      message = "Nie znaleziono produktu z kalkulacji!";
      break;
    case "017":
      message = "Nie posiadasz tego produktu w koszyku!";
      break;
    case "018":
      message = "Nie posiadasz wystarczającej ilości tego produktu w koszyku!";
      break;
    case "019":
      message = "Musisz podać wszystkie ceny";
      break;
    case "020":
      message = "Przekroczono maksymalną ilość produktów do rozdzielenia";
      break;
    case "021":
      message = "Nie znaleziono zakładki!";
      break;
    case "101":
      message = "Wypełnij pole email dla klienta!";
      break;
    case "102":
      message =
        "Subskrypcja aktywna! Możliwość opłacenia po wygaśnięciu abonamentu.";
      break;
    case "103":
      message = "Uzupełnij pole email w profilu użytkownika";
      break;
    case "104":
      message = "Email klienta obowiązkowy";
      break;
    case "201":
      message = "Zamówienie jest nie opłacone";
      break;
    case "400":
      message = "Brak autoryzacji dla roli!";
      break;
    case "401":
      message = "Brak tokenu!";
      break;
    case "402":
      message = "Zły token!";
      break;
    case "403":
      message = "Brak weryfikacji w bazie danych!";
      break;
    case "404":
      message = "Błąd przy sprawdzaniu subskrypcji!";
      break;
    case "405":
      message = "Subskrypcja wygasła!";
      break;
    case "406":
      message = "Wymagane jest potwierdzenia adresu email!";
      break;
    case "407":
      message = "Niepoprawny kod weryfikacyjny!";
      break;
    case "408":
      message = "Niepoprawny kod resetowania!";
      break;
    case "409":
      message = "Email został już zatwierdzony!";
      break;
    case "410":
      message = "Niepoprawne dane logowania!";
      break;
    case "600":
      message = "Zdjęcie jest wymagane!";
      break;
    case "601":
      message = "Zły typ pliku!";
      break;
    case "602":
      message = "Zły format pliku! Dozwolone formaty to .jpg oraz .png";
      break;
    case "603":
      message = "Za duży rozmiar pliku!";
      break;
    case "604":
      message = "Zdjęcie nie zostało usunięte!";
      break;
    default:
      message = text;
      break;
  }
  return message;
};
export default errorHandler;
