import axios from "../../axios";
import errorHandler from "../../errorHandler";
import store from "../../store"
const promotionalCodes = {
    namespaced: true,

    state: () => ({
        promotionalCodes: [],
        promotionalCode: null,
        downloadMessage: null
    }),

    mutations: {
        SET_PROMOTIONAL_CODES(state, data) {
            state.promotionalCodes = data;
        },
        SET_DOWNLOAD_REPORT_MESSAGE(state, message) {
            state.downloadMessage = message;
        }
    },

    actions: {
        async fetchAllPromotionalCodes({ dispatch, commit }, object) {
            let _limit = ''
            let sortBy = ''
            let filterByStatus = ''
            if (object) {
                let { limit, sorting } = object;
                _limit = limit ? `limit=${limit}&` : "";
                sortBy =
                    sorting?.sortBy0 && sorting?.sortBy1
                        ? `sortBy[0]=${object.sorting.sortBy0}&sortBy[1]=${object.sorting.sortBy1}&`
                        : "";

                filterByStatus =
                    sorting && sorting.status && sorting.status !== ""
                        ? `status=${sorting.status}&`
                        : "";
            }
            const baseUrl = "promotionalCode?";
            let url = baseUrl + _limit + sortBy + filterByStatus;
            url = url.slice(0, -1);
            try {
                const response = await axios.get(url);
                commit("SET_PROMOTIONAL_CODES", response.data);
            } catch (error) {
                dispatch(
                    "snackbar/setSnackbar",
                    {
                        color: "red",
                        icon: "exclamation-triangle",
                        message: errorHandler(
                            error.response.data.message,
                            error.response.data.code
                        ),
                    },
                    { root: true }
                );
                dispatch("snackbar/toggleSnackbar", true, { root: true });
            }
        },
        async fetchProductForAdmin({ dispatch, commit }, promotionalCode) {
            try {
                commit("SET_PROMOTIONAL_CODE", promotionalCode);
            } catch (error) {
                dispatch(
                    "snackbar/setSnackbar",
                    {
                        color: "red",
                        icon: "exclamation-triangle",
                        message: errorHandler(
                            error.response.data.message,
                            error.response.data.code
                        ),
                    },
                    { root: true }
                );
                dispatch("snackbar/toggleSnackbar", true, { root: true });
            }
        },

        async addPromotionalCode({ dispatch }, data) {
            try {
                await axios.post(`promotionalCode`, data);
                store.dispatch("promotionalCodes/fetchAllPromotionalCodes")
            } catch (error) {
                dispatch(
                    "snackbar/setSnackbar",
                    {
                        color: "red",
                        icon: "exclamation-triangle",
                        message: errorHandler(
                            error.response.data.message,
                            error.response.data.code
                        ),
                    },
                    { root: true }
                );
                dispatch("snackbar/toggleSnackbar", true, { root: true });
            }
        },

        async editPromotionalCode({ dispatch }, data) {
            try {
                await axios.put(`promotionalCode`, data);
                store.dispatch("promotionalCodes/fetchAllPromotionalCodes")
            } catch (error) {
                dispatch(
                    "snackbar/setSnackbar",
                    {
                        color: "red",
                        icon: "exclamation-triangle",
                        message: errorHandler(
                            error.response.data.message,
                            error.response.data.code
                        ),
                    },
                    { root: true }
                );
                dispatch("snackbar/toggleSnackbar", true, { root: true });
            }
        },

        async deletePromotionalCode({ dispatch }, data) {
            try {
                await axios.delete(`promotionalCode?id=${data.id}`);
                store.dispatch("promotionalCodes/fetchAllPromotionalCodes")
            } catch (error) {
                dispatch(
                    "snackbar/setSnackbar",
                    {
                        color: "red",
                        icon: "exclamation-triangle",
                        message: errorHandler(
                            error.response.data.message,
                            error.response.data.code
                        ),
                    },
                    { root: true }
                );
                dispatch("snackbar/toggleSnackbar", true, { root: true });
            }
        },

        async downloadReport({ commit }, data) {
            let url = `promotionalCode/report?dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`;
            const response = await axios.get(url, {
                headers: { 'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
                responseType: 'arraybuffer',
            })
            let fileName = "";
            for (const [key, value] of Object.entries(response.headers)) {
                if (key === "content-disposition") {
                    const res = value.split('"');
                    fileName = res[1];
                    fileName = unescape(fileName);
                }
            }
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(downloadUrl);
            commit("SET_DOWNLOAD_REPORT_MESSAGE", "Pomyślnie pobrano plik");
            return true;
        },
    },

    getters: {
        getAllPromotionalCodes: (state) => state.promotionalCodes,
        getPromotionalCode: state => state.promotionalCode
    },
};
export default promotionalCodes;
