<template>
  <div class="contact">
    <h1 class="black--text py-3 display-2">
      <span class="font">Pozostańmy w kontakcie!</span>
    </h1>
    <h1 class="black--text py-4 display-1">
      <span class="font">Wybierz swój ulubiony sposób:</span>
    </h1>
    <font-awesome-icon size="5x" icon="comments" />
    <div class="mt-4 contact-container">
      <h3>ForMYever Office</h3>
      <div class="contact-container__item">
        <font-awesome-icon size="2x" icon="map-marker-alt" />
        <p>ul. Twarda 18, 00-105 Warszawa</p>
      </div>
      <div class="contact-container__item">
        <font-awesome-icon size="2x" icon="phone" />
        <br />
        <a href="tel:+48735427558">+48 735-427-558</a>
      </div>
      <div class="contact-container__item">
        <font-awesome-icon size="2x" icon="envelope" />
        <p>kontakt@formyeveroffice.com</p>
      </div>
      <div class="contact-container__item icons">
        <a
          target="_blank"
          href="https://www.facebook.com/profile.php?id=100091565872768"
        >
          <font-awesome-icon
            size="2x"
            style="width: 40px; color: rgb(251, 197, 1)"
            :icon="['fab', 'facebook']"
        /></a>

        <a
          target="_blank"
          href="https://instagram.com/formyever.office?igshid=MzMyNGUyNmU2YQ=="
        >
          <font-awesome-icon
            size="2x"
            style="width: 40px; color: rgb(251, 197, 1)"
            :icon="['fab', 'instagram']"
        /></a>

        <a
          target="_blank"
          href="https://www.facebook.com/groups/132375898739146/?ref=share_group_link"
        >
          <font-awesome-icon
            size="2x"
            :icon="['fas', 'users']"
            style="width: 40px; color: rgb(251, 197, 1)"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.contact {
  max-width: 1000px;
  padding: 10px;
  margin: auto;
  text-align: center;

  &-container {
    &__item {
      a {
        color: black;
        text-decoration: none;
      }
      margin: 22px;
    }
  }
}
.icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
</style>
