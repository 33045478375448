import axios from "axios";
import store from "./store";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { API_URL } from "./config";

const instance = axios.create({
  baseURL: API_URL,
});
const uninterceptedAxiosInstance = axios.create({
  baseURL: API_URL,
});

// Obtain the fresh token each time the function is called
const getToken = () => {
  return localStorage.getItem("token");
};

const getRefreshToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    return store.dispatch("auth/forceLogout")
  }
  return localStorage.getItem("refreshToken");
};

//Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
  const token = localStorage.getItem("token");
  if (token) {
    uninterceptedAxiosInstance
      .post(`auth/token`, { refreshToken: getRefreshToken() })
      .then((tokenRefreshResponse) => {
        localStorage.setItem("token", tokenRefreshResponse.data.accessToken);
        localStorage.setItem(
          "refreshToken",
          tokenRefreshResponse.data.refreshToken
        );
        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + tokenRefreshResponse.data.accessToken;
        return Promise.resolve();
      });
  }
}



// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(instance, refreshAuthLogic);

instance.interceptors.request.use((request) => {
  if (getToken()) {
    request.headers["Authorization"] = `Bearer ${getToken()}`;
  }
  return request;
});

uninterceptedAxiosInstance.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response && error.response.status && error.response.status === 401) {
      store.dispatch("auth/forceLogout");
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

instance.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response && error.response.status && error.response.status === 403) {
      store.dispatch("auth/redirectBack", error);
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default instance;
