import axios from "../../axios";
import errorHandler from "../../errorHandler";

const faq = {
  namespaced: true,

  state: () => ({
    faqs: [],
    faq: {},
  }),

  mutations: {
    SET_FAQS(state, faqs) {
      state.faqs = faqs;
    },
    SET_FAQ(state, faq) {
      state.faq = faq;
    },
  },

  actions: {
    async fetchFaqs({ dispatch, commit }) {
      try {
        const response = await axios.get("faq");
        commit("SET_FAQS", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async deleteFaq({ dispatch }, faqId) {
      try {
        await axios.delete(`admin/faq/${faqId}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto pytanie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchFaqs");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async addFaq({ dispatch }, object) {
      try {
        await axios.post(`admin/faq`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano pytanie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchFaqs");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async editFaq({ dispatch }, object) {
      const { id, data } = object;
      try {
        console.log({ data });
        await axios.put(`admin/faq/${id}`, { ...data });
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano pytanie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchFaqs");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async setFaq({ commit }, faq) {
      commit("SET_FAQ", faq);
    },
  },

  getters: {
    getFaqs: (state) => state.faqs,
    getFaq: (state) => state.faq,
  },
};
export default faq;
