<template>
  <v-row justify="center" dense>
    <v-col cols="12" sm="11" md="9" lg="7" xl="6">
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-center pb-5"
      >
        <v-card-title class="black--text pb-1 justify-center display-2"
          ><span class="font">Ustawienia</span>
        </v-card-title>
      </v-card>
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-space-between"
      >
      </v-card>

      <v-card tile elevation="0" class="d-flex justify-space-around">
        <v-card-text
          style="cursor:pointer"
          class="text-center fontSize px-1"
          :class="{
            selectedTab: selectedTab === 'myAccount',
            background: selectedTab === 'myAccount',
            unselectedTab: selectedTab != 'myAccount',
          }"
          @click="selectedTab = 'myAccount'"
          >Moje konto</v-card-text
        >
        <v-card-text
          style="cursor:pointer"
          class="text-center fontSize px-1"
          :class="{
            selectedTab: selectedTab === 'changePassword',
            background: selectedTab === 'changePassword',
            unselectedTab: selectedTab != 'changePassword',
          }"
          @click="selectedTab = 'changePassword'"
          >Zmiana hasła</v-card-text
        >
        <v-card-text
          style="cursor:pointer"
          class="text-center fontSize px-1"
          :class="{
            selectedTab: selectedTab === 'changeRole',
            background: selectedTab === 'changeRole',
            unselectedTab: selectedTab != 'changeRole',
          }"
          @click="selectedTab = 'changeRole'"
          >Zmiana pozycji</v-card-text
        >
      </v-card>
      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'myAccount'"
      >
        <v-card-actions class="d-flex justify-center"> </v-card-actions
        ><v-card-actions>
          <div class="subtitle-1">
            <div>
              Imię i nazwisko: <span class="bold">{{ user.fullname }}</span>
            </div>
            <div>
              Email: <span class="bold">{{ user.email }}</span>
            </div>
            <div>
              Rola: <span class="bold">{{ user.role.name }}</span>
            </div>
            <div>
              Wybrany język:
              <span class="bold">{{ user.language.shortName }}</span>
            </div>
            <div>
              Telefon: <span class="bold">{{ user.phone }}</span>
            </div>
            <div>
              Numer FBO: <span class="bold">{{ user.fbo }}</span>
            </div>
            <div>
              Newsletter: <span class="bold">{{ user.newsletter ? 'Tak' : 'Nie' }}</span>
            </div>
            <div>
              Jak dowiedziałaś/łeś się o aplikacji?: <span class="bold">{{user.howFoundApp}}</span>
            </div>
          </div>
          <div></div>
        </v-card-actions>
        <v-card-actions class="d-flex justify-center">
          <YellowButton
            text="Edytuj"
            padding
            icon="edit"
            @click.native="setEditUser()"
          />
          <v-btn
            @click="deleteModal = true"
            color="#F32013"
            rounded
            class="shadow grad padding"
          >
            Usuń konto</v-btn
          >
        </v-card-actions>
        <v-card-actions class="d-flex justify-center">
          <div>
            Aktualna wersja systemu: <strong>{{ VERSION }}</strong>
          </div>
        </v-card-actions>
      </v-card>
      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'changePassword'"
      >
        <v-container class="py-10 px-10 text-center">
          <ValidationObserver
            ref="obs"
            v-slot="{ invalid, handleSubmit }"
            mode="eager"
          >
            <v-form @submit.prevent="handleSubmit(onPasswordChange)">
              <v-card-subtitle
                style="padding:0px 0px 0px 10px"
                class="text-left label"
                >Stare hasło</v-card-subtitle
              >
              <VTextFieldWithValidation
                type="password"
                rules="required"
                v-model="oldPassword"
                outlined
                dense
                :disabled="loading"
              />
              <v-card-subtitle
                style="padding:0px 0px 0px 10px"
                class="text-left label"
                >Nowe hasło</v-card-subtitle
              >
              <VTextFieldWithValidation
                type="password"
                rules="required"
                vid="password1"
                v-model="password"
                outlined
                dense
                :disabled="loading"
              />
              <v-card-subtitle
                style="padding:0px 0px 0px 10px"
                class="text-left label"
                >Powtórz hasło</v-card-subtitle
              >
              <VTextFieldWithValidation
                type="password"
                rules="required|password:@password1"
                v-model="passwordConfirmation"
                outlined
                dense
                :disabled="loading"
              />

              <YellowButton
                :disabled="invalid"
                :loading="loading"
                type="submit"
                text="Zmień hasło"
                class="mt-5"
                icon="unlock"
              />
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card>
      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'changeRole'"
      >
        <v-container class="py-10 px-10 text-center">
          <ValidationObserver
            ref="obs"
            v-slot="{ invalid, handleSubmit }"
            mode="eager"
          >
            <v-form @submit.prevent="handleSubmit(onChangeRole)">
              <h3>
                Twoja aktualna pozycja to: {{ formatRole(user.role.name) }}
              </h3>
              <br />
              <hr />
              <br />
              <v-card-subtitle
                style="padding:0px 0px 0px 10px"
                class="text-left label"
                >Jeśli chcesz zmienić pozycję, wybierz z listy a następnie
                zatwierdź</v-card-subtitle
              >
              <v-select
                v-model="role"
                :items="roles"
                item-value="id"
                item-text="name"
                label="Wybierz pozycję"
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                ><template v-slot:selection="{ item }">
                  <span class="bold selectText">{{
                    formatRole(item.name)
                  }}</span>
                </template>
                <template v-slot:item="slotProps">
                  <span>{{ formatRole(slotProps.item.name) }}</span>
                </template></v-select
              >
              <v-card-subtitle
                style="padding:0px 0px 0px 10px"
                class="text-left label"
              >
                <strong>Uwaga!</strong> Po zmianie pozycji nastąpi wylogowanie.
                Zaloguj się ponownie.</v-card-subtitle
              >
              <YellowButton
                :disabled="invalid"
                :loading="loading"
                type="submit"
                text="Zmień pozycję"
                class="mt-5"
                icon="edit"
              />
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card>
      <DeleteAccountModal
        :isVisible="deleteModal"
        @closeModal="deleteModal = false"
      />
      <EditUserModal
        :isVisible="editUserModal"
        @closeModal="editUserModal = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import VTextFieldWithValidation from "../components/inputs/VTextFieldWithValidation";
import DeleteAccountModal from "../components/modals/DeleteAccountModal";
import EditUserModal from "../components/modals/EditUserModal";
import shared from "../shared";
import { ValidationObserver } from "vee-validate";
import YellowButton from "../components/buttons/YellowButton";
import { VERSION } from "../config";

export default {
  name: "SettingsPage",
  components: {
    VTextFieldWithValidation,
    ValidationObserver,
    YellowButton,
    DeleteAccountModal,
    EditUserModal,
  },
  created() {
    this.$store.dispatch("publics/fetchRoles");
    this.$store.dispatch("user/fetchUser");
  },

  data: () => ({
    deleteModal: false,
    editUserModal: false,
    email: "",
    oldPassword: "",
    password: "",
    role: null,
    passwordConfirmation: "",
    loading: false,
    selectedTab: "myAccount",
    VERSION: VERSION,
  }),
  computed: {
    roles() {
      return this.$store.getters["publics/getRoles"];
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
  },
  methods: {
    formatRole(role) {
      return shared.formatRole(role);
    },
    setEditUser() {
      this.$store.dispatch("publics/fetchRoles");
      this.$store.dispatch("publics/fetchLanguages");
      this.$store.dispatch("user/fetchUser");
      this.editUserModal = true;
    },
    async onChangeRole() {
      this.loading = true;
      const data = {
        roleId: this.role,
      };
      await this.$store.dispatch("user/changeRole", data);
      this.loading = false;
      this.$refs.obs.reset();
    },
    async onPasswordChange() {
      this.loading = true;
      const data = {
        oldPassword: this.oldPassword,
        newPassword: this.password,
        newPassword_confirmation: this.passwordConfirmation,
      };
      await this.$store.dispatch("user/changePassword", data);
      this.loading = false;
      this.oldPassword = "";
      this.password = "";
      this.passwordConfirmation = "";
      this.$refs.obs.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.selectedTab {
  border-color: #fbc501 !important;
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-left: 2px solid grey;
  border-radius: 10px 10px 0px 0px !important;
  font-weight: bold;
}
.unselectedTab {
  border-color: #fbc501 !important;
  border-bottom: 2px solid grey;
  border-radius: 0px;
}
.selectedTabItem {
  border-color: #fbc501 !important;
  border-bottom: 2px solid grey;
  border-right: 2px solid grey;
  border-left: 2px solid grey;
  border-radius: 0px 0px 10px 10px !important;
}
.theme--light.v-data-table
  .v-data-table__wrapper
  table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  .v-data-table__wrapper
  table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .fontSize {
    font-size: 0.8rem;
  }
}
text {
  color: black !important;
}
.background {
  background-color: #fbc501;
  color: black !important;
}
.break {
  word-break: break-word;
}
.padding {
  padding: 18px 22px !important;
}
</style>
