<template>
  <v-btn
    rounded
    :to="to"
    :width="width"
    elevation="2"
    :height="height"
    :loading="loading"
    :class="{
      'disable-events': disabled,
      'small-font': smallFont,
      'medium-font': mediumFont,
    }"
    color="green"
    class="text-none text-truncate gradient shadow"
    :small="small"
  >
    <font-awesome-icon v-if="icon" :icon="icon" class="mr-2 " />
    <span class="bold white--text">{{ text }}</span></v-btn
  >
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    text: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    smallFont: {
      type: Boolean,
      default: false,
    },
    mediumFont: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.disable-events {
  pointer-events: none;
}
.small-font {
  font-size: 0.75rem;
}
.medium-font {
  font-size: 0.8rem;
}
.gradient {
  background-image: linear-gradient(to bottom, #00e613, #00b30f);
}
.v-btn:not(.v-btn--round).v-size--small {
  padding: 18px 22px !important;
}
</style>
