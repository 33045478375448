<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="1024px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card min-height="300px">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          {{ title ? title : "Edycja" }}
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-text class="card">
            <v-text-area-with-validation
              rules="max:1024"
              v-model="value"
              :label="label ? label : ''"
              color="indigo"
              outlined
              dense
              :maxLengthInfo="1024"
            />
            <v-card-actions class="mt-4">
              <v-btn color="warning" text v-on:click="closeModal">
                <font-awesome-icon icon="times" class="mr-2" />
                Zamknij
              </v-btn>
              <v-btn color="success" text :disabled="invalid" @click="confirm">
                <font-awesome-icon icon="check" class="mr-2" />
                Zapisz
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BlackButton from "../buttons/BlackButton";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";

export default {
  name: "EditTextFieldModal",
  props: ["initValue", "isVisible", "title", "label"],

  components: {
    ValidationObserver,
    BlackButton,
    VTextAreaWithValidation,
  },
  data: () => ({
    value: "",
  }),
  mounted() {
    this.value = this.initValue;
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    confirm() {
      this.$emit("confirm", this.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.close {
  position: absolute;
  top: 4%;
  right: 3%;
  z-index: 10000000;
}
.card {
  margin-top: 2rem;
}
</style>
