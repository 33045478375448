<template>
  <ValidationProvider
    :name="$attrs.label"
    :rules="rules"
    v-slot="{ errors }"
    :vid="vid"
  >
    <div style="position: relative">
      <v-textarea
        :type="type"
        rows="5"
        v-model="innerValue"
        :error-messages="errors"
        v-bind="$attrs"
        auto-grow
        no-resize
        v-on="$listeners"
        :outlined="!filled"
        :filled="filled"
        color="black"
        :background-color="!filled ? 'white' : ''"
        :rounded="!filled"
        dense
        :style="{ borderRadius: withoutBorderRadius ? '0' : '' }"
      >
      </v-textarea>
      <span
        v-if="maxLengthInfo"
        class="number-of-characters"
        :style="innerValue.length > maxLengthInfo ? 'bottom: -20px' : ''"
        >Liczba znaków: {{ innerValue.length }}/{{ maxLengthInfo }}</span
      >
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    maxLengthInfo: {
      type: Number,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
    vid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    filled: {
      type: Boolean,
      default: false,
    },
    withoutBorderRadius: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      if (
        this.type === "number" &&
        this.innerValue.toString().match(/^[0-9]+,[0-9]+$/)
      ) {
        this.innerValue = this.innerValue.toString().replace(",", ".");
      }

      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>

<style scoped>
.number-of-characters {
  position: absolute;
  bottom: -5px;
  right: 5px;
  opacity: 0.5;
}
</style>