<template>
  <v-row justify="center" style="z-index:9999;">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="340px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" mode="eager">
          <v-card-text class="text-center">
            <div>
              {{ status }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="warning"
              text
              v-on:click="closeModal"
              style="width: 100%"
            >
              <font-awesome-icon icon="times" class="mr-2" />
              Zamknij
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BlackButton from "../buttons/BlackButton";

export default {
  name: "ProductModal",
  props: ["status", "isVisible"],

  components: {
    ValidationObserver,
    BlackButton,
  },
  data: () => ({
    visible: true,
  }),
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 4%;
  right: 3%;
  z-index: 10000000;
}
.infoImg {
  width: 100%;
}
</style>
