<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="320px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>{{
            multiple && selectedDate && isEdit
              ? "Edytuj listę zadań"
              : multiple && selectedDate
              ? "Kopiuj listę"
              : multiple
              ? "Dodaj listę"
              : isEdit
              ? "Edytuj zadanie"
              : isCopying
              ? "Kopiuj zadanie"
              : "Dodaj zadanie"
          }}</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-text class="mb-0 pb-0">
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-date-picker
                    class="mt-0 pt-0 mb-0 pb-0"
                    v-model="date"
                    first-day-of-week="1"
                    color="yellow"
                    full-width
                    no-title
                    style="height: 260px"
                    :disabled="
                      task &&
                      task.tasks &&
                      task.tasks.length > 0 &&
                      isEdit &&
                      multiple
                    "
                    :disabled-date="disabledAfterToday"
                  />
                </v-col>
              </v-row>
              <div v-if="!multiple">
                <v-row class="align-baseline">
                  <v-col cols="11" class="mt-2">
                    <v-textarea
                      v-model="title"
                      label="Zadanie"
                      color="indigo"
                      auto-grow
                      rows="1"
                      maxlength="255"
                      outlined
                    />
                  </v-col>

                  <v-col cols="1">
                    <font-awesome-icon
                      icon="star"
                      :color="isPriority ? '#fbc501' : 'black'"
                      @click="setTaskIsPriority()"
                      style="cursor: pointer"
                    />
                  </v-col>
                </v-row>
              </div>
              <div v-if="multiple">
                <v-row
                  v-for="(title, index) in titles"
                  :key="`title_${index}`"
                  class="align-baseline"
                >
                  <v-col cols="11" sm="11" style="padding: 0">
                    <v-textarea
                      v-model="titles[index]"
                      :label="`Zadanie nr ${index + 1}`"
                      color="indigo"
                      auto-grow
                      rows="1"
                      maxlength="255"
                      outlined
                    />
                  </v-col>
                  <v-col cols="1" sm="1" style="padding: 0">
                    <font-awesome-icon
                      class="ml-3"
                      icon="star"
                      :color="
                        priorityIndex && priorityIndex.includes(index)
                          ? '#fbc501'
                          : 'black'
                      "
                      @click="setPriorityIndex(index)"
                      style="cursor: pointer"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <yellow-button
                    class="mr-4 mt-2"
                    text="Dodaj kolejne zadanie"
                    icon="plus"
                    @click.native="addNextTask()"
                    xsmall
                    :width="'100%'"
                  />
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-0 pt-0">
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              v-if="!isEdit"
              color="success"
              text
              :disabled="invalid"
              v-on:click="addTask()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              {{
                multiple && selectedDate
                  ? "Kopiuj listę"
                  : multiple
                  ? "Dodaj listę"
                  : isCopying
                  ? "Kopiuj zadanie"
                  : "Dodaj zadanie"
              }}
            </v-btn>
            <v-btn
              v-else
              color="success"
              text
              :disabled="invalid"
              v-on:click="editTask()"
            >
              <font-awesome-icon icon="check" class="mr-1" />

              {{
                multiple && selectedDate
                  ? "Edytuj listę"
                  : multiple
                  ? "Zapisz listę"
                  : "Edytuj zadanie"
              }}
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BlackButton from "../buttons/BlackButton";
import moment from "moment";
import YellowButton from "../buttons/YellowButton.vue";

export default {
  name: "TaskModal",
  props: [
    "isVisible",
    "isEdit",
    "add",
    "selectedDate",
    "multiple",
    "selectedTitle",
    "selectedTitles",
    "disabled",
    "isCopying",
  ],

  components: {
    ValidationObserver,
    BlackButton,
    YellowButton,
  },
  updated() {
    if (this.selectedTitle) {
      this.title = this.selectedTitle;
    }

    if (this.selectedTitles) {
      this.titles = this.selectedTitles;
    } else {
      this.titles = ["", "", "", "", "", ""];
    }

    if (this.selectedDate) {
      this.date = this.selectedDate;
    } else {
      this.date = moment().format("YYYY-MM-DD");
    }
  },
  data: () => ({
    title: "",
    date: "",
    isPriority: false,
    titles: ["", "", "", "", "", ""],
    priorityIndex: [],
  }),
  mounted() {
    if (this.selectedDate) {
      this.date = this.selectedDate;
    } else {
      this.date = moment().format("YYYY-MM-DD");
    }
  },
  computed: {
    task() {
      return this.$store.getters["userTask/getUserTask"];
    },
  },
  watch: {
    task: function () {
      this.date = moment().format("YYYY-MM-DD");
      if (this.selectedDate) {
        this.date = this.selectedDate;
      }
      if (this.task) {
        this.title = this.task.title;
        if (this.task.date) {
          this.date = this.task.date;
        }

        this.isPriority = this.task.isPriority;

        if (this.task.tasks) {
          this.task.tasks.forEach((task, index) => {
            if (task.isPriority && !this.priorityIndex.includes(index)) {
              this.priorityIndex.push(index);
            }
          });
        }
      }
    },
    selectedDate() {
      if (this.selectedDate) {
        this.date = this.selectedDate;
      } else {
        this.date = moment().format("YYYY-MM-DD");
      }
    },
  },
  methods: {
    setTaskIsPriority() {
      this.isPriority = !this.isPriority;
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },

    setPriorityIndex(index) {
      if (!this.priorityIndex.includes(index)) {
        this.priorityIndex.push(index);
      } else {
        this.priorityIndex = this.priorityIndex.filter((i) => i !== index);
      }
    },
    addNextTask() {
      this.titles.push("");
    },
    async closeModal() {
      this.title = null;
      this.isPriority = false;
      this.titles = ["", "", "", "", "", ""];
      this.priorityIndex = [];
      await this.$store.dispatch("userTask/setUserTask", null);
      this.$refs.obs.reset();
      this.$emit("closeModal");
    },
    async addTask() {
      const titlesToSave = this.titles.filter((title) => title !== "");
      if (this.multiple) {
        let object = {
          titles: titlesToSave,
          date: this.date,
          priorityIndex: this.priorityIndex,
        };
        await this.$store.dispatch("userTask/addUserTasks", object);
      } else {
        let object = {
          title: this.title,
          date: this.date,
          isPriority: this.isPriority,
        };
        await this.$store.dispatch("userTask/addUserTask", object);
      }

      this.$emit("fetch");
      this.closeModal();
    },
    async editTask() {
      if (this.task.tasks) {
        let counter = 0;
        for await (let title of this.titles) {
          const taskToEdit = this.task.tasks[counter];
          if (taskToEdit) {
            if (!title) {
              await this.$store.dispatch(
                "userTask/deleteUserTask",
                taskToEdit.id
              );
            } else {
              let object = {
                id: taskToEdit.id,
                data: {
                  title,
                  isPriority: this.priorityIndex.includes(counter),
                },
              };
              await this.$store.dispatch("userTask/editUserTask", object);
            }
          } else {
            let object = {
              title,
              date: this.date,
              isPriority: this.priorityIndex.includes(counter),
            };
            if (title) {
              await this.$store.dispatch("userTask/addUserTask", object);
            }
          }

          counter++;
        }
      } else {
        let object = {
          id: this.task.id,
          data: {
            title: this.title,
            date: this.date,
            isPriority: this.isPriority,
          },
        };
        await this.$store.dispatch("userTask/editUserTask", object);
      }

      this.closeModal();
      this.$emit("fetch");
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 1%;
  right: 3%;
  z-index: 10000000;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
</style>
