<template>
  <v-btn
    rounded
    :to="to"
    elevation="2"
    :loading="loading"
    :class="{
      'disable-events': disabled,
      border: border,
      padding: padding,
      'border-yellow': borderYellow && !disabled,
    }"
    :color="disabled ? 'grey' : 'white'"
    class="text-none shadow "
    :small="small"
    :x-small="xsmall"
    :fab="fab"
    :height="height"
    :width="width"
  >
    <font-awesome-icon v-if="icon" :icon="icon" :class="iconMargin" />
    {{ text }}</v-btn
  >
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    text: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    borderYellow: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconMargin: {
      type: String,
      default: "mr-2",
    },
  },
};
</script>

<style scoped>
.disable-events {
  pointer-events: none;
}
.border {
  border: 1px solid black !important;
}
.border-yellow {
  border: 1px solid #fcc502 !important;
}
.padding {
  padding: 18px 22px !important;
}
.v-btn:not(.v-btn--round).v-size--x-small {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
</style>
