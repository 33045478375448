import axios from "../../axios";
import errorHandler from "../../errorHandler";

const instructionalVideo = {
  namespaced: true,

  state: () => ({
    instructionalVideos: [],
    instructionalVideo: {},
  }),

  mutations: {
    SET_FAQS(state, instructionalVideos) {
      state.instructionalVideos = instructionalVideos;
    },
    SET_FAQ(state, instructionalVideo) {
      state.instructionalVideo = instructionalVideo;
    },
  },

  actions: {
    async fetchInstructionalVideos({ dispatch, commit }) {
      try {
        const response = await axios.get("instructional-videos");
        commit("SET_FAQS", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async deleteInstructionalVideo({ dispatch }, instructionalVideoId) {
      try {
        await axios.delete(
          `admin/instructional-videos/${instructionalVideoId}`
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto film!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchInstructionalVideos");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async addInstructionalVideo({ dispatch }, object) {
      try {
        await axios.post(`admin/instructional-videos`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano film!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchInstructionalVideos");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async editInstructionalVideo({ dispatch }, object) {
      const { id, data } = object;
      try {
        console.log({ data });
        await axios.put(`admin/instructional-videos/${id}`, { ...data });
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano film!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchInstructionalVideos");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async setInstructionalVideo({ commit }, instructionalVideo) {
      commit("SET_FAQ", instructionalVideo);
    },
  },

  getters: {
    getInstructionalVideos: (state) => state.instructionalVideos,
    getInstructionalVideo: (state) => state.instructionalVideo,
  },
};
export default instructionalVideo;
