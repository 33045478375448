<template>
  <recommendations />
</template>

<script>
import Recommendations from "../components/Recommendations.vue";
export default {
  name: "RecommendationsView",
  components: { Recommendations },
};
</script>

<style scoped lang="scss">
</style>
