var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',{staticClass:"text-none gradient shadow",class:{
    'disable-events': _vm.disabled,
    'small-font': _vm.smallFont,
    'medium-font': _vm.mediumFont,
    padding: _vm.padding,
    'without-padding': _vm.withoutPadding,
  },style:({
    minWidth: _vm.minWidth ? '95px' : '0px',
  }),attrs:{"rounded":"","to":_vm.to,"width":_vm.width,"height":_vm.height,"loading":_vm.loading,"color":"#fbc501","small":_vm.small,"x-small":_vm.xsmall,"x-large":_vm.xlarge,"large":_vm.large,"fab":_vm.fab,"href":_vm.href}},[(_vm.icon)?_c('font-awesome-icon',{class:{ 'mr-2': !_vm.onlyIcon },attrs:{"color":_vm.iconColor,"icon":_vm.icon}}):_vm._e(),_c('span',{staticClass:"bold",class:{ 'text-width': _vm.truncate, 'text-truncate': !_vm.wrap },style:(_vm.maxWidthAndWrap
        ? !_vm.widthToWrap
          ? 'max-width: 290px; width: 100%; text-wrap: wrap'
          : ("max-width: " + _vm.widthToWrap + "; width: 100%; text-wrap: wrap")
        : '')},[_vm._v(_vm._s(_vm.text))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }