<template>
  <v-row style="max-width:1400px; margin:auto;" justify="center" dense>
    <Localisation :category="category" />
    <v-col cols="12" sm="12" md="12" lg="12" xl="12"
      ><v-text-field
        dense
        filled
        flat
        v-model="filter"
        label="Wyszukaj produkt"
      >
        <template slot="append">
          <font-awesome-icon icon="search" class="mt-1" />
        </template> </v-text-field
    ></v-col>
    <v-col v-if="loading"
      ><v-card elevation="0" tile class="text-center"
        ><v-progress-circular
          :size="70"
          :width="7"
          color="#fbc501"
          indeterminate
        ></v-progress-circular></v-card
    ></v-col>
    <v-col v-if="!loading && products.length < 1"
      ><v-card elevation="0" tile
        ><v-card-title class="justify-center text-h4 font-weight-bold break"
          >Brak produktów z tej kategorii</v-card-title
        ></v-card
      ></v-col
    >
    <v-col
      cols="6"
      sm="6"
      md="4"
      lg="3"
      xl="3"
      :class="{
        'column-margin':
          index + 1 == products.length &&
          ($vuetify.breakpoint.name === 'xs' ||
            $vuetify.breakpoint.name === 'sm') &&
          products.length % 2 != 0,
      }"
      v-for="(product, index) in products"
      :key="product.id"
    >
      <ProductCard
        :src="product.imgPath"
        :text="product.name"
        :to="`product/${product.id}`"
      />
    </v-col>
  </v-row>
</template>

<script>
import Localisation from "../components/Localisation";
import ProductCard from "../components/ProductCard";
export default {
  name: "ProductsPage",
  components: {
    ProductCard,
    Localisation,
  },
  data: () => ({
    filter: "",
    loading: true,
  }),
  watch: {
    filter() {
      this.fetchFilteredProducts();
    },
    products() {
      this.loading = false;
    },
  },
  created() {
    let object = {
      category: this.$route.params.categoryId,
      filter: null,
    };
    this.$store.dispatch("catalogue/fetchProducts", object);
  },
  methods: {
    fetchFilteredProducts() {
      let object = {
        filter: this.filter,
        category: this.$route.params.categoryId,
      };
      this.$store.dispatch("catalogue/fetchProducts", object);
    },
  },

  computed: {
    products() {
      return this.$store.getters["catalogue/getProducts"];
    },
    category() {
      return this.$store.getters["catalogue/getCategory"];
    },
  },
  destroyed() {
    this.$store.commit("catalogue/SET_PRODUCTS", []);
  },
};
</script>

<style scoped>
.column-margin {
  margin-right: 50% !important;
}

.break {
  word-break: break-word;
}
</style>
