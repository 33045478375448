<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="340px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card class="pb-4">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          Wybierz cenę dostawy
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />

        <div class="content">
          <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
            <v-select
              class="mb-4 mt-8"
              style="width: 80%; margin: 0 auto"
              dense
              outlined
              label="Dostawa"
              item-value="value"
              item-text="name"
              v-model="selectedDelivery"
              :items="options"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>

            <div
              style="width: 80%; margin: 0 auto"
              v-if="selectedDelivery === ''"
              class="mb-8"
            >
              <VTextFieldWithValidation
                v-model="otherPrice"
                label="Koszt dostawy"
                color="indigo"
                outlined
                dense
                filled
                rules="required"
                type="number"
              />
            </div>

            <div style="text-align: center">
              <v-btn
                color="success"
                text
                :disabled="invalid"
                v-on:click="addDelivery()"
              >
                <font-awesome-icon icon="check" class="mr-1" />
                Dodaj dostawę
              </v-btn>
            </div>
          </ValidationObserver>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BlackButton from "../buttons/BlackButton";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation.vue";
import { ValidationObserver } from "vee-validate";
export default {
  name: "AddDeliveryModal",
  props: ["isVisible"],

  components: {
    BlackButton,
    VTextFieldWithValidation,
    ValidationObserver,
  },
  data: () => ({
    selectedDelivery: 0.0,
    otherPrice: 0,
    options: [
      {
        name: "dostawa (0,00zł)",
        value: 0.0,
      },
      {
        name: "dostawa (19,90zł)",
        value: 19.9,
      },
      {
        name: "dostawa (inna cena)",
        value: "",
      },
    ],
  }),
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    addDelivery() {
      if (this.selectedDelivery == "") {
        this.$emit("deliverySelected", Number(this.otherPrice));
      } else {
        this.$emit("deliverySelected", this.selectedDelivery);
      }
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  margin: 0 auto;
}
.close {
  position: absolute;
  top: 2%;
  right: 1%;
  z-index: 10000000;
}
</style>
