<template>
  <v-btn
    rounded
    :to="to"
    :width="width"
    :height="height"
    :loading="loading"
    :class="{
      'disable-events': disabled,
      'small-font': smallFont,
      'medium-font': mediumFont,
      padding: padding,
      'without-padding': withoutPadding,
    }"
    color="#fbc501"
    class="text-none gradient shadow"
    :small="small"
    :x-small="xsmall"
    :x-large="xlarge"
    :large="large"
    :fab="fab"
    :href="href"
    :style="{
      minWidth: minWidth ? '95px' : '0px',
    }"
  >
    <font-awesome-icon
      v-if="icon"
      :color="iconColor"
      :icon="icon"
      :class="{ 'mr-2': !onlyIcon }"
    />
    <span
      :class="{ 'text-width': truncate, 'text-truncate': !wrap }"
      class="bold"
      :style="
        maxWidthAndWrap
          ? !widthToWrap
            ? 'max-width: 290px; width: 100%; text-wrap: wrap'
            : `max-width: ${widthToWrap}; width: 100%; text-wrap: wrap`
          : ''
      "
      >{{ text }}</span
    ></v-btn
  >
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    text: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xlarge: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: null,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    smallFont: {
      type: Boolean,
      default: false,
    },
    mediumFont: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: "",
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    withoutPadding: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: Boolean,
      default: false,
    },
    maxWidthAndWrap: {
      type: Boolean,
      default: false,
    },
    widthToWrap: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.disable-events {
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    #d1d1d1 1%,
    #d1d1d1 35%
  ) !important;
}
.small-font {
  font-size: 0.75rem;
}
.medium-font {
  font-size: 0.8rem;
}
.text-width {
  width: 80% !important;
}
.gradient {
  background-image: linear-gradient(to bottom, #fcde6f 1%, #fcc502 35%);
}
.padding {
  padding: 18px 22px !important;
}
.v-btn:not(.v-btn--round).v-size--small {
  padding: 18px 22px !important;
}
.v-btn:not(.v-btn--round).v-size--x-small {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.without-padding {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
::v-deep .v-btn__content {
  max-width: 100% !important;
}
</style>
