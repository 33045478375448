<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="500px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Wyślij wizytówkę</v-toolbar-title>
        </v-toolbar>
        <v-card
          tile
          elevation="0"
          class="d-flex align-center justify-space-between"
        >
          <v-card-text
            v-if="user.fullname !== ''"
            class="black--text pb-0 body-1 font-weight-bold"
            >{{ user.fullname }}</v-card-text
          >
          <v-card-text
            v-if="user.phone"
            class="black--text text-right pl-0 pb-0 text-truncate"
            >{{ "nr tel. " + user.phone }}</v-card-text
          >
        </v-card>
        <v-card
          tile
          elevation="0"
          class="d-flex align-center justify-space-between"
        >
          <v-card-text
            class="black--text pt-0 text-truncate"
            style="padding-bottom: 5px !important"
            v-if="user && user.fbo"
            ><u>{{ `${user.fbo}.fbo.foreverliving.com` }}</u></v-card-text
          >
          <v-card-text
            class="black--text text-right pl-0 pt-0 text-truncate"
            style="padding-bottom: 5px !important"
            >{{ user.email }}</v-card-text
          >
        </v-card>
        <span @click="copyLink">
          <YellowButton
            v-if="user && user.fbo"
            class="contactButton"
            style="
              font-size: 12px;
              margin-top: 0;
              margin-left: 8px;
              padding: 0 !important;
            "
            v-clipboard="`${user.fbo}.fbo.foreverliving.com`"
            :text="`Skopiuj link`"
            small
            width="25%"
            height="28"
            :underline="true"
          />
        </span>
        <input
          type="hidden"
          :value="`${user.fbo}.fbo.foreverliving.com`"
          id="fboLink"
        />
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="12" sm="12">
                <v-card-subtitle
                  style="padding: 0px 0px 0px 10px"
                  class="text-left label"
                  >Adres e-mail odbiorcy</v-card-subtitle
                >
                <VTextFieldWithValidation
                  type="email"
                  rules="email|required"
                  v-model="email"
                  filled
                  placeholder="Wpisz adres..."
                  color="indigo"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center align-self-center pt-0"
            ><WhiteButton
              width="70%"
              text="Wyślij przez e-mail"
              :disabled="invalid"
              border
              @click.native="sendCard()"
            />
          </v-card-actions>
          <v-card-actions class="justify-center align-self-center pt-0 wrap"
            ><WhiteButton
              width="70%"
              text="Udostępnij"
              border
              icon="share"
              :disabled="!user || !webShareApiSupported"
              class="mt-3 mb-4"
              @click.native="share()"
            />
            <small v-if="!webShareApiSupported" class="mt-2 text-center w-80">
              Przycisk udostępnij jest kompatybilny z przeglądarkami: Edge,
              Safari. Kompatybilność z przeglądarkami mobile: Chrome Andriod,
              Firefox Android, Opera Android, Safari iOS, Samsung
              Internet"</small
            >
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import WhiteButton from "../buttons/WhiteButton";
import BlackButton from "../buttons/BlackButton";
import YellowButton from "../buttons/YellowButton";
export default {
  name: "SendBusinessCardModal",
  props: ["isVisible"],

  components: {
    VTextFieldWithValidation,
    ValidationObserver,
    WhiteButton,
    BlackButton,
    YellowButton,
  },
  created() {
    this.$store.dispatch("catalogue/fetchCategories");
    this.$store.dispatch("user/fetchUser");
  },

  data: () => ({
    email: "",
  }),
  computed: {
    user() {
      return this.$store.getters["user/getUser"];
    },
    webShareApiSupported() {
      if (navigator) {
        return navigator.share;
      } else {
        return false;
      }
    },
  },
  methods: {
    share() {
      const content = `Dzień dobry,\nPrzesyłam moje dane kontaktowe.\n\nImię i nazwisko: ${this.user.fullname}\nNumer telefonu: ${this.user.phone}\nAdres email: ${this.user.email}\nFBO: ${this.user.fbo}\n\nPozdrawiam,\n${this.user.fullname}`;
      navigator.share({
        title: "Wizytówka",
        text: content,
      });
    },
    copyLink() {
      let codeToCopy = document.getElementById("fboLink");
      if (codeToCopy !== null) {
        codeToCopy.value = "";
        codeToCopy.value = `${this.user.fbo}.fbo.foreverliving.com`;
        codeToCopy.setAttribute("type", "text");
        codeToCopy.select();
        document.execCommand("copy");
        codeToCopy.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();
      }
    },
    closeModal() {
      this.email = "";
      this.$refs.obs.reset();
      this.$emit("closeModal");
    },
    async sendCard() {
      let data = {
        recipientEmail: this.email,
      };
      this.closeModal();
      await this.$store.dispatch("user/sendBusinessCard", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10000000;
}
.label {
  font-size: 0.8rem;
  color: black !important;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.wrap {
  flex-wrap: wrap;
}
</style>
