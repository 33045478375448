<template>
  <div>
    <v-col cols="12" class="text-center">
      <h1>
        Dziękujemy za przedłużenie subskrypcji!
      </h1>
      <v-card
        elevation="0"
        class="mt-10 flex-column d-flex align-center justify-center"
      >
        <span
          class="fa-stack check-circle-size"
          style="vertical-align: top;"
        ></span>
        <font-awesome-icon
          color="black"
          icon="circle"
          class=" check-circle-size fa-stack-1x"
        />
        <font-awesome-icon
          color="green"
          class="check-circle-size2 fa-stack-1x"
          icon="check-circle"
        />
      </v-card>
      <h3 style="max-width:400px;margin:30px auto 5px auto;">
        Jeżeli płatność przebiegła pomyślnie na maila zostało wysłane
        potwierdzenie oraz w zakładce abonament przedłużona została data
        ważności abonamentu.
      </h3>

      <YellowButton text="Home" xlarge to="/home" class="mt-5" />
    </v-col>
  </div>
</template>

<script>
import YellowButton from "../components/buttons/YellowButton.vue";

export default {
  components: { YellowButton },
  name: "PaymentStatus",
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.check-circle-size {
  font-size: 138px !important;
}
.check-circle-size2 {
  font-size: 139.5px !important;
}
h1 {
  font-family: "BebasNeue", sans-serif !important;
  color: black;
  font-size: 38px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  h1 {
    font-size: 32px;
  }
  .check-circle-size {
    font-size: 80px !important;
  }
  .check-circle-size2 {
    font-size: 83px !important;
  }
}
.fa-stack {
  height: 1em;
}
</style>
