<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="600px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title v-if="!isEdit">Dodaj zakładkę</v-toolbar-title>
          <v-toolbar-title v-else>Edytuj zakładkę</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-file-input
                    ref="fileupload"
                    v-model="photo"
                    dense
                    outlined
                    rounded
                    label="Załącz zdjęcie"
                    :key="inputKey"
                    accept="image/png,image/jpeg"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    rules="required"
                    v-model="name"
                    label="Nazwa"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              v-if="!isEdit"
              color="success"
              text
              :disabled="invalid"
              v-on:click="addTab()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Dodaj zakładkę
            </v-btn>
            <v-btn
              v-else
              color="success"
              text
              :disabled="invalid"
              v-on:click="editTab()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zakładkę
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";

import BlackButton from "../buttons/BlackButton";
import shared from "../../shared";

export default {
  name: "TabModal",
  props: ["isVisible", "isEdit"],

  components: {
    ValidationObserver,
    BlackButton,
    VTextFieldWithValidation,
  },
  created() {},

  data: () => ({
    name: "",
    photo: null,
    inputKey: 0,
  }),
  computed: {
    tab() {
      return this.$store.getters["catalogue/getTab"];
    },
  },
  watch: {
    tab: function () {
      this.name = this.tab.name;
    },
  },
  methods: {
    formatRole(role) {
      return shared.formatRole(role);
    },
    closeModal() {
      this.name = "";
      this.photo = null;
      this.inputKey++;
      this.$refs.obs.reset();
      this.$emit("closeModal");
    },
    async addTab() {
      let formData = new FormData();
      if (this.photo) {
        formData.append("file", this.photo);
      }
      formData.append("name", this.name);
      await this.$store.dispatch("catalogue/addTab", formData);
      this.closeModal();
    },
    async editTab() {
      let formData = new FormData();
      if (this.photo) {
        formData.append("file", this.photo);
      }
      formData.append("name", this.name);
      let object = {
        id: this.tab.id,
        formData: formData,
      };
      await this.$store.dispatch("catalogue/editTab", object);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 1%;
  right: 3%;
  z-index: 10000000;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
</style>
