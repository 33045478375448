import axios from "../../axios";
import errorHandler from "../../errorHandler";

const userTask = {
  namespaced: true,

  state: () => ({
    userTasks: [],
    userTask: {},
  }),

  mutations: {
    SET_USER_TASKS(state, userTasks) {
      state.userTasks = userTasks;
    },
    SET_USER_TASK(state, userTask) {
      state.userTask = userTask;
    },
  },

  actions: {
    async fetchUserTasks({ dispatch, commit }, data) {
      const params = {};
      if (data && data.date) {
        params.date = data.date;
      }
      try {
        const response = await axios.get("user-tasks", { params });
        commit("SET_USER_TASKS", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async deleteUserTask({ dispatch }, userTaskId) {
      try {
        await axios.delete(`user-tasks/${userTaskId}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto zadanie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchUserTasks");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async addUserTask({ dispatch }, object) {
      try {
        await axios.post(`user-tasks`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano zadanie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchUserTasks");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async addUserTasks({ dispatch }, object) {
      try {
        await axios.post(`user-tasks/multiple`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano listę!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchUserTasks");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async editUserTask({ dispatch }, object) {
      const { id, data, withoutNotification, withoutFetch } = object;
      try {
        console.log({ data });
        await axios.put(`user-tasks/${id}`, { ...data });
        if (!withoutNotification) {
          dispatch(
            "snackbar/setSnackbar",
            {
              color: "success",
              icon: "check",
              message: "Pomyślnie edytowano zadanie!",
            },
            { root: true }
          );
          dispatch("snackbar/toggleSnackbar", true, { root: true });
        }

        if (!withoutFetch) {
          dispatch("fetchUserTasks");
        }
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async setUserTask({ commit }, userTask) {
      commit("SET_USER_TASK", userTask);
    },
  },

  getters: {
    getUserTasks: (state) => state.userTasks,
    getUserTask: (state) => state.userTask,
  },
};
export default userTask;
