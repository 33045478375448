<template>
  <ValidationProvider
    :name="$attrs.label"
    :rules="rules"
    v-slot="{ errors }"
    :vid="vid"
  >
    <v-text-field
      :style="height ? `height: ${height}px` : ''"
      :type="showPassword ? 'text' : type"
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
      :outlined="!filled"
      :filled="filled"
      color="black"
      :background-color="!filled ? 'white' : ''"
      :rounded="!filled"
      dense
      :append-icon="
        type === 'password' ? (showPassword ? 'mdi-eye-off' : 'mdi-eye') : null
      "
      @click:append="showPassword = !showPassword"
      v-mask="computedMask"
    ></v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { VueMaskDirective } from "v-mask";
export default {
  directives: {
    mask: VueMaskDirective,
  },
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
    vid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    filled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    innerValue: "",
    showPassword: false,
  }),
  watch: {
    // Handles internal model changes.
    innerValue() {
      // if (
      //   this.type === "number" &&
      //   this.innerValue.toString().match(/^[0-9]+,[0-9]+$/)
      // ) {
      //   this.innerValue = this.innerValue.toString().replace(",", ".");
      // }
      // this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  computed: {
    computedMask() {
      // if (this.rules.includes("phoneWithPrefix")) {
      //   if (this.innerValue && this.innerValue.startsWith("+")) {
      //     // Remove all non-digit characters except '+'
      //     const numbersOnly = this.innerValue.replace(/[^\d]/g, "");
      //     const numDigits = numbersOnly.length;
      //     // Base mask '+## ### ### ###', adjust for additional digits
      //     let mask = "+## ### ### ###";
      //     if (numDigits > 9) {
      //       const extraGroups = Math.ceil((numDigits - 9) / 3);
      //       for (let i = 0; i < extraGroups; i++) {
      //         mask += " ###";
      //       }
      //     }
      //     return mask;
      //   } else {
      //     const numbersOnly = this.innerValue.replace(/\D/g, "");
      //     const numDigits = numbersOnly.length;
      //     let mask = "### ### ###";
      //     if (numDigits > 9) {
      //       const extraGroups = Math.ceil((numDigits - 9) / 3);
      //       for (let i = 0; i < extraGroups; i++) {
      //         mask += " ###";
      //       }
      //     }
      //     return mask;
      //   }
      // }
      return null;
    },
  },
};
</script>
