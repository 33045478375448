<template>
  <v-row justify="center">
    <v-dialog
      @keydown.esc="closeModal()"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Pobieranie raportu kodów rabatowych</v-toolbar-title>
        </v-toolbar>
        <v-card-actions class="bold pt-5 content">
          <div class="d-flex">
            <div class="d-flex flex-column">
              <label class="text-xl">Data od</label>
              <v-date-picker
                :show-current="false"
                v-model="dateFrom"
                color="indigo"
                class="mr-12"
              />
            </div>
            <div class="d-flex flex-column">
              <label class="ml-12">Data do</label>
              <v-date-picker
                :show-current="false"
                v-model="dateTo"
                color="indigo"
                class="ml-12"
              />
            </div>
          </div>
          <div>
            <YellowButton
              @click.native="downloadReport()"
              width="100%"
              text="Pobierz raport"
              class="mt-12 mb-12 w-100"
            />
          </div>
        </v-card-actions>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BlackButton from "../buttons/BlackButton";
import YellowButton from "../buttons/YellowButton";
export default {
  name: "PromotionalCodesReportModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BlackButton,
    YellowButton,
  },
  data: () => ({
    dateFrom: "",
    dateTo: "",
  }),
  computed: {},
  methods: {
    async downloadReport() {
      const response = await this.$store.dispatch(
        "promotionalCodes/downloadReport",
        {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        }
      );
      if (response) {
        this.closeModal();
      }
    },

    closeModal() {
      this.$emit("closeModal", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 1%;
  z-index: 10000000;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.content {
  display: flex;
  flex-direction: column;
}
</style>
