<template>
  <div v-if="orders">
    <v-card-actions class="justify-end">
      <YellowButton
        text="Filtruj/Sortuj"
        icon="filter"
        xsmall
        @click.native="filterOrders()"
    /></v-card-actions>
    <v-data-table
      :loading="orders.data.length === 0"
      loading-text="Brak użytkowników"
      :headers="orderHeaders"
      :items="orders.data"
      :search="orderSearch"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.paid`]="{ item }">
        <div>
          <font-awesome-icon v-if="item.paid" icon="check" color="green" />
          <font-awesome-icon v-else icon="times" color="red" />
        </div>
      </template>
      <template v-slot:[`item.invoice`]="{ item }">
        <div v-if="item.invoice && item.invoice.invoicePath">
          <a @click="editInvoice(item)">Edytuj</a>
          <br />
          <div v-if="item.invoice.invoicePath">
            <a
              :href="generateInvoicePdfUrl(item.invoice.invoicePath)"
              target="_blank"
              >Podgląd</a
            >
            <br />
            <a @click="sendInvoiceAgain(item.id)">Wyślij ponownie</a>
          </div>
        </div>
      </template>
    </v-data-table>
    <EditInvoiceModal
      :isVisible="editInvoiceModal"
      @closeModal="editInvoiceModal = false"
      :invoice="invoice"
      :orderId="orderId"
    />
    <FilterOrdersModal
      @fetchOrders="fetchOrders"
      :isVisible="filterOrdersModal"
      @closeModal="filterOrdersModal = false"
    />
  </div>
</template>

<script>
import { URL } from "../../../config";
import EditInvoiceModal from "../../modals/EditInvoiceModal";
import FilterOrdersModal from "../../modals/FilterOrdersModal";
import YellowButton from "../../buttons/YellowButton";
export default {
  components: {
    EditInvoiceModal,
    FilterOrdersModal,
    YellowButton,
  },
  data: () => ({
    invoice: null,
    editInvoiceModal: false,
    filterOrdersModal: false,
    orderId: null,
    orderHeaders: [
      { text: "Kupujący", value: "name" },
      { text: "Email", value: "email" },
      { text: "Kwota", value: "value" },
      { text: "Opłacone", value: "paid" },
      { text: "Id użytkownika", value: "userId" },
      { text: "Faktura", value: "invoice" },
    ],
    orderSearch: "",
  }),
  methods: {
    async filterOrders() {
      this.filterOrdersModal = true;
    },
    fetchOrders(sortingOptions) {
      this.$store.dispatch("payment/getAllOrders", sortingOptions);
    },
    generateInvoicePdfUrl(path) {
      if (path) {
        return URL + "/" + path.split("/")[1] + "/" + path.split("/")[2];
      }
    },
    editInvoice(order) {
      this.invoice = order.invoice;
      this.orderId = order.id;
      this.editInvoiceModal = true;
    },
    sendInvoiceAgain(orderId) {
      var confirm = window.confirm(
        "Przesłać fakturę ponownie? Upewnij się, że dane są poprawne."
      );
      if (confirm) {
        this.$store.dispatch("payment/sendInvoiceAgain", orderId);
      }
    },
  },
  created() {
    this.$store.dispatch("payment/getAllOrders", {});
  },
  computed: {
    orders() {
      return this.$store.getters["payment/getAdminOrders"];
    },
  },
};
</script>

<style></style>
