<template>
  <v-row justify="center" align="center">
    <v-col cols="12" sm="8" md="6" lg="5" xl="3">
      <v-card elevation="0" style="background-color: #fbc501;">
        <v-card-title>
          <v-img class="mx-2 logo" src="../assets/logowhite.svg" contain>
          </v-img>
        </v-card-title>
        <h1
          class="text-center black--text pb-5 font "
          style="color:black; font-size:36px"
        >
          Zresetuj Hasło
        </h1>
        <v-container class="py-10 px-10 text-center">
          <ValidationObserver
            ref="obs"
            v-slot="{ invalid, handleSubmit }"
            mode="eager"
          >
            <v-form @submit.prevent="handleSubmit(onSubmit)">
              <v-card-subtitle
                style="padding:0px 0px 0px 10px"
                class="text-left label"
                >Email</v-card-subtitle
              >
              <VTextFieldWithValidation
                rules="required|email"
                v-model="email"
                color="indigo"
                outlined
                dense
                :disabled="loading"
              />
              <BlackButton
                :disabled="invalid"
                :loading="loading"
                width="100%"
                type="submit"
                text="Resetuj"
                class="mt-5"
              />
              <v-card-actions class="mt-15">
                <v-flex>
                  <v-btn
                    color="grey"
                    to="entry"
                    text
                    :ripple="false"
                    class="small-text-button"
                    style=""
                  >
                    Wróć
                  </v-btn>
                </v-flex>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import VTextFieldWithValidation from "../components/inputs/VTextFieldWithValidation";
import { ValidationObserver } from "vee-validate";
import BlackButton from "../components/buttons/BlackButton";

export default {
  name: "ResetPasswordPage",

  components: { VTextFieldWithValidation, ValidationObserver, BlackButton },

  data: () => ({
    email: "",
    loading: false,
  }),

  methods: {
    onSubmit() {
      this.loading = true;
      const email = this.email;

      this.$store.dispatch("auth/resetPassword", { email }).then(() => {
        this.loading = false;
        this.email = "";
        this.$refs.obs.reset();
      });
    },
  },
};
</script>

<style scoped>
.small-text-button {
  text-decoration: underline;
  font-size: 0.8em;
}
.small-text-button::before {
  background-color: transparent;
}
.label {
  font-size: 0.8rem;
}
.logo {
  height: 150px;
}
</style>
