<template>
  <v-btn
    rounded
    elevation="2"
    :to="to"
    :width="width"
    :height="height"
    :x-large="xlarge"
    :loading="loading"
    dark
    color="black"
    :class="{ 'disable-events': disabled, grad: gradient }"
    class="text-none shadow"
    :small="small"
    :fab="fab"
    :depressed="depressed"
  >
    <font-awesome-icon
      v-if="icon"
      :icon="icon"
      color="white"
      :class="iconMargin"
    />
    {{ text }}</v-btn
  >
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    xlarge: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    depressed: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconMargin: {
      type: String,
      default: "mr-2",
    },
  },
};
</script>

<style scoped>
.disable-events {
  pointer-events: none;
}
</style>
