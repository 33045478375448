<template>
  <div>
    <div class="section-payment grey-background" id="section-payment">
      <div class="section-payment__title">
        <h1>plany</h1>
        <h5>aplikacji</h5>
        <h3>Subskrypcja wygasa</h3>
        <h3>{{ formatDate(user.subscriptionExpirationDate) }}</h3>
      </div>
      <v-container>
        <v-row class="responsive-4">
          <v-col
            v-for="(item, index) in payments"
            :key="index"
            cols="12"
            sm="6"
            lg="3"
            class="section-payment__item"
          >
            <div
              class="item"
              :class="item.yellow_background ? 'bg-yellow' : 'bg-white'"
            >
              <small v-if="item.discount" class="item__promotion"
                >PROMOCJA</small
              >
              <p
                :class="
                  item.yellow_background
                    ? 'item__desc--uppercase'
                    : 'item__desc--capitalize'
                "
                class="item__desc"
              >
                {{ item.desc }}
              </p>
              <div v-if="item.discount" class="item__price strikethrough">
                <span class="item__price--small" v-if="item.month"
                  >{{ item.price }} / MIESIĄC</span
                >
              </div>
              <div class="item__price">
                <span
                  :class="
                    item.yellow_background
                      ? 'item__price--black'
                      : 'item__price--yellow'
                  "
                  class="item__price--big"
                  >{{ item.discount ? item.discount : item.price }}
                </span>
                <span class="item__price--small" v-if="item.month"
                  >/ MIESIĄC</span
                >
              </div>
              <div v-if="!item.yellow_background" class="item__price--summary">
                Całkowita kwota do zapłaty
                {{ item.discount ? item.totalDiscount : item.totalPrice }} zł
                <span v-if="item.discount"
                  >/ zamiast {{ item.totalPrice }} zł</span
                >
              </div>
              <button
                @click="
                  onClick(
                    item.id,
                    item.discount ? item.totalDiscount : item.totalPrice
                  )
                "
                :class="
                  item.yellow_background
                    ? 'button--white-shadow'
                    : 'button--white'
                "
                class="item__button button"
              >
                {{ item.button }}
              </button>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <PaymentModal
        :isVisible="modalVisible"
        @closeModal="modalVisible = false"
        :paymentBody="paymentBody"
        :price="selectedPrice"
      />
    </div>
  </div>
</template>

<script>
import shared from "../shared";
import PaymentModal from "../components/modals/PaymentModal";
export default {
  components: {
    PaymentModal,
  },
  async created() {
    await this.$store.dispatch("payment/fetchPaymentOptions");
    await this.$store.dispatch("user/fetchUser");
    await this.$store.dispatch("user/fetchLatestInvoiceData");
    this.payments = this.$store.getters["payment/getPaymentOptions"].map(
      (item) => {
        const month =
          item.numberOfMonth === 1
            ? "miesiąc"
            : [2, 3, 4].includes(item.numberOfMonth)
            ? "miesiące"
            : "miesięcy";
        const popular = item.numberOfMonth === 6 ? true : false;
        return {
          id: item.id,
          desc: `abonament na ${item.numberOfMonth} ${month}`,
          price: parseFloat(item.price / item.numberOfMonth).toFixed(2),
          totalPrice: item.price,
          totalDiscount: item.discount,
          discount: item.discount
            ? parseFloat(item.discount / item.numberOfMonth).toFixed(2)
            : null,
          month: true,
          button: "Kupuję",
          popular,
          yellow_background: false,
        };
      }
    );
  },
  computed: {
    transaction: function () {
      return this.$store.getters["payment/getTransInfo"];
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
    latestInvoiceData() {
      return this.$store.getters["user/getLatestInvoiceData"];
    },
  },
  data: function () {
    return {
      modalVisible: false,
      payments: [],
      subscriptionId: null,
      buyer: {
        name: "",
        address: "",
        postalCode: "",
        city: "",
        nip: "",
      },
      paymentBody: null,
      selectedPrice: null,
    };
  },
  methods: {
    roundNumber(number, precision) {
      return shared.roundNumber(number, precision);
    },
    calcMonthPrice(price, discount, numberOfMonth) {
      return this.roundNumber((price - discount) / numberOfMonth, 2);
    },
    formatDate(date) {
      return `${shared.formattedDate(
        date
      )} o ${shared.formattedTimeWithoutSeconds(date)}`;
    },
    onClick(subscriptionId, price) {
      this.selectedPrice = price;
      const { email, fullname } = this.user;
      const { name, postalCode, city, address, nip, isCompany } =
        this.latestInvoiceData;
      this.buyer = {
        name: name ? name : fullname ? fullname : "",
        postalCode: postalCode || "",
        city: city || "",
        address: address || "",
        nip: nip || "",
      };
      this.paymentBody = {
        email,
        name: fullname ? fullname : "Brak",
        subscriptionId,
        buyer: this.buyer,
        isCompany: isCompany ? isCompany : false,
        promotionalCode: "",
      };
      this.modalVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.strikethrough {
  text-decoration: line-through;
}
</style>
