<template>
  <ValidationProvider
    :name="$attrs.label"
    :rules="rules"
    v-slot="{ errors }"
    :vid="vid"
  >
    <v-checkbox
      :color="color ? color : '#fbc501'"
      :error-messages="errors"
      v-model="innerValue"
      v-on="$listeners"
      v-bind="$attrs"
      :label="label"
    >
      <template v-if="!label" v-slot:label>
        <div v-if="regulation" :class="{ redText: errors.length > 0 }">
          Akceptuję
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a target="_blank" href="/regulations" @click.stop v-on="on">
                regulamin
              </a>
            </template>
            Wyświetl regulamin
          </v-tooltip>
          aplikacji
        </div>
        <div v-else-if="privacy" :class="{ redText: errors.length > 0 }">
          Akceptuję
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a target="_blank" href="/privacy" @click.stop v-on="on">
                politykę prywatności
              </a>
            </template>
            Wyświetl politykę prywatności
          </v-tooltip>
          aplikacji
        </div>
        <div v-else>
          <slot name="label"></slot>
        </div>
      </template>
    </v-checkbox>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
    vid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    filled: {
      type: Boolean,
      default: false,
    },
    privacy: {
      type: Boolean,
      default: false,
    },
    regulation: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      if (
        this.type === "number" &&
        this.innerValue.toString().match(/^[0-9]+,[0-9]+$/)
      ) {
        this.innerValue = this.innerValue.toString().replace(",", ".");
      }

      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-messages__message {
  padding-left: 21px !important;
}
.redText {
  color: #ff5252 !important;
}
</style>
