<template>
  <div class="img-container" v-if="pdfUrls && pdfUrls.length > 0">
    <div class="page" v-for="pdfUrl in pdfUrls" :key="pdfUrl">
      <img :src="pdfUrl" />
    </div>
  </div>
  <div v-else>Podgląd niedostępny</div>
</template>


<script>
export default {
  components: {},
  name: "PdfPreviewPage",
  data: function () {
    return {};
  },

  async created() {
    if (!this.pdfUrls || this.pdfUrls.length === 0) {
      const jpgPaths = window.localStorage.getItem("pdfPreviewUrls");
      await this.$store.dispatch(
        "publics/setPdfPreviewUrls",
        JSON.parse(jpgPaths)
      );
    }
  },
  computed: {
    pdfUrls() {
      return this.$store.getters["publics/getPdfPreviewUrls"];
    },
  },
};
</script>

<style scoped>
.page {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.img-container {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
img {
  padding: 5px;
  border: 1px solid rgba(193, 193, 193, 0.5);
  max-width: 100%;
  max-height: 90vh;
  margin: auto;
}
</style>
