<template>
  <div class="regulations">
    <div class="image">
      <img src="../assets/logowhite.svg" />
    </div>

    <div class="mt-4 content">
      <div class="text-center mb-7">
        <h1 class="black--text py-3 display-2">
          <span class="font">Regulamin!</span>
        </h1>

        <font-awesome-icon size="7x" icon="gavel" />
      </div>

      <P STYLE="margin-bottom: 0in; line-height: 150%"
        ><FONT FACE="Arial, serif"
          ><FONT SIZE="4"
            ><B>Regulamin Aplikacji webowej forMYever office</B></FONT
          ></FONT
        ></P
      >
      <br />
      <P STYLE="margin-bottom: 0in; line-height: 150%"
        ><FONT FACE="Arial, serif"
          ><FONT SIZE="4"
            ><B
              >Regulamin dostępu do
              aplikacji&nbsp;forMyever&nbsp;office&nbsp;firmy NDM sp. z
              o.o.&nbsp;</B
            ></FONT
          ></FONT
        ><FONT FACE="Arial, serif">&nbsp;</FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>Postanowienia ogólne&nbsp;</B></FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>I PREFABUŁA</B></FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >1. Niniejszy Regulamin określa zasady świadczenia drogą elektroniczną
          przez NDM sp.&nbsp;z o.o.&nbsp;&nbsp;</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >usług w zakresie korzystania z aplikacji&nbsp;forMYever office.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >2. Każdy Użytkownik z chwilą podjęcia czynności zmierzających do
          korzystania ze strony internetowej/aplikacji zobowiązany jest do
          zapoznania, przestrzegania oraz akceptacji Regulaminu bez ograniczeń i
          zastrzeżeń.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >3. W przypadku niewyrażenia zgody na wszystkie zapisy Regulaminu
          należy zaprzestać korzystania z aplikacji/ strony internetowej.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>II DEFINICJE</B></FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">1.</FONT
        ><FONT FACE="Arial, serif">
          Użyte w Regulaminie pojęcia definiuje się następująco:&nbsp;</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >NDM sp. z o.o. z siedzibą w Warszawie przy ul.Twarda 18, 00-105
          Warszawa zapewniający i nadzorujący dostęp do aplikacji forMYever
          office.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >Hasło&nbsp;– ciąg znaków podawany przez Użytkownika podczas tworzenia
          Konta Użytkownika; wraz z Loginem umożliwia dostęp do Konta
          Użytkownika; Hasło składa się z minimum 6 znaków alfanumerycznych
          (cyfr, liter, znaków specjalnych); system logowania rozróżnia małe i
          wielkie litery.&nbsp;</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >Konto Użytkownika lub Konto&nbsp;– utworzone w&nbsp;forMYever office
          (po skutecznej rejestracji konta)</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%">
        <FONT FACE="Arial, serif"
          >indywidualne konto w systemie elektronicznym, przez które zapewniany
          jest dostęp do usług&nbsp;&nbsp; forMYever office ; dostęp do Konta
          Użytkownika możliwy jest przez wprowadzenie ustalonych przez
          Użytkownika Loginu i Hasła.&nbsp;</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >Login&nbsp;– nazwa podawana przez Użytkownika podczas tworzenia Konta
          Użytkownika służąca do procesu logowania się do&nbsp; forMYever
          office.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >Odbiorca lub Użytkownik&nbsp;– osoba fizyczna, w tym także prowadząca
          działalność gospodarczą (przedsiębiorca), osoba prawna albo jednostka
          organizacyjna nieposiadająca osobowości prawnej, korzystająca lub
          chcąca skorzystać z aplikacji forMYever office w wyniku utworzenia
          Konta Użytkownika i Akceptacji Regulaminu dostępu do aplikacji
          forMYever office.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >forMYever office– serwis teleinformatyczny należący do NDM sp.&nbsp;z
          o.o.umożliwiający Użytkownikowi – za pośrednictwem środków komunikacji
          elektronicznej – dostęp do&nbsp;forMYever office &nbsp;możliwy jest za
          pośrednictwem strony internetowej</FONT
        ><FONT FACE="Arial, serif">.</FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >Przeglądarka Internetowa&nbsp;– program komputerowy umożliwiający
          przeglądanie dokumentów w standardzie HTML.&nbsp;</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >Regulamin&nbsp;– niniejszy regulamin.&nbsp;</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >Rejestracja Konta&nbsp;– procedura umożliwiająca utworzenie Konta
          Użytkownika, wymagająca podania odpowiednich danych, tj. określenia
          loginu oraz podania adresu e-mail, a także ustanowienia
          hasła.&nbsp;</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >Prawo właściwe – do celów realizacji Regulaminu zastosowanie ma prawo
          polskie.</FONT
        ></P
      >
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        >&nbsp;</P
      >
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        ><BR />
      </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>III ZAKRES WARUNKÓW</B></FONT></P
      >
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        ><BR />
      </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">1. </FONT
        ><FONT FACE="Arial, serif"
          >Niniejszy regulamin, zwany dalej Regulaminem, określa zasady
          korzystania z&nbsp;Aplikacji.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">2.</FONT
        ><FONT FACE="Arial, serif"
          >Regulamin, o&nbsp;którym mowa w&nbsp;punkcie 1 jest regulaminem
          w&nbsp;rozumieniu art. 8 ustawy z&nbsp;dnia 18 lipca 2002 r.
          o&nbsp;świadczeniu usług drogą elektroniczną (Dz.U. z&nbsp;2019 r.
          poz. 123).</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">3. </FONT
        ><FONT FACE="Arial, serif"
          >Aplikacja oraz wszelkie zawarte w&nbsp;niej materiały redakcyjne
          stanowią wyłączną własność Usługodawcy </FONT
        ><FONT FACE="Arial, serif"
          >- z wyłączeniem planu marketingowego,katalogu produktów oraz
          zaprezentowanego modelu biznesowego i sposobu rozliczeń
        </FONT>
      </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">4. </FONT
        ><FONT FACE="Arial, serif"
          >Treść forMYever office, w tym wszelkie zamieszczone tam materiały
          służą wyłącznie celom poglądowym i nie mogą być przedmiotem roszczeń
          Użytkowników podejmujących na ich podstawie jakiekolwiek
          decyzje.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >5. NDM sp. z o. o. posiada prawa do dóbr niematerialnych w postaci
          projektu graficznego forMYever office, struktury forMYever office, a
          także wszelkich oznaczeń przedsiębiorstwa i znaków towarowych
          wykorzystywanych w ramach forMYever office.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >6. Właściciel ma prawo zamieszczania treści reklamowych, które
          stanowią integralną część serwisu i prezentowanych w nim treści.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >7. Niniejszy Regulamin oraz kolejne jego wersje są dostępne na
          stronie internetowej formy</FONT
        ><FONT FACE="Arial, serif">everoffice.com</FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >8. Z aplikacji można korzystać za pomocą </FONT
        ><FONT FACE="Arial, serif">
          urządzeń z zapewnionym dostępem do internetu.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >9.Usługi świadczone za pośrednictwem Aplikacji:</FONT
        ></P
      >
      <UL>
        <LI
          ><P STYLE="margin-bottom: 0in; line-height: 100%"
            ><FONT FACE="Arial, serif"
              >usługi bezpłatne - w&nbsp;ramach 7 dniowego testu.</FONT
            ></P
          > </LI
        ><LI
          ><P STYLE="margin-bottom: 0in; line-height: 100%"
            ><FONT FACE="Arial, serif"
              >usługi odpłatne - dostęp do aplikacji jest odpłatny. Dostęp do
              forMYever office wymaga posiadania wykupionego aktualnego
              abonamentu. Korzystanie z&nbsp;usług odpłatnych wymaga zalogowania
              się do Aplikacji </FONT
            ><FONT FACE="Arial, serif">f</FONT
            ><FONT FACE="Arial, serif">orMYever office.</FONT></P
          ></LI
        >
      </UL>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >10.Koszty transmisji danych wymaganych do pobrania, instalacji
          i&nbsp;korzystania z&nbsp;Aplikacji pokrywa Użytkownik Aplikacji we
          własnym zakresie na podstawie umowy zawartej z&nbsp;operatorem usługi
          dostępu do sieci internet. Usługodawca nie ponosi odpowiedzialności
          za&nbsp;wysokość opłat naliczanych z&nbsp;tytułu wykorzystania
          transmisji danych niezbędnej do korzystania z&nbsp;Aplikacji.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >11. Aby utworzyć konto i korzystać z naszych Usług, Użytkownik musi
          mieć ukończone 13 lat (albo spełniać minimalne kryterium wieku w swoim
          Kraju Zamieszkania określone w procesie rejestracji).
        </FONT>
      </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >12. Korzystanie z Aplikacji jest równoznaczne z wyrażeniem zgody na
          otrzymywanie wiadomości mailowych od formyeveroffice.com, które
          zapewnią dostęp do informacji o aplikacji.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>IV W</B></FONT
        ><FONT FACE="Arial, serif"
          ><B>YMAGANIA TECHNICZNE I ZASADY KORZYSTANIA</B></FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">1. </FONT
        ><FONT FACE="Arial, serif"
          >Aplikacja obsługiwana jest przez wszelkiego rodzaju przeglądarki
          internetowe. Nie wymaga żadnych szczególnych właściwości urządzenia
          końcowego Użytkownika.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">2. </FONT
        ><FONT FACE="Arial, serif"
          >Do uruchomienia i&nbsp;prawidłowego działania Aplikacji niezbędny
          jest dostęp do sieci internetowej.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">3. </FONT
        ><FONT FACE="Arial, serif">
          Po zaakceptowaniu warunków, Użytkownik ma prawo przeglądać, kopiować,
          drukować</FONT
        ><FONT FACE="Arial, serif">, a także</FONT
        ><FONT FACE="Arial, serif">
          rozpowszechniać, bez dokonywania zmian w treści, zawartość niniejszej
          aplikacji, pod warunkiem, że:</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >a) treści te będą wykorzystywane wyłącznie informacyjnie.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >b) Każda wykonana kopia będzie zawierała informację na temat praw
          autorskich lub dane dotyczące autora treści.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">4.</FONT
        ><FONT FACE="Arial, serif"
          >Użytkownik zobowiązany jest do korzystania z&nbsp;Aplikacji:</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">a) </FONT
        ><FONT FACE="Arial, serif"
          >w sposób zgodny z&nbsp;obowiązującym prawem, niniejszym Regulaminem
          i&nbsp;regulaminami sklepów, w&nbsp;których Aplikacja została
          udostępniona,</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">b) </FONT
        ><FONT FACE="Arial, serif"
          >w sposób zgodny z&nbsp;zasadami współżycia społecznego, w&nbsp;tym
          ogólnymi zasadami korzystania z&nbsp;sieci internet i&nbsp;aplikacji
          mobilnych.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">c) </FONT
        ><FONT FACE="Arial, serif"
          >w sposób nieuciążliwy dla innych Użytkowników oraz operatora usługi
          dostępu do sieci internet,</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">d) </FONT
        ><FONT FACE="Arial, serif"
          >z poszanowaniem dóbr osobistych osób trzecich (w tym prawa do
          prywatności) i&nbsp;wszelkich innych przysługującym im praw.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">2. </FONT
        ><FONT FACE="Arial, serif"
          >Użytkownik zobowiązany jest do korzystania z&nbsp;wszelkich
          informacji i&nbsp;materiałów udostępnionych za</FONT
        ><FONT FACE="Arial, serif"> </FONT
        ><FONT FACE="Arial, serif"
          >pośrednictwem Aplikacji jedynie w&nbsp;zakresie dozwolonego
          użytku.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">3. </FONT
        ><FONT FACE="Arial, serif"
          >Użytkownik jest zobowiązany niezwłocznie powiadomić Usługodawcę
          o&nbsp;każdym przypadku naruszenia praw w&nbsp;związku
          z&nbsp;korzystaniem z&nbsp;Aplikacji.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">4. </FONT
        ><FONT FACE="Arial, serif"
          >Użytkownik może w&nbsp;dowolnym czasie przestać korzystać
          z&nbsp;Aplikacji.
        </FONT>
      </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">5. </FONT
        ><FONT FACE="Arial, serif"
          >W przypadku stwierdzenia, że Użytkownik dopuszcza się działań
          zabronionych prawem lub Regulaminem, albo naruszających zasady
          współżycia społecznego lub godzących w&nbsp;usprawiedliwiony interes
          Usługodawcy, Usługodawca może podjąć działania, w&nbsp;tym ograniczyć
          możliwość korzystania przez Użytkownika z&nbsp;Aplikacji
          i&nbsp;świadczonych za jej pośrednictwem usług.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">6. </FONT
        ><FONT FACE="Arial, serif"
          >Użytkownik korzysta z&nbsp;Aplikacji dobrowolnie, na własną
          odpowiedzialność.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">7. </FONT
        ><FONT FACE="Arial, serif"
          >Korzystanie z Aplikacji oznacza akceptację treści niniejszego
          Regulaminu przez Użytkownika.&nbsp; W przypadku braku zgody
          Użytkownika na akceptację Regulaminu, należy odinstalować
          Aplikację.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>V P</B></FONT
        ><FONT FACE="Arial, serif"><B>RAWA AUTORSKIE</B></FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">1. </FONT
        ><FONT FACE="Arial, serif"
          >Majątkowe prawa autorskie do treści publikowanych w Aplikacji należą
          do Usługodawcy. Prawa autorskie podlegają ochronie przewidzianej
          w&nbsp;ustawie z&nbsp;dnia 4 lutego 1994 roku o&nbsp;prawie autorskim
          i&nbsp;prawach pokrewnych (Dz.&nbsp;U. z&nbsp;2018&nbsp;r.
          poz.&nbsp;1191 ze zm.).</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">2.</FONT
        ><FONT FACE="Arial, serif"
          >Korzystanie z&nbsp;treści publikowanych w&nbsp;Aplikacji nie oznacza
          nabycia przez Użytkownika jakichkolwiek praw autorskich. Użytkownik
          może korzystać z&nbsp;tych treści jedynie w&nbsp;zakresie dozwolonym
          w&nbsp;Ustawie o&nbsp;prawie autorskim i&nbsp;prawach
          pokrewnych.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">3.</FONT
        ><FONT FACE="Arial, serif"
          >Żadna część, jak i&nbsp;całość treści, z&nbsp;których Użytkownik
          korzystał, nie może być powielana i&nbsp;rozpowszechniana,
          w&nbsp;jakiejkolwiek formie i&nbsp;w&nbsp;jakikolwiek inny sposób
          (elektroniczny lub mechaniczny), włącznie z&nbsp;kopiowaniem
          i&nbsp;zamieszczaniem w&nbsp;internecie. Użycie lub wykorzystanie
          treści w&nbsp;całości lub w&nbsp;części, bez pisemnej zgody
          Usługodawcy jest zabronione.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>VI O</B></FONT
        ><FONT FACE="Arial, serif"><B>CHRONA DANYCH OSOBOWYCH</B></FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">1. </FONT
        ><FONT FACE="Arial, serif"
          >Aplikacja przetwarza dane osobowe w postaci adresu e-mail podawanego
          jako Login w procesie aktywacji Aplikacji danymi formyever office w
          celu umożliwienia bezpiecznego, autoryzowanego dostępu do usług
          w&nbsp;ramach Aplikacji zgodnie z licencją.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">2. </FONT
        ><FONT FACE="Arial, serif"
          >Usługodawca informuje, że w celu realizacji usługi dostępu niezbędne
          jest przetwarzanie danych eksploatacyjnych obejmujących datę oraz czas
          logowania.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">3.</FONT
        ><FONT FACE="Arial, serif">
          Informacje wysyłane są do Usługodawcy w&nbsp;procesie logowania do
          Aplikacji i&nbsp;wykorzystywane w&nbsp;celu świadczenia usługi dostępu
          do materiału udostępnianego przez Aplikację w&nbsp;ramach wykupionej
          prenumeraty oraz wsparcia technicznego w przypadku reklamacji.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">4</FONT
        ><FONT FACE="Arial, serif"
          >. Użytkownik instalując aplikację akceptuje warunki Regulaminu i
          akceptuje przetwarzanie danych opisanych w pkt. VI</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">5. </FONT
        ><FONT FACE="Arial, serif"
          >Instalacja Aplikacji jest dobrowolna, ale niezbędna do uruchomienia
          Aplikacji.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >6. Dane użytkownika są usuwane automatycznie po upływie roku od
          nieprzedłużenia ostatniej subskrypcji lub na prośbę użytkownika,
          ewentualnie przez samego użytkownika w dowolnym momencie w
          ustawieniach aplikacji.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">7. </FONT
        ><FONT FACE="Arial, serif"
          >Usługodawca przetwarza dane osobowe zgodnie z obowiązującymi
          przepisami prawa i&nbsp;</FONT
        ><A target="_blank" HREF="https://www.gofin.pl/polityka-prywatnosci"
          ><FONT COLOR="#ff0000"
            ><FONT FACE="Arial, serif"><U>polityką prywatności</U></FONT></FONT
          ></A
        ><FONT COLOR="#ff0000"><FONT FACE="Arial, serif">.</FONT></FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>VII O</B></FONT
        ><FONT FACE="Arial, serif"><B>DPOWIEDZIALNOŚĆ USŁUGODAWCY</B></FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">1. </FONT
        ><FONT FACE="Arial, serif"
          >Użytkownik przyjmuje do wiadomości, że Aplikacja jest programem
          komputerowym, na którego funkcjonowanie ma wpływ szereg czynników
          wymagających współistnienia programu z innymi programami
          komputerowymi, sterownikami podzespołów komputerowych, przeglądarkami
          internetowymi, innymi urządzeniami, w tym sieciami komputerowymi czy
          infrastrukturą operatorów telekomunikacyjnych.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">2.</FONT
        ><FONT FACE="Arial, serif"
          >Użytkownik przyjmuje do wiadomości, iż mogą zachodzić okoliczności
          wpływające na działanie Aplikacji, niebędące w zakresie
          odpowiedzialności Usługodawcy, co do których nie jest możliwe
          wyczerpujące ich zbadanie oraz zapobieganie ewentualnym
          nieprawidłowościom funkcjonowania Aplikacji.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">3.</FONT
        ><FONT FACE="Arial, serif"
          >W związku z powyższym żadnego postanowienia Regulaminu, jak również
          żadnego elementu treści stron internetowych należących do Usługodawcy,
          nie należy odczytywać jako gwarancji prawidłowego działania Aplikacji,
          a Użytkownik pomimo braku takiej gwarancji samodzielnie podejmuje
          decyzję o rozpoczęciu korzystania z Aplikacji, mając przede wszystkim
          na uwadze, że Aplikacja nie została zaprojektowana w sposób
          zapewniający optymalne jej funkcjonowanie na urządzeniu posiadanym
          przez Użytkownika w stosowanej przez niego konfiguracji.</FONT
        ></P
      >
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        ><BR />
      </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>VIII </B></FONT
        ><FONT FACE="Arial, serif"
          ><B
            >ODSTĄPIENIE OD UMOWY SPRZEDAŻY USŁUGI ELEKTRONICZNEJ ZAWARTEJ NA
            ODLEGŁOŚĆ PRZEZ UŻYTKOWNIKA</B
          ></FONT
        ><FONT FACE="Arial, serif"><B> </B></FONT>
      </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>Zasady zwrotów</B></FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >1. Użytkownik ma prawo odstąpić od złożonego zamówienia w ciągu 14dni
          od otrzymania potwierdzenia bez podania przyczyny.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >2. Aby dotrzymać terminu odstąpienia od umowy, należy wysłać stosowną
          informację przed wygaśnięciem 14 dniowego okresu. Aby odstąpić od
          zamówienia, należy poinformować ForMyEver Office o takiej decyzji.
          Celem zapewnienia bezzwłocznej realizacji odstąpienia zalecamy
          wysłanie wiadomości e-mail na adres kontakt@formyeveroffice.com.
          Oświadczenie o&nbsp;odstąpieniu od umowy Użytkownik może złożyć na
          formularzu:</FONT
        ></P
      >
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">Wzór formularza odstąpienia:</FONT></P
      >
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >- Do NDM sp. z o.o. 00-105 Warszawa, ul. Twarda 18</FONT
        ></P
      >
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >- Niniejszym oświadczam, że odstępuję od umowy dotyczącej:</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"> - Zamówienie z dnia [WSTAWIĆ DATĘ] </FONT>
      </P>
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">- Imię i nazwisko klienta</FONT></P
      >
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">- Adres klienta</FONT></P
      >
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >- Adres e-mail klienta (opcjonalnie)</FONT
        ></P
      >
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">- Data</FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >3. W razie skutecznego odstąpienia od umowy, umowa sprzedaży jest
          uważana za niezawartą, a&nbsp;Użytkownik będący konsumentem jest
          zwolniony z&nbsp;wszelkich zobowiązań.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >4. Skutki odstąpienia: Dokonamy zwrotu płatności nie później niż w
          ciągu 14 dni od dnia otrzymania informacji o odstąpieniu. Skorzystamy
          z tej samej metody płatności, która została użyta podczas Transakcji i
          nie pobierzemy żadnych opłat za zwrot.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">5. Wyjątki od prawa do odstąpienia: </FONT>
      </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >a) Nie można odstąpić od zamówienia dotyczącego dostarczania
          Aplikacji, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną
          prośbą i zgodą użytkownika, co oznacza utratę prawa do odstąpienia.
        </FONT>
      </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"
          >b) Użytkownik będący konsumentem, rozpoczynając korzystanie
          z&nbsp;usługi elektronicznej przed upływem 14 dni od zawarcia umowy
          wyraża zgodę na rozpoczęcie świadczenia usługi przed upływem terminu
          do odstąpienia od umowy zgodnie z&nbsp;art. 38 pkt 13 ustawy
          z&nbsp;dnia 30 maja 2014 r. o&nbsp;prawach konsumenta (Dz. U. z 2019
          r. poz. 134). Po&nbsp;wystąpieniu tych zdarzeń Użytkownik traci prawo
          do odstąpienia od umowy.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>Uwaga:</B></FONT
        ><FONT FACE="Arial, serif">
          aplikację lub grę możesz zwrócić tylko raz, by otrzymać zwrot środków.
          Jeśli kupisz ją ponownie, kolejny zwrot będzie niemożliwy. Jeśli
          otrzymasz zwrot środków, stracisz dostęp do zakupionego
          produktu.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">6. </FONT
        ><FONT FACE="Arial, serif"
          >Rozpoczęcie korzystania z&nbsp;usługi elektronicznej następuje po
          akceptacji niniejszego Regulaminu.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>IX </B></FONT
        ><FONT FACE="Arial, serif"><B>P</B></FONT
        ><FONT FACE="Arial, serif"><B>ROCEDURA REKLAMACYJNA</B></FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">1. </FONT
        ><FONT FACE="Arial, serif"
          >Wszelkie reklamacje dotyczące korzystania z&nbsp;Aplikacji należy
          zgłaszać w&nbsp;terminie 14 dni od&nbsp;stwierdzenia jakichkolwiek
          nieprawidłowości.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">2. </FONT
        ><FONT FACE="Arial, serif"
          >Zgłoszenia reklamacji można dokonać:</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">a) </FONT
        ><FONT FACE="Arial, serif"
          >za pośrednictwem poczty elektronicznej na adres e-mail:&nbsp;</FONT
        ><FONT FACE="Arial, serif"> kontakt@formyeveroffice.com</FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">b)</FONT
        ><FONT FACE="Arial, serif">pod numerem telefonu </FONT
        ><FONT FACE="Arial, serif"> 735 427 558</FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">3. </FONT
        ><FONT FACE="Arial, serif"
          >Zgłoszenie reklamacji powinno zawierać: treść reklamacji oraz adres
          kontaktowy, na&nbsp;który ma zostać wysłana informacja zwrotna.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">4. </FONT
        ><FONT FACE="Arial, serif"
          >Czas na rozpatrzenie reklamacji i&nbsp;udzielenie informacji
          zwrotnych wynosi 7&nbsp;dni.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">5. </FONT
        ><FONT FACE="Arial, serif"
          >Odpowiedzi na reklamacje będą przesyłane pocztą elektroniczną, pocztą
          tradycyjną, lub&nbsp;telefonicznie, zgodnie z&nbsp;danymi kontaktowymi
          podanymi przez składającego reklamację w&nbsp;zgłoszeniu
          reklamacyjnym.</FONT
        ></P
      >
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 100%"
        ><BR />
      </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif"><B>X</B></FONT
        ><FONT FACE="Arial, serif"><B> P</B></FONT
        ><FONT FACE="Arial, serif"><B>OSTANOWIENIA KOŃCOWE</B></FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">1. </FONT
        ><FONT FACE="Arial, serif"
          >W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie mają
          odpowiednie przepisy prawa polskiego, a&nbsp;w&nbsp;szczególności
          Kodeksu cywilnego i ustawy o&nbsp;świadczeniu usług drogą
          elektroniczną.</FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">2. </FONT
        ><FONT FACE="Arial, serif"
          >Niniejszy regulamin dostępny jest pod adresem: </FONT
        ><FONT FACE="Arial, serif">formyeveroffice.com</FONT></P
      >
      <P STYLE="margin-bottom: 0in; line-height: 100%"
        ><FONT FACE="Arial, serif">3. </FONT
        ><FONT FACE="Arial, serif">Regulamin obowiązuje od </FONT
        ><FONT FACE="Arial, serif">11.02.2021</FONT></P
      >
      <P
        STYLE="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; font-variant: normal; font-style: normal; line-height: 150%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto"
      >
        <BR />
      </P>
      <P STYLE="margin-left: 0.5in; margin-bottom: 0in; line-height: 150%"
        ><BR />
      </P>
      <P
        STYLE="margin-left: 0.5in; margin-top: 0.13in; margin-bottom: 0.13in; font-variant: normal; font-style: normal; line-height: 115%; widows: 2; orphans: 2"
      >
        <BR /><BR />
      </P>
      <P STYLE="margin-bottom: 0in; line-height: 100%"><BR /> </P>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.regulations {
  max-width: 1000px;
  padding: 10px;
  margin: auto;
  text-align: center;
}
.content {
  text-align: left;
  background-color: white;
  padding: 30px;
  border-radius: 20px;
}
img {
  max-width: 260px;
  transform: translateX(10px);
}
</style>
