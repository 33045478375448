<template>
  <v-row justify="center" style="z-index:9999;">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="400px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Filtruj kalkulacje</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <v-card flat tile elevation="0">
          <v-card-title>Sortowanie</v-card-title>
          <v-card-actions class="flex-column">
            <v-select
              style="width:90%"
              dense
              outlined
              clearable
              label="Sposób sortowania"
              item-value="value"
              item-text="name"
              v-model="selectedSortingType"
              :items="sortingTypes"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
            <v-select
              style="width:90%"
              dense
              outlined
              clearable
              label="Sortuj po"
              item-value="value"
              item-text="name"
              v-model="selectedSortingCategory"
              :items="sortingCategories"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
          </v-card-actions>
          <v-card-title>Filtrowanie</v-card-title>
          <v-card-actions class="flex-column">
            <v-select
              style="width:90%"
              dense
              outlined
              clearable
              label="Wybierz kategorię"
              item-value="name"
              item-text="name"
              v-model="selectedStatus"
              :items="statuses.data"
              :menu-props="{ bottom: true, offsetY: true }"
            >
              <template v-slot:selection="{ item }">
                <span>{{ getStatusName(item.name) }}</span>
              </template>
              <template v-slot:item="slotProps">
                <span>{{ getStatusName(slotProps.item.name) }}</span>
              </template>
            </v-select>
          </v-card-actions>

          <v-card-actions class="flex-column  py-10 px-10"
            ><WhiteButton
              width="100%"
              text="Filtruj"
              border
              class="mt-3 ml-0"
              @click.native="filterCalculations()"
            />
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import WhiteButton from "../buttons/WhiteButton";
import BlackButton from "../buttons/BlackButton";
import shared from "../../shared";
export default {
  name: "FilterCalculationsModal",
  props: ["isVisible"],

  components: {
    WhiteButton,
    BlackButton,
  },
  created() {},

  data: () => ({
    filter: "",
    sortingTypes: [
      { name: "malejąco", value: "DESC" },
      { name: "rosnąco", value: "ASC" },
    ],
    sortingCategories: [
      { name: "Imię", value: "identifier" },
      { name: "Data utworzenia", value: "createdAt" },
    ],
    selectedSortingType: "DESC",
    selectedSortingCategory: "createdAt",
    selectedStatus: "",
  }),
  computed: {
    statuses() {
      return this.$store.getters["calculation/getCalculationStatuses"];
    },
  },
  watch: {},
  methods: {
    getStatusName(status) {
      return shared.formatRole(status);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    filterCalculations() {
      const sorting = {
        sortBy0: this.selectedSortingCategory,
        sortBy1: this.selectedSortingType,
        status: this.selectedStatus,
      };
      this.$emit("fetchCalculations", sorting);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10000000;
}
</style>
