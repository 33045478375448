<template>
  <v-row justify="center" align-content="center">
    <v-col cols="12" class="pb-0">
      <h1 class="text-center">
        Email został wysłany, sprawdź skrzynkę pocztową
      </h1>
    </v-col>
    <v-col cols="10" xl="3" lg="4" md="5" sm="7">
      <v-card
        elevation="0"
        class="text-center mt-10"
        style="background-color:#fbc501;"
      >
        <span
          class="fa-stack check-circle-size"
          style="vertical-align: top;"
        ></span>
        <font-awesome-icon
          color="black"
          icon="circle"
          class=" check-circle-size fa-stack-1x"
        />
        <font-awesome-icon
          color="green"
          class="check-circle-size2 fa-stack-1x"
          icon="check-circle"
        />
      </v-card>
      <br />
      <h4 class="text-center">
        Jeśli email nie dotarł,
        <u style="cursor:pointer;" @click="show = true"> kliknij tutaj!</u>
      </h4>
      <div v-if="show" class="text-center">
        <VTextFieldWithValidation
          type="email"
          rules="email|required"
          v-model="email"
          label="Wprowadź adres email"
          color="indigo"
          outlined
          dense
        />
        <BlackButton
          text="Wyślij kod ponownie!"
          @click.native="sendEmail"
          border
        />
        <div v-if="show2" class="pt-3">
          <router-link style="color:black;" to="/#section-contact"
            ><u>
              W razie dalszych problemów, skontaktuj się z nami.
            </u></router-link
          >
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import VTextFieldWithValidation from "../components/inputs/VTextFieldWithValidation";
import BlackButton from "../components/buttons/BlackButton";
export default {
  components: {
    VTextFieldWithValidation,
    BlackButton,
  },
  data: function() {
    return {
      show: false,
      show2: false,
      email: "",
    };
  },
  methods: {
    async sendEmail() {
      const { email } = this;
      this.show2 = true;
      await this.$store.dispatch("auth/resendVerificationCode", { email });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.row {
  margin: 0;
}
.check-circle-size {
  font-size: 110px !important;
}
.check-circle-size2 {
  font-size: 113px !important;
}
h1 {
  font-family: "BebasNeue", sans-serif !important;
  font-size: 38px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  h1 {
    font-size: 32px;
  }
  .check-circle-size {
    font-size: 80px !important;
  }
  .check-circle-size2 {
    font-size: 83px !important;
  }
}
.fa-stack {
  height: 1em;
}
</style>
