<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="600px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title v-if="!isEdit">Dodaj film</v-toolbar-title>
          <v-toolbar-title v-else>Edytuj film</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field-with-validation
                    v-model="title"
                    label="Tytuł"
                    color="indigo"
                    outlined
                    dense
                    rules="required|max:2048"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <html-editor
                    :editorId="`productEditor`"
                    @contentchanged="handleHtmlEditorForVideo($event)"
                    :startContent="description"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field-with-validation
                    v-model="link"
                    label="Link YT"
                    color="indigo"
                    outlined
                    dense
                    rules="required|link"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              v-if="!isEdit"
              color="success"
              text
              :disabled="invalid"
              v-on:click="addInstructionalVideo()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Dodaj film
            </v-btn>
            <v-btn
              v-else
              color="success"
              text
              :disabled="invalid"
              v-on:click="editInstructionalVideo()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz film
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BlackButton from "../buttons/BlackButton";
import shared from "../../shared";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation.vue";
import HtmlEditor from "../HtmlEditor.vue";

export default {
  name: "InstructionalVideoModal",
  props: ["isVisible", "isEdit"],

  components: {
    ValidationObserver,
    BlackButton,
    VTextFieldWithValidation,
    HtmlEditor,
  },
  created() {},

  data: () => ({
    title: "",
    description: "",
    link: "",
    inputKey: 0,
  }),
  computed: {
    instructionalVideo() {
      return this.$store.getters["instructionalVideo/getInstructionalVideo"];
    },
  },
  watch: {
    instructionalVideo: function () {
      this.title = this.instructionalVideo?.title || "";
      this.description = this.instructionalVideo?.description || "";
      this.link = this.instructionalVideo?.link || "";
    },
  },
  methods: {
    handleHtmlEditorForVideo(newDescription) {
      this.description = newDescription;
    },
    formatRole(role) {
      return shared.formatRole(role);
    },
    closeModal() {
      this.title = "";
      this.description = "";
      this.link = "";
      this.inputKey++;
      this.$refs.obs.reset();
      this.$emit("closeModal");
    },
    async addInstructionalVideo() {
      await this.$store.dispatch("instructionalVideo/addInstructionalVideo", {
        title: this.title,
        description: this.description,
        link: this.link,
      });
      this.closeModal();
    },
    async editInstructionalVideo() {
      await this.$store.dispatch("instructionalVideo/editInstructionalVideo", {
        id: this.instructionalVideo.id,
        data: {
          description: this.description,
          title: this.title,
          link: this.link,
        },
      });
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 1%;
  right: 3%;
  z-index: 10000000;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
</style>
