<template>
  <v-dialog
    @keydown.esc="closeModal()"
    v-model="isVisible"
    :width="dialogWidth"
    v-on:click:outside="closeModal"
    content-class="my-custom-dialog"
  >
    <v-card
      :height="$vuetify.breakpoint.smAndDown ? '70vh' : ''"
      :class="{ overflow: $vuetify.breakpoint.smAndDown }"
    >
      <v-toolbar class="black--text d-flex justify-center" color="#fbc501" tile>
        <v-toolbar-title v-if="!contact && customerId" style="font-size: 0.9rem"
          >Zaplanuj następny kontakt:</v-toolbar-title
        >
        <v-toolbar-title style="font-size: 0.9rem" v-if="userId"
          >Zmień datę subskrypcji</v-toolbar-title
        >
        <v-toolbar-title style="font-size: 0.9rem" v-if="contact && customerId"
          >Edytuj kontakt</v-toolbar-title
        >
      </v-toolbar>
      <BlackButton
        fab
        small
        icon="times"
        iconMargin=""
        class="close"
        @click.native="closeModal()"
      />

      <v-card-text class="px-0 py-0">
        <v-tabs color="#fbc501" fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <font-awesome-icon icon="calendar-alt" size="2x" />
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <font-awesome-icon icon="clock" size="2x" />
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              v-on:input="showTimePicker"
              first-day-of-week="1"
              color="white"
              full-width
              :timezone="`UTC`"
            />
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              v-model="time"
              v-bind="timePickerProps"
              format="24hr"
              color="white"
              full-width
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-card-actions class="d-flex px-2 pt-0 justify-center flex-column">
        <v-checkbox
          v-if="customerId"
          color="#fbc501"
          style="height: 30px"
          v-model="contactMade"
          label="Kontakt odbył się"
        ></v-checkbox>
        <v-text-field
          v-if="customerId"
          class="my-5"
          v-model="topic"
          dense
          placeholder="Temat"
        />
        <YellowButton
          :disabled="this.date === '' || this.time === '00:00:00'"
          text="Ok"
          width="60%"
          @click.native="okHandler()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import YellowButton from "../buttons/YellowButton";
import BlackButton from "../buttons/BlackButton";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00:00";
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_DATE_FORMAT_TEXT = "DD/MM/YYYY";
const DEFAULT_TIME_FORMAT = "HH:mm";
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = "Wyczyść";
const DEFAULT_OK_TEXT = "Zatwierdź";

export default {
  name: "SetDateTimeModal",
  components: {
    YellowButton,
    BlackButton,
  },
  model: {
    prop: "datetime",
    event: "input",
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    timeFormat: {
      type: String,
      default: DEFAULT_TIME_FORMAT,
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT,
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT,
    },
    textFieldProps: {
      type: Object,
    },
    datePickerProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    customerId: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
    contact: {
      type: [String, Object],
      default: "",
    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
      contactMade: false,
      topic: "",
      notDone: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    dateTimeFormat() {
      return this.timeFormat + ", " + this.Date;
    },
    dateTimeFormatText() {
      return this.timeFormat + ", " + DEFAULT_DATE_FORMAT_TEXT;
    },
    defaultDateTimeFormat() {
      return DEFAULT_TIME_FORMAT + ", " + DEFAULT_DATE_FORMAT;
    },
    formattedDatetime() {
      return this.selectedDatetime
        ? moment(this.selectedDatetime).format(this.dateTimeFormat)
        : "";
    },
    formattedDatetimeText() {
      return this.selectedDatetime
        ? moment(this.selectedDatetime).format(this.dateTimeFormatText)
        : "";
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + " " + this.time;
        // if (this.time.length === 5) {
        //     datetimeString += ':00'
        // }
        return moment(datetimeString).toDate();
      } else {
        return null;
      }
    },
    dateSelected() {
      return !this.date;
    },
  },
  methods: {
    init() {
      if (!this.datetime) {
        return;
      }

      let initDateTime;
      if (this.datetime instanceof Date) {
        initDateTime = this.datetime;
      } else if (
        typeof this.datetime === "string" ||
        this.datetime instanceof String
      ) {
        // see https://stackoverflow.com/a/9436948
        initDateTime = moment(this.datetime).toDate();
      }

      this.date = moment(initDateTime).format(DEFAULT_DATE_FORMAT);
      this.time = moment(initDateTime).format(DEFAULT_TIME_FORMAT);
    },
    async okHandler() {
      let dateTime = this.date + " " + this.time + ":00";
      if (this.customerId && !this.contact) {
        let object = {
          customerId: this.customerId,
          contactDate: dateTime,
          topic: this.topic,
          contactMade: this.contactMade,
          contactNotMade: this.notDone,
        };
        await this.$store.dispatch("customers/addContact", object);
      }
      if (this.customerId && this.contact) {
        let object = {
          customerId: this.customerId,
          contactId: this.contact.id,
          contactDate: dateTime,
          topic: this.topic,
          contactMade: this.contactMade,
          contactNotMade: this.notDone,
        };
        await this.$store.dispatch("customers/editContact", object);
      }
      if (this.userId) {
        let object = {
          expiredDate: dateTime,
          id: this.userId,
        };
        this.$store.dispatch("user/updateExpiryDate", object);
      }

      this.closeModal();
    },
    closeModal() {
      this.resetPicker();
      this.topic = "";
      this.contactMade = false;
      this.$emit("closeModal");
    },
    resetPicker() {
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },
  },
  watch: {
    datetime: function () {
      this.init();
    },
    contact() {
      if (this.contact) {
        this.contactMade = this.contact.done;
        this.topic = this.contact.topic;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-picker.v-card.v-picker--date.v-picker--full-width {
  height: 392px;
}

.v-card > :first-child:not(.v-btn):not(.v-chip),
.v-card > .v-card__progress + :not(.v-btn):not(.v-chip),
.v-picker__title {
  border-radius: 0 !important;
}
::v-deep .v-picker__title {
  color: black !important;
}
::v-deep .v-time-picker-title {
  color: black !important;
}
::v-deep .v-time-picker-clock__item {
  color: black !important;
}
::v-deep .v-btn--rounded {
  color: black !important;
}
::v-deep .white--text {
  color: black !important;
}
.close {
  position: absolute;
  top: 1%;
  right: 3%;
  z-index: 10000000;
}
.overflow {
  overflow: scroll;
}

@import "~vuetify/src/styles/settings/_variables";
</style>
