<template>
  <ValidationProvider :name="$attrs.label" :rules="rules" v-slot="{ errors }">
    <v-select
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
      outlined
      dense
      color="black"
      background-color="white"
      :menu-props="{ bottom: true, offsetY: true }"
      rounded
    >
      <template slot="item" slot-scope="slotData"
        ><slot name="item" v-bind="slotData"
      /></template>
      <template slot="selection" slot-scope="slotData"
        ><slot name="selection" v-bind="slotData"
      /></template>
      <!-- <template slot="prepend-item" slot-scope="slotData"><slot name="prepend-item" v-bind="slotData" /></template> -->
    </v-select>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
