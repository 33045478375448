<template>
  <v-row justify="center" dense>
    <v-col cols="12" sm="11" md="9" lg="7" xl="6">
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-space-between pb-10"
        style="background-color: rgba(112, 112, 112, 0.05)"
      >
        <v-card-actions class="flex-column">
          <div class="align-self-start">Klient</div>
          <span
            style="cursor: pointer"
            @click="
              $router.push(`/my-office/customer/${calculation.customer.id}`)
            "
            v-if="calculation.customer"
            class="p-0 display-1"
            >{{ calculation.customer.fullname }}</span
          >
          <span v-else class="p-0 display-1">Brak Klienta</span></v-card-actions
        >
        <v-card-actions>
          <font-awesome-icon
            v-if="!isRealizedAndPaid(calculation)"
            class="mr-5"
            icon="edit"
            style="cursor: pointer"
            @click="moveToEditOfferPage(calculation.id)"
          />
          <font-awesome-icon
            v-if="!isRealizedAndPaid(calculation)"
            icon="eye"
            class="mr-5"
            style="cursor: pointer"
            @click="moveToOfferDetailsPage(calculation.id)"
          />
          <font-awesome-icon
            icon="list"
            class="mr-5"
            style="cursor: pointer"
            @click="moveToOffersListPage()"
          />
        </v-card-actions>
      </v-card>

      <v-card elevation="0" tile class="px-5">
        <v-row>
          <v-col cols="6">
            <div class="time-picker">
              <div class="inputLabel">Data oferty</div>
              <div class="text-center" v-if="calculation.offerDate">
                {{ getFormattedDate(calculation.offerDate) }}
              </div>
              <div v-else class="text-center">Brak</div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="time-picker">
              <div class="inputLabel">Data zamówienia</div>
              <div class="text-center" v-if="calculation.orderDate">
                {{ getFormattedDate(calculation.orderDate) }}
              </div>
              <div v-else class="text-center">Brak</div>
            </div>
          </v-col>
          <!-- <v-col cols="6">
            <div class="inputLabel"><b>Data zamówienia</b></div>
            <p class="inputLabel">
              {{ getFormattedDate(calculation.createdAt) }}
            </p>
          </v-col> -->
        </v-row>
      </v-card>

      <v-card elevation="0" tile class="">
        <v-card-actions class="justify-center bold title">
          <div class="bold">KALKULACJA</div>
        </v-card-actions>
        <v-simple-table
          dense
          v-if="
            calculation.calculation_products &&
            calculation.calculation_products.length != 0
          "
        >
          <template>
            <tbody>
              <tr
                v-for="(item, index) in calculation.calculation_products"
                :key="item.id"
                :class="{ tableColor: index % 2 === 0 }"
              >
                <td class="bold">{{ item.product.name }}</td>
                <td>
                  {{ item.amount }}
                </td>
                <td class="bold">
                  {{ calcProductValue(item) }}
                </td>
                <td>
                  {{ roundNumber(item.product.cc * item.amount, 3) }}
                  CC
                </td>
              </tr>

              <tr v-if="deliveryPriceToSet != null">
                <td class="bold">Dostawa</td>
                <td class="">
                  <span>1</span>
                </td>
                <td class="bold">{{ roundNumber(deliveryPriceToSet, 2) }}zł</td>

                <td>
                  {{ roundNumber(0, 3) }}
                  CC
                </td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td style="width: 40%">
                  <span style="color: #f2be02" class="headline"
                    >{{
                      deliveryPriceToSet
                        ? roundNumber(
                            Number(calculation.totalValue) +
                              Number(deliveryPriceToSet),
                            2
                          )
                        : roundNumber(calculation.totalValue, 2)
                    }}
                    zł</span
                  >
                </td>
                <td>
                  <span class="bold">{{ calculation.ccTotalValue }} CC</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div
          class="text-center"
          v-if="
            calculation.calculation_products &&
            calculation.calculation_products.length === 0
          "
        >
          Brak produktów
        </div>
        <CalculationActionButtons
          :calculation="calculation"
          v-if="calculation"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import shared from "../shared";
import CalculationActionButtons from "../components/CalculationActionButtons.vue";
export default {
  name: "CalculationOfferPage",
  components: {
    CalculationActionButtons,
  },
  data() {
    return {
      deliveryPriceToSet: null,
    };
  },
  methods: {
    isRealizedAndPaid(calculation) {
      if (
        calculation.calculation_status.name === "REALIZED_PAID" &&
        calculation.isPaid
      ) {
        return true;
      } else {
        return false;
      }
    },
    calcProductValue(item) {
      const { discountId, discount } = this.calculation;
      const { product_prices, priceDetal } = item.product;
      const value = discountId
        ? product_prices.find((x) => x.roleId === discountId).value
        : discount && discount > 0
        ? parseFloat(priceDetal - (priceDetal * discount) / 100).toFixed(2)
        : priceDetal;
      return `${value} zł`;
    },

    roundNumber(number, precision) {
      return shared.roundNumber(number, precision);
    },

    moveToEditOfferPage(calculationId) {
      this.$router.replace(`/calculator?id=${calculationId}`);
    },
    moveToOfferDetailsPage(calculationId) {
      this.$router.replace(`/calculation/${calculationId}`);
    },
    moveToOffersListPage() {
      this.$router.replace("/my-office?tab=3");
    },
    getFormattedDate(date) {
      return shared.formattedDatePolish(date);
    },
  },

  async created() {
    await this.$store.dispatch(
      "calculation/fetchCalculationById",
      this.$route.params.calculationId
    );
    if (this.calculation.deliveryPrice) {
      this.deliveryPriceToSet = this.calculation.deliveryPrice;
    }
  },
  computed: {
    calculation() {
      return this.$store.getters["calculation/getCalculation"];
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-data-table
  .v-data-table__wrapper
  table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  .v-data-table__wrapper
  table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.inputLabel {
  font-size: 0.8rem;
  text-align: center;
  width: 100%;
}
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .fontSize {
    font-size: 0.8rem;
  }
}
text {
  color: black !important;
}
.tableColor {
  background: rgb(252, 197, 2, 0.15) !important;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  ::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
    padding-left: 24px;
    padding-right: 13px;
  }
  ::v-deep .v-input__append-inner {
    margin-top: 3px !important;
  }
  ::v-deep .v-input__slot {
    min-height: 30px !important;
    display: flex !important;
    align-items: center !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 0.75rem;
  }
  ::v-deep tr th {
    font-size: 0.75rem !important;
    font-weight: 400;
  }
}
</style>
