<template>
  <div class="action-icons">
    <div class="date">
      <div class="date-box">
        <customer-contact-dots
          :contact="contact"
          @contactsUpdated="handleContactsUpdated"
        />

        {{ formattedTimeWithoutSeconds(contact.date)
        }}{{
          contact.dateTo && contact.dateTo !== contact.date
            ? "-" + formattedTimeWithoutSeconds(contact.dateTo)
            : ""
        }}
      </div>
      <div style="font-weight: 500">{{ contact.customer.fullname }}</div>
    </div>
    <div class="action-icons">
      <font-awesome-icon
        class="action-icon"
        color="red"
        icon="times"
        v-if="contact.done !== true && contact.notDone !== true"
        @click.stop="markContactAsNotDone(contact)"
      />
      <font-awesome-icon
        class="action-icon"
        color="green"
        icon="check"
        v-if="contact.done !== true && contact.notDone !== true"
        @click.stop="markContactAsDone(contact)"
      />
      <font-awesome-icon
        class="action-icon"
        icon="edit"
        color="#3f51b5"
        @click.stop="editContact(contact)"
      />
    </div>

    <span v-if="contact.businessConversation" class="business-conversations">
      {{ extractTextInParentheses(contact.businessConversation) }}
    </span>

    <div
      v-if="isDateAfterNowAndNoActionMade(contact)"
      class="question-container"
    >
      <span class="smaller-font">Kontakt odbył się -</span>
      <font-awesome-icon
        v-if="isDateAfterNowAndNoActionMade(contact)"
        class="action-icon"
        icon="question-circle"
        color="#fbc501"
      />
    </div>
    <div class="smaller-font" v-else-if="contact.done">
      Kontakt odbył się - tak
    </div>
    <div class="smaller-font" v-else-if="contact.notDone">
      Kontakt odbył się - nie
    </div>
    <div v-else class="question-container">
      <span class="smaller-font">Kontakt odbył się -</span>
      <font-awesome-icon
        class="action-icon"
        icon="question-circle"
        color="#fbc501"
      />
    </div>

    <set-date-time-from-to-modal
      :isVisible="contactModal"
      @closeModal="closeSetDateTimeModal()"
      :datetimeFrom="selectedDateTimeFrom"
      :datetimeTo="selectedDateTimeTo"
      :contact="selectedContact"
      :customerId="selectedContact ? selectedContact.customerId : ''"
      @contactsUpdated="handleContactsUpdated"
    />
  </div>
</template>
<script>
import moment from "moment";
import shared from "../shared";
import SetDateTimeFromToModal from "./modals/SetDateTimeFromToModal.vue";
import CustomerContactDots from "./CustomerContactDots.vue";
export default {
  components: { SetDateTimeFromToModal, CustomerContactDots },
  emits: ["contactsUpdated"],
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  name: "CustomerContactActionsIcons",
  data() {
    return {
      contactModal: false,
      selectedDateTimeTo: "",
      selectedDateTimeFrom: "",
      selectedContact: null,
    };
  },
  methods: {
    isDateAfterNowAndNoActionMade(contact) {
      return (
        moment().isAfter(moment(contact.date)) &&
        !contact.done &&
        !contact.notDone
      );
    },
    formattedTimeWithoutSeconds(date) {
      return shared.formattedTimeWithoutSeconds(date);
    },
    extractTextInParentheses(text) {
      const result = text.match(/\((.*?)\)/);
      return result ? result[1] : null;
    },
    async markContactAsDone(contact) {
      let object = {
        customerId: contact.customerId,
        id: contact.id,
      };
      await this.$store.dispatch("customers/markContactAsDone", object);
      this.$emit("contactsUpdated");
    },
    async markContactAsNotDone(contact) {
      let object = {
        customerId: contact.customerId,
        id: contact.id,
      };
      await this.$store.dispatch("customers/markContactAsNotDone", object);
      this.$emit("contactsUpdated");
    },

    editContact(contact) {
      this.selectedContact = contact;
      this.selectedDateTimeFrom = contact.date;
      this.selectedDateTimeTo = contact.dateTo;
      this.contactModal = true;
    },

    async closeSetDateTimeModal() {
      this.contactModal = false;
      this.selectedDateTimeFrom = null;
      this.selectedDateTimeTo = null;
      this.selectedContact = null;
    },
    handleContactsUpdated() {
      this.$emit("contactsUpdated", {
        customer: this.selectedContact?.customerId,
      });
    },
  },
};
</script>

<style scoped>
.action-icons {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 5px;
}
.date {
  margin-right: 3px;
  min-width: 40px;
  align-items: center;
}
.business-conversations {
  border: 1px solid rgba(0, 0, 0, 1);
  padding: 1px 3px;
  width: fit-content;
  color: black;
}
.action-icon {
  margin-top: 2px;
  cursor: pointer;
  margin-right: 3px;
}
.question-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.date-box {
  display: flex;
}
@media (max-width: 768px) {
  .smaller-font,
  .date,
  .business-conversations {
    font-size: 8px;
  }
  .date,
  .smaller-font {
    margin-top: 2px;
  }
}
</style>