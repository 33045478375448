<template>
  <v-row justify="center" style="z-index:9999;">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="600px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Edytuj fakture</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver
          ref="obs"
          v-slot="{
            invalid,
          }"
          mode="eager"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" class="pb-0 ">
                  <VTextFieldWithValidation
                    v-model="invoice.name"
                    label="Imię i nazwisko"
                    color="indigo"
                    outlined
                    dense
                    rules="required"
                  />
                </v-col>
                <v-col cols="12" sm="12" class="pb-0 ">
                  <VTextFieldWithValidation
                    v-model="invoice.address"
                    label="Adres"
                    color="indigo"
                    outlined
                    dense
                    rules="required"
                  />
                </v-col>
                <v-col cols="12" sm="12" class="pb-0">
                  <VTextFieldWithValidation
                    v-model="invoice.postalCode"
                    label="Kod pocztowy"
                    color="indigo"
                    outlined
                    dense
                    rules="required"
                  />
                </v-col>
                <v-col cols="12" sm="12" class="pb-0">
                  <VTextFieldWithValidation
                    v-model="invoice.city"
                    label="Miasto"
                    color="indigo"
                    outlined
                    dense
                    rules="required"
                  />
                </v-col>
                <v-col cols="12" sm="12" class="pb-0">
                  <VTextFieldWithValidation
                    v-model="invoice.nip"
                    label="NIP"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="editInvoice()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import BlackButton from "../buttons/BlackButton";

export default {
  name: "EditUserModal",
  props: ["isVisible", "invoice", "orderId"],

  components: {
    VTextFieldWithValidation,
    ValidationObserver,
    BlackButton,
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    editInvoice() {
      const { name, address, postalCode, city, nip } = this.invoice;
      const data = {
        name,
        address,
        postalCode,
        city,
        nip,
      };
      this.$store.dispatch(`payment/editInvoice`, { data, id: this.orderId });
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10000000;
}
</style>
