import errorHandler from "../errorHandler";

export const showSuccessAlert = (dispatch, message) => {
  dispatch(
    "snackbar/setSnackbar",
    {
      color: "success",
      icon: "check",
      message,
    },
    { root: true }
  );
  toggleSnackbar(dispatch);
};

export const showErrorAlert = (dispatch, message, code) => {
  dispatch(
    "snackbar/setSnackbar",
    {
      color: "red",
      icon: "exclamation-triangle",
      message: errorHandler(message, code),
    },
    { root: true }
  );
  toggleSnackbar(dispatch);
};

export const showWarningAlert = (dispatch, message) => {
  dispatch(
    "snackbar/setSnackbar",
    {
      color: "yellow",
      icon: "check",
      message,
    },
    { root: true }
  );
  toggleSnackbar(dispatch);
};

const toggleSnackbar = (dispatch) => {
  dispatch("snackbar/toggleSnackbar", true, { root: true });
};
