<template>
  <v-row justify="center">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="500px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Wyślij informator</v-toolbar-title>
        </v-toolbar>
        <v-card-actions class="bold pt-5 d-flex flex-column">
          <ValidationObserver
            ref="logObs"
            v-slot="{ invalid }"
            mode="lazy"
            style="width: 100%"
          >
            <span style="width: 100%" class="mb-5">
              <span class="inputLabel">Wybierz klienta</span>
              <span style="width: 100%">
                <v-autocomplete
                  dense
                  filled
                  clearable
                  class="mb-5"
                  full-width
                  color="#fbc501"
                  hide-details
                  v-model="selectedCustomer"
                  :search-input.sync="filter"
                  :items="customers"
                  item-text="fullname"
                  item-value="email"
                  placeholder="Wyszukaj klienta"
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                >
                  <template slot="append">
                    <font-awesome-icon icon="search" class="mt-1" />
                  </template>
                </v-autocomplete>
              </span>
            </span>
            <span style="width: 100%">
              <span class="inputLabel">Email klienta</span>

              <VTextFieldWithValidation
                type="email"
                rules="email|required"
                v-model="selectedCustomer"
                dense
                filled
                placeholder="Email"
              />
            </span>
            <div class="text-center">
              <YellowButton
                :disabled="invalid"
                @click.native="sendInformant()"
                class="my-5"
                width="60%"
                text="Wyślij"
              />
            </div>
          </ValidationObserver>
        </v-card-actions>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BlackButton from "../buttons/BlackButton";
import YellowButton from "../buttons/YellowButton";
import { URL } from "../../config";
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import moment from "moment";
export default {
  name: "SendInformantModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BlackButton,
    YellowButton,
    ValidationObserver,
    VTextFieldWithValidation,
  },
  data: () => ({
    productInformantModal: false,
    selectedCustomer: "",
    filter: "",
  }),
  watch: {
    filter() {
      this.fetchFilteredCustomers();
      if (!this.filter) {
        this.selectedCustomer = "";
      }
    },
  },
  computed: {
    customers() {
      return this.$store.getters["customers/getCustomers"].data;
    },
  },
  methods: {
    getURL() {
      const currentTimestamp = new Date().getTime();
      return (
        URL +
        `/assets/InformatorOProduktach${moment().get(
          "year"
        )}_v2.pdf=v=${currentTimestamp}`
      );
    },
    async downloadInformant() {
      await this.$store.dispatch("catalogue/downloadInformant");
    },
    closeModal() {
      this.selectedCustomer = "";
      this.$refs.logObs.reset();
      this.$emit("closeModal");
    },
    fetchFilteredCustomers() {
      if (this.filter) {
        if (this.filter.length >= 3) {
          let object = {
            filter: this.filter,
          };
          this.$store.dispatch("customers/fetchCustomers", object);
        }
      }
    },
    async sendInformant() {
      let object = {
        email: this.selectedCustomer,
      };
      await this.$store.dispatch("catalogue/sendInformant", object);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 1%;
  z-index: 10000000;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.inputLabel {
  font-size: 0.8rem;
}
</style>
