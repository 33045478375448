<template>
  <div class="regulations">
    <div class="image">
      <img src="../assets/logowhite.svg" />
    </div>

    <div class="mt-4 content">
      <div class="text-center mb-7">
        <h1 class="black--text py-3 display-2 text-center">
          <span class="font">Polityka Prywatności!</span>
        </h1>

        <font-awesome-icon size="7x" icon="user-shield" />
      </div>
      <H1
        CLASS="western"
        ALIGN="CENTER"
        STYLE="margin-top: 0.21in; margin-bottom: 0.21in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_pupuixe7flve"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="6" STYLE="font-size: 23pt"
            ><B>Polityka prywatności Aplikacji ForMyEver Office</B></FONT
          ></FONT
        ></H1
      >
      <P STYLE="margin-bottom: 0in"><BR /> </P>
      <H2
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.15in; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_j344yhlk2kcr"></A>
        <FONT COLOR="#111111"
          ><B
            ><SPAN STYLE="background: #ffffff">Drogi Użytkowniku!</SPAN></B
          ></FONT
        ></H2
      >
      <P
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; border: none; padding: 0in; line-height: 100%"
      >
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >Dbamy o Twoją prywatność i chcemy, abyś w czasie korzystania z
              naszych usług czuł się komfortowo. Dlatego też poniżej
              prezentujemy Ci najważniejsze informacje o zasadach przetwarzania
              przez nas Twoich danych osobowych oraz plikach cookies, które są
              wykorzystywane przez naszą Aplikację. Informacje te zostały
              przygotowane z uwzględnieniem RODO, czyli ogólnego rozporządzenia
              o ochronie danych.</SPAN
            ></FONT
          ></FONT
        ></P
      >
      <H2
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.15in; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_pxisnacafkzc"></A>
        <FONT COLOR="#111111"
          ><B
            ><SPAN STYLE="background: #ffffff"
              >ADMINISTRATOR DANYCH OSOBOWYCH</SPAN
            ></B
          ></FONT
        ></H2
      >
      <P
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; border: none; padding: 0in; line-height: 100%"
      >
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >NDM Concept Sp. z o.o. z siedzibą pod adresem ul. Twarda 18,
              00-105 Warszawa, wpisana do Krajowego Rejestru Sądowego - rejestru
              przedsiębiorców przez Sąd Rejonowy dla m.st. Warszawy, XII Wydział
              Gospodarczy KRS, pod nr KRS 0000872735, NIP 5252845159, nr REGON
              387683045, kapitał zakładowy 5100,00 zł.</SPAN
            ></FONT
          ></FONT
        ></P
      >
      <P
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; border: none; padding: 0in; line-height: 100%"
      >
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >Jeśli chcesz skontaktować się z nami w związku z przetwarzaniem
              przez nas Twoich danych osobowych, napisz do nas na adres e-mail:
              kontakt@formyeveroffice.pl</SPAN
            ></FONT
          ></FONT
        ></P
      >
      <H2
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.15in; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_ps994xcbmng5"></A>
        <FONT COLOR="#111111"
          ><B
            ><SPAN STYLE="background: #ffffff">TWOJE UPRAWNIENIA</SPAN></B
          ></FONT
        ></H2
      >
      <P
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; border: none; padding: 0in; line-height: 100%"
      >
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >Przysługuje Ci prawo żądania:</SPAN
            ></FONT
          ></FONT
        ></P
      >
      <UL>
        <LI
          ><P
            STYLE="margin-top: 0.28in; margin-bottom: 0in; border: none; padding: 0in; line-height: 150%"
          >
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >dostępu do Twoich danych osobowych, w tym uzyskania kopii
                  Twoich danych (art. 15 RODO lub - jeśli ma to zastosowanie -
                  art. 13 ust. 1 lit. f RODO),</SPAN
                ></FONT
              ></FONT
            ></P
          >
        </LI>
        <LI
          ><P
            STYLE="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%"
          >
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >ich sprostowania (art. 16 RODO),</SPAN
                ></FONT
              ></FONT
            ></P
          > </LI
        ><LI
          ><P
            STYLE="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%"
          >
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >usunięcia (art. 17 RODO),</SPAN
                ></FONT
              ></FONT
            ></P
          > </LI
        ><LI
          ><P
            STYLE="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%"
          >
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >ograniczenia przetwarzania (art. 18 RODO),</SPAN
                ></FONT
              ></FONT
            ></P
          > </LI
        ><LI
          ><P
            STYLE="margin-bottom: 0.28in; border: none; padding: 0in; line-height: 150%"
          >
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >przeniesienia danych do innego administratora (art. 20
                  RODO).</SPAN
                ></FONT
              ></FONT
            ></P
          ></LI
        >
      </UL>
      <P
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; border: none; padding: 0in; line-height: 100%"
      >
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff">A także prawo:</SPAN></FONT
          ></FONT
        ></P
      >
      <UL>
        <LI
          ><P
            STYLE="margin-top: 0.28in; margin-bottom: 0in; border: none; padding: 0in; line-height: 150%"
          >
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania
                  Twoich danych:</SPAN
                ></FONT
              ></FONT
            ></P
          ></LI
        >
        <UL>
          <LI
            ><P
              STYLE="margin-bottom: 0in; border: none; padding: 0in; line-height: 150%"
            >
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >z przyczyn związanych z Twoją szczególną sytuacją – wobec
                    przetwarzania dotyczących Ciebie danych osobowych, opartego
                    na art. 6 ust. 1 lit. f RODO (tj. na realizowanych przez nas
                    prawnie uzasadnionych interesach) (art. 21 ust. 1
                    RODO);</SPAN
                  ></FONT
                ></FONT
              ></P
            > </LI
          ><LI
            ><P
              STYLE="margin-bottom: 0.56in; border: none; padding: 0in; line-height: 150%"
            >
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >jeżeli dane osobowe są przetwarzane na potrzeby marketingu
                    bezpośredniego, w zakresie, w jakim przetwarzanie jest
                    związane z takim marketingiem bezpośrednim (art. 21 ust. 2
                    RODO).</SPAN
                  ></FONT
                ></FONT
              ></P
            ></LI
          >
        </UL>
      </UL>
      <P
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; border: none; padding: 0in; line-height: 100%"
      >
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >Skontaktuj się z nami, jeśli chcesz skorzystać ze swoich praw.
              Sprzeciw w odniesieniu do wykorzystywania przez nas plików cookies
              (o których przeczytasz poniżej) możesz wyrazić zwłaszcza za pomocą
              odpowiednich ustawień przeglądarki.</SPAN
            ></FONT
          ></FONT
        ></P
      >
      <P
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; border: none; padding: 0in; line-height: 100%"
      >
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >Jeśli uznasz, że Twoje dane są przetwarzane niezgodnie z prawem,
              możesz złożyć skargę do Prezesa Urzędu Ochrony Danych
              Osobowych.</SPAN
            ></FONT
          ></FONT
        ></P
      >
      <H2
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.15in; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_3ftwn6kq0xq2"></A>
        <FONT COLOR="#111111"
          ><B
            ><SPAN STYLE="background: #ffffff"
              >DANE OSOBOWE I PRYWATNOŚĆ</SPAN
            ></B
          ></FONT
        ></H2
      >
      <P
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; border: none; padding: 0in; line-height: 100%"
      >
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >Poniżej znajdziesz szczegółowe informacje na temat przetwarzania
              Twoich danych w zależności od podejmowanych przez Ciebie
              działań.</SPAN
            ></FONT
          ></FONT
        ></P
      >

      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_apjdbguju9k4"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="3" STYLE="font-size: 13pt"
            ><B>1.Zakup aplikacji</B></FONT
          ></FONT
        ></H3
      >
      <div class="overflowTable">
        <TABLE CELLPADDING="7" CELLSPACING="0">
          <COL WIDTH="286" />
          <COL WIDTH="286" />
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="15"
              VALIGN="TOP"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"><B>W jakim celu?</B></FONT></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="16"
              VALIGN="TOP"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2">realizacja Twojego zamówienia</FONT></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="16"
              VALIGN="TOP"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"><B>Na jakiej podstawie?</B></FONT></P
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD
              WIDTH="286"
              HEIGHT="32"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >umowa sprzedaży lub umowa o dostarczenie treści cyfrowych
                  (art. 6 ust. 1 lit. b RODO)</FONT
                ></P
              >
            </TD>
            <TD
              WIDTH="286"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >obowiązek prawny, związany z rachunkowością, zobowiązujący
                  nas do przetwarzania Twoich danych osobowych (art. 6 ust. 1
                  lit. c RODO)</FONT
                ></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="16"
              VALIGN="TOP"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"><B>Jak długo?</B></FONT></P
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD
              WIDTH="286"
              HEIGHT="32"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >przez okres obowiązywania wyżej wymienionej umowy</FONT
                ></P
              >
            </TD>
            <TD
              WIDTH="286"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >do momentu wygaśnięcia ciążącego na nas obowiązku prawnego,
                  związanego z rachunkowością</FONT
                ></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="32"
              VALIGN="TOP"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in; margin-bottom: 0in"
                ><FONT SIZE="2"
                  >ponadto, Twoje dane będą przetwarzane do upływu okresu, w
                  którym możliwe jest dochodzenie roszczeń – przez Ciebie lub
                  przez nas</FONT
                ></P
              >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >(więcej informacji na ten temat znajdziesz w ostatniej tabeli
                  tej sekcji)</FONT
                ></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="16"
              VALIGN="TOP"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  ><B>Co się stanie, jeśli nie podasz danych?</B></FONT
                ></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="15"
              VALIGN="TOP"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >nie będziesz mieć możliwości złożenia zamówienia</FONT
                ></P
              >
            </TD>
          </TR>
        </TABLE>
      </div>
      <P STYLE="margin-bottom: 0in"><BR /> </P>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_la38424o0o5j"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="3" STYLE="font-size: 13pt"
            ><B>2. Założenie konta w Aplikacji</B></FONT
          ></FONT
        ></H3
      >
      <TABLE CELLPADDING="7" CELLSPACING="0">
        <COL WIDTH="586" />
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="15"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"><B>W jakim celu?</B></FONT></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"
                >realizacja umowy o świadczenie usługi prowadzenia konta w
                Aplikacji</FONT
              ></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"><B>Na jakiej podstawie?</B></FONT></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"
                >umowa o świadczenie usług (art. 6 ust. 1 lit. b RODO)</FONT
              ></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"><B>Jak długo?</B></FONT></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"
                >do momentu, w którym konto zostanie usunięte przez Ciebie lub
                przez nas na Twoje żądanie, a także po roku od nie przedłużenia
                subskrypcji.</FONT
              ></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="32"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in; margin-bottom: 0in"
              ><FONT SIZE="2"
                >ponadto, Twoje dane będą przetwarzane do upływu okresu, w
                którym możliwe jest dochodzenie roszczeń – przez Ciebie lub
                przez nas</FONT
              ></P
            >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"
                >(więcej informacji na ten temat znajdziesz w ostatniej tabeli
                tej sekcji)</FONT
              ></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"
                ><B>Co się stanie, jeśli nie podasz danych?</B></FONT
              ></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="31"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"
                >nie będziesz mieć możliwości założenia konta i korzystania z
                jego funkcji.</FONT
              ></P
            >
          </TD>
        </TR>
      </TABLE>
      <P STYLE="margin-bottom: 0in"><BR /> </P>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_jbf0mhe07v14"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="3" STYLE="font-size: 13pt"
            ><B
              >3. Nawiązanie z nami kontaktu (np. w celu zadania pytania)</B
            ></FONT
          ></FONT
        ></H3
      >
      <div class="overflowTable">
        <TABLE CELLPADDING="7" CELLSPACING="0">
          <COL WIDTH="286" />
          <COL WIDTH="286" />
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="15"
              VALIGN="TOP"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"><B>W jakim celu?</B></FONT></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="16"
              VALIGN="TOP"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2">obsługa Twoich zapytań lub zgłoszeń</FONT></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="16"
              VALIGN="TOP"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"><B>Na jakiej podstawie?</B></FONT></P
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD
              WIDTH="286"
              HEIGHT="62"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >umowa lub działania podejmowane na Twoje żądanie, zmierzające
                  do jej zawarcia (art. 6 ust. 1 lit. b RODO) – w przypadku, gdy
                  Twoje zapytanie lub zgłoszenie dotyczy umowy, której jesteśmy
                  lub możemy być stroną</FONT
                ></P
              >
            </TD>
            <TD
              WIDTH="286"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >nasz prawnie uzasadniony interes, polegający na przetwarzaniu
                  Twoich danych w celu prowadzenia z Tobą komunikacji (art. 6
                  ust. 1 lit. f RODO) – jeżeli Twoje zapytanie lub zgłoszenie
                  nie ma związku z umową</FONT
                ></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="16"
              VALIGN="TOP"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"><B>Jak długo?</B></FONT></P
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD
              WIDTH="286"
              HEIGHT="47"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >przez czas trwania wiążącej nas umowy lub – jeśli umowa nie
                  zostanie zawarta - do upływu okresu dochodzenia roszczeń –
                  zobacz ostatnią tabelę tej sekcji*</FONT
                ></P
              >
            </TD>
            <TD
              WIDTH="286"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >do upływu okresu dochodzenia roszczeń – zobacz ostatnią
                  tabelę tej sekcji - lub do momentu, w którym uwzględnimy Twój
                  sprzeciw wobec przetwarzania*</FONT
                ></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="32"
              VALIGN="TOP"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in; margin-bottom: 0in"
                ><FONT SIZE="2"
                  >ponadto, Twoje dane będą przetwarzane do upływu okresu, w
                  którym możliwe jest dochodzenie roszczeń – przez Ciebie lub
                  przez nas</FONT
                ></P
              >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >(więcej informacji na ten temat znajdziesz w ostatniej tabeli
                  tej sekcji)</FONT
                ></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="16"
              VALIGN="TOP"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  ><B>Co się stanie, jeśli nie podasz danych?</B></FONT
                ></P
              >
            </TD>
          </TR>
          <TR>
            <TD
              COLSPAN="2"
              WIDTH="586"
              HEIGHT="15"
              VALIGN="TOP"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
                ><FONT SIZE="2"
                  >nie będziemy mieli możliwości udzielenia odpowiedzi na Twoje
                  zapytanie lub zgłoszenie</FONT
                ></P
              >
            </TD>
          </TR>
        </TABLE>
      </div>
      <P STYLE="margin-bottom: 0in"
        ><FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >* w zależności od tego, które ma zastosowanie w danym przypadku i
              które nastąpi najwcześniej</SPAN
            ></FONT
          ></FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in"><BR /> </P>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_z9yqiz56j4c4"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="3" STYLE="font-size: 13pt"
            ><B
              >4. Ustawienia przeglądarki lub inne zbliżone działanie
              zezwalające na prowadzenie działań analitycznych</B
            ></FONT
          ></FONT
        ></H3
      >
      <TABLE CELLPADDING="7" CELLSPACING="0">
        <COL WIDTH="586" />
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="15"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"><B>W jakim celu?</B></FONT></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="47"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in; margin-bottom: 0in"
              ><FONT SIZE="2"
                >analiza sposobu korzystania i poruszania się przez Ciebie po
                stronie Aplikacji, celem dostosowania strony do potrzeb i
                zachowania Użytkowników</FONT
              ></P
            >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"
                >(więcej na ten temat przeczytasz w sekcji „Działania
                analityczne” i „Pliki cookies” Polityki prywatności)</FONT
              ></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"><B>Na jakiej podstawie?</B></FONT></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"
                >nasz prawnie uzasadniony interes, polegający na przetwarzaniu
                danych w podanym wyżej celu (art. 6 ust. 1 lit. f RODO)</FONT
              ></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"><B>Jak długo?</B></FONT></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"
                >do momentu wygaśnięcia ważności lub usunięcia przez Ciebie
                plików cookies, wykorzystywanych do celów analitycznych*</FONT
              ></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"
                ><B>Co się stanie, jeśli nie podasz danych?</B></FONT
              ></P
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="15"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <P ALIGN="CENTER" STYLE="margin-top: 0.14in"
              ><FONT SIZE="2"
                >nie uwzględnimy Twoich preferencji dotyczących korzystania z
                Aplikacji w pracach nad jego rozwojem</FONT
              ></P
            >
          </TD>
        </TR>
      </TABLE>
      <P STYLE="margin-bottom: 0in"
        ><FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >* w zależności od tego, które ma zastosowanie w danym przypadku i
              które nastąpi najwcześniej</SPAN
            ></FONT
          ></FONT
        ></P
      >
      <P STYLE="margin-bottom: 0in"><BR /> </P>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="3" STYLE="font-size: 13pt"
            ><B
              >5. Wyrażenie przez Ciebie zgody na otrzymywanie od nas
            </B></FONT
          ></FONT
        ><FONT COLOR="#111111"
          ><FONT SIZE="3"
            ><B
              ><SPAN STYLE="background: #ffffff">
                (np. informacji o ofertach specjalnych)</SPAN
              ></B
            ></FONT
          ></FONT
        ></H3
      >
      <TABLE CELLPADDING="7" CELLSPACING="0">
        <COL WIDTH="586" />
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="15"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background-color: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo1"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background-color: #eeeeee"
                      >W jakim celu?</SPAN
                    ></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo2"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >wysyłka informacji marketingowych, zwłaszcza ofert
                    specjalnych</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo3"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background: #eeeeee"
                      >Na jakiej podstawie?</SPAN
                    ></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo4"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >Twoja zgoda na nasze działania marketingowe (art. 6 ust. 1
                    lit. a RODO)</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo5"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background: #eeeeee">Jak długo?</SPAN></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="32"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo6"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >do momentu wycofania przez Ciebie zgody – pamiętaj, w
                    każdej chwili możesz wycofać zgodę. Przetwarzanie danych do
                    momentu cofnięcia przez Ciebie zgody pozostaje zgodne z
                    prawem.</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="32"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo7"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >ponadto, Twoje dane będą przetwarzane do upływu okresu, w
                    którym możliwe jest dochodzenie roszczeń – przez Ciebie lub
                    przez nas</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo8"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >(więcej informacji na ten temat znajdziesz w ostatniej
                    tabeli tej sekcji</SPAN
                  ></FONT
                ></FONT
              ><FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B><SPAN STYLE="background: #ffffff">)</SPAN></B></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo9"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background: #eeeeee"
                      >Co się stanie, jeśli nie podasz danych?</SPAN
                    ></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="15"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo10"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >nie będziesz otrzymywać naszych materiałów marketingowych,
                    w tym informacji o naszych ofertach specjalnych</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
      </TABLE>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo11"></A> <BR /><BR />
      </H3>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.17in; margin-bottom: 0.11in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_thea49igzldh"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="3"
            ><B
              ><SPAN STYLE="background: #ffffff"
                >6. Subskrypcja newslettera</SPAN
              ></B
            ></FONT
          ></FONT
        ></H3
      >
      <TABLE CELLPADDING="7" CELLSPACING="0">
        <COL WIDTH="586" />
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="15"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo12"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background: #eeeeee">W jakim celu?</SPAN></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo13"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >wysyłanie newslettera</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo14"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background: #eeeeee"
                      >Na jakiej podstawie?</SPAN
                    ></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo15"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >umowa o świadczenie usługi wysyłki newslettera (art. 6 ust.
                    1 lit. b RODO)</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo16"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background: #eeeeee">Jak długo?</SPAN></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo17"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >do momentu, w którym wypiszesz się z naszego
                    newslettera</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="32"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo18"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >ponadto, Twoje dane będą przetwarzane do upływu okresu, w
                    którym możliwe jest dochodzenie roszczeń – przez Ciebie lub
                    przez nas</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo19"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >(więcej informacji na ten temat znajdziesz w ostatniej
                    tabeli tej sekcji)</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo20"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background: #eeeeee"
                      >Co się stanie, jeśli nie podasz danych?</SPAN
                    ></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="15"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo21"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >nie będziesz mieć możliwości otrzymywania informacji
                    dotyczących Aplikacji i naszych usług</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
      </TABLE>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo22"></A> <BR /><BR />
      </H3>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.17in; margin-bottom: 0.11in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_k4bd3uop3xwe"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="3"
            ><B
              ><SPAN STYLE="background: #ffffff"
                >7. Podjęcie działania lub zaniechanie mogące powodować
                powstanie roszczeń związanych z Aplikacji lub naszymi
                usługami</SPAN
              ></B
            ></FONT
          ></FONT
        ></H3
      >
      <TABLE CELLPADDING="7" CELLSPACING="0">
        <COL WIDTH="586" />
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="15"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo23"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background: #eeeeee">W jakim celu?</SPAN></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo24"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >ustalenie, dochodzenie lub obrona ewentualnych roszczeń,
                    związanych z zawartą umową lub świadczonymi usługami</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo25"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background: #eeeeee"
                      >Na jakiej podstawie?</SPAN
                    ></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo26"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >nasz prawnie uzasadniony interes, polegający na
                    przetwarzaniu danych osobowych we wskazanym powyżej celu
                    (art. 6 ust. 1 lit. f RODO)</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo27"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background: #eeeeee">Jak długo?</SPAN></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo28"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >do upływu okresu przedawnienia roszczeń lub do momentu, w
                    którym uwzględnimy Twój sprzeciw wobec przetwarzania*</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="16"
            VALIGN="TOP"
            BGCOLOR="#eeeeee"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo29"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><B
                    ><SPAN STYLE="background: #eeeeee"
                      >Co się stanie, jeśli nie podasz danych?</SPAN
                    ></B
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
        <TR>
          <TD
            WIDTH="586"
            HEIGHT="15"
            VALIGN="TOP"
            STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
          >
            <H3
              CLASS="western"
              ALIGN="CENTER"
              STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
              ><A NAME="_hjm16nt3bxqo30"></A>
              <FONT COLOR="#111111"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >brak możliwości ustalenia, dochodzenia lub obrony
                    roszczeń</SPAN
                  ></FONT
                ></FONT
              ></H3
            >
          </TD>
        </TR>
      </TABLE>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo31"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><B
              ><SPAN STYLE="background: #ffffff"
                >* w zależności od tego, które ma zastosowanie w danym przypadku
                i które nastąpi najwcześniej</SPAN
              ></B
            ></FONT
          ></FONT
        ></H3
      >
      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_tp1vjbs1vr77"></A> <BR /><BR />
      </H3>
      <H2
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.15in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_73f8ayn0a2tn"></A>
        <FONT COLOR="#111111"
          ><B
            ><SPAN STYLE="background: #ffffff">DZIAŁANIA ANALITYCZNE</SPAN></B
          ></FONT
        ></H2
      >
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo32"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >W ramach Aplikacji prowadzimy działania analityczne, mające na
              celu zwiększenie jej intuicyjności i przystępności – w odniesieniu
              do Ciebie będzie to miało miejsce, jeśli zezwolisz na takie
              działania. W ramach analizy będziemy brać pod uwagę sposób, w jaki
              poruszasz się po Aplikacji – a więc np. to, ile czasu spędzasz na
              danej podstronie, czy w które miejsca w Aplikacji klikasz. Dzięki
              temu możemy dostosować układ i wygląd Aplikacji oraz zamieszczane
              w niej treści do potrzeb Użytkowników.</SPAN
            ></FONT
          ></FONT
        ></H3
      >
      <H2
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.15in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_mrfso8wuea6a"></A>
        <FONT COLOR="#111111"
          ><B
            ><SPAN STYLE="background: #ffffff">BEZPIECZEŃSTWO DANYCH</SPAN></B
          ></FONT
        ></H2
      >
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo33"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >Przetwarzając Twoje dane osobowe stosujemy środki organizacyjne i
              techniczne zgodne z właściwymi przepisami prawa, w tym stosujemy
              szyfrowanie połączenia za pomocą certyfikatu SSL.</SPAN
            ></FONT
          ></FONT
        ></H3
      >
      <H2
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.15in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_y3bb9rlg80eu"></A>
        <FONT COLOR="#111111"
          ><B><SPAN STYLE="background: #ffffff">PLIKI COOKIES</SPAN></B></FONT
        ></H2
      >
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo34"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >Nasza Aplikacja, jak większość witryn internetowych, korzysta z
              tzw. plików cookies (ciasteczek). Pliki te:</SPAN
            ></FONT
          ></FONT
        ></H3
      >
      <UL>
        <LI
          ><H3
            CLASS="western"
            STYLE="margin-top: 0.28in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
            ><A NAME="_hjm16nt3bxqo35"></A>
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >są zapisywane w pamięci Twojego urządzenia (komputera,
                  telefonu, itd.);</SPAN
                ></FONT
              ></FONT
            ></H3
          > </LI
        ><LI
          ><H3
            CLASS="western"
            STYLE="margin-top: 0in; margin-bottom: 0.28in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
            ><A NAME="_hjm16nt3bxqo36"></A>
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >nie powodują zmian w ustawieniach Twojego urządzenia.</SPAN
                ></FONT
              ></FONT
            ></H3
          ></LI
        >
      </UL>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo37"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >W tej Aplikacji ciasteczka wykorzystywane są w celach:</SPAN
            ></FONT
          ></FONT
        ></H3
      >
      <UL>
        <LI
          ><H3
            CLASS="western"
            STYLE="margin-top: 0.28in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
            ><A NAME="_hjm16nt3bxqo38"></A>
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >zapamiętywania Twojej sesji</SPAN
                ></FONT
              ></FONT
            ></H3
          > </LI
        ><LI
          ><H3
            CLASS="western"
            STYLE="margin-top: 0in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
            ><A NAME="_hjm16nt3bxqo39"></A>
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff">statystycznych</SPAN></FONT
              ></FONT
            ></H3
          > </LI
        ><LI
          ><H3
            CLASS="western"
            STYLE="margin-top: 0in; margin-bottom: 0.28in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
            ><A NAME="_hjm16nt3bxqo40"></A>
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >udostępniania funkcji Aplikacji</SPAN
                ></FONT
              ></FONT
            ></H3
          ></LI
        >
      </UL>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo41"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >Aby dowiedzieć się, jak zarządzać plikami cookies, w tym jak
              wyłączyć ich obsługę w Twojej przeglądarce, możesz skorzystać z
              pliku pomocy Twojej przeglądarki. Z informacjami na ten temat
              możesz zapoznać się wciskając klawisz F1 w przeglądarce. Ponadto
              odpowiednie wskazówki znajdziesz na następujących podstronach, w
              zależności od przeglądarki, której używasz:</SPAN
            ></FONT
          ></FONT
        ></H3
      >
      <UL>
        <LI
          ><H3
            CLASS="western"
            STYLE="margin-top: 0.28in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
            ><A NAME="_hjm16nt3bxqo42"></A>
            <A
              HREF="https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop?redirectlocale=pl&amp;redirectslug=Włączanie+i+wyłączanie+obsługi+ciasteczek"
              ><FONT COLOR="#333333"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff">Firefox</SPAN></FONT
                ></FONT
              ></A
            ></H3
          > </LI
        ><LI
          ><H3
            CLASS="western"
            STYLE="margin-top: 0in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
            ><A NAME="_hjm16nt3bxqo43"></A>
            <A HREF="https://support.google.com/chrome/answer/95647?hl=pl"
              ><FONT COLOR="#333333"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff">Chrome</SPAN></FONT
                ></FONT
              ></A
            ></H3
          > </LI
        ><LI
          ><H3
            CLASS="western"
            STYLE="margin-top: 0in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
            ><A NAME="_hjm16nt3bxqo44"></A>
            <A HREF="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac"
              ><FONT COLOR="#333333"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff">Safari</SPAN></FONT
                ></FONT
              ></A
            ></H3
          > </LI
        ><LI
          ><H3
            CLASS="western"
            STYLE="margin-top: 0in; margin-bottom: 0.28in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
            ><A NAME="_hjm16nt3bxqo45"></A>
            <A
              HREF="https://support.microsoft.com/pl-pl/help/4468242/microsoft-edge-browsing-data-and-privacy"
              ><FONT COLOR="#333333"
                ><FONT SIZE="2"
                  ><SPAN STYLE="background: #ffffff"
                    >Internet Explorer / Microsoft Edge</SPAN
                  ></FONT
                ></FONT
              ></A
            ></H3
          ></LI
        >
      </UL>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo46"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><B
              ><SPAN STYLE="background: #ffffff"
                >Poniżej znajdziesz informacje na temat funkcji przetwarzanych
                przez nas plików cookie oraz ich okresie ważności.</SPAN
              ></B
            ></FONT
          ></FONT
        ></H3
      >
      <div class="overflowTable">
        <TABLE CELLPADDING="7" CELLSPACING="0">
          <COL WIDTH="155" />
          <COL WIDTH="221" />
          <COL WIDTH="182" />
          <TR VALIGN="TOP">
            <TD
              WIDTH="155"
              HEIGHT="15"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo47"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #eeeeee"
                        >nazwa pliku cookie</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD
              WIDTH="221"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo48"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #eeeeee"
                        >okres ważności pliku cookie</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD
              WIDTH="182"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo49"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #eeeeee"
                        >funkcja pliku cookie</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD
              WIDTH="155"
              HEIGHT="15"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo50"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >&quot;cookie&quot;</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD
              WIDTH="221"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo51"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >czas trwania sesji</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD
              WIDTH="182"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo52"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >W celach statystycznych</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
          </TR>
        </TABLE>
      </div>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo53"></A> <BR /><BR />
      </H3>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo54"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><B
              ><SPAN STYLE="background: #ffffff"
                >Korzystając z odpowiednich opcji Twojej przeglądarki, w każdej
                chwili możesz:</SPAN
              ></B
            ></FONT
          ></FONT
        ></H3
      >
      <UL>
        <LI
          ><H3
            CLASS="western"
            STYLE="margin-top: 0.28in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
            ><A NAME="_hjm16nt3bxqo55"></A>
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >usunąć pliki cookies,</SPAN
                ></FONT
              ></FONT
            ></H3
          > </LI
        ><LI
          ><H3
            CLASS="western"
            STYLE="margin-top: 0in; margin-bottom: 0.28in; background: #ffffff; border: none; padding: 0in; line-height: 150%; page-break-inside: auto; page-break-after: auto"
            ><A NAME="_hjm16nt3bxqo56"></A>
            <FONT COLOR="#111111"
              ><FONT SIZE="2"
                ><SPAN STYLE="background: #ffffff"
                  >blokować wykorzystanie plików cookies w przyszłości.</SPAN
                ></FONT
              ></FONT
            ></H3
          ></LI
        >
      </UL>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo57"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >W takich przypadkach nie będziemy ich już dłużej
              przetwarzać.</SPAN
            ></FONT
          ></FONT
        ></H3
      >
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo58"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >Więcej informacji o plikach cookies znajdziesz w
            </SPAN></FONT
          ></FONT
        ><A HREF="https://pl.wikipedia.org/wiki/HTTP_cookie"
          ><FONT COLOR="#333333"
            ><FONT SIZE="2"
              ><SPAN STYLE="background: #ffffff">Wikipedii</SPAN></FONT
            ></FONT
          ></A
        ><FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff">.</SPAN></FONT
          ></FONT
        ></H3
      >
      <H2
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.15in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_azilx12df26g"></A>
        <FONT COLOR="#111111"
          ><B
            ><SPAN STYLE="background: #ffffff"
              >USŁUGI ZEWNĘTRZNE / ODBIORCY DANYCH</SPAN
            ></B
          ></FONT
        ></H2
      >
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo59"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><SPAN STYLE="background: #ffffff"
              >Korzystamy z usług podmiotów zewnętrznych, które wspierają nas w
              prowadzeniu działalności. Powierzamy im do przetwarzania Twoje
              dane – podmioty te przetwarzają dane wyłącznie na nasze
              udokumentowane polecenie.</SPAN
            ></FONT
          ></FONT
        ></H3
      >
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo60"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><B
              ><SPAN STYLE="background: #ffffff"
                >Poniżej znajdziesz listę odbiorców Twoich danych:</SPAN
              ></B
            ></FONT
          ></FONT
        ></H3
      >
      <div class="overflowTable">
        <TABLE CELLPADDING="7" CELLSPACING="0">
          <COL WIDTH="207" />
          <COL WIDTH="227" />
          <COL WIDTH="124" />
          <TR VALIGN="TOP">
            <TD
              HEIGHT="31"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo61"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #eeeeee">DZIAŁANIE</SPAN></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo62"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #eeeeee"
                        >ODBIORCY DANYCH</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo63"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #eeeeee"
                        >PRZEKAZANIE DANYCH POZA UNIĘ EUROPEJSKĄ</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD
              HEIGHT="16"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo64"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #eeeeee"
                        >każde działanie w związku z Aplkacją</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo65"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >operator internetowej platformy handlowej</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo66"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >nie ma miejsca</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD
              ROWSPAN="4"
              HEIGHT="32"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo67"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #eeeeee"
                        >zakup Aplikacji</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo68"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >dostawca oprogramowania ułatwiającego prowadzenie
                        działalności (np. oprogramowanie księgowe)</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo69"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >nie ma miejsca</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo71"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >dostawca standardowego oprogramowania biurowego (w tym
                        skrzynki poczty elektronicznej)</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo72"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >nie ma miejsca</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo74"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >biuro rachunkowe</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo75"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >nie ma miejsca</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo77"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >podmiot zapewniający system ratalny</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo78"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >nie ma miejsca</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD
              HEIGHT="32"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo79"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #eeeeee"
                        >zapis na newsletter lub wyrażenie zgody na przesyłanie
                        wiadomości marketingowych</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo80"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >podmiot zapewniający wysyłkę newslettera lub wiadomości
                        marketingowych</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo81"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >nie ma miejsca</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD
              HEIGHT="32"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo82"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #eeeeee"
                        >skorzystanie z komunikatora dostępnego w
                        Aplikacji</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo83"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >podmiot dostarczający komunikator dostępny w
                        Sklepie</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo84"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >nie ma miejsca</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
          </TR>
          <TR VALIGN="TOP">
            <TD
              HEIGHT="31"
              BGCOLOR="#eeeeee"
              STYLE="border: 1px solid #000000; padding: 0.07in 0.14in"
            >
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #eeeeee; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo85"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #eeeeee"
                        >nawiązanie z nami kontaktu (np. zadanie pytania)</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo86"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >dostawca standardowego oprogramowania biurowego (w tym
                        skrzynki poczty elektronicznej)</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
            <TD STYLE="border: 1px solid #000000; padding: 0.07in 0.14in">
              <H3
                CLASS="western"
                ALIGN="CENTER"
                STYLE="margin-top: 0.14in; background: #ffffff; border: none; padding: 0in; page-break-inside: auto; page-break-after: auto"
                ><A NAME="_hjm16nt3bxqo87"></A>
                <FONT COLOR="#111111"
                  ><FONT SIZE="2"
                    ><B
                      ><SPAN STYLE="background: #ffffff"
                        >nie ma miejsca</SPAN
                      ></B
                    ></FONT
                  ></FONT
                ></H3
              >
            </TD>
          </TR>
        </TABLE>
      </div>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo88"></A> <BR /><BR />
      </H3>
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_hjm16nt3bxqo89"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><B><SPAN STYLE="background: #ffffff">A ponadto:</SPAN></B></FONT
          ></FONT
        ></H3
      >
      <H3
        CLASS="western"
        STYLE="margin-top: 0.14in; margin-bottom: 0.14in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_7n5d219selqf"></A>
        <FONT COLOR="#111111"
          ><FONT SIZE="2"
            ><B
              ><SPAN STYLE="background: #ffffff"
                >odpowiednie organy publiczne w zakresie, w jakim jesteśmy
                zobowiązani do udostępnienia im danych.</SPAN
              ></B
            ></FONT
          ></FONT
        ></H3
      >
      <H3
        CLASS="western"
        STYLE="margin-top: 0.18in; margin-bottom: 0.13in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; page-break-after: auto"
        ><A NAME="_uk7gzpvhkdud"></A> <BR /><BR />
      </H3>
      <P STYLE="margin-bottom: 0in"><BR /> </P>
      <P STYLE="margin-bottom: 0in"><BR /> </P>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.regulations {
  max-width: 1000px;
  padding: 10px;
  margin: auto;
  text-align: center;
}
.content {
  text-align: left;
  background-color: white;
  padding: 30px;
  border-radius: 20px;
}
img {
  max-width: 260px;
  transform: translateX(10px);
}
/* @page {
  margin: 1in;
} */
p {
  margin-bottom: 0.1in;
  line-height: 115%;
}
h1 {
  margin-top: 0.28in;
  margin-bottom: 0.08in;
  direction: ltr;
  line-height: 100%;
  text-align: left;
  page-break-inside: avoid;
  widows: 2;
  orphans: 2;
}
h1.western {
  font-size: 20pt;
  font-weight: normal;
}
h1.cjk {
  font-size: 20pt;
  font-weight: normal;
}
h1.ctl {
  font-size: 20pt;
  font-weight: normal;
}
h2 {
  margin-top: 0.25in;
  margin-bottom: 0.08in;
  direction: ltr;
  line-height: 100%;
  text-align: left;
  page-break-inside: avoid;
  widows: 2;
  orphans: 2;
}
h2.western {
  font-size: 16pt;
  font-weight: normal;
}
h2.cjk {
  font-size: 16pt;
  font-weight: normal;
}
h2.ctl {
  font-size: 16pt;
  font-weight: normal;
}
h3 {
  margin-top: 0.22in;
  margin-bottom: 0.06in;
  direction: ltr;
  color: #434343;
  line-height: 100%;
  text-align: left;
  page-break-inside: avoid;
  widows: 2;
  orphans: 2;
}
h3.western {
  font-weight: normal;
}
h3.cjk {
  font-weight: normal;
}
a:link {
  so-language: zxx;
}
table {
  margin-left: auto;
  margin-right: auto;
}
.overflowTable {
  overflow: scroll;
}
.overflowTable::-webkit-scrollbar {
  -webkit-appearance: none;
}

.overflowTable::-webkit-scrollbar:vertical {
  width: 11px;
}

.overflowTable::-webkit-scrollbar:horizontal {
  height: 11px;
}

.overflowTable::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
