<template>
  <v-row justify="center" align="center">
    <v-col cols="12" sm="8" md="6" lg="5" xl="3">
      <v-card elevation="0" class="card">
        <v-card-title>
          <v-img class="mx-2 logo" src="../assets/logowhite.svg" contain>
          </v-img>
        </v-card-title>
        <h1
          class="text-center black--text pb-5 font "
          style="color:black; font-size:36px"
        >
          Zmień hasło
        </h1>
        <v-container class="py-10 px-10 text-center">
          <ValidationObserver
            ref="obs"
            v-slot="{ invalid, handleSubmit }"
            mode="eager"
          >
            <v-form @submit.prevent="handleSubmit(onSubmit)" class="pt-5">
              <v-card-subtitle
                style="padding:0px 0px 0px 10px"
                class="text-left label"
                >Nowe hasło</v-card-subtitle
              >
              <VTextFieldWithValidation
                type="password"
                rules="required"
                vid="password1"
                v-model="password"
                outlined
                dense
                :disabled="loading"
              />
              <v-card-subtitle
                style="padding:0px 0px 0px 10px"
                class="text-left label"
                >Powtórz hasło</v-card-subtitle
              >
              <VTextFieldWithValidation
                type="password"
                rules="required|password:@password1"
                v-model="passwordConfirmation"
                outlined
                dense
                :disabled="loading"
              />
              <WhiteButton
                :disabled="invalid"
                :loading="loading"
                border
                type="submit"
                text="Zmień"
                class="mt-5"
                icon="unlock"
              />
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import VTextFieldWithValidation from "../components/inputs/VTextFieldWithValidation";
import { ValidationObserver } from "vee-validate";
import WhiteButton from "../components/buttons/WhiteButton";

export default {
  name: "LoginPage",

  components: { VTextFieldWithValidation, ValidationObserver, WhiteButton },

  data: () => ({
    email: "",
    password: "",
    passwordConfirmation: "",
    loading: false,
  }),

  methods: {
    onSubmit() {
      this.loading = true;
      let object = {
        email: this.$route.query.email,
        code: this.$route.query.code,
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
      };

      this.$store
        .dispatch("auth/setNewPassword", {
          object,
        })
        .then(() => {
          this.loading = false;
          this.password = "";
          this.passwordConfirmation = "";
          this.$refs.obs.reset();
        });
    },
  },
};
</script>

<style scoped>
.text-button::before {
  background-color: transparent;
}
.card {
  background-color: #fbc501;
}
.label {
  font-size: 0.8rem;
}
.logo {
  height: 150px;
}
</style>
