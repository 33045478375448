<template>
  <v-row justify="center" dense style="margin-top: -28px" no-gutters>
    <v-col cols="12" sm="11" md="9" lg="8" xl="7">
      <div v-if="simulations">
        <v-card
          tile
          elevation="0"
          class="d-flex align-center justify-space-between"
        >
          <v-img
            src="../assets/simulationx2.png"
            aspect-ratio="2"
            class="white--text align-end"
            gradient="to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0,1.99) 120%"
            max-height="300px"
          >
            <v-card-title class="cardTitle pb-10 noSelect justify-center bold">
              SYMULATOR PROWIZJI
            </v-card-title></v-img
          >
        </v-card>
        <v-card
          tile
          elevation="0"
          class="d-flex align-center justify-space-between"
        >
          <div id="name-container">
            <div id="name" ref="name" :style="{ fontSize: fontSize + 'px' }">
              {{ user.fullname }}
            </div>
          </div>
        </v-card>

        <v-card
          tile
          elevation="0"
          class="d-flex"
          :class="{
            'flex-row': $vuetify.breakpoint.smAndUp,
            'flex-column': $vuetify.breakpoint.xsOnly,
          }"
        >
          <v-card tile elevation="0" class="flex-grow-1 cardWidth">
            <small class="pl-7">{{ formatRole(user.role.name) }}</small>
            <v-card tile elevation="0" class="pb-3 px-3">
              <v-card tile elevation="0">
                <v-card-actions class="py-0 d-flex justify-space-between">
                  <div style="max-width: 300px">
                    <span class="pl-2" style="font-size: 0.7rem"
                      >MOJA POZYCJA</span
                    >
                    <v-select
                      :items="simulations"
                      item-text="name"
                      item-value="identifier"
                      v-model="position"
                      outlined
                      rounded
                      dense
                      class="pt-0"
                      :menu-props="{
                        bottom: true,
                        offsetY: true,
                      }"
                      hide-details
                    ></v-select>
                  </div>
                </v-card-actions>
              </v-card>
            </v-card>
          </v-card>
          <v-card flat elevation="0" class="cardWidth d-flex"
            ><v-card-actions class="px-5 pb-5 pt-5 align-self-end"
              ><small
                >*symulacja jest orientacyjna i nie uwzględnia premii
                BOOST</small
              ></v-card-actions
            ></v-card
          >
        </v-card>

        <v-card class="ma-4 px-3">
          <div class="point-container">
            <v-progress-linear
              :value="position * 25"
              color="#fcc502"
              height="11"
            >
            </v-progress-linear>
            <div
              v-for="(i, j) in 5"
              v-bind:key="j"
              class="point"
              :style="pointStyle(i)"
              @click="setPointPower(i)"
              :id="(i - 1) * 25"
            >
              <div v-if="position === i - 1" class="dot">
                <div class="pointer"></div>
              </div>
              <div v-else>|</div>
            </div>
          </div>
        </v-card>
        <div class="text-center px-3">
          <v-row>
            <v-col class="table-element" cols="4">
              <strong>Moja struktura</strong>
            </v-col>
            <v-col class="table-element" cols="4">
              <strong>CC:</strong>
            </v-col>
            <v-col class="table-element" cols="4">
              <strong>Prowizja</strong>
            </v-col>
          </v-row>
          <v-row
            v-for="(item, index) in simulations[position].simulation_items"
            :key="index + new Date()"
          >
            <v-col class="table-element" cols="4">{{
              formatName(item.name)
            }}</v-col>
            <v-col class="table-element" cols="4"
              ><div class="customField">
                <!-- <input class="customFieldInput" type="number" v-model="item.cc" /> -->
                <input
                  class="customFieldInput"
                  type="number"
                  :disabled="checkDisabled(index)"
                  :value="item.cc"
                  @change="calcNumber($event, index)"
                />
              </div>
            </v-col>
            <v-col class="table-element" cols="4"
              ><div class="customField" v-if="item.identifier !== -1">
                {{ roundNumber(calcProvision(item.percent, item.cc), 2) }}
              </div>
            </v-col>
          </v-row>
        </div>
        <hr />
        <div class="text-center">
          <v-row>
            <v-col class="table-element" cols="4">Podsumowanie:</v-col>
            <v-col class="table-element" cols="4">
              <strong>{{ calculateCC() }} pkt </strong>
            </v-col>
            <v-col class="table-element" cols="4"
              ><strong>{{ calculateProvision() }} zł</strong>
            </v-col>
          </v-row>
          <hr />
          <v-row justify="center">
            <div class="message">
              <v-card
                v-if="ccMessage() != ''"
                class="mx-5 rounded-xl py-2"
                flat
                style="background-color: rgb(252, 197, 2, 0.3)"
              >
                <p class="px-5" v-html="ccMessage()"></p>
              </v-card>
            </div>
          </v-row>
          <YellowButton
            text="Plan Marketingowy"
            @click.native="marketingModal = true"
          />

          <MarketingPlanModal
            :isVisible="marketingModal"
            @closeModal="marketingModal = false"
          />
        </div>
      </div>
      <div v-else>Loading...</div>
    </v-col>
  </v-row>
</template>

<script>
import shared from "../shared";
import MarketingPlanModal from "../components/modals/MarketingPlanModal";
import YellowButton from "../components/buttons/YellowButton";
export default {
  name: "Simulation",
  components: {
    YellowButton,
    MarketingPlanModal,
  },
  data: function () {
    return {
      marketingModal: false,
      cc: 5,
      points: 12,
      position: 1,
      provision: 0,
      simulations: null,
      ccSum: 0,
      fontSize: 34,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustFontSize);
  },

  async created() {
    await this.$store.dispatch("user/fetchUser");
    await this.$store.dispatch("user/getUserSimulation");
    this.simulations = await this.$store.getters["user/getSimulation"];
    this.position = this.positionValue();
    this.adjustFontSize();
    window.addEventListener("resize", this.adjustFontSize);
  },
  computed: {
    user() {
      return this.$store.getters["user/getUser"];
    },
  },
  watch: {
    ccSum() {
      var num = 0;
      if (
        this.simulations[this.position].simulation_items[0] &&
        this.simulations[this.position].simulation_items[1] &&
        this.simulations[this.position].simulation_items[2] &&
        this.simulations[this.position].simulation_items[0].cc * 1 +
          this.simulations[this.position].simulation_items[1].cc * 1 +
          this.simulations[this.position].simulation_items[2].cc * 1 <
          4
      ) {
        this.simulations[this.position].simulation_items.forEach((j, index) => {
          if (index > 2) {
            j.cc = num.toFixed(3);
          }
        });
      }
    },
  },
  destroyed() {
    const { simulations } = this;
    if (simulations && simulations !== []) {
      return this.$store.dispatch("user/saveUserSimulation", { simulations });
    }
  },
  methods: {
    adjustFontSize() {
      this.$nextTick(() => {
        const nameElement = this.$refs.name;
        if (!nameElement) return;
        const containerWidth = nameElement.parentElement.offsetWidth;
        let fontSize = 34; // Maksymalny początkowy rozmiar czcionki
        nameElement.style.fontSize = fontSize + "px";
        while (nameElement.scrollWidth > containerWidth && fontSize > 0) {
          fontSize--;
          nameElement.style.fontSize = fontSize + "px";
        }
        this.fontSize = fontSize;
      });
    },
    formatName(name) {
      if (name === "MyShopping") return "Moje zakupy";
      return name;
    },
    checkDisabled(index) {
      if (index <= 2) return false;
      else if (
        this.simulations[this.position].simulation_items[0].cc * 1 +
          this.simulations[this.position].simulation_items[1].cc * 1 +
          this.simulations[this.position].simulation_items[2].cc * 1 <
        4
      )
        return true;
      else return false;
    },
    roundNumber(number, precision) {
      return shared.roundNumber(number, precision);
    },
    formatRole(data) {
      return shared.formatRole(data);
    },
    calcNumber(e, index) {
      var num = 0;
      if (!e.target.value || e.target.value === "") {
        e.target.value = parseFloat(num).toFixed(3);
        this.simulations.forEach((simulation) => {
          if (
            simulation.simulation_items[index] &&
            simulation.simulation_items.length > 0
          ) {
            simulation.simulation_items[index].cc = parseFloat(num).toFixed(3);
          }
        });
      } else {
        e.target.value = parseFloat(e.target.value).toFixed(3);
        this.simulations.forEach((simulation) => {
          if (
            simulation.simulation_items[index] &&
            simulation.simulation_items.length > 0
          ) {
            simulation.simulation_items[index].cc = parseFloat(
              e.target.value
            ).toFixed(3);
          }
        });
      }
    },
    pointStyle(i) {
      const num = (i - 1) * 25;
      return `left: ${num}%;`;
    },
    setPointPower(i) {
      const num = i - 1;
      this.position = num;
    },
    calcProvision(percent, cc) {
      const provision = 1254 * (percent / 100) * cc;
      return this.roundNumber(provision * 100, 2) / 100;
    },
    calculateProvision() {
      let sum = 0;
      this.simulations[this.position].simulation_items.forEach((item) => {
        const provision = 1254 * (item.percent / 100) * item.cc;
        sum += provision;
      });
      return parseFloat(sum).toFixed(2);
    },
    calculateCC() {
      const result = this.simulations[this.position].simulation_items.reduce(
        (a, b) => {
          return a + parseFloat(b.cc);
        },
        0
      );

      this.ccSum = result;
      return parseFloat(result).toFixed(3);
    },
    positionValue() {
      const role = this.user?.role?.name;
      if (role) {
        if (role === "PREFFERED_CUSTOMER") return 0;
        if (role === "ASSISTANT_SUPERVISOR") return 1;
        if (role === "SUPERVISOR") return 2;
        if (role === "ASSISTANT_MANAGER") return 3;
        if (role === "MANAGER") return 4;
        else return 0;
      }
    },
    ccMessage() {
      let message = "";
      let threshold;
      if (this.position === 0) threshold = 2;
      if (this.position === 1) threshold = 25;
      if (this.position === 2) threshold = 75;
      if (this.position === 3) threshold = 120;
      if (this.position === 4) threshold = 0;
      let x = parseFloat(threshold) - parseFloat(this.ccSum);
      x = x.toFixed(2);
      if (this.position === 3) {
        let y = parseFloat(threshold) + 30 - parseFloat(this.ccSum);
        if (x > 0) {
          message = `<span>Jeżeli osiągniesz założony cel, do następnego awansu wystarczy zrobić <strong>${x}</strong> CC
w kolejnym miesiącu, lub <strong>${y}</strong> CC w kolejnych trzech.</span>`;
        } else {
          message = `<span>Jeżeli osiągniesz założony cel, przy następnym awansie przekroczysz próg o <strong>${x}</strong> CC</span>`;
        }
      } else if (this.position !== 4) {
        if (x > 0) {
          message = `<span>Jeżeli osiągniesz założony cel, do nastepnego awansu wystarczy zrobić <strong>${x}</strong> CC</span>`;
        } else {
          message = `<span>Jeżeli osiągniesz założony cel, przy następnym awansie przekroczysz próg o <strong>${x}</strong> CC</span>`;
        }
      }
      return message;
    },
  },
};
</script>

<style lang="scss" scoped>
.max-width {
  max-width: 1200px;
  margin: auto;
}

.row {
  margin-left: 0;
  margin-right: 0;
}
.table-element {
  padding: 10px !important;
}
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .table-element {
    font-size: 12px;
  }
}
.message {
  margin: 10px 0;
}
.point-container {
  position: relative;
  width: 100%;
  margin: 50px 0 30px 0;
}
.point {
  position: absolute;
  top: -13px;
  cursor: pointer;
  font-size: 24px;
  transform: translateX(-3px);
}
.dot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: black solid 4px;
  display: inline-block;
  transform: translate(-45%, 10%);
  background-color: #fcc502;
  position: relative;
}
.dot:before {
  background: none;
  border: 5px solid #fff;
  border-radius: 50%;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  pointer-events: none;
}
.pointer {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid black;
  transform: translate(1px, -27px);
}
.customField {
  background-color: #f0f0f0;
  border-radius: 20px;
  border: black 2px solid;
  padding: 3px;
  text-align: center;
}
.customFieldInput {
  text-align: center;
  width: 75%;
}

#name-container {
  width: 95%;
  max-width: 1280px;
  padding: 10px 0;
  overflow: hidden; /* Zapobiega przewijaniu, jeśli tekst jest zbyt długi */
  margin: 0 auto;
}
#name {
  white-space: nowrap; /* Utrzymuje tekst w jednej linii */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.cardTitle {
  font-weight: bold;
  font-size: 30px;
  word-break: break-word;
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .cardTitle {
    font-weight: bold;
    font-size: 48px;
    word-break: break-word;
  }
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
</style>
