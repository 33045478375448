var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-navigation-drawer',{staticClass:"font",style:(_vm.$vuetify.breakpoint.name === 'sm' || _vm.$vuetify.breakpoint.name === 'xs'
        ? 'margin-top:56px'
        : 'margin-top:64px'),attrs:{"width":_vm.$vuetify.breakpoint.name === 'xs' ? '101vw' : '280',"color":"#fbc501","right":"","fixed":"","clipped":"","temporary":false,"mobile-breakpoint":"0"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"bold",class:{
        'text-center': _vm.$vuetify.breakpoint.name === 'xs',
      },attrs:{"nav":""}},[_c('v-list-item-group',{class:{ 'padding-drawer': _vm.$vuetify.breakpoint.name === 'xs' },attrs:{"active-class":"black--text text--accent-4"}},[_c('v-list-item',{attrs:{"to":{ name: 'home' }},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-list-item-title',{class:{
              'large-font': _vm.$vuetify.breakpoint.name === 'xs',
              'normal-font': _vm.$vuetify.breakpoint.name != 'xs',
            }},[_vm._v("STRONA GŁÓWNA")])],1),(_vm.token.roleId != 6)?_c('v-list-item',{attrs:{"to":{ name: 'recommendations' }},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-list-item-title',{class:{
              'large-font': _vm.$vuetify.breakpoint.name === 'xs',
              'normal-font': _vm.$vuetify.breakpoint.name != 'xs',
            }},[_vm._v("REKOMENDACJE")])],1):_vm._e(),(_vm.token.roleId != 6)?_c('v-list-item',{attrs:{"to":{ name: 'subscription' }},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-list-item-title',{class:{
              'large-font': _vm.$vuetify.breakpoint.name === 'xs',
              'normal-font': _vm.$vuetify.breakpoint.name != 'xs',
            }},[_vm._v("ABONAMENT")])],1):_vm._e(),(_vm.token.roleId != 6)?_c('v-list-item',{attrs:{"to":{ name: 'settings' }},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-list-item-title',{class:{
              'large-font': _vm.$vuetify.breakpoint.name === 'xs',
              'normal-font': _vm.$vuetify.breakpoint.name != 'xs',
            }},[_vm._v("USTAWIENIA")])],1):_vm._e(),_c('v-list-item',{attrs:{"to":{ name: 'how-use-app' }},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-list-item-title',{class:{
              'large-font': _vm.$vuetify.breakpoint.name === 'xs',
              'normal-font': _vm.$vuetify.breakpoint.name != 'xs',
            }},[_vm._v("JAK KORZYSTAĆ")])],1),_c('v-list-item',{attrs:{"to":{ name: 'help' }},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-list-item-title',{class:{
              'large-font': _vm.$vuetify.breakpoint.name === 'xs',
              'normal-font': _vm.$vuetify.breakpoint.name != 'xs',
            }},[_vm._v("FAQ")])],1),_c('v-list-item',{attrs:{"to":{ name: 'contact' }},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-list-item-title',{class:{
              'large-font': _vm.$vuetify.breakpoint.name === 'xs',
              'normal-font': _vm.$vuetify.breakpoint.name != 'xs',
            }},[_vm._v("KONTAKT")])],1),(_vm.token.roleId === 6)?_c('v-list-item',{attrs:{"to":{ name: 'admin' }},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-list-item-title',{staticClass:"break",class:{
              'large-font': _vm.$vuetify.breakpoint.name === 'xs',
              'normal-font': _vm.$vuetify.breakpoint.name != 'xs',
            }},[_vm._v("PANEL ADMINISTRATORA")])],1):_vm._e(),_c('v-list-item',{attrs:{"to":{ name: 'add-to-desktop' }},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-list-item-title',{class:{
              'large-font': _vm.$vuetify.breakpoint.name === 'xs',
              'normal-font': _vm.$vuetify.breakpoint.name != 'xs',
            }},[_vm._v("DODAJ DO PULPITU")])],1),_c('v-list-item',{attrs:{"to":{ name: 'landing-page' }}},[_c('v-list-item-title',{class:{
              'large-font': _vm.$vuetify.breakpoint.name === 'xs',
              'normal-font': _vm.$vuetify.breakpoint.name != 'xs',
            }},[_vm._v("STRONA STARTOWA")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-title',{class:{
              'large-font': _vm.$vuetify.breakpoint.name === 'xs',
              'normal-font': _vm.$vuetify.breakpoint.name != 'xs',
            }},[_vm._v("WYLOGUJ SIĘ")])],1)],1)],1),_c('v-divider'),_c('v-list',{staticClass:"text-center",attrs:{"nav":""}},[_c('v-list-item-title',{class:{
          'xsmall-font': _vm.$vuetify.breakpoint.name != 'xs',
        }},[_vm._v(" Potrzebujesz pomocy? ")]),_c('v-list-item-title',{class:{
          'xsmall-font': _vm.$vuetify.breakpoint.name != 'xs',
        }},[_vm._v("Skontaktuj się!")]),_c('v-list-item',[_c('v-list-item-title',{staticClass:"bold text-decoration-underline",class:{
            'small-font': _vm.$vuetify.breakpoint.name === 'xs',
            'xsmall-font': _vm.$vuetify.breakpoint.name != 'xs',
          }},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"color":"white","icon":"envelope"}}),_vm._v("kontakt@formyeveroffice.com")],1)],1)],1)],1),_c('v-app-bar',{staticStyle:{"z-index":"99"},attrs:{"color":"#fbc501","dark":"","app":""}},[_c('router-link',{attrs:{"to":"/home"}},[_c('v-img',{staticClass:"mx-2 logo",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../assets/logowhite.svg"),"contain":""},on:{"click":function($event){return _vm.logoClick()}}})],1),_c('v-spacer'),_c('v-app-bar-nav-icon',{attrs:{"color":"black","right":""},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('font-awesome-icon',{staticClass:"burger",class:{ 'burger--show': !_vm.drawer },attrs:{"icon":"bars"}}),_c('font-awesome-icon',{staticClass:"burger",class:{ 'burger--show': _vm.drawer },attrs:{"icon":"times"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }