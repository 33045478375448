<template>
  <v-row justify="center" style="z-index:9999;">
    <v-dialog
      @keydown.esc="closeModal()"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <BlackButton
          width="20px"
          height="20px"
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />

        <v-img :src="getImageUrl() + 'PlanMarketingowy.jpg'"></v-img>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { IMAGE_URL } from "../../config";
import BlackButton from "../buttons/BlackButton";

export default {
  name: "ProductModal",
  props: ["isVisible"],

  components: {
    BlackButton,
  },
  data: () => ({}),
  computed: {},
  watch: {},
  methods: {
    getImageUrl() {
      return IMAGE_URL;
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 1%;
  right: 1%;
  z-index: 10000000;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
</style>
