import axios from "../../axios";
import errorHandler from "../../errorHandler";

const calculation = {
  namespaced: true,

  state: () => ({
    calculations: [],
    calculation: "",
    calculationId: "",
    calculationStatuses: [],
    calculationPdfPath: null,
  }),

  mutations: {
    SET_CALCULATIONS(state, data) {
      state.calculations = data;
    },
    SET_CALCULATION(state, data) {
      state.calculation = data;
    },
    SET_CALCULATION_PDF_PATH(state, data) {
      state.calculationPdfPath = data;
    },
    SET_CALCULATION_ID(state, data) {
      state.calculationId = data;
    },
    SET_CALCULATION_STATUSES(state, data) {
      state.calculationStatuses = data;
    },
  },

  actions: {
    async fetchCalculations({ dispatch, commit }, object) {
      let { limit, sorting, deleted } = object;
      const _limit = limit ? `limit=${limit}&` : "";
      const sortBy =
        sorting?.sortBy0 && sorting?.sortBy1
          ? `sortBy[0]=${object.sorting.sortBy0}&sortBy[1]=${object.sorting.sortBy1}&`
          : "";

      const filterByStatus =
        sorting && sorting.status && sorting.status !== ""
          ? `status=${sorting.status}&`
          : "";

      const showDeleted = deleted ? "deleted=true&" : "";
      const baseUrl = "calculation?";
      let url = baseUrl + _limit + sortBy + filterByStatus + showDeleted;
      url = url.slice(0, -1);
      try {
        const response = await axios.get(url);
        commit("SET_CALCULATIONS", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchCalculationById({ dispatch, commit }, object) {
      try {
        const response = await axios.get(`calculation/${object}`);
        commit("SET_CALCULATION", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchCalculationStatuses({ dispatch, commit }) {
      try {
        const response = await axios.get("calculation-statuses");
        commit("SET_CALCULATION_STATUSES", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async createCalculation({ commit, dispatch }, object) {
      try {
        const response = await axios.post("calculation", object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie stworzono kalkulację!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        commit("SET_CALCULATION_ID", response.data.calculationId);
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async softDeleteCalculation({ dispatch }, object) {
      try {
        await axios.delete(`calculation/${object}/softDelete`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Kalkulacja została przeniesiona do kosza!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async restoreCalculation({ dispatch }, calculationId) {
      try {
        await axios.post(`calculation/${calculationId}/restoreDeleted`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Kalkulacja została przywrócona!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async deleteCalculation({ dispatch }, object) {
      try {
        await axios.delete(`calculation/${object}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto kalkulację!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async editCalculation({ dispatch, commit }, object) {
      let data = {
        newCustomer: object.newCustomer,
        note: object.note,
        clientNote: object.clientNote,
        customerId: object.customerId,
        discount: object.discount,
        roleId: object.roleId,
        updatedProducts: object.updatedProducts,
        deletedProducts: object.deletedProducts,
        orderDate: object.orderDate ? object.orderDate : null,
        offerDate: object.offerDate ? object.offerDate : null,
      };
      try {
        await axios.put(`calculation/${object.calculationId}`, data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano kalkulację!",
          },
          { root: true }
        );
        commit("SET_CALCULATION_ID", object.calculationId);
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async addProduct({ commit, dispatch }, object) {
      var data = {
        products: object.products,
      };
      try {
        const response = await axios.post(
          `calculation/${object.calculation}/products`,
          data
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano produkt!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        commit("SET_CALCULATION_ID", response.data.calculationId);
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async sendEmail({ dispatch }, object) {
      try {
        await axios.post(`calculation/${object}/pdf`, {});
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie wysłano kalkulację!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async editStatus({ dispatch }, object) {
      try {
        await axios.put(
          `calculation/${object.calculation}/status`,
          object.data
        );

        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie zmieniono status!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async handleCalculationPaymentStatus({ dispatch }, object) {
      try {
        await axios.put(`calculation/${object.calculationId}/payment`, {
          isPaid: object.isPaid,
        });
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie zmieniono status płatności kalkulacji!",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async editCalculationProductsStatuses({ dispatch }, object) {
      try {
        await axios.put(
          `calculation/${object.calculationId}/products/statuses`,
          object.products
        );

        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie zaktualizowano statusy produktów!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async editCalculationProductAmount({ dispatch }, object) {
      try {
        await axios.put(
          `calculation/${object.calculationId}/products/${object.productId}/amount`,
          {
            amount: object.amount,
          }
        );

        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie zaktualizowano ilość produktu!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async editProducts({ dispatch }, object) {
      let { updated, deleted } = object;
      let data = {};
      if (updated && deleted) {
        data = {
          updatedProducts: updated,
          deletedProducts: deleted,
        };
      } else if (!updated && deleted) {
        data = {
          deletedProducts: deleted,
        };
      } else {
        data = {
          updatedProducts: updated,
        };
      }
      try {
        await axios.put(`calculation/${object.calculation}/products`, data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano kalkulację!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async downloadPdf({ commit, dispatch }, object) {
      try {
        if (object.onlyFilePath) {
          const response = await axios.get(
            `calculation/${object.id}/pdf?onlyFilePath=`
          );
          commit("SET_CALCULATION_PDF_PATH", response.data.split("/")[1]);
        } else {
          const response = await axios.get(`calculation/${object.id}/pdf`, {
            responseType: "blob",
          });
          let fileName = "";
          for (const [key, value] of Object.entries(response.headers)) {
            if (key === "content-disposition") {
              const res = value.split('"');
              fileName = res[1];
              fileName = unescape(fileName);
            }
          }
          fileName = encodeURI(fileName);

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);

          fileLink.click();
        }
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async deleteCalculationProduct({ dispatch }, object) {
      try {
        await axios.delete(
          `calculation/${object.calculationId}/products/${object.calculationProductId}`
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto produkt z kalkulacji!",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async getCalculationJpgPaths(_, object) {
      const response = await axios.get(`calculation/${object.id}/jpg`);
      return response.data;
    },

    async downloadCalculationImage(_, object) {
      const { calculationId, productName, jpgFilename } = object;
      const response = await axios.get(
        `calculation/${calculationId}/jpg/${jpgFilename}`,
        { responseType: "blob" }
      );

      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${productName}.jpg`);
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    async updateCalculationDeliveryPrice(_, object) {
      const { deliveryPrice, calculationId } = object;
      const response = await axios.put(
        `calculation/${calculationId}/delivery-price`,
        {
          deliveryPrice,
        }
      );
      return response.data;
    },
  },

  getters: {
    getCalculations: (state) => state.calculations,
    getCalculation: (state) => state.calculation,
    getCalculationId: (state) => state.calculationId,
    getCalculationPdfPath: (state) => state.calculationPdfPath,
    getCalculationStatuses: (state) => state.calculationStatuses,
  },
};
export default calculation;
