<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="600px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          id="customer-modal-title"
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title v-if="!isEdit">Dodaj kontakt</v-toolbar-title>
          <v-toolbar-title v-else>Edytuj kontakt</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    rules="required|max:255"
                    v-model="fullname"
                    label="Imię i nazwisko"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    type="email"
                    rules="email|max:255"
                    v-model="email"
                    label="Adres e-mail"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextAreaWithValidation
                    rules="max:255"
                    label="Adres"
                    style="height: 150px"
                    v-model="address"
                    outlined
                    dense
                    :maxLengthInfo="255"
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    v-model="phone"
                    label="Numer telefonu"
                    color="indigo"
                    outlined
                    dense
                    rules="max:255|phoneWithPrefix"
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextAreaWithValidation
                    rules="max:10240"
                    label="Notatka"
                    v-model="note"
                    outlined
                    dense
                    :maxLengthInfo="10240"
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    v-model="fbo"
                    label="Numer FBO"
                    color="indigo"
                    outlined
                    dense
                    rules="max:255|onlyNumbers"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  v-for="(socialLink, index) in socialLinks"
                  :key="socialLink.id"
                >
                  <VTextFieldWithValidation
                    v-model="socialLinks[index].link"
                    :label="`Link do social mediów/strony www`"
                    color="indigo"
                    outlined
                    dense
                    rules="link|max:255"
                  />
                </v-col>

                <v-col cols="12" sm="12">
                  <yellow-button
                    width="100%"
                    text="Dodaj kolejny link"
                    border
                    class="ml-0"
                    @click.native="addNextSocialLink"
                  />
                </v-col>

                <v-col cols="12" sm="12" class="mt-12">
                  <v-file-input
                    ref="fileupload"
                    v-model="photo"
                    dense
                    outlined
                    rounded
                    label="Załącz zdjęcie profilowe"
                    accept="image/png,image/jpeg"
                  ></v-file-input>
                </v-col>

                <div class="px-2">
                  <span class="">Data urodzin</span>
                  <v-row class="p-0 m-0">
                    <v-col cols="4" class="pt-0">
                      <v-select
                        :items="days"
                        v-model="birthDateDay"
                        label="Dzień"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-select
                        :items="months"
                        v-model="birthDateMonth"
                        label="Miesiąc"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-select
                        :items="years"
                        v-model="birthDateYear"
                        label="Rok"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <span class="">Data imienin</span>
                  <v-row class="p-0 m-0">
                    <v-col cols="4" class="pt-0">
                      <v-select
                        :items="days"
                        v-model="nameDayDay"
                        label="Dzień"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <v-select
                        :items="months"
                        v-model="nameDayMonth"
                        label="Miesiąc"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="warning"
              text
              v-on:click="closeModal"
              class="mr-4"
              :disabled="creatingCustomerInProgress"
            >
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-progress-circular
              v-if="creatingCustomerInProgress"
              color="amber"
              indeterminate
              size="20"
            ></v-progress-circular>
            <v-btn
              v-if="!isEdit"
              color="success"
              text
              :disabled="invalid || creatingCustomerInProgress"
              v-on:click="addCustomer()"
            >
              <font-awesome-icon
                v-if="!creatingCustomerInProgress"
                icon="check"
                class="mr-1"
              />
              Dodaj kontakt
            </v-btn>
            <v-btn
              v-else
              color="success"
              text
              :disabled="invalid || creatingCustomerInProgress"
              v-on:click="editCustomer()"
            >
              <font-awesome-icon
                v-if="!creatingCustomerInProgress"
                icon="check"
                class="mr-1"
              />
              Zapisz kontakt
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import BlackButton from "../buttons/BlackButton";
import YellowButton from "../buttons/YellowButton.vue";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";

export default {
  name: "CustomerModal",
  props: ["isVisible", "isEdit", "add"],

  components: {
    VTextFieldWithValidation,
    ValidationObserver,
    BlackButton,
    YellowButton,
    VTextAreaWithValidation,
  },
  data: () => ({
    fullname: "",
    note: "",
    phone: "",
    email: "",
    address: "",
    fbo: "",
    photo: null,
    socialLinks: [
      {
        id: 1,
        link: "",
      },
    ],
    days: [
      "Brak",
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
    ],
    months: ["Brak", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    years: ["Brak"],
    birthDateDay: "Brak",
    birthDateMonth: "Brak",
    birthDateYear: "Brak",
    nameDayDay: "Brak",
    nameDayMonth: "Brak",
    creatingCustomerInProgress: false,
  }),
  created() {
    for (let year = new Date().getFullYear(); year >= 1900; year--) {
      this.years.push(year);
    }
  },
  updated() {
    if (!this.customer?.id) {
      this.$nextTick(() => {
        const element = document.getElementById("customer-modal-title");
        if (element) {
          element.scrollIntoView();
        }
      });
      this.socialLinks = [
        {
          id: 1,
          link: "",
        },
      ];
    }
  },
  computed: {
    customer() {
      return this.$store.getters["customers/getCustomer"];
    },
  },
  watch: {
    customer: function () {
      if (!this.add && this.customer) {
        this.fullname = this.customer.fullname || "";
        this.email = this.customer.email || "";
        this.address = this.customer.address || "";
        this.note = this.customer.note || "";
        this.phone = this.customer.phone || "";
        this.fbo = this.customer.fbo || "";
        this.birthDateDay = !this.customer.birthDateDay
          ? "Brak"
          : this.customer.birthDateDay;
        this.birthDateMonth = !this.customer.birthDateMonth
          ? "Brak"
          : this.customer.birthDateMonth;
        this.birthDateYear = !this.customer.birthDateYear
          ? "Brak"
          : this.customer.birthDateYear;
        this.nameDayDay = !this.customer.nameDayDay
          ? "Brak"
          : this.customer.nameDayDay;
        this.nameDayMonth = !this.customer.nameDayMonth
          ? "Brak"
          : this.customer.nameDayMonth;
        if (
          this.customer.customers_social_links &&
          this.customer.customers_social_links.length > 0
        ) {
          this.socialLinks = this.customer.customers_social_links.map(
            (customerSocialLink) => {
              return {
                id: customerSocialLink.id,
                link: customerSocialLink.link,
              };
            }
          );
        }
      }
    },
  },
  methods: {
    addNextSocialLink() {
      const numberOfSocialLinks = this.socialLinks.length;
      if (numberOfSocialLinks === 5) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Można dodać maksymalnie 5 linków!",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        return;
      }
      this.socialLinks.push({
        id: numberOfSocialLinks + 1,
        link: "",
      });
    },

    closeModal() {
      this.fullname = "";
      this.email = "";
      this.address = "";
      this.note = "";
      this.phone = "";
      this.fbo = "";
      this.photo = null;
      this.socialLinks = [
        {
          id: 1,
          link: "",
        },
      ];
      this.$refs.obs.reset();
      this.$emit("closeModal");
    },
    async addCustomer() {
      if (!this.fullname) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Wymagane imię i nazwisko dla kontaktu!",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        return;
      }
      let object = {
        fullname: this.fullname,
        email: this.email,
        address: this.address,
        note: this.note,
        phone: this.phone,
        fbo: this.fbo,
        socialLinks: this.socialLinks.map((socialLink) => socialLink.link),
        birthDateDay: this.birthDateDay === "Brak" ? null : this.birthDateDay,
        birthDateMonth:
          this.birthDateMonth === "Brak" ? null : this.birthDateMonth,
        birthDateYear:
          this.birthDateYear === "Brak" ? null : this.birthDateYear,
        nameDayDay: this.nameDayDay === "Brak" ? null : this.nameDayDay,
        nameDayMonth: this.nameDayMonth === "Brak" ? null : this.nameDayMonth,
      };
      try {
        this.creatingCustomerInProgress = true;
        const customer = await this.$store.dispatch(
          "customers/addCustomer",
          object
        );
        if (this.photo) {
          await this.$store.dispatch("customers/updateCustomerPhoto", {
            customerId: customer.id,
            file: this.photo,
          });
        }
        this.$emit("fetch", customer.id);
        this.$emit("newCustomerAdded", customer.id);
        this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        this.creatingCustomerInProgress = false;
      }
    },
    async editCustomer() {
      let object = {
        id: this.customer.id,
        data: {
          fullname: this.fullname,
          email: this.email,
          address: this.address,
          note: this.note,
          phone: this.phone,
          fbo: this.fbo,
          socialLinks: this.socialLinks.map((socialLink) => socialLink.link),
          birthDateDay: this.birthDateDay === "Brak" ? null : this.birthDateDay,
          birthDateMonth:
            this.birthDateMonth === "Brak" ? null : this.birthDateMonth,
          birthDateYear:
            this.birthDateYear === "Brak" ? null : this.birthDateYear,
          nameDayDay: this.nameDayDay === "Brak" ? null : this.nameDayDay,
          nameDayMonth: this.nameDayMonth === "Brak" ? null : this.nameDayMonth,
        },
      };
      const updatedCustomer = await this.$store.dispatch(
        "customers/editCustomer",
        object
      );
      if (this.photo) {
        await this.$store.dispatch("customers/updateCustomerPhoto", {
          customerId: updatedCustomer.id,
          file: this.photo,
        });
      }
      this.closeModal();
      this.$emit("fetch");
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 1%;
  right: 3%;
  z-index: 10000000;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
</style>
