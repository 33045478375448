var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 10 : 0,"tile":"","to":_vm.to}},[_c('v-img',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"black--text align-end ",attrs:{"height":_vm.$vuetify.breakpoint.name != 'xs' &&
            _vm.$vuetify.breakpoint.name != 'sm'
              ? '39.5vh'
              : '',"aspect-ratio":_vm.$vuetify.breakpoint.name === 'xs' ||
            _vm.$vuetify.breakpoint.name === 'sm'
              ? '1'
              : '',"src":_vm.getPhoto()}})],1)]}}])}),_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],attrs:{"elevation":"0","color":"transparent","to":_vm.to}},[_c('v-card-title',{staticClass:"cardTitle smallPadding noSelect justify-center"},[_c('span',{staticClass:"break text-center"},[_vm._v(_vm._s(_vm.text))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }