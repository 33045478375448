<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="600px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Edytuj swoje dane</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    v-model="fullname"
                    label="Imię i nazwisko"
                    color="indigo"
                    outlined
                    dense
                    rules="max:60"
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    rules="onlyNumbers"
                    v-model="fbo"
                    label="Fbo"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    rules="onlyNumbers"
                    v-model="phone"
                    label="Telefon"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>

                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    rules="required|max:1024"
                    v-model="howFoundApp"
                    label="Jak dowiedziałaś/łeś się o aplikacji?"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>

                <v-col cols="12" sm="12">
                  <VSelectWithValidation
                    label="Wybierz język"
                    outlined
                    color="black"
                    v-model="languageId"
                    dense
                    rounded
                    :menu-props="{
                      bottom: true,
                      offsetY: true,
                    }"
                    background-color="white"
                    :items="languages"
                    item-value="id"
                  >
                    <template v-slot:selection="{ item }">
                      <img
                        height="15px"
                        width="25px"
                        class="mr-1"
                        :src="getImageURL() + item.imagePath"
                      />
                      <span>{{ item.name }}</span>
                    </template>
                    <template v-slot:item="slotProps">
                      <img
                        height="15px"
                        width="25px"
                        class="mr-1"
                        :src="getImageURL() + slotProps.item.imagePath"
                      />
                      <span>{{ slotProps.item.name }}</span>
                    </template></VSelectWithValidation
                  >
                </v-col>

                <v-col cols="12" sm="12" class="">
                  <VCheckboxWithValidation
                    color="black"
                    v-model="newsletterCheckbox"
                    label="Dołączam do newslettera, aby otrzymywać informacje o aktualizacjach funkcji w aplikacji ForMyever Office."
                    required="false"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="editUser"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { IMAGE_URL } from "../../config";
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VSelectWithValidation from "../inputs/VSelectWithValidation";
import BlackButton from "../buttons/BlackButton";
import VCheckboxWithValidation from "../inputs/VCheckboxWithValidation.vue";

export default {
  name: "EditUserModal",
  props: ["isVisible"],

  components: {
    VTextFieldWithValidation,
    VSelectWithValidation,
    ValidationObserver,
    BlackButton,
    VCheckboxWithValidation,
  },

  data: () => ({
    fullname: "",
    phone: "",
    fbo: "",
    languageId: "",
    position: "",
    newsletterCheckbox: false,
    howFoundApp: "",
  }),
  computed: {
    roles() {
      return this.$store.getters["publics/getRoles"];
    },
    languages() {
      return this.$store.getters["publics/getLanguages"];
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
  },
  watch: {
    user: function () {
      this.fullname = this.user.fullname;
      this.fbo = this.user.fbo;
      this.phone = this.user.phone;
      this.languageId = this.user.languageId;
      this.newsletterCheckbox = this.user.newsletter;
      this.howFoundApp = this.user.howFoundApp;
    },
  },
  methods: {
    getImageURL() {
      return IMAGE_URL;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    async editUser() {
      const {
        fullname,
        fbo,
        phone,
        languageId,
        newsletterCheckbox,
        howFoundApp,
      } = this;
      let object = {
        fullname: fullname ? fullname : "",
        fbo: fbo ? fbo : "",
        phone: phone ? phone : "",
        languageId: languageId,
        newsletter: newsletterCheckbox,
        howFoundApp: howFoundApp ? howFoundApp : "",
      };
      await this.$store.dispatch("user/updateUser", object);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10000000;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
</style>
