// eslint-disable-next-line no-unused-vars
import {
  required,
  email,
  max,
  min_value,
  numeric,
  oneOf,
  max_value,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "To pole nie może być puste!",
});

extend("max", {
  ...max,
  message: "To pole może zawierać maksymalnie {length} znaków!",
});

extend("email", {
  ...email,
  message: "Podany adres e-mail jest niepoprawny!",
});

extend("numeric", {
  ...numeric,
  message: "To pole może zawierać jedynie liczbę!",
});

extend("min_value", {
  ...min_value,
  message: "Minimalna wartość dla tego pola to: {min}!",
});

extend("max_value", {
  ...max_value,
  message: "Maksymalna wartość dla tego pola to: {max}!",
});

extend("checkbox", {
  ...required,
  validate(value) {
    return value === true;
  },
  message: "Musisz wyrazić zgodę!",
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Hasła muszą być identyczne!",
});

extend("oneOf", {
  ...oneOf,
});

extend("link", {
  validate(value) {
    let url;
    try {
      url = new URL(value);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  },
  message: "Niepoprawny link",
});

extend("phone", {
  validate: (v) => {
    return (
      !v ||
      /(?<!\w)(\(?(\+|00)?48\)?)??\d{3}?\d{3}?\d{3}(?!\w)/.test(String(v)) ||
      `Niepoprawny numer telefonu`
    );
  },
});

extend("onlyLettersWithSpaces", {
  validate: (v) => {
    return (
      !v ||
      /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/.test(String(v)) ||
      "Wartość powinna zawierać tylko litery"
    );
  },
});

extend("postalCode", {
  validate: (v) => {
    return (
      !v ||
      /^\d{2}-\d{3}$/.test(String(v)) ||
      "Nieprawidłowy format kodu pocztowego (np. 00-000)"
    );
  },
});

extend("alphanumericWithSpaces", {
  validate: (v) => {
    return (
      !v ||
      /^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/.test(String(v)) ||
      "Wartość powinna zawierać tylko litery i cyfry"
    );
  },
});

extend("alphanumericWithSpacesDotAndSlash", {
  validate: (v) => {
    return (
      !v ||
      /^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s\\./\\/]+$/.test(String(v)) ||
      "Wartość powinna zawierać tylko litery, cyfry, kropki i ukośniki"
    );
  },
});

extend("onlyNumbers", {
  ...numeric,
  message: "To pole może zawierać jedynie cyfry!",
});

extend("phoneWithPrefix", {
  validate(value) {
    return /^[0-9+\-\s()]+$/.test(value);
  },
  message: "Niepoprawny numer telefonu!",
});
