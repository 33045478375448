<template>
  <v-row justify="center" style="z-index:9999;">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="600px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" mode="eager">
          <v-card-text>
            <div v-if="isOrdered" class="mb-10">
              Klient zaakceptował Twoją ofertę. Po kliknięciu ZAPISZ produkty
              zmienią status na ZAMÓWIONO i znajdą się na Twojej liście zakupów
              w zakładce MOJE BIURO.
              <div class="mt-2 inputGroup">
                <input
                  type="checkbox"
                  name="orderedInfoInput"
                  id="orderedInfoInput"
                  v-model="orderedInfoInput"
                />
                <label for="orderedInfoInput" class="ml-2"
                  >Nie pokazuj więcej</label
                >
              </div>
            </div>

            <div v-if="isRealized" class="mb-10">
              Produkty dotarły do Twojego klienta. Po kliknięciu ZAPISZ produkty
              zmienią status na ZREALIZOWANE, a ich ilości odjęte z Twojej Listy
              zakupów. Aby móc zrealizować dany produkt, musisz go najpierw
              KUPIĆ na swojej Liście zakupów.
              <div class="mt-2 inputGroup">
                <input
                  type="checkbox"
                  name="realizedInfoInput"
                  id="realizedInfoInput"
                  v-model="realizedInfoInput"
                />
                <label for="realizedInfoInput" class="ml-2"
                  >Nie pokazuj więcej</label
                >
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn color="success" text v-on:click="saveProducts()">
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz produkty
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BlackButton from "../buttons/BlackButton";

export default {
  name: "ProductModal",
  props: ["isOrdered", "isRealized", "isVisible"],

  components: {
    ValidationObserver,
    BlackButton,
  },
  data: () => ({
    visible: true,
    orderedInfoInput: false,
    realizedInfoInput: false,
  }),
  computed: {},
  watch: {},
  methods: {
    saveProducts() {
      if (this.orderedInfoInput) {
        localStorage.setItem("orderedInfoInput", true);
      }
      if (this.realizedInfoInput) {
        localStorage.setItem("realizedInfoInput", true);
      }
      this.$emit("saveProducts");
      this.closeModal();
    },
    closeModal() {
      this.$refs.obs.reset();
      this.$emit("closeModal");
      (this.orderedInfoInput = false), (this.realizedInfoInput = false);
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 50%;
  right: 3%;
  z-index: 10000000;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.inputGroup {
  display: flex;
  align-items: center;
}
</style>
