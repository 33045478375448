import axios from "../../axios";
import router from "../../router";
import errorHandler from "../../errorHandler";
import { showSuccessAlert, showErrorAlert } from "../../utils/alert";
const zero = 0;
const cc = zero.toFixed(3);
const user = {
  namespaced: true,

  state: () => ({
    user: "",
    users: [],
    subscription: "",
    simulations: [
      {
        identifier: 0,
        name: "Preffered Customer",
        treshhold: 2,
        simulation_items: [
          { name: "MyShopping", percent: 0, cc, identifier: 0 },
          { name: "Detal", percent: 5, cc, identifier: 1 },
        ],
      },
      {
        identifier: 1,
        name: "Assistant Supervisor",
        treshhold: 25,
        simulation_items: [
          { name: "MyShopping", percent: 0, cc, identifier: 0 },
          { name: "Detal", percent: 30, cc, identifier: 1 },
          { name: "Preffered Customer", percent: 25, cc, identifier: 2 },
        ],
      },
      {
        identifier: 2,
        name: "Supervisor",
        treshhold: 75,
        simulation_items: [
          { name: "MyShopping", percent: 0, cc, identifier: 0 },
          { name: "Detal", percent: 38, cc, identifier: 1 },
          { name: "Preffered Customer", percent: 33, cc, identifier: 2 },
          { name: "Assistant Supervisor", percent: 3, cc, identifier: 3 },
        ],
      },
      {
        identifier: 3,
        name: "Assistant Manager",
        treshhold: 120,
        simulation_items: [
          { name: "MyShopping", percent: 0, cc, identifier: 0 },
          { name: "Detal", percent: 43, cc, identifier: 1 },
          { name: "Preffered Customer", percent: 38, cc, identifier: 2 },
          { name: "Assistant Supervisor", percent: 8, cc, identifier: 3 },
          { name: "Supervisor", percent: 5, cc, identifier: 4 },
        ],
      },
      {
        identifier: 4,
        name: "Manager",
        treshhold: 0,
        simulation_items: [
          { name: "MyShopping", percent: 0, cc, identifier: -1 },
          { name: "Detal", percent: 48, cc, identifier: 0 },
          { name: "Preffered Customer", percent: 43, cc, identifier: 1 },
          { name: "Assistant Supervisor", percent: 13, cc, identifier: 2 },
          { name: "Supervisor", percent: 10, cc, identifier: 3 },
          { name: "Assistant Manager", percent: 5, cc, identifier: 4 },
        ],
      },
    ],
    contacts: [],
    invoiceData: [],
    contactsSearchQuery: "",
    contactsSortBy: {
      sortBy0: "contactDate",
      sortBy1: "DESC",
      category: null,
      categoryLevel2: null,
    },
    contactsPagination: {
      limit: 5,
    },
  }),

  mutations: {
    SET_CONTACTS_SORT_BY(state, sortBy) {
      state.contactsSortBy = sortBy;
    },
    SET_CONTACTS_SEARCH_QUERY(state, query) {
      state.contactsSearchQuery = query;
    },
    SET_CONTACTS_PAGINATION(state, pagination) {
      state.contactsPagination = pagination;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_SIMULATION(state, simulations) {
      simulations.forEach((item) => {
        const myShoppingExist = item.simulation_items.some(
          (item) => item.name === "MyShopping"
        );
        if (!myShoppingExist) {
          item.simulation_items = [
            { name: "MyShopping", percent: 0, cc, identifier: -1 },
            ...item.simulation_items,
          ];
        }
      });
      state.simulations = simulations;
    },
    SET_CONTACTS(state, contacts) {
      state.contacts = contacts;
    },
    SET_SUBSCRIPTION(state, subscription) {
      state.subscription = subscription;
    },
    SET_LATEST_INVOICE_DATA(state, data) {
      state.invoiceData = data;
    },
  },

  actions: {
    resetContactsSearchQueryAndSortByAndPagination({ commit }) {
      commit("SET_CONTACTS_SEARCH_QUERY", "");
      commit("SET_CONTACTS_SORT_BY", {
        sortBy0: "contactDate",
        sortBy1: "DESC",
        category: null,
        categoryLevel2: null,
      });
      commit("SET_CONTACTS_PAGINATION", {
        limit: 5,
      });
    },

    setContactsPagination({ commit }, pagination) {
      commit("SET_CONTACTS_PAGINATION", pagination);
    },
    setContactsSortBy({ commit }, sortBy) {
      commit("SET_CONTACTS_SORT_BY", sortBy);
    },
    setContactsSearchQuery({ commit }, query) {
      commit("SET_CONTACTS_SEARCH_QUERY", query);
    },
    async fetchUser({ commit }) {
      try {
        const response = await axios.get("user");
        commit("SET_USER", response.data);
      } catch (err) {
        console.log(err.response);
      }
    },
    async updateUser({ dispatch }, object) {
      try {
        await axios.put(`user`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano dane!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchUser");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchLatestInvoiceData({ commit }) {
      try {
        const response = await axios.get("user/latest-invoice-data");
        commit("SET_LATEST_INVOICE_DATA", response.data);
      } catch (err) {
        console.log(err.response);
      }
    },
    async updateExpiryDate({ dispatch }, object) {
      let data = {
        expiredDate: object.expiredDate,
      };
      try {
        await axios.put(`admin/user/${object.id}/sub-expired-date`, data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano datę końcową subskrypcji!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchUsers");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async updateUserDetails({ dispatch }, object) {
      try {
        await axios.put(`admin/user/${object.id}/details`, object.data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano użytkownika!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchUsers");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async changeRole({ dispatch }, data) {
      try {
        await axios.put(`user/change-role`, data);
        showSuccessAlert(dispatch, "Pomyślnie zaktualizowano pozycję!");
        dispatch("auth/logout", "", { root: true });
      } catch (error) {
        const { message, code } = error.response.data;
        if (message && code) {
          showErrorAlert(dispatch, message, code);
        }
      }
    },
    async changePassword({ dispatch }, data) {
      try {
        await axios.put(`user/change-password`, data);
        dispatch("user/fetchUser");
        showSuccessAlert(dispatch, "Pomyślnie zaktualizowano hasło!");
      } catch (error) {
        const { message, code } = error.response.data;
        if (message && code) {
          showErrorAlert(dispatch, message, code);
        }
      }
    },
    async sendBusinessCard({ dispatch }, object) {
      try {
        await axios.post(`user/business-card`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie wysłano wizytówkę!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async saveUserSimulation({ dispatch }, data) {
      try {
        await axios.put(`simulation`, data);
        showSuccessAlert(dispatch, "Pomyślnie zaktualizowano symulację!");
      } catch (error) {
        const message =
          "Błąd podczas zapisu symulacji. Skontaktuj się z administratorem.";
        showErrorAlert(dispatch, message, null);
      }
    },
    async getUserSimulation({ commit }) {
      const response = await axios.get(`simulation`);
      const { data } = response.data;
      if (data && data.length !== 0) {
        commit("SET_SIMULATION", data);
      }
    },
    async checkContactsForToday({ commit }) {
      try {
        const response = await axios.get("user/check-contacts");
        commit("SET_CONTACTS", response.data.data);
      } catch (err) {
        console.log(err.response);
      }
    },
    async checkSubscription({ commit }) {
      try {
        const response = await axios.get("user/check-subscription");
        commit("SET_SUBSCRIPTION", response.data);
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchUsers({ commit }) {
      try {
        const response = await axios.get("admin/user/not-deleted");
        commit("SET_USERS", response.data.data);
      } catch (err) {
        console.log(err.response);
      }
    },
    async deleteAccount({ commit, dispatch }) {
      try {
        await axios.delete("user");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        delete axios.defaults.headers.common["Authorization"];
        commit("auth/SET_TOKEN", null, { root: true });
        router.push("/welcome");
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Konto zostało usunięte!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (err) {
        console.log(err.response);
      }
    },
    async adminDeleteAccount({ dispatch }, userId) {
      try {
        await axios.delete(`admin/user/${userId}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Użytkownik został usunięty!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (err) {
        console.log(err.response);
      }
    },
  },

  getters: {
    getUser: (state) => state.user,
    getUsers: (state) => state.users,
    getSimulation: (state) => state.simulations,
    getContacts: (state) => state.contacts,
    getSubscription: (state) => state.subscription,
    getLatestInvoiceData: (state) => state.invoiceData,
    getContactsSearchQuery: (state) => state.contactsSearchQuery,
    getContactsSortBy: (state) => state.contactsSortBy,
    getContactsPagination: (state) => state.contactsPagination,
  },
};
export default user;
