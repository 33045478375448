<template>
  <div
    class="image-container"
    :style="sizeInPx ? `width:${sizeInPx}px;height:${sizeInPx}px` : ''"
  >
    <img
      :style="sizeInPx ? `width:${sizeInPx}px;height:${sizeInPx}px` : ''"
      @click="openImageInNewCard"
      :src="getPhoto()"
      alt=""
    />

    <div class="phone-icon" v-if="!sizeInPx">
      <a v-if="phone" :href="`tel:${phone}`">
        <span class="fa-stack" style="vertical-align: top">
          <font-awesome-icon
            color="#FCC502"
            icon="circle"
            class="fa-stack-2x"
          />
          <font-awesome-icon
            icon="phone-alt"
            color="black"
            class="fa-stack-1x"
          />
        </span>
      </a>

      <span
        v-else
        @click.stop="showPhoneMessage()"
        class="fa-stack"
        style="vertical-align: top; cursor: pointer"
      >
        <font-awesome-icon color="#f5f0e1" icon="circle" class="fa-stack-2x" />
        <font-awesome-icon icon="phone-alt" color="black" class="fa-stack-1x" />
      </span>
    </div>
  </div>
</template>

<script>
import { URL } from "../config";
export default {
  name: "CustomerImagePreview",
  props: ["imagePath", "sizeInPx", "phone"],

  methods: {
    getImageUrl() {
      return URL;
    },
    getPhoto() {
      let path = "/uploads/";
      if (
        !this.imagePath ||
        (this.imagePath && this.imagePath === "no-profile-photo.png")
      ) {
        path = "/assets/";
      }
      return this.getImageUrl() + path + this.imagePath;
    },
    openImageInNewCard() {
      console.log("AA");
      window.open(this.getPhoto(), "_blank");
    },

    showPhoneMessage() {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "red",
          icon: "exclamation-triangle",
          message: "Klient nie ma przypisanego numeru!",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    },
  },
};
</script>

<style scoped>
.image-container {
  min-width: 45px;
  min-height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  position: relative;
}
.image-container img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  cursor: pointer;
  object-fit: cover;
}
.phone-icon {
  position: absolute;
  bottom: -10px;
  right: -10px;
  z-index: 2;
}
.phone-icon svg:first-child {
  width: 20px;
}
.phone-icon svg:nth-child(2) {
  width: 10px;
}
</style>