<template>
  <v-row justify="center">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="500px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Płatność</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <v-card flat tile elevation="0">
          <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
            <form :action="PAYMENT_URL" method="post" id="form">
              <input
                type="hidden"
                name="p24_session_id"
                :value="transaction.data.p24_session_id"
              />
              <input
                type="hidden"
                name="p24_merchant_id"
                :value="transaction.data.p24_merchant_id"
              />
              <input
                type="hidden"
                name="p24_pos_id"
                :value="transaction.data.p24_pos_id"
              />
              <input
                type="hidden"
                name="p24_amount"
                :value="transaction.data.p24_amount"
              />
              <input
                type="hidden"
                name="p24_currency"
                :value="transaction.data.p24_currency"
              />
              <input
                type="hidden"
                name="p24_description"
                :value="transaction.data.p24_description"
              />
              <input
                type="hidden"
                name="p24_country"
                :value="transaction.data.p24_country"
              />
              <input
                type="hidden"
                name="p24_email"
                :value="transaction.data.p24_email"
              />
              <input
                type="hidden"
                name="p24_url_return"
                :value="transaction.data.p24_url_return"
              />
              <input
                type="hidden"
                name="p24_url_status"
                :value="transaction.data.p24_url_status"
              />
              <input
                type="hidden"
                name="p24_api_version"
                :value="transaction.data.p24_api_version"
              />
              <input
                type="hidden"
                name="p24_sign"
                :value="transaction.data.p24_sign"
              />
              <input type="submit" ref="pay" v-show="false" />

              <v-card-actions class="flex-column pb-5">
                <v-container>
                  <p>Uzupełnij dane, wszystkie pola są obowiązkowe.</p>
                  <v-row>
                    <v-col cols="12" sm="12" class="pb-0">
                      <v-checkbox
                        v-model="paymentBody.isCompany"
                        color="#fbc501"
                        label="Faktura na firmę"
                        @change="
                          paymentBody.isCompany === !paymentBody.isCompany
                        "
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" class="pb-0">
                      <VTextFieldWithValidation
                        v-model="paymentBody.buyer.name"
                        :label="
                          paymentBody.isCompany
                            ? 'Nazwa firmy'
                            : 'Imię i nazwisko'
                        "
                        color="indigo"
                        outlined
                        dense
                        :rules="
                          !paymentBody.isCompany
                            ? 'required|onlyLettersWithSpaces'
                            : 'required'
                        "
                      />
                    </v-col>
                    <v-col cols="12" sm="12" class="pb-0">
                      <VTextFieldWithValidation
                        v-model="paymentBody.buyer.address"
                        label="Adres"
                        color="indigo"
                        outlined
                        dense
                        rules="required|alphanumericWithSpacesDotAndSlash"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" class="pb-0">
                      <VTextFieldWithValidation
                        v-model="paymentBody.buyer.postalCode"
                        label="Kod pocztowy"
                        color="indigo"
                        outlined
                        dense
                        rules="required|postalCode"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" class="pb-0">
                      <VTextFieldWithValidation
                        v-model="paymentBody.buyer.city"
                        label="Miasto"
                        color="indigo"
                        outlined
                        dense
                        rules="required|onlyLettersWithSpaces"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      class="pb-0"
                      v-if="paymentBody.isCompany"
                    >
                      <VTextFieldWithValidation
                        v-model="paymentBody.buyer.nip"
                        label="NIP"
                        color="indigo"
                        outlined
                        dense
                        :rules="
                          paymentBody.isCompany ? 'required|onlyNumbers' : ''
                        "
                      />
                    </v-col>
                    <v-col cols="12" sm="12" class="pb-0">
                      <div class="promotion">
                        <p class="promotion-code-label">kod rabatowy:</p>
                        <input
                          placeholder="wpisz kod rabatowy..."
                          type="text"
                          v-model="promotionalCode"
                          @change="resetFoundCodeResponse"
                        />
                        <p
                          class="promotion-code-label use-code-label"
                          @click="usePromotionalCode"
                        >
                          zastosuj
                        </p>
                      </div>
                      <p class="wrong-code" v-if="checkCodeRespose === false">
                        Podany kod jest niepoprawny
                      </p>
                      <p class="correct-code" v-if="checkCodeRespose">
                        Cena zamówienia została obniżona o
                        {{ checkCodeRespose.discountInPercent }}%
                      </p>
                    </v-col>

                    <v-col cols="12" sm="12" class="pb-0">
                      <p class="price">
                        Wartość zakupu:
                        {{
                          !checkCodeRespose
                            ? price
                            : parseFloat(
                                parseFloat(price) -
                                  parseFloat(price) *
                                    (checkCodeRespose.discountInPercent / 100)
                              ).toFixed(2)
                        }}zł
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn
                  :disabled="invalid || buttonDisabled"
                  @click="pay"
                  type="button"
                  style="display: inline-block"
                  color="#fbc501"
                  class="text--black mt-5"
                >
                  {{ buttonContent }}
                </v-btn>
                <br />
                <small
                  >* po kliknięciu nastąpi przekierowanie do serwisu
                  Przelewy24</small
                >
              </v-card-actions>
            </form>
          </ValidationObserver>
          <v-card-actions class="d-flex justify-center">
            <YellowButton
              width="30%"
              text="Anuluj"
              @click.native="closeModal()"
          /></v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { PAYMENT_URL } from "../../config";
import axios from "../../axios";
import BlackButton from "../buttons/BlackButton";
import YellowButton from "../buttons/YellowButton";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import { ValidationObserver } from "vee-validate";
export default {
  name: "SubscriptionModal",
  props: ["isVisible", "hours", "paymentBody", "price"],

  components: {
    BlackButton,
    YellowButton,
    VTextFieldWithValidation,
    ValidationObserver,
  },
  data: () => ({
    PAYMENT_URL,
    buttonContent: "Dokonaj płatności*",
    buttonDisabled: false,
    promotionalCode: "",
    checkCodeRespose: null,
  }),
  computed: {
    transaction: function () {
      return this.$store.getters["payment/getTransInfo"];
    },
    paymentOptions() {
      return this.$store.getters["payment/getPaymentOptions"];
    },
  },
  methods: {
    resetFoundCodeResponse() {
      if (this.promotionalCode === "") {
        this.checkCodeRespose = null;
      }
    },
    async usePromotionalCode() {
      if (this.promotionalCode) {
        const response = await axios.get(
          `promotionalCode/check?code=${this.promotionalCode}`
        );
        this.checkCodeRespose = response.data;
      }
    },
    pay() {
      this.buttonContent = "Proszę czekać...";
      this.buttonDisabled = true;
      if (this.checkCodeRespose) {
        this.paymentBody.promotionalCode = this.checkCodeRespose.id;
      } else {
        this.paymentBody.promotionalCode = "none";
      }
      this.$store.dispatch("payment/createOrder", this.paymentBody);
      setTimeout(() => {
        this.p24();
        this.buttonContent = "Dokonaj płatności*";
        this.buttonDisabled = false;
      }, 2000);
    },
    p24() {
      this.$refs.pay.click();
    },
    closeModal() {
      this.$emit("closeModal");
      this.clearObs();
    },
    clearObs() {
      this.$refs.obs.reset();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;
  padding: 0.2rem 0.5rem;
  background-color: white;
  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10000000;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.promotion {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.promotion input {
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  margin-left: 1rem;
  width: 120px;
  padding: 0.3rem;
  padding-left: 0.5rem;
}
.promotion-code-label {
  margin-left: 0.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.use-code-label {
  margin-left: 1rem;
  font-weight: 600;
  cursor: pointer;
}
.promotion input:active,
.promotion input:focus {
  outline: 1px solid black !important;
}
.price {
  margin-top: 0.3rem;
  color: #aaa;
  font-weight: 500;
  font-size: 18px;
  margin-left: 0.5rem;
}
.wrong-code {
  margin-left: 0.5rem;
  font-size: 12px;
  color: red;
  margin-top: 10px;
}

.correct-code {
  margin-left: 0.5rem;
  font-size: 12px;
  color: green;
  margin-top: 10px;
}
</style>
