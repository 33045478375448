import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/vee-validate";
import "./registerServiceWorker";
import VuePwaInstallPlugin from "vue-pwa-install";
import VueClipboards from "vue-clipboards";
import VueSocialSharing from "vue-social-sharing";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "./plugins/vee-validate";
import "plyr/dist/plyr.css";

Vue.use(VueSocialSharing);
Vue.use(VueClipboards);
Vue.use(VuePwaInstallPlugin);
Vue.use(VueQuillEditor, {
  placeholder: "Dodaj treść...",
  modules: {
    toolbar: {
      container: [
        [{ size: ["small", true, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["emoji"],
        [{ align: [] }],
      ],
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
