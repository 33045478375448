<template>
  <v-row style="max-width: 1400px; margin: auto" justify="center" dense>
    <Localisation />
    <v-col cols="12" sm="12" md="12" lg="12" xl="12"
      ><v-autocomplete
        dense
        filled
        flat
        v-model="product"
        :search-input.sync="filter"
        :items="filteredProducts"
        item-text="name"
        item-value="id"
        return-object
        label="Wyszukaj produkt"
        :menu-props="{ bottom: true, offsetY: true }"
      >
        <template slot="append">
          <font-awesome-icon icon="search" class="mt-1" />
        </template> </v-autocomplete
    ></v-col>
    <v-col
      cols="6"
      sm="6"
      md="4"
      lg="4"
      xl="4"
      :class="{
        'column-margin':
          index + 1 == categories.length &&
          ($vuetify.breakpoint.name === 'xs' ||
            $vuetify.breakpoint.name === 'sm') &&
          categories.length % 2 != 0,
      }"
      v-for="(category, index) in categories"
      :key="category.id"
    >
      <CategoryCard
        :src="category.imagePath"
        :text="category.name"
        :to="`/categories/${category.id}/`"
      />
    </v-col>
    <v-col class="text-center mt-10 mb-8" cols="9"
      ><YellowButton
        @click.native="catalogModal = true"
        width="100%"
        xlarge
        text="Katalog produktów" />
      <ProductCatalogueModal
        :isVisible="catalogModal"
        @closeModal="catalogModal = false"
    /></v-col>

    <v-col class="text-center mb-10" cols="9"
      ><YellowButton
        @click.native="informantModal = true"
        width="100%"
        xlarge
        text="Informator o produktach" />
      <product-informant-modal
        :isVisible="informantModal"
        @closeModal="informantModal = false"
    /></v-col>
  </v-row>
</template>

<script>
import Localisation from "../components/Localisation";
import YellowButton from "../components/buttons/YellowButton";
import CategoryCard from "../components/CategoryCard";
import ProductCatalogueModal from "../components/modals/ProductCatalogueModal";
import ProductInformantModal from "../components/modals/ProductInformantModal.vue";

export default {
  components: {
    CategoryCard,
    Localisation,
    YellowButton,
    ProductCatalogueModal,
    ProductInformantModal,
  },
  data: () => ({
    filter: "",
    product: {},
    catalogModal: false,
    currentPage: 0,
    pageCount: 0,
    informantModal: false,
  }),
  created() {
    this.$store.dispatch("catalogue/fetchCategories");
  },
  watch: {
    filter() {
      this.fetchFilteredProducts();
    },
    product() {
      this.goToProduct();
    },
  },
  computed: {
    categories() {
      return this.$store.getters["catalogue/getCategories"];
    },
    filteredProducts() {
      return this.$store.getters["catalogue/getFilteredProducts"];
    },
  },
  methods: {
    fetchFilteredProducts() {
      if (this.filter) {
        if (this.filter.length >= 3) {
          this.$store.dispatch("catalogue/fetchFilteredProducts", this.filter);
        }
      }
    },
    goToProduct() {
      this.$store.commit("catalogue/SET_FILTERED_PRODUCTS", []);
      this.$router.push(
        `/categories/${this.product.categoryId}/product/${this.product.id}`
      );
    },
  },
};
</script>

<style scoped>
.column-margin {
  margin-right: 50% !important;
}
</style>
