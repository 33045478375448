<template>
  <div v-if="payments">
    <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager"
      ><v-container>
        <v-row v-for="(item, i) in payments" :key="item.id">
          <v-col cols="12"
            ><h5>Cena {{ i + 1 }}</h5>

            <v-checkbox
              color="#fbc501"
              id="markAllCheckbox"
              label="Czy jest aktywny?"
              v-model="payments[i].isActive"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="4">
            <VTextFieldWithValidation
              v-model="payments[i].price"
              label="Cena"
              color="indigo"
              outlined
              dense
              rules="required"
            />
            <small>{{
              calcValuePerMonth(payments[i].price, payments[i].numberOfMonth)
            }}</small>
          </v-col>
          <v-col cols="12" sm="4">
            <VTextFieldWithValidation
              v-model="payments[i].numberOfMonth"
              label="Liczba miesięcy"
              color="indigo"
              outlined
              dense
              rules="required"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <VTextFieldWithValidation
              v-model="payments[i].discount"
              label="Obniżona cena"
              color="indigo"
              outlined
              dense
            />
            <small>{{
              calcValuePerMonth(payments[i].discount, payments[i].numberOfMonth)
            }}</small>
          </v-col>
        </v-row>
        <v-btn
          :disabled="invalid"
          type="button"
          class="text--white white--text"
          color="grey darken-2"
          @click="onSave()"
        >
          Zapisz
        </v-btn>
      </v-container>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../../inputs/VTextFieldWithValidation";
export default {
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
  },
  async created() {
    await this.$store.dispatch("payment/fetchPaymentOptions");
  },
  computed: {
    payments() {
      return this.$store.getters["payment/getPaymentOptions"];
    },
  },
  methods: {
    calcValuePerMonth(value, month) {
      const result = parseFloat(value / month).toFixed(2);
      return `miesięcznie: ${result} zł`;
    },
    onSave() {
      const subscriptions = this.payments.map((item) => {
        if (item.discount === "" || parseFloat(item.discount) <= 0)
          item.discount = null;
        return item;
      });
      this.$store.dispatch("payment/updatePaymentOptions", {
        subscriptions,
      });
    },
  },
};
</script>

<style></style>
