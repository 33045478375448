<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="400px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Poleć klientowi</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <v-card flat tile elevation="0">
          <v-card-actions class="px-10 pt-10 d-flex flex-column">
            <span
              style="padding: 0px 0px 0px 10px"
              class="label align-self-start"
              >Klient:</span
            >
            <v-autocomplete
              width="70%"
              dense
              filled
              multiple
              cache-items
              clearable
              chips
              hide-details
              deletable-chips
              v-model="selectedCustomers"
              :search-input.sync="filter"
              :items="customers"
              item-text="fullname"
              item-value="id"
              label="Wpisz nazwę klienta..."
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
            >
              <template slot="append">
                <font-awesome-icon icon="search" class="mt-1" />
              </template>
            </v-autocomplete>
          </v-card-actions>
          <v-card-actions class="flex-column py-10 px-10">
            <WhiteButton
              width="100%"
              text="Wyślij przez e-mail"
              border
              class="mt-3 ml-0"
              @click.native="recommendProduct()"
            />
            <WhiteButton
              width="100%"
              text="Udostępnij"
              border
              icon="share"
              :v-show="!webShareApiSupported"
              class="mt-3 ml-0"
              @click.native="share()"
            />
            <small v-if="!webShareApiSupported" class="mt-2 text-center w-80">
              Przycisk udostępnij jest kompatybilny z przeglądarkami: Edge,
              Safari. Kompatybilność z przeglądarkami mobile: Chrome Andriod,
              Firefox Android, Opera Android, Safari iOS, Samsung
              Internet"</small
            >
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import WhiteButton from "../buttons/WhiteButton";
import BlackButton from "../buttons/BlackButton";
export default {
  name: "RecommendCustomerModal",
  props: ["isVisible", "productDescription", "user", "productName"],

  components: {
    WhiteButton,
    BlackButton,
  },
  created() {
    this.$store.dispatch("user/fetchUser");
    this.$store.commit("customers/SET_CUSTOMERS", []);
  },

  data: () => ({
    selectedCustomers: [],
    filter: "",
  }),
  computed: {
    customers() {
      return this.$store.getters["customers/getCustomers"].data;
    },
    webShareApiSupported() {
      return navigator.share;
    },
  },
  watch: {
    filter() {
      this.fetchFilteredCustomers();
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    share() {
      navigator.share({
        title: "Polecenie produktu",
        text: `Dzień dobry, \nprodukt, o którym była ostatnio mowa:\n ${this.productName} \n ${this.productDescription}\n\nZachęcam do kontaktu,\n${this.user.fullname}\n${this.user.email}\n ${this.user.phone}`,
      });
    },
    fetchFilteredCustomers() {
      if (this.filter) {
        if (this.filter.length >= 3) {
          let object = {
            filter: this.filter,
          };
          this.$store.dispatch("customers/fetchCustomers", object);
        }
      }
    },
    async recommendProduct() {
      if (this.selectedCustomers.length >= 1) {
        let body = {
          id: this.$route.params.productId,
          customers: this.selectedCustomers,
        };
        await this.$store.dispatch("catalogue/recommendProduct", body);
        this.closeModal();
        this.selectedCustomers = [];
      } else {
        this.closeModal();
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Wybierz conajmniej jednego klienta!",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10000000;
}
.label {
  font-size: 0.8rem;
  color: black !important;
}
</style>
