<template>
  <div class="tasks-container">
    <v-card-actions class="justify-space-between">
      <YellowButton
        class="my-4"
        text="Dodaj listę"
        icon="plus"
        @click.native="addList()"
        xsmall
      />

      <YellowButton
        class="my-4"
        :text="!forToday ? 'Zadania na dziś' : 'Wszystkie zadania'"
        @click.native="showTodayTasks()"
        xsmall
      />
    </v-card-actions>

    <div v-if="userTasks && userTasks.length > 0">
      <v-simple-table>
        <thead>
          <tr>
            <th style="width: 25%" class="date-column">Data</th>
            <th style="width: 55%" class="tasks-column">Lista zadań</th>
            <th style="width: 15%; text-align: center" class="priority-column">
              Priorytet
            </th>
            <th style="width: 5%" class="settings-column"></th>
          </tr>
        </thead>
      </v-simple-table>

      <div
        v-for="taskObject in userTasks"
        :key="taskObject.date"
        class="task-object"
      >
        <v-simple-table>
          <tbody>
            <tr v-for="(task, index) in taskObject.tasks" :key="task.id">
              <td class="pt-2 date-column" style="width: 25%">
                <span>{{ !index ? formatTaskDate(taskObject.date) : "" }}</span>
                <br />
                <span class="weekday mr-1" v-if="!index">
                  {{ getWeekdayName(taskObject.date) }}
                </span>
                <div class="mt-2" style="position: absolute">
                  <p v-if="!index" class="mb-0" style="cursor: pointer">
                    <font-awesome-icon
                      class="mr-2"
                      color="#FCC502"
                      icon="edit"
                      @click="editTasks(taskObject)"
                      size="2x"
                    />
                  </p>
                  <p v-if="!index" class="mb-0" style="cursor: pointer">
                    <font-awesome-icon
                      class="mr-1 mt-4"
                      color="#FCC502"
                      icon="copy"
                      @click="copyTasks(taskObject)"
                      size="2x"
                    />
                  </p>
                </div>
              </td>
              <td style="width: 55%" class="tasks-column">
                <div class="d-flex">
                  <v-checkbox
                    style="padding: 0; margin-top: 7px"
                    color="#fbc501"
                    v-model="task.isCompleted"
                    @change="handleIsTaskCompleted(task)"
                  >
                  </v-checkbox>
                  <span
                    @click="handleIsTaskCompletedLabel(task)"
                    class="mb-1 checkbox-label"
                    :class="task.isPriority ? 'underline' : ''"
                    >{{ task.title }}</span
                  >
                </div>
              </td>
              <td
                style="width: 5%; text-align: center"
                class="pt-2 priority-column"
              >
                <span>
                  <font-awesome-icon
                    v-if="task.isPriority"
                    color="#fbc501"
                    icon="star"
                  />
                </span>
              </td>
              <td class="pt-2 settings-column" style="width: 15%">
                <v-menu nudge-bottom="20" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <font-awesome-icon
                      icon="ellipsis-v"
                      style="cursor: pointer"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-list dense style="margin: 0; padding: 0">
                    <v-list-item @click="setTaskAsPriority(task)">
                      <v-list-item-title>Priorytet</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="openTaskModal(task, undefined, undefined, true)"
                    >
                      <v-list-item-title>Edytuj zadanie</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteTask(task)">
                      <v-list-item-title>Usuń zadanie</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="copyTask(task)">
                      <v-list-item-title>Kopiuj zadanie</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="moveTaskToAnotherDay(task)">
                      <v-list-item-title>Przenieś zadanie</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <div class="add-task-button">
          <yellow-button
            class="mr-4 my-4"
            text="Dodaj kolejne zadanie"
            icon="plus"
            @click.native="openTaskModal(null, taskObject.date)"
            xsmall
          />
        </div>
      </div>
    </div>
    <div class="text-center" v-if="!userTasks || userTasks.length === 0">
      <hr />
      <p class="py-4">Brak zadań</p>
    </div>

    <TaskModal
      :isEdit="isEditTask"
      :isVisible="taskModal"
      @closeModal="handleCloseTaskModal()"
      @fetch="fetch()"
      :selectedDate="date"
      :multiple="multiple"
      :selectedTitle="title"
      :selectedTitles="titles"
      :isCopying="isCopying"
    />
    <MoveTaskToAnotherDayModal
      :isVisible="moveTaskToAnotherDayModalVisible"
      @closeModal="moveTaskToAnotherDayModalVisible = false"
      @fetch="fetch()"
      :selectedDate="date"
      :taskId="selectedUserTask ? selectedUserTask.id : null"
    />
    <ConfirmDeleteModal
      :isVisible="confirmDeleteModalVisible"
      @closeModal="confirmDeleteModalVisible = false"
      :userTask="selectedUserTask"
    />
  </div>
</template>

<script>
import moment from "moment";
import YellowButton from "../components/buttons/YellowButton.vue";
import ConfirmDeleteModal from "../components/modals/ConfirmDeleteModal.vue";
import TaskModal from "../components/modals/TaskModal.vue";
import shared from "../shared";
import MoveTaskToAnotherDayModal from "../components/modals/MoveTaskToAnotherDayModal.vue";
export default {
  name: "UserTasks",
  components: {
    YellowButton,
    TaskModal,
    ConfirmDeleteModal,
    MoveTaskToAnotherDayModal,
  },
  data() {
    return {
      isEditTask: false,
      taskModal: false,
      date: null,
      selectedUserTask: null,
      confirmDeleteModalVisible: false,
      multiple: false,
      title: null,
      titles: null,
      forToday: false,
      isCopying: false,
      moveTaskToAnotherDayModalVisible: false,
    };
  },
  async created() {
    await this.fetch();
  },
  methods: {
    async setTaskAsPriority(task) {
      let object = {
        id: task.id,
        data: {
          isPriority: true,
        },
        withoutNotification: true,
        withoutFetch: true,
      };
      await this.$store.dispatch("userTask/editUserTask", object);
      this.fetch();
    },
    getWeekdayName(date) {
      if (date) {
        const weekdayNumber = moment(date).get("day");
        return shared.weekdays()[weekdayNumber];
      } else {
        return "";
      }
    },
    showTodayTasks() {
      this.forToday = !this.forToday;
      this.fetch();
    },
    handleCloseTaskModal() {
      this.taskModal = false;
      this.isEditTask = false;
      this.date = null;
      this.selectedUserTask = null;
      this.multiple = false;
      this.title = null;
      this.titles = null;
      this.isCopying = false;
    },
    async copyTask(task) {
      this.title = task.title;
      this.isCopying = true;
      this.openTaskModal(null, task.date);
    },
    moveTaskToAnotherDay(task) {
      this.selectedUserTask = task;
      this.date = task.date;
      this.moveTaskToAnotherDayModalVisible = true;
    },

    async copyTasks(taskObject) {
      this.multiple = true;
      this.titles = taskObject.tasks.map((task) => task.title);
      this.openTaskModal(
        null,
        moment(taskObject.date).add(1, "day").format("YYYY-MM-DD"),
        true,
        false
      );
    },
    async editTasks(taskObject) {
      this.multiple = true;
      this.titles = taskObject.tasks.map((task) => task.title);
      this.openTaskModal(taskObject, taskObject.date, true, true);
    },
    async handleIsTaskCompleted(task) {
      let object = {
        id: task.id,
        data: {
          isCompleted: task.isCompleted,
        },
        withoutNotification: true,
      };
      await this.$store.dispatch("userTask/editUserTask", object);
      this.fetch();
    },
    async handleIsTaskCompletedLabel(task) {
      task.isCompleted = !task.isCompleted;
      await this.handleIsTaskCompleted(task);
    },
    deleteTask(task) {
      this.selectedUserTask = task;
      this.confirmDeleteModalVisible = true;
    },
    formatTaskDate(date) {
      return shared.formattedDatePolish(date);
    },
    async openTaskModal(task, date, multiple, isEditTask) {
      this.multiple = multiple || false;
      this.taskModal = true;
      this.date = date;
      if (task) {
        this.date = task.date;
        await this.$store.dispatch("userTask/setUserTask", task);
      }
      if (isEditTask) {
        this.isEditTask = true;
      } else {
        this.isEditTask = false;
      }
    },
    async fetch() {
      await this.$store.dispatch("userTask/fetchUserTasks", {
        date: this.forToday ? moment().format("YYYY-MM-DD") : null,
      });
    },
    addList() {
      this.isEditTask = false;
      this.taskModal = true;
      this.multiple = true;
    },
  },
  computed: {
    userTasks() {
      return this.$store.getters["userTask/getUserTasks"];
    },
  },
};
</script>

<style scoped lang="scss">
td {
  border: none !important;
  vertical-align: top;
}
.add-task-button {
  text-align: right;
}
.task-object {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  padding-bottom: 1rem;
  min-height: 160px;
}
::v-deep .v-label {
  font-size: 13px;
}
.checkbox-label {
  cursor: pointer;
  margin-top: 9px;
}
.underline {
  text-decoration: underline;
}
.weekday {
  font-size: 11px;
}
.copy-label {
  font-size: 12px;
  margin-left: 5px;
}

@media (max-width: 500px) {
  .date-column {
    padding-right: 0 !important;
    padding-left: 12px !important;
    width: 80px !important;
    min-width: 80px !important;
    max-width: 80px !important;
    font-size: 12px !important;
    //background: green;
  }
  .tasks-column {
    padding-right: 0 !important;
    padding-left: 5px !important;
    width: 170px !important;
    min-width: 170px !important;
    max-width: 170px !important;
    //background: red;
  }
  .priority-column {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
    //background: blue;
  }
  .settings-column {
    //background: yellow;
    width: 20px !important;
    min-width: 20px !important;
    max-width: 20px !important;
  }
}
</style>
