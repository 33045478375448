import axios from "../../axios";
import errorHandler from "../../errorHandler";

const shoppingCart = {
  namespaced: true,

  state: () => ({
    shoppingCart: [],
  }),

  mutations: {
    SET_SHOPPING_CART(state, data) {
      state.shoppingCart = data;
    },
  },

  actions: {
    async fetchShoppingCart({ dispatch, commit }, object) {
      let _limit = ''
      let sortBy = ''
      let filterByStatus = ''
      if (object) {
        let { limit, sorting } = object;
        _limit = limit ? `limit=${limit}&` : "";
        sortBy =
          sorting?.sortBy0 && sorting?.sortBy1
            ? `sortBy[0]=${object.sorting.sortBy0}&sortBy[1]=${object.sorting.sortBy1}&`
            : "";

        filterByStatus =
          sorting && sorting.status && sorting.status !== ""
            ? `status=${sorting.status}&`
            : "";
      }
      const baseUrl = "shopping-cart?";
      let url = baseUrl + _limit + sortBy + filterByStatus;
      url = url.slice(0, -1);
      try {
        const response = await axios.get(url);
        commit("SET_SHOPPING_CART", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async editShoppingCart({ dispatch }, object) {
      let { updated, deleted } = object;
      let data = {};
      if (updated && deleted) {
        data = {
          products: updated,
          deleted: deleted,
        };
      } else if (!updated && deleted) {
        data = {
          deleted: deleted,
        };
      } else {
        data = {
          products: updated,
        };
      }
      try {
        await axios.put("shopping-cart", data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano koszyk!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchShoppingCart");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async deleteFromShoppingCart({ dispatch }, object) {
      try {
        await axios.delete("shopping-cart", object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano koszyk!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchShoppingCart");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async addToShoppingCart({ dispatch }, data) {
      let object = {
        products: data,
      };
      try {
        await axios.post("shopping-cart", object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano produkt do koszyka!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchShoppingCart");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },


    async deleteProductFromShoppingCart({ dispatch }, object) {
      try {
        await axios.delete(
          `shopping-cart/${object.shoppingCartId}/products/${object.productId}`
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto produkt z koszyka!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });

      } catch (error) {
        console.log(error)
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
  },

  getters: {
    getShoppingCart: (state) => state.shoppingCart,
  },
};
export default shoppingCart;
