<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="600px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title v-if="!isEdit">Dodaj produkt</v-toolbar-title>
          <v-toolbar-title v-else>Edytuj produkt</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12"
                  ><v-checkbox
                    color="#fbc501"
                    v-model="visible"
                    label="Widoczny"
                  ></v-checkbox>
                  <v-checkbox
                    v-if="hasBlockedDiscounts"
                    color="#fbc501"
                    v-model="hasBlockedDiscounts"
                    label="Blokada - brak możliwości rabatowania tego produktu"
                    disabled
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    rules="required"
                    v-model="name"
                    label="Nazwa"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <!-- <VTextAreaWithValidation
                    rules="required|max:10000"
                    label="Opis"
                    style="height: 300px"
                    v-model="description"
                    outlined
                    dense
                  /> -->
                  <html-editor
                    :editorId="`productEditor`"
                    @contentchanged="handleHtmlEditorForProduct($event)"
                    :startContent="description"
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    rules="required"
                    v-model="cc"
                    type="number"
                    label="CC"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    rules="required"
                    v-model="priceDetal"
                    type="number"
                    label="Cena detaliczna"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  v-for="(role, index) in roles"
                  :key="role.id"
                  v-show="!isEdit"
                >
                  <VTextFieldWithValidation
                    rules="required"
                    v-model="prices[index]"
                    type="number"
                    :label="'Cena ' + formatRole(role.name)"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  v-for="(product, index) in product.product_prices"
                  :key="product.id"
                  v-show="isEdit"
                >
                  <VTextFieldWithValidation
                    rules="required"
                    v-model="prices[index]"
                    type="number"
                    :label="'Cena ' + formatRole(product.role.name)"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="12">
                  <VSelectWithValidation
                    rules="required"
                    v-model="categoryId"
                    label="Wybierz kategorię"
                    outlined
                    :items="categories"
                    item-value="id"
                    :menu-props="{
                      top: true,
                      offsetY: true,
                    }"
                    dense
                  >
                    <template v-slot:selection="{ item }">{{
                      item.name
                    }}</template>
                    <template v-slot:item="{ item }">{{ item.name }}</template>
                  </VSelectWithValidation>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-file-input
                    ref="fileupload"
                    v-model="photo"
                    dense
                    outlined
                    rounded
                    label="Załącz zdjęcie"
                    :key="inputKey"
                    accept="image/png,image/jpeg"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              v-if="!isEdit"
              color="success"
              text
              :disabled="invalid"
              v-on:click="addProduct()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Dodaj produkt
            </v-btn>
            <v-btn
              v-else
              color="success"
              text
              :disabled="invalid"
              v-on:click="editProduct()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz produkt
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VSelectWithValidation from "../inputs/VSelectWithValidation";
import BlackButton from "../buttons/BlackButton";
import shared from "../../shared";
import HtmlEditor from "../HtmlEditor.vue";

export default {
  name: "ProductModal",
  props: ["isVisible", "isEdit"],

  components: {
    VTextFieldWithValidation,
    VSelectWithValidation,
    ValidationObserver,
    BlackButton,
    HtmlEditor,
  },
  created() {
    this.$store.dispatch("publics/fetchRoles");
    this.$store.dispatch("catalogue/fetchCategories");
  },

  data: () => ({
    name: "",
    prices: [],
    cc: "",
    description: "",
    categoryId: "",
    visible: true,
    priceDetal: "",
    photo: null,
    inputKey: 0,
    hasBlockedDiscounts: false,
  }),
  computed: {
    categories() {
      return this.$store.getters["catalogue/getCategories"];
    },
    product() {
      return this.$store.getters["catalogue/getProduct"];
    },
    roles() {
      return this.$store.getters["publics/getRoles"];
    },
  },
  watch: {
    roles() {
      for (let i = 0; i < this.roles.length; i++) {
        var price = "";
        this.prices.push(price);
      }
    },
    product: function () {
      this.name = this.product.name;
      this.cc = this.product.cc;
      this.priceDetal = this.product.priceDetal;
      this.description = this.product.description;
      this.categoryId = this.product.categoryId;
      this.visible = this.product.visible;
      this.prices = [];
      for (let i = 0; i < this.product.product_prices.length; i++) {
        this.prices.push(this.product.product_prices[i].value);
      }
      this.hasBlockedDiscounts = this.product.hasBlockedDiscounts;
    },
  },
  methods: {
    handleHtmlEditorForProduct(newDescription) {
      this.description = newDescription;
    },
    formatRole(role) {
      return shared.formatRole(role);
    },
    closeModal() {
      this.name = "";
      this.cc = "";
      this.priceDetal = "";
      this.prices = [];
      this.description = "";
      this.categoryId = "";
      this.visible = true;
      this.photo = null;
      this.inputKey++;
      this.$refs.obs.reset();
      this.$emit("closeModal");
    },
    async addProduct() {
      let formData = new FormData();
      if (this.photo) {
        formData.append("file", this.photo);
      }
      formData.append("name", this.name);
      formData.append("cc", this.cc);
      formData.append("description", this.description);
      formData.append("visible", this.visible);
      formData.append("categoryId", this.categoryId);
      formData.append("priceDetal", this.priceDetal);
      formData.append("prices", JSON.stringify(this.prices));
      await this.$store.dispatch("catalogue/addProduct", formData);
      this.closeModal();
    },
    async editProduct() {
      let formData = new FormData();
      if (this.photo) {
        formData.append("file", this.photo);
      }
      formData.append("name", this.name);
      formData.append("cc", this.cc);
      formData.append("description", this.description);
      formData.append("visible", this.visible);
      formData.append("categoryId", this.categoryId);
      formData.append("priceDetal", this.priceDetal);
      formData.append("prices", JSON.stringify(this.prices));
      let object = {
        id: this.product.id,
        formData: formData,
      };
      await this.$store.dispatch("catalogue/editProduct", object);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 1%;
  right: 3%;
  z-index: 10000000;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
</style>
