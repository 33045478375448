var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"font",class:{ negativeMargin: _vm.$vuetify.breakpoint.xsOnly },staticStyle:{"max-width":"1400px","margin":"auto"},attrs:{"justify":"center","dense":""}},[(_vm.token.roleId != 6)?_c('v-col',{class:{ 'px-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"cardHeight",attrs:{"elevation":hover ? 10 : 0,"tile":"","to":"/my-office?tab=1"}},[_c('v-img',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"white--text align-end justify-center",attrs:{"height":_vm.$vuetify.breakpoint.name != 'xs' ? '39.5vh' : '',"src":_vm.getTabImagePathByOrder(1)
                ? _vm.getPhoto(1)
                : '../assets/my-officex2.png',"aspect-ratio":_vm.$vuetify.breakpoint.name === 'xs' ? '2.1' : '',"gradient":"to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"}},[_c('v-card-title',{staticClass:"cardTitle officeCard smallPadding noSelect",class:{ 'font-size': _vm.$vuetify.breakpoint.smAndUp }},[_vm._v(" "+_vm._s(_vm.getTabNameByOrder(1))+" ")])],1)],1)]}}],null,false,1411956209)})],1):_vm._e(),(_vm.token.roleId != 6)?_c('v-col',{class:{ 'pl-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":"6","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 10 : 0,"tile":"","to":"/calculator"}},[_c('v-img',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"white--text align-end",attrs:{"height":_vm.$vuetify.breakpoint.name != 'xs' ? '39.5vh' : '',"src":_vm.getTabImagePathByOrder(2)
                ? _vm.getPhoto(2)
                : '../assets/calculatex2.png',"gradient":"to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%","aspect-ratio":_vm.$vuetify.breakpoint.name === 'xs' ? '1.1' : ''}},[_c('v-card-title',{staticClass:"cardTitle smallPadding noSelect",class:{ 'font-size': _vm.$vuetify.breakpoint.smAndUp }},[_vm._v(" "+_vm._s(_vm.getTabNameByOrder(2))+" ")])],1)],1)]}}],null,false,2423293818)})],1):_vm._e(),(_vm.token.roleId != 6)?_c('v-col',{class:{ 'pr-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":"6","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 10 : 0,"tile":"","to":"/simulation"}},[_c('v-img',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"white--text align-end",attrs:{"height":_vm.$vuetify.breakpoint.name != 'xs' ? '39.5vh' : '',"aspect-ratio":_vm.$vuetify.breakpoint.name === 'xs' ? '1.1' : '',"src":_vm.getTabImagePathByOrder(3)
                ? _vm.getPhoto(3)
                : '../assets/simulationx2.png',"gradient":"to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"}},[_c('v-card-title',{staticClass:"cardTitle smallPadding noSelect",class:{ 'font-size': _vm.$vuetify.breakpoint.smAndUp }},[_vm._v(" "+_vm._s(_vm.getTabNameByOrder(3))+" ")])],1)],1)]}}],null,false,615831875)})],1):_vm._e(),(_vm.token.roleId != 6)?_c('v-col',{class:{ 'pl-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":"6","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 10 : 0,"tile":"","to":"categories"}},[_c('v-img',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"white--text align-end",attrs:{"height":_vm.$vuetify.breakpoint.name != 'xs' ? '39.5vh' : '',"src":_vm.getTabImagePathByOrder(4)
                ? _vm.getPhoto(4)
                : '../assets/cataloguex2.png',"gradient":"to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%","aspect-ratio":_vm.$vuetify.breakpoint.name === 'xs' ? '1.1' : ''}},[_c('v-card-title',{staticClass:"cardTitle smallPadding noSelect",class:{ 'font-size': _vm.$vuetify.breakpoint.smAndUp }},[_vm._v(" "+_vm._s(_vm.getTabNameByOrder(4))+" ")])],1)],1)]}}],null,false,2957322078)})],1):_vm._e(),(_vm.token.roleId != 6)?_c('v-col',{class:{ 'pr-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":"6","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 10 : 0,"tile":"","to":"gadgets"}},[_c('v-img',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"white--text align-end",attrs:{"height":_vm.$vuetify.breakpoint.name != 'xs' ? '39.5vh' : '',"aspect-ratio":_vm.$vuetify.breakpoint.name === 'xs' ? '1.1' : '',"src":_vm.getTabImagePathByOrder(5)
                ? _vm.getPhoto(5)
                : '../assets/gadgetsx2.png',"gradient":"to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"}},[_c('v-card-title',{staticClass:"cardTitle smallPadding noSelect",class:{
                'font-size': _vm.$vuetify.breakpoint.smAndUp,
                smallWidth: _vm.$vuetify.breakpoint.xsOnly,
              }},[_vm._v(" "+_vm._s(_vm.getTabNameByOrder(5))+" ")])],1)],1)]}}],null,false,4051204271)})],1):_vm._e(),(_vm.token.roleId != 6)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 10 : 0,"tile":""},nativeOn:{"click":function($event){_vm.isBusinessCardModalVisible = true}}},[_c('v-img',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"white--text buttonCard",class:{
              'align-center': _vm.$vuetify.breakpoint.name === 'xs',
              'align-end': _vm.$vuetify.breakpoint.name != 'xs',
            },attrs:{"height":_vm.$vuetify.breakpoint.name != 'xs' ? '39.5vh' : '',"aspect-ratio":_vm.$vuetify.breakpoint.name === 'xs' ? '8' : '',"src":require("../assets/sampleImage.jpg"),"gradient":"to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"}},[_c('v-card-title',{staticClass:"cardTitle noSelect",class:{
                'justify-center': _vm.$vuetify.breakpoint.name === 'xs',
                'font-size': _vm.$vuetify.breakpoint.smAndUp,
              }},[_vm._v(" "+_vm._s(_vm.getTabNameByOrder(6))+" ")])],1)],1)]}}],null,false,3184889655)}):_c('div',[_c('YellowButton',{attrs:{"width":"100%","gradient":"","xlarge":"","text":"Wyślij wizytówkę"},nativeOn:{"click":function($event){_vm.isBusinessCardModalVisible = true}}})],1),_c('SendBusinessCardModal',{attrs:{"isVisible":_vm.isBusinessCardModalVisible},on:{"closeModal":function($event){return _vm.closeSendBusinessCardModal()}}}),_c('TodayContactsModal',{attrs:{"isVisible":_vm.contactsModal},on:{"closeModal":function($event){_vm.contactsModal = false}}}),_c('SubscriptionModal',{attrs:{"isVisible":_vm.subscriptionModal,"hours":_vm.subscription.hours,"expirationDate":_vm.subscription.expirationDate},on:{"closeModal":function($event){_vm.subscriptionModal = false}}})],1):_c('v-col',{class:{ 'pr-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"cols":"12","sm":"6","md":"6","lg":"6","xl":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 10 : 0,"tile":"","to":"admin"}},[_c('v-img',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"white--text align-end",attrs:{"height":_vm.$vuetify.breakpoint.name != 'xs' ? '39.5vh' : '',"aspect-ratio":_vm.$vuetify.breakpoint.name === 'xs' ? '1.1' : '',"src":require("../assets/sampleImage.jpg"),"gradient":"to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"}},[_c('v-card-title',{staticClass:"adminCardTitle smallPadding noSelect"},[_vm._v(" PANEL ADMINA ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }