<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="400px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Dodaj kategorię</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />

        <!-- <font-awesome-icon
          @click="closeModal()"
          style="cursor:pointer;"
          color="black"
          icon="times-circle"
          class="fa-2x close"
        /> -->
        <v-card flat tile elevation="0">
          <v-card-actions class="px-10 pt-10">
            <v-Select
              class="category-select"
              width="70%"
              dense
              filled
              hide-selected
              no-data-text="Brak kategorii"
              v-model="selectedCategoriesIds"
              :items="categoriesLevel1"
              item-text="name"
              item-value="id"
              label="Kategorie"
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
              multiple
              small-chips
              deletable-chips
            >
            </v-Select>
          </v-card-actions>

          <v-card-actions class="px-10 pt-0">
            <v-Select
              class="category-select"
              width="70%"
              dense
              filled
              hide-selected
              no-data-text="Brak kategorii"
              v-model="selectedCategoriesIdsLevel2"
              :items="categoriesLevel2"
              item-text="name"
              item-value="id"
              label="Podkategorie"
              :menu-props="{
                bottom: true,
                offsetY: true,
              }"
              multiple
              small-chips
              deletable-chips
            >
            </v-Select>
          </v-card-actions>

          <v-card-actions class="flex-column py-10 px-10"
            ><WhiteButton
              width="100%"
              text="Dodaj"
              border
              :disabled="!selectedCategoriesIds && !selectedCategoriesIdsLevel2"
              class="mt-3 ml-0"
              @click.native="addCategory()"
            />
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import WhiteButton from "../buttons/WhiteButton";
import BlackButton from "../buttons/BlackButton";
export default {
  name: "CustomerCategoriesModal",
  props: ["isVisible"],

  components: {
    WhiteButton,
    BlackButton,
  },
  created() {
    this.$store.commit("customers/SET_CUSTOMERS", []);
  },

  data: () => ({
    selectedCategoriesIds: null,
    selectedCategoriesIdsLevel2: null,
    filter: "",
  }),
  computed: {
    customer() {
      return this.$store.getters["customers/getCustomer"];
    },
    categories() {
      return this.$store.getters["customers/getCustomerCategories"];
    },
    categoriesLevel1() {
      if (this.categories && this.categories.length > 0) {
        return this.categories.filter((c) => !c.level || c.level === 1);
      } else {
        return [];
      }
    },
    categoriesLevel2() {
      if (this.categories && this.categories.length > 0) {
        return this.categories.filter((c) => c.level === 2);
      } else {
        return [];
      }
    },
    customerCategoriesLevel1() {
      if (
        this.customer &&
        this.customer.customer_categories &&
        this.customer.customer_categories.length > 0
      ) {
        return this.customer.customer_categories.filter(
          (c) => !c.level || c.level === 1
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    closeModal() {
      this.selectedCategoriesIds = null;
      this.selectedCategoriesIdsLevel2 = null;
      this.$emit("closeModal");
    },
    async addCategory() {
      let body = {
        customerId: this.$route.params.customerId || this.customer.id,
      };
      if (this.selectedCategoriesIds) {
        body.categoriesIds = this.selectedCategoriesIds;
        await this.$store.dispatch("customers/addCategories", body);
      }
      if (this.selectedCategoriesIdsLevel2) {
        if (
          (!this.customerCategoriesLevel1 ||
            this.customerCategoriesLevel1.length === 0) &&
          !this.selectedCategoriesIds
        ) {
          this.$store.dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message:
                "Wymagane dodanie przynajmniej jednej głównej kategorii!",
            },
            { root: true }
          );
          this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
          return this.customerCategoriesLevel1;
        }
        body.categoriesIds = this.selectedCategoriesIdsLevel2;
        await this.$store.dispatch("customers/addCategories", body);
      }
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10000000;
}
::v-deep .v-chip--select {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
</style>
