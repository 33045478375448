import axios from "../../axios";
import { VERSION } from "../../config";
import errorHandler from "../../errorHandler";
import { showErrorAlert } from "../../utils/alert";

const publics = {
  namespaced: true,

  state: () => ({
    roles: [],
    languages: [],
    backgroundClick: "",
    isLoading: false,
    pdfPreviewUrls: [],
  }),

  mutations: {
    SET_LANGUAGES(state, languages) {
      state.languages = languages;
    },
    SET_BACKGROUND_CLICK(state, click) {
      state.backgroundClick = click;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_LOADING(state, data) {
      state.isLoading = data;
    },
    SET_PDF_PREVIEW_URLS(state, urls) {
      state.pdfPreviewUrls = urls;
    },
  },

  actions: {
    async checkVersion({ commit, dispatch }) {
      try {
        await axios.post(`check-system-version/${VERSION}`);
        commit("SET_LOADING", false);
      } catch (error) {
        // dispatch("auth/logout", "", { root: true });
        commit("SET_LOADING", false);
        showErrorAlert(
          dispatch,
          "Błędna wersja systemu - trwa aktualizacja. Jeśli problem nadal będzie występował - skontaktuj się z nami!"
        );
        setTimeout(function() {
          location.reload();
        }, 3000);
      }
    },
    async fetchRoles({ dispatch, commit }) {
      try {
        const response = await axios.get("roles");
        commit("SET_ROLES", response.data.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchLanguages({ dispatch, commit }) {
      try {
        const response = await axios.get("languages");
        commit("SET_LANGUAGES", response.data.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async sendQuestion({ dispatch }, object) {
      try {
        await axios.post(`email`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie wysłano zapytanie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async setPdfPreviewUrls({ commit }, urls) {
      commit("SET_PDF_PREVIEW_URLS", urls);
      window.localStorage.setItem("pdfPreviewUrls", JSON.stringify(urls));
    },
  },

  getters: {
    getLanguages: (state) => state.languages,
    getRoles: (state) => state.roles,
    getIsLoading: (state) => state.isLoading,
    getClick: (state) => state.backgroundClick,
    getPdfPreviewUrls: (state) => state.pdfPreviewUrls,
  },
};
export default publics;
