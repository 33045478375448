<template>
  <v-row
    style="max-width: 1400px; margin: auto"
    justify="center"
    dense
    class="font"
    :class="{ negativeMargin: $vuetify.breakpoint.xsOnly }"
  >
    <v-col
      v-if="token.roleId != 6"
      cols="12"
      sm="6"
      md="6"
      lg="4"
      xl="4"
      :class="{ 'px-0': $vuetify.breakpoint.xsOnly }"
    >
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            :elevation="hover ? 10 : 0"
            class="cardHeight"
            tile
            to="/my-office?tab=1"
          >
            <v-img
              v-ripple
              :height="$vuetify.breakpoint.name != 'xs' ? '39.5vh' : ''"
              class="white--text align-end justify-center"
              :src="
                getTabImagePathByOrder(1)
                  ? getPhoto(1)
                  : '../assets/my-officex2.png'
              "
              :aspect-ratio="$vuetify.breakpoint.name === 'xs' ? '2.1' : ''"
              gradient="to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"
            >
              <v-card-title
                class="cardTitle officeCard smallPadding noSelect"
                :class="{ 'font-size': $vuetify.breakpoint.smAndUp }"
              >
                {{ getTabNameByOrder(1) }}
              </v-card-title></v-img
            >
          </v-card>
        </template>
      </v-hover>
    </v-col>
    <v-col
      v-if="token.roleId != 6"
      cols="6"
      sm="6"
      md="6"
      lg="4"
      xl="4"
      :class="{ 'pl-0': $vuetify.breakpoint.xsOnly }"
    >
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card :elevation="hover ? 10 : 0" tile to="/calculator">
            <v-img
              v-ripple
              :height="$vuetify.breakpoint.name != 'xs' ? '39.5vh' : ''"
              class="white--text align-end"
              :src="
                getTabImagePathByOrder(2)
                  ? getPhoto(2)
                  : '../assets/calculatex2.png'
              "
              gradient="to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"
              :aspect-ratio="$vuetify.breakpoint.name === 'xs' ? '1.1' : ''"
            >
              <v-card-title
                class="cardTitle smallPadding noSelect"
                :class="{ 'font-size': $vuetify.breakpoint.smAndUp }"
              >
                {{ getTabNameByOrder(2) }}
              </v-card-title></v-img
            >
          </v-card>
        </template>
      </v-hover>
    </v-col>
    <v-col
      v-if="token.roleId != 6"
      cols="6"
      sm="6"
      md="6"
      lg="4"
      xl="4"
      :class="{ 'pr-0': $vuetify.breakpoint.xsOnly }"
    >
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card :elevation="hover ? 10 : 0" tile to="/simulation">
            <v-img
              v-ripple
              :height="$vuetify.breakpoint.name != 'xs' ? '39.5vh' : ''"
              class="white--text align-end"
              :aspect-ratio="$vuetify.breakpoint.name === 'xs' ? '1.1' : ''"
              :src="
                getTabImagePathByOrder(3)
                  ? getPhoto(3)
                  : '../assets/simulationx2.png'
              "
              gradient="to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"
            >
              <v-card-title
                class="cardTitle smallPadding noSelect"
                :class="{ 'font-size': $vuetify.breakpoint.smAndUp }"
              >
                {{ getTabNameByOrder(3) }}
              </v-card-title></v-img
            >
          </v-card>
        </template>
      </v-hover>
    </v-col>
    <v-col
      v-if="token.roleId != 6"
      cols="6"
      sm="6"
      md="6"
      lg="4"
      xl="4"
      :class="{ 'pl-0': $vuetify.breakpoint.xsOnly }"
    >
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card :elevation="hover ? 10 : 0" tile to="categories" class="">
            <v-img
              v-ripple
              :height="$vuetify.breakpoint.name != 'xs' ? '39.5vh' : ''"
              class="white--text align-end"
              :src="
                getTabImagePathByOrder(4)
                  ? getPhoto(4)
                  : '../assets/cataloguex2.png'
              "
              gradient="to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"
              :aspect-ratio="$vuetify.breakpoint.name === 'xs' ? '1.1' : ''"
            >
              <v-card-title
                class="cardTitle smallPadding noSelect"
                :class="{ 'font-size': $vuetify.breakpoint.smAndUp }"
              >
                {{ getTabNameByOrder(4) }}
              </v-card-title></v-img
            >
          </v-card>
        </template>
      </v-hover>
    </v-col>
    <v-col
      v-if="token.roleId != 6"
      cols="6"
      sm="6"
      md="6"
      lg="4"
      xl="4"
      :class="{ 'pr-0': $vuetify.breakpoint.xsOnly }"
    >
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card :elevation="hover ? 10 : 0" tile to="gadgets">
            <v-img
              v-ripple
              :height="$vuetify.breakpoint.name != 'xs' ? '39.5vh' : ''"
              class="white--text align-end"
              :aspect-ratio="$vuetify.breakpoint.name === 'xs' ? '1.1' : ''"
              :src="
                getTabImagePathByOrder(5)
                  ? getPhoto(5)
                  : '../assets/gadgetsx2.png'
              "
              gradient="to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"
            >
              <v-card-title
                class="cardTitle smallPadding noSelect"
                :class="{
                  'font-size': $vuetify.breakpoint.smAndUp,
                  smallWidth: $vuetify.breakpoint.xsOnly,
                }"
              >
                {{ getTabNameByOrder(5) }}
              </v-card-title></v-img
            >
          </v-card>
        </template>
      </v-hover>
    </v-col>
    <v-col v-if="token.roleId != 6" cols="12" sm="6" md="6" lg="4" xl="4">
      <v-hover v-if="!$vuetify.breakpoint.xsOnly">
        <template v-slot:default="{ hover }">
          <v-card
            :elevation="hover ? 10 : 0"
            tile
            @click.native="isBusinessCardModalVisible = true"
          >
            <v-img
              :height="$vuetify.breakpoint.name != 'xs' ? '39.5vh' : ''"
              :aspect-ratio="$vuetify.breakpoint.name === 'xs' ? '8' : ''"
              v-ripple
              class="white--text buttonCard"
              :class="{
                'align-center': $vuetify.breakpoint.name === 'xs',
                'align-end': $vuetify.breakpoint.name != 'xs',
              }"
              src="../assets/sampleImage.jpg"
              gradient="to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"
            >
              <v-card-title
                class="cardTitle noSelect"
                :class="{
                  'justify-center': $vuetify.breakpoint.name === 'xs',
                  'font-size': $vuetify.breakpoint.smAndUp,
                }"
              >
                {{ getTabNameByOrder(6) }}
              </v-card-title></v-img
            >
          </v-card>
        </template>
      </v-hover>
      <div v-else>
        <YellowButton
          width="100%"
          gradient
          xlarge
          text="Wyślij wizytówkę"
          @click.native="isBusinessCardModalVisible = true"
        />
      </div>
      <SendBusinessCardModal
        :isVisible="isBusinessCardModalVisible"
        @closeModal="closeSendBusinessCardModal()"
      />
      <TodayContactsModal
        :isVisible="contactsModal"
        @closeModal="contactsModal = false"
      />
      <SubscriptionModal
        :isVisible="subscriptionModal"
        @closeModal="subscriptionModal = false"
        :hours="subscription.hours"
        :expirationDate="subscription.expirationDate"
      />
    </v-col>
    <v-col
      v-else
      cols="12"
      sm="6"
      md="6"
      lg="6"
      xl="6"
      :class="{ 'pr-0': $vuetify.breakpoint.xsOnly }"
    >
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card :elevation="hover ? 10 : 0" tile to="admin">
            <v-img
              v-ripple
              :height="$vuetify.breakpoint.name != 'xs' ? '39.5vh' : ''"
              class="white--text align-end"
              :aspect-ratio="$vuetify.breakpoint.name === 'xs' ? '1.1' : ''"
              src="../assets/sampleImage.jpg"
              gradient="to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0) 120%"
            >
              <v-card-title class="adminCardTitle smallPadding noSelect">
                PANEL ADMINA
              </v-card-title></v-img
            >
          </v-card>
        </template>
      </v-hover></v-col
    >
  </v-row>
</template>

<script>
import SendBusinessCardModal from "../components/modals/SendBusinessCardModal";
import TodayContactsModal from "../components/modals/TodayContactsModal";
import SubscriptionModal from "../components/modals/SubscriptionModal";
import YellowButton from "../components/buttons/YellowButton";
import moment from "moment";
import { URL } from "../config";
export default {
  name: "HomePage",
  components: {
    SendBusinessCardModal,
    TodayContactsModal,
    SubscriptionModal,
    YellowButton,
  },

  data: () => ({
    isBusinessCardModalVisible: false,
    contactsModal: false,
    subscriptionModal: false,
  }),

  methods: {
    getTabNameByOrder(orderNumber) {
      return this.tabs[orderNumber - 1]?.name || "";
    },
    getPhoto(order) {
      const imagePath = this.getTabImagePathByOrder(order);
      if (imagePath) {
        let path = "/uploads/";
        return this.getImageUrl() + path + imagePath;
      } else {
        return "";
      }
    },
    getImageUrl() {
      return URL;
    },

    getTabImagePathByOrder(order) {
      if (this.tabs && Array.isArray(this.tabs)) {
        const tab = this.tabs.find((tab) => tab.order === order);
        if (tab) {
          return tab.imagePath;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    async getContacts() {
      var contactsCounter;
      contactsCounter = parseInt(localStorage.getItem("contactsCounter"));
      await this.$store.dispatch("user/checkContactsForToday");
      if (this.contacts.length > 0 && contactsCounter === 0) {
        this.contactsModal = true;
      }
      if (contactsCounter < 10) {
        localStorage.setItem("contactsCounter", (contactsCounter += 1));
      } else {
        localStorage.setItem("contactsCounter", 0);
      }
    },
    async getSubscriptionExpiry() {
      var subscriptionCounter;
      subscriptionCounter = parseInt(
        localStorage.getItem("subscriptionCounter")
      );
      await this.$store.dispatch("user/checkSubscription");
      if (this.subscription.hours < 24 && subscriptionCounter === 0) {
        this.subscriptionModal = true;
      }
      if (subscriptionCounter < 10) {
        localStorage.setItem("subscriptionCounter", (subscriptionCounter += 1));
      } else {
        localStorage.setItem("subscriptionCounter", 0);
      }
    },
    closeSendBusinessCardModal() {
      this.isBusinessCardModalVisible = false;
    },
  },
  computed: {
    contacts() {
      return this.$store.getters["user/getContacts"];
    },
    token() {
      return this.$store.getters["auth/getToken"];
    },
    subscription() {
      return this.$store.getters["user/getSubscription"];
    },
    currentYear() {
      const year = moment().get("year");
      return year;
    },
    tabs() {
      return this.$store.getters["catalogue/getTabs"];
    },
  },

  created() {
    this.$store.dispatch("user/resetContactsSearchQueryAndSortByAndPagination");
    if (!localStorage.getItem("contactsCounter")) {
      localStorage.setItem("contactsCounter", 0);
    }
    if (!localStorage.getItem("subscriptionCounter")) {
      localStorage.setItem("subscriptionCounter", 0);
    }
    this.getSubscriptionExpiry();
    this.getContacts();
    this.$store.dispatch("catalogue/fetchTabs");
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.cardTitle {
  letter-spacing: inherit;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .cardTitle {
    font-weight: bold;
    font-size: 23px;
    word-break: break-word;
  }
  .smallPadding {
    padding-bottom: 5px;
  }
  .officeCard {
    font-size: 48px;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .cardTitle {
    font-weight: bold;
    font-size: 38px;
    word-break: break-word;
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .cardTitle {
    font-weight: bold;
    font-size: 48px;
    word-break: break-word;
  }
}
.adminCardTitle {
  font-weight: bold;
  font-size: 48px;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.bottom-gradient {
  background-image: linear-gradient(
    to top,
    rgba(251, 197, 1.99) 0%,
    transparent 72px
  );
}
.button-card {
  border-radius: 30px !important;
}
.negativeMargin {
  margin-top: -27px !important;
}
.smallWidth {
  width: 87%;
}
</style>
