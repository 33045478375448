<template>
  <v-row justify="center" dense>
    <v-col cols="12" sm="11" md="9" lg="7" xl="6">
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-space-between"
      >
        <v-card-actions class="pl-0">
          <Localisation office officeType="oferty" />
        </v-card-actions>

        <v-card-text
          v-if="calculation.user"
          class="text-right"
          style="width: 40%"
          >FBO: {{ calculation.user.fbo }}
        </v-card-text>
      </v-card>
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-space-between"
      >
        <v-card-actions class="d-flex align-end">
          <span
            style="color: #fbc501"
            class="align-self-end pt-0 display-1 break"
            >{{ calculation.identifier }}</span
          >
          <span class="align-self-end bold pt-3 pb-1 pl-3">{{
            calculation.customer
              ? calculation.customer.fullname
              : "Brak Klienta"
          }}</span>
        </v-card-actions>

        <v-card-actions class="pt-0 pr-3 pb-5 align-self-end"
          ><font-awesome-icon
            icon="edit"
            style="cursor: pointer"
            @click="editCalculation()"
          />
          <font-awesome-icon
            class="ml-5"
            color="red"
            icon="times"
            style="cursor: pointer"
            @click="deleteCalculation(calculation)"
          />
        </v-card-actions>
      </v-card>

      <v-card tile elevation="0" class="d-flex flex-row">
        <v-card-actions style="width: 50%" class="flex-column align-start">
          <v-card-actions style="width: 100%" v-if="calculation">
            <div style="width: 100%">
              <div class="buttonLabel">Status oferty</div>
              <v-menu :close-on-content-click="false" offset-y rounded>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="selectButton"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleSelectButton()"
                    ><div
                      class="insideSelectButton"
                      :class="{
                        blueText:
                          calculation.calculation_status.name === 'ORDERED',
                        yellowText:
                          calculation.calculation_status.name ===
                          'PARTIALLY_REALIZED',
                        greenText:
                          calculation.calculation_status.name ===
                          'REALIZED_PAID',
                        redText:
                          calculation.calculation_status.name ===
                          'NOT_REALIZED',
                      }"
                    >
                      {{ formatRole(calculation.calculation_status.name) }}
                    </div>
                    <div class="openCloseIcon">
                      <i
                        v-if="selectOpen"
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-menu-up theme--light black--text"
                      ></i>
                      <i
                        v-else
                        aria-hidden="true"
                        class="v-icon notranslate mdi mdi-menu-down theme--light black--text"
                      ></i>
                    </div>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in statuses.data"
                    :key="index"
                  >
                    <v-list-item-title
                      :class="{
                        blueText: item.name === 'ORDERED',
                        yellowText: item.name === 'PARTIALLY_REALIZED',
                        greenText: item.name === 'REALIZED_PAID',
                        redText: item.name === 'NOT_REALIZED',
                      }"
                    >
                      <div
                        @click="
                          item.name === 'NOT_REALIZED'
                            ? changeStatusToUnrealized()
                            : ''
                        "
                        class="statusText"
                        :style="
                          item.name === 'NOT_REALIZED'
                            ? 'cursor:pointer'
                            : 'cursor: default '
                        "
                      >
                        {{ formatRole(item.name) }}
                      </div>
                      <div class="statusIcon">
                        <img
                          style="cursor: pointer"
                          @click="showStatusInfoModal(item.name)"
                          class="infoIcon"
                          src="../assets/infoIcon.png"
                          alt="Informacja o statusie"
                        />
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card-actions>

          <v-card-actions style="width: 100%" class="flex-column align-start">
            <div style="visibility: hidden" class="buttonLabel">Statusy</div>
            <YellowButton
              style="padding: 0 !important"
              class="infoImageButton"
              :text="'Jak działają statusy?'"
              small
              width="100%"
              wrap
              @click.native="calculationImageModal = true"
            />
          </v-card-actions>
        </v-card-actions>

        <v-card-actions
          v-if="calculation.customer"
          class="flex-column"
          style="width: 50%"
        >
          <v-card-actions
            class="flex-column align-start"
            v-if="calculation.customer.phone"
            style="width: 100%"
          >
            <div class="buttonLabel">Nr tel</div>
            <YellowButton
              class="contactButton"
              style="padding: 0 !important"
              v-clipboard="calculation.customer.phone"
              @success="handlePhoneCopy"
              :text="calculation.customer.phone"
              small
              :truncate="calculation.customer.phone.length > 9 ? true : false"
              width="100%"
            />
          </v-card-actions>
          <v-card-actions
            v-if="calculation.customer.email"
            class="flex-column align-start"
            style="width: 100%"
          >
            <div class="buttonLabel">Adres e-mail</div>

            <YellowButton
              v-clipboard="calculation.customer.email"
              @success="handleEmailCopy"
              :text="calculation.customer.email"
              :xsmall="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.smAndUp"
              :minWidth="true"
              width="100%"
              :maxWidthAndWrap="true"
            />
          </v-card-actions>
        </v-card-actions>
      </v-card>
      <v-card
        tile
        elevation="0"
        v-if="
          calculation &&
          calculation.customer &&
          calculation.customer.address &&
          calculation.customer.address != ''
        "
      >
        <div class="buttonLabel text-center p-0 m-0">Adres</div>
        <v-card-actions style="width: 100%" class="flex-column align-start">
          <YellowButton
            v-clipboard="calculation.customer.address"
            @success="handleAddressCopy"
            :text="calculation.customer.address"
            :xsmall="$vuetify.breakpoint.xs"
            :small="$vuetify.breakpoint.smAndUp"
            width="100%"
            truncate
          />
        </v-card-actions>
      </v-card>
      <v-card elevation="0" tile>
        <v-card-actions
          class="flex-row justify-space-between"
          style="width: 100%"
        >
          <v-card-text class="pa-0 heading-two">Lista produktów:</v-card-text>
        </v-card-actions>

        <v-simple-table dense>
          <template>
            <thead>
              <tr>
                <th class="text-left">
                  <div
                    v-if="countProductsToEdit() > 0"
                    style="display: flex; align-items: center"
                  >
                    <v-checkbox
                      @click="markAll"
                      color="#fbc501"
                      id="markAllCheckbox"
                      :value="allSelected"
                    ></v-checkbox
                    ><label for="markAllCheckbox" style="cursor: pointer">{{
                      allSelected ? "Odznacz wszystko" : "Zaznacz wszystko"
                    }}</label>
                  </div>
                </th>
                <th class="text-left">Produkt</th>
                <th class="text-left">Ilość</th>
                <th class="text-left">Cena</th>
                <th class="text-left">CC:</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in products"
                :key="item.id"
                :class="{ tableColor: index % 2 != 0 }"
              >
                <td class="selectBox" v-if="item.status != 'REALIZED'">
                  <div class="markProduct">
                    <v-checkbox
                      v-if="item.status != 'REALIZED'"
                      :id="item.id"
                      :value="item"
                      v-model="productsToUpdate"
                      color="#fbc501"
                    ></v-checkbox>

                    <v-select
                      v-if="item.status === 'NOT_ORDERED'"
                      item-text="displayName"
                      :ref="`statusToSetFor${item.id}`"
                      :items="statusesInSelect"
                      item-value="name"
                      :value="item.name"
                      dense
                      placeholder="Zamów"
                    ></v-select>
                  </div>
                </td>
                <td v-else></td>
                <td
                  :class="{
                    greenText: item.status === 'REALIZED',
                    blueText: item.status === 'ORDERED',
                  }"
                >
                  {{ item.product.name }}
                </td>
                <td>
                  <span
                    class="noSelect"
                    v-if="!item.disabled"
                    style="cursor: pointer"
                    @click="decreaseAmount(item)"
                    >-</span
                  >
                  <span class="mx-5 noSelect">{{ item.amount }}</span>
                  <span
                    v-if="!item.disabled"
                    class="noSelect"
                    style="cursor: pointer"
                    @click="increaseAmount(item)"
                    >+</span
                  >

                  <div
                    v-if="item.status === 'ORDERED' && item.disabled"
                    class="splitInput"
                  >
                    <small class="text-center" v-if="item.status === 'ORDERED'"
                      >Zrealizuj częściowo</small
                    >
                    <input
                      v-if="splittedProducts[item.productId]"
                      style="width: 30px"
                      placeholder="Podaj ilość"
                      type="number"
                      :max="splittedProducts[item.productId].amount"
                      min="0"
                      v-model="splittedProducts[item.productId].amount"
                      :ref="`splittedAmount${item.id}`"
                    />
                  </div>
                </td>
                <td>{{ calcProductValue(item) }}zł</td>
                <td>{{ roundNumber(item.product.cc * item.amount, 3) }}</td>
                <td>
                  <v-btn
                    v-if="!item.disabled"
                    icon
                    color="red"
                    @click="deleteCalculationProduct(item, index)"
                  >
                    <font-awesome-icon icon="times" />
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions class="justify-center"
          ><YellowButton
            text="Dodaj produkt"
            width="45%"
            icon="plus"
            small
            @click.native="addProductsModal = true"
          />
          <GreenButton
            v-if="productsToUpdate.length > 0"
            width="45%"
            text="Zapisz"
            small
            @click.native="setProductsToUpdate()"
          />
        </v-card-actions>
        <v-card-text class="py-0"
          >Notatka:<br />
          <span style="font-size: 0.7rem">
            {{ calculation.note }}</span
          ></v-card-text
        >

        <CalculationActionButtons
          :calculation="calculation"
          v-if="calculation"
        />
      </v-card>
      <v-card-actions v-if="calculation.customer" class="justify-space-between">
        <div style="width: 45%">
          <div>Ostatnia edycja karty kontaktu</div>
          <div style="font-size: 0.8rem">
            {{
              calculation.customer.updatedAt
                ? getLastContact(calculation.customer.updatedAt)
                : "Brak"
            }}
          </div>
        </div>
        <div style="width: 45%">
          <YellowButton
            icon="calendar-alt"
            width="100%"
            small
            text="Zaplanuj datę"
            @click.native="contactModal = true"
          />
        </div>
      </v-card-actions>
      <AddProductsModal
        :isVisible="addProductsModal"
        :calculation="true"
        @closeModal="addProductsModal = false"
        @addToCalculation="addProductsToCalculation"
        :percentDiscount="percentDiscount"
      />
      <SetDateTimeModal
        :isVisible="contactModal"
        @closeModal="contactModal = false"
        :customerId="calculation.customerId"
      />
      <ConfirmDeleteModal
        :isVisible="confirmDeleteModal"
        @closeModal="closeConfirmDeleteModal"
        :calculation="calculationForDeletion"
        :calculationProduct="calculationProductForDelete"
        @clearUpdatedProductsList="clearUpdatedProductsList()"
      />
      <CalculationInfoModal
        :isVisible="calculationInfoModalVisible"
        :isOrdered="isOrdered"
        :isRealized="isRealized"
        @closeModal="closeCalculationInfoModal()"
        @saveProducts="saveProducts(productsToSend)"
      />
      <CalculationFlowInfoModal
        :isVisible="calculationImageModal"
        @closeModal="closeCalculationImageModal()"
      />
      <StatusInfoModal
        :isVisible="statusInfoModal"
        :status="selectedStatusInfo"
        @closeModal="closeStatusInfoModal()"
      />
    </v-col>
  </v-row>
</template>

<script>
import YellowButton from "../components/buttons/YellowButton";
import GreenButton from "../components/buttons/GreenButton";
import Localisation from "../components/Localisation";
import AddProductsModal from "../components/modals/AddProductsModal";
import SetDateTimeModal from "../components/modals/SetDateTimeModal";
import ConfirmDeleteModal from "../components/modals/ConfirmDeleteModal";
import CalculationInfoModal from "../components/modals/CalculationInfoModal";
import CalculationFlowInfoModal from "../components/modals/CalculationFlowInfoModal";
import StatusInfoModal from "../components/modals/StatusInfoModal";

import {
  dateCalcDays,
  dateWithTimeWithoutSeconds,
} from "../utils/dateFormatting";
import shared from "../shared";
import CalculationActionButtons from "../components/CalculationActionButtons.vue";
export default {
  name: "CalculationPage",
  components: {
    SetDateTimeModal,
    YellowButton,
    GreenButton,
    Localisation,
    AddProductsModal,
    ConfirmDeleteModal,
    CalculationInfoModal,
    CalculationFlowInfoModal,
    StatusInfoModal,
    CalculationActionButtons,
  },

  data: () => ({
    tab: "",
    selectedTab: "calculations",
    addProductsModal: false,
    contactModal: false,
    selectedStatus: "",
    selectedStatusInfo: "",
    products: [],
    updatedProducts: [],
    deletedProducts: [],
    isEdit: false,
    calculationForDeletion: "",
    confirmDeleteModal: false,
    calculationProductForDelete: "",
    splittedProducts: {},
    productsToUpdate: [],
    allSelected: false,
    calculationInfoModalVisible: false,
    productsToSend: [],
    isOrdered: false,
    isRealized: false,
    calculationImageModal: false,
    statusInfoModal: false,
    selectOpen: false,
    statusesInSelect: [
      {
        id: "9a03ecab-5b68-4c54-9ccd-f362598d2159",
        name: "ORDERED",
        displayName: "Zamów",
      },
      {
        id: "4fb8fbc1-99d8-429c-a8ff-bb796bd3163a",
        name: "REALIZED_PAID",
        displayName: "Zrealizuj",
      },
    ],
  }),
  methods: {
    getLastContact(date) {
      return dateWithTimeWithoutSeconds(date);
    },
    handleAddressCopy() {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "success",
          icon: "check",
          message: "Skopiowano adres do schowka!",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    },
    handleSelectButton() {
      this.selectOpen = !this.selectOpen;
    },
    showStatusInfoModal(status) {
      if (status === "ORDERED") {
        this.selectedStatusInfo =
          "Status aktywny, gdy klient zamówił u Ciebie produkty.Znajdują się one również na Twojej liście zakupów";
      }
      if (status === "NOT_REALIZED") {
        this.selectedStatusInfo =
          "Zaznacz, gdy Twój klient zrezygnował z zamówienia.";
      }
      if (status === "PARTIALLY_REALIZED") {
        this.selectedStatusInfo =
          "Status aktywny, gdy zamówienie jest zrealizowane częściowo.";
      }
      if (status === "REALIZED_PAID") {
        this.selectedStatusInfo =
          "Status aktywny, gdy klient otrzyma zamówienie w całości. Podgląd oferty znajduje się w Historii zamówień danego klienta.";
      }
      if (status === "SENT") {
        this.selectedStatusInfo =
          "Status aktywny, gdy przesłałeś lub przedstawiłeś ofertę swojemu klientowi.";
      }
      this.statusInfoModal = true;
    },
    closeStatusInfoModal() {
      this.statusInfoModal = false;
    },

    closeCalculationImageModal() {
      this.calculationImageModal = false;
    },
    closeCalculationInfoModal() {
      this.calculationInfoModalVisible = false;
      this.productsToSend = [];
    },
    async changeStatusToUnrealized() {
      let statusId;
      this.statuses.data.forEach((status) => {
        if (status.name === "NOT_REALIZED") {
          statusId = status.id;
        }
      });
      var object = {
        calculation: this.calculation.id,
        data: {
          calculationStatusId: statusId,
          splittedProducts: Object.values(this.splittedProducts),
        },
      };
      await this.$store.dispatch("calculation/editStatus", object);
      this.fetchCalculation();
    },
    countProductsToEdit() {
      return this.products.filter((product) => product.status !== "REALIZED")
        .length;
    },
    markAll() {
      if (!this.allSelected) {
        this.allSelected = true;
        this.productsToUpdate = this.products.filter(
          (product) => product.status !== "REALIZED"
        );
      } else {
        this.allSelected = false;
        this.productsToUpdate = [];
      }
    },
    calcProductValue(item) {
      const { discountId, discount } = this.calculation;
      const { product_prices, priceDetal } = item.product;
      const value = discountId
        ? product_prices.find((x) => x.roleId === discountId).value
        : discount && discount > 0
        ? parseFloat(priceDetal - (priceDetal * discount) / 100).toFixed(2)
        : priceDetal;
      return parseFloat(value * item.amount).toFixed(2);
    },
    roundNumber(number, precision) {
      return shared.roundNumber(number, precision);
    },
    editCalculation() {
      this.$router.push(`/calculator?id=${this.$route.params.calculationId}`);
    },
    calcDays(date) {
      return dateCalcDays(date);
    },
    async fetchCalculation() {
      await this.$store.dispatch(
        "calculation/fetchCalculationById",
        this.$route.params.calculationId
      );
      this.productsToUpdate = [];
    },
    async setProductsToUpdate() {
      let products = [];
      let isOrdered = false;
      let isRealized = false;
      this.productsToUpdate.forEach((product) => {
        let nextStatus;
        if (product.status === "NOT_ORDERED") {
          nextStatus =
            this.$refs[`statusToSetFor${product.id}`][0] &&
            this.$refs[`statusToSetFor${product.id}`][0].$el.children[0]
              .innerText === "Zrealizuj"
              ? "REALIZED"
              : "ORDERED";
          if (nextStatus === "ORDERED") {
            isOrdered = true;
          }
          if (nextStatus === "REALIZED") {
            isRealized = true;
          }
        }
        if (product.status === "ORDERED") {
          isRealized = true;
          nextStatus = "REALIZED";
        }
        let amount = product.amount;
        if (this.$refs[`splittedAmount${product.id}`]) {
          amount = this.$refs[`splittedAmount${product.id}`][0].value;

          if (product.amount - amount > 0) {
            products.push({
              productId: product.id,
              status: "ORDERED",
              amount: product.amount - amount,
              splitted: true,
              product: product.productId,
            });
          }
        }

        products.push({
          productId: product.id,
          status: nextStatus,
          amount: amount,
          splitted: false,
          product: product.productId,
        });
      });
      this.isOrdered = isOrdered;
      this.isRealized = isRealized;
      this.productsToSend = products;

      if (isOrdered && !isRealized) {
        if (localStorage.getItem("orderedInfoInput")) {
          this.saveProducts(products);
        } else {
          this.calculationInfoModalVisible = true;
        }
      }
      if (!isOrdered && isRealized) {
        if (localStorage.getItem("realizedInfoInput")) {
          this.saveProducts(products);
        } else {
          this.calculationInfoModalVisible = true;
        }
      }

      if (isOrdered && isRealized) {
        if (
          localStorage.getItem("realizedInfoInput") &&
          localStorage.getItem("orderedInfoInput")
        ) {
          this.saveProducts(products);
        } else {
          this.calculationInfoModalVisible = true;
        }
      }
    },
    async saveProducts(products) {
      const calculationId = this.calculation.id;
      await this.$store.dispatch(
        "calculation/editCalculationProductsStatuses",
        {
          calculationId: calculationId,
          products,
        }
      );
      await this.fetchCalculation();
      this.productsToUpdate = [];
      this.allSelected = false;
      (this.isOrdered = false), (this.isRealized = false);
      this.productsToSend = [];
    },

    addToUpdated(item) {
      let product = {
        id: item.id,
        productId: item.productId,
        amount: item.amount,
        status: item.status,
      };
      var i;
      if (this.updatedProducts.length < 1) {
        this.updatedProducts.push(product);
      } else {
        for (i = 0; i < this.updatedProducts.length; i++) {
          if (this.updatedProducts[i].id === product.id) {
            this.updatedProducts.splice(i, 1);
            var result = this.calculation.calculation_products.filter((obj) => {
              return obj.id === product.id;
            });
            if (
              result[0].amount === product.amount &&
              result[0].status === product.status
            ) {
              return;
            } else {
              this.updatedProducts.push(product);
              return;
            }
          }
        }
        this.updatedProducts.push(product);
      }
    },
    deleteProduct(item, index) {
      this.products.splice(index, 1);
      this.deletedProducts.push(item.id);
      this.deleteFromUpdated(item);
    },
    deleteFromUpdated(item) {
      if (this.updatedProducts < 1) {
        return;
      } else {
        for (let i = 0; i < this.updatedProducts.length; i++) {
          if (this.updatedProducts[i].productId === item.productId) {
            this.updatedProducts.splice(i, 1);
          }
        }
      }
    },
    async increaseAmount(item) {
      await this.$store.dispatch("calculation/editCalculationProductAmount", {
        calculationId: this.calculation.id,
        productId: item.id,
        amount: item.amount + 1,
      });

      this.fetchCalculation();
    },
    async decreaseAmount(item) {
      await this.$store.dispatch("calculation/editCalculationProductAmount", {
        calculationId: this.calculation.id,
        productId: item.id,
        amount: item.amount - 1,
      });

      this.fetchCalculation();
    },
    async changeStatus() {
      var object = {
        calculation: this.calculation.id,
        data: {
          calculationStatusId: this.selectedStatus,
          splittedProducts: Object.values(this.splittedProducts),
        },
      };
      // if (this.splittedProducts && Object.keys(this.splittedProducts).length) {
      //   object.splittedProducts = this.splittedProducts;
      // }
      await this.$store.dispatch("calculation/editStatus", object);
    },
    async addProductsToCalculation(item) {
      var products = [];
      for (let i = 0; i < item.length; i++) {
        var temp = {
          productId: item[i].id,
          amount: item[i].amount,
        };
        products.push(temp);
      }
      var object = {
        products: products,
        calculation: this.calculation.id,
      };
      await this.$store.dispatch("calculation/addProduct", object);
      this.fetchCalculation();
      this.productsToUpdate = [];
    },
    formatRole(data) {
      return shared.formatRole(data);
    },
    handleEmailCopy() {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "success",
          icon: "check",
          message: "Skopiowano adres email do schowka!",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    },
    handlePhoneCopy() {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "success",
          icon: "check",
          message: "Skopiowano numer do schowka!",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    },
    removeCategory(categoryId) {
      let object = {
        customerId: this.$route.params.customerId,
        categoryId: categoryId,
      };
      this.$store.dispatch("customers/removeCategory", object);
    },
    async editCustomer() {
      let object = {
        id: this.$route.params.customerId,
      };
      await this.$store.dispatch("customers/fetchCustomer", object);
      this.isEdit = true;
      this.editModal = true;
    },
    async addCategory() {
      await this.$store.dispatch("customers/fetchCustomerCategories");
      this.categoryModal = true;
    },
    closeModal() {
      this.isEdit = false;
      this.editModal = false;
    },
    async deleteCalculation(item) {
      this.calculationForDeletion = item;
      this.confirmDeleteModal = true;
    },
    clearUpdatedProductsList() {
      this.updatedProducts = [];
      this.calculationProductForDelete = null;
    },
    async deleteCalculationProduct(item) {
      (this.calculationProductForDelete = {
        calculationId: this.calculation.id,
        calculationProductId: item.id,
        calculationProductName: item.product.name,
      }),
        (this.confirmDeleteModal = true);
    },
    closeConfirmDeleteModal(deleted) {
      this.confirmDeleteModal = false;
      if (deleted && this.calculationForDeletion) {
        this.$router.push("/my-office");
      } else {
        return;
      }
      this.calculationForDeletion = "";
    },
  },

  created() {
    this.fetchCalculation();
    this.$store.dispatch("calculation/fetchCalculationStatuses");
  },
  computed: {
    roles() {
      return this.$store.getters["publics/getRoles"];
    },
    calculation() {
      return this.$store.getters["calculation/getCalculation"];
    },
    statuses() {
      return this.$store.getters["calculation/getCalculationStatuses"];
    },
    percentDiscount() {
      const { discountId, discount } = this.calculation;
      if (discount) {
        return discount;
      }
      if (discountId) {
        const foundRole = this.roles.find((role) => role.id === discountId);
        if (foundRole) {
          return foundRole.discountTreshold;
        }
      }
      return 0;
    },
  },
  watch: {
    calculation() {
      this.selectedStatus = this.calculation.calculationStatusId;
      this.products = JSON.parse(
        JSON.stringify(this.calculation.calculation_products)
      );
      for (let i = 0; i < this.products.length; i++) {
        if (
          this.products[i].status === "ORDERED" ||
          this.products[i].status === "REALIZED"
        ) {
          this.products[i].disabled = true;
        } else {
          this.products[i].disabled = false;
        }
        if (this.products[i].status === "ORDERED") {
          this.splittedProducts[this.products[i].productId] = {
            productId: this.products[i].productId,
            amount: this.products[i].amount,
          };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .fontSize {
    font-size: 0.8rem;
  }
  .smallFont {
    font-size: 0.6rem;
  }
  .mediumFont {
    font-size: 0.7rem;
  }
}
.category {
  word-wrap: none;
}
.break {
  word-break: break-word;
}
a {
  color: black !important;
}
.selectBox {
  width: 135px;
}
.v-input--checkbox {
  width: 30px !important;
}
.selectBox .v-input {
  font-size: 12px !important;
  padding: 0 !important;
  width: 80px;
  justify-content: flex-start;
}
.v-input__append-inner > div {
  width: 0 !important;
}

.selectBox select {
  width: 60px;
  height: 30px;
  border: 1px solid rgb(214, 213, 213);
  border-radius: 7px;
  font-size: 12px;
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
}
.selectBox select:hover {
  cursor: pointer;
}
.selectBox select:focus {
  outline: none;
}

.infoIcon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
  font-size: 20px;
  color: rgb(65, 62, 62);
  width: 22px;
  height: 22px;
}
.markProduct {
  display: flex;
  align-items: center;
  justify-content: center;
}
.splitInput {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-expansion-panels {
  width: 250px;
}
.v-expansion-panel {
  font-size: 11px;
  border-radius: 10px;
  width: auto;
  min-width: 150px;
  border: none;
}
.v-expansion-panel-header {
  font-size: 11px;
  width: auto;
  padding: 0;
  padding-left: 10px;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.infoImageButton,
.contactButton {
  font-size: 12px;
}

.selectButton {
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white !important;
  box-shadow: none;
  border: 1px solid black;
  text-align: left !important;
  text-transform: none;
  font-size: 16px !important;
  height: 28px !important;
}
.selectButton button:focus {
  background: white !important;
}
.statusText {
  font-size: 16px !important;
  width: 150px;
}
.selectButton.v-btn:focus {
  background: white !important;
}
.selectButton.v-btn::before {
  background: white !important;
}
.heading-two {
  font-weight: bold;
  font-size: 1rem;
}
.insideSelectButton {
  font-size: 14px;
  letter-spacing: 0;
  font-weight: 400;
}
.buttonLabel {
  font-size: 0.9rem;
  font-weight: bold;
}

@media (max-width: 510px) {
  .contactButton,
  .infoImageButton {
    font-size: 10px;
  }

  .infoIcon {
    width: 19px;
    height: 19px;
  }
  .infoIcon {
    font-size: 16px;
  }
  .statusText {
    font-size: 12px !important;
    width: 150px;
  }
}

@media (max-width: 430px) {
  .infoImageButton {
    white-space: pre-wrap !important;
  }
}

@media (max-width: 400px) {
  .insideSelectButton {
    font-size: 11px;
  }
  .selectButton {
    padding: 7px !important;
  }
  .infoIcon {
    width: 18px;
    height: 18px;
  }
  .labelInStatusSelect {
    font-size: 12px;
    width: 100px;
  }
}
</style>
