<template>
  <div class="box">
    <p class="title">{{ instructionalVideo.title }}</p>
    <div class="video">
      <video-player
        :videoId="instructionalVideo.link"
        :resourceId="instructionalVideo.id"
      />
    </div>
    <p class="description" v-html="instructionalVideo.description"></p>
  </div>
</template>
<script>
import VideoPlayer from "./VideoPlayer.vue";
export default {
  components: { VideoPlayer },
  props: ["instructionalVideo"],
};
</script>
<style >
.box {
  margin-bottom: 4rem;
}
.title {
  font-weight: 600;
  font-size: 1.4rem;
}
.description,
.video {
  margin: 0 auto;
  text-align: justify;
  max-width: 1024px;
  margin-bottom: 1rem;
}
</style>