import { render, staticRenderFns } from "./RecommendCustomerModal.vue?vue&type=template&id=51ca7ef5&scoped=true&"
import script from "./RecommendCustomerModal.vue?vue&type=script&lang=js&"
export * from "./RecommendCustomerModal.vue?vue&type=script&lang=js&"
import style0 from "./RecommendCustomerModal.vue?vue&type=style&index=0&id=51ca7ef5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ca7ef5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAutocomplete,VCard,VCardActions,VDialog,VRow,VToolbar,VToolbarTitle})
