import Vue from "vue";
import Vuex from "vuex";
import auth from "./store/Auth/auth";
import user from "./store/User/user";
import publics from "./store/Publics/publics";
import catalogue from "./store/Catalogue/catalogue";
import gadgets from "./store/Gadgets/gadgets";
import customers from "./store/Customers/customers";
import snackbar from "./store/Snackbar/snackbar";
import shoppingCart from "./store/ShoppingCart/shoppingCart";
import payment from "./store/Payment/payment.js";
import calculation from "./store/Calculation/calculation.js";
import promotionalCodes from "./store/PromotionalCodes/promotionalCodes.js";
import faq from "./store/Faq/faq.js";
import instructionalVideo from "./store/InstructionalVideos/instructionalVideo.js";
import userTask from "./store/UserTask/userTask.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    publics,
    snackbar,
    customers,
    catalogue,
    gadgets,
    shoppingCart,
    payment,
    calculation,
    promotionalCodes,
    faq,
    instructionalVideo,
    userTask,
  },
});
