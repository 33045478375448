<template>
  <div class="recommendation-container">
    <div class="recommendation-card">
      <img src="../assets/michal_recommendation_v2.png" alt="" />
      <div class="icons">
        <a target="_blank" href="https://michalczekaj.pl/">
          <font-awesome-icon size="2x" :icon="['fas', 'globe']"
        /></a>

        <a
          target="_blank"
          href="https://www.facebook.com/profile.php?id=100001218414011"
        >
          <font-awesome-icon size="2x" :icon="['fab', 'facebook']"
        /></a>

        <a
          target="_blank"
          href="https://www.facebook.com/groups/michalczekajmlm/?ref=share&mibextid=K35XfP"
        >
          <font-awesome-icon size="2x" :icon="['fas', 'users']"
        /></a>

        <a
          target="_blank"
          href="https://www.instagram.com/skutecznybiznesmlm_m.czekaj/?igshid=MzMyNGUyNmU2YQ%3D%3D"
        >
          <font-awesome-icon size="2x" :icon="['fab', 'instagram']"
        /></a>

        <a target="_blank" href="https://www.youtube.com/@MichaCzekaj1">
          <font-awesome-icon size="2x" :icon="['fab', 'youtube']"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Recommendations",
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.recommendation-container {
  width: 100%;
  text-align: center;
}
img {
  max-width: 100%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.icons {
  display: flex;
  gap: 5%;
  justify-content: center;
  align-items: center;
}
.icons svg {
  width: 40px;
  color: #fbc501;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 20px;
  }
}
.recommendation-card {
  margin: 2rem;
}
</style>
