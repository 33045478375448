import moment from "moment";

export const dateOnly = (date) => {
  if (!date) return "";
  return moment(date).format("DD.MM.YYYY");
};

export const dateWithTime = (date) => {
  if (!date) return "";
  return moment(date).format("DD.MM.YYYY HH:mm:ss");
};

export const dateWithTimeWithoutSeconds = (date) => {
  if (!date) return "";
  return moment(date).format("DD.MM.YYYY HH:mm");
};

export const dateCalcDays = (date) => {
  if (!date) return "-";
  return moment(date)
    .locale("pl")
    .calendar();
};
