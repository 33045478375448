<template>
  <v-row justify="center" align="baseline">
    <v-col cols="12" sm="8" md="6" lg="5" xl="3">
      <v-container>
        <v-card elevation="0" class="card">
          <v-card-title>
            <v-img class="mx-2 logo" src="../assets/logowhite.svg" contain>
            </v-img>
          </v-card-title>
          <v-card-text>
            <v-card-subtitle
              class="black--text"
              style="padding: 0px 0px 0px 25px"
              >{{ login[selectedCountry.shortName].country }}</v-card-subtitle
            ><v-select
              label=""
              outlined
              color="black"
              v-model="selectedCountry"
              dense
              rounded
              :menu-props="{ bottom: true, offsetY: true }"
              background-color="white"
              :items="languages"
              item-value="id"
              return-object
            >
              <template v-slot:selection="{ item }">
                <img
                  height="15px"
                  width="25px"
                  class="mr-1"
                  :src="getImageURL() + item.imagePath"
                />
                <span>{{ item.name }}</span>
              </template>
              <template v-slot:item="slotProps">
                <img
                  height="15px"
                  width="25px"
                  class="mr-1"
                  :src="getImageURL() + slotProps.item.imagePath"
                />
                <span>{{ slotProps.item.name }}</span>
              </template></v-select
            ></v-card-text
          >
          <v-card-title class="text text-left"
            >{{ login[selectedCountry.shortName].welcome }}
          </v-card-title>
          <v-card-actions class="d-flex justify-space-around"
            ><v-btn
              text
              @click="selected = 'login'"
              class="text-button textColor textSize"
              :ripple="false"
              style="cursor: pointer"
              :class="{ 'selected-button': selected == 'login' }"
            >
              {{ login[selectedCountry.shortName].logIn }} </v-btn
            ><v-btn
              text
              :ripple="false"
              @click="selected = 'register'"
              class="text-button textColor textSize"
              :class="{ 'selected-button': selected == 'register' }"
            >
              {{ login[selectedCountry.shortName].register }}
            </v-btn></v-card-actions
          >
          <v-card elevation="0" class="card" v-if="selected == 'login'">
            <v-container class="text-center">
              <ValidationObserver
                ref="logObs"
                v-slot="{ handleSubmit }"
                mode="lazy"
              >
                <v-form @submit.prevent="handleSubmit(onLogin)">
                  <v-card-subtitle
                    style="padding: 0px 0px 0px 10px"
                    class="text-left label"
                    >{{
                      login[selectedCountry.shortName].email
                    }}</v-card-subtitle
                  >
                  <VTextFieldWithValidation
                    type="email"
                    rules="email|required"
                    v-model="loginEmail"
                    outlined
                    dense
                  />
                  <v-card-subtitle
                    style="padding: 0px 0px 0px 10px"
                    class="text-left label"
                    >{{
                      login[selectedCountry.shortName].password
                    }}</v-card-subtitle
                  >
                  <VTextFieldWithValidation
                    type="password"
                    rules="required"
                    v-model="loginPassword"
                    outlined
                    dense
                  />
                  <v-checkbox
                    style="display: inline-block; float: left"
                    class="mt-0"
                    color="black"
                    v-model="rememberMe"
                    label="Zapamiętaj mnie"
                  ></v-checkbox>
                  <BlackButton
                    width="100%"
                    type="submit"
                    :text="login[selectedCountry.shortName].logIn"
                    class="mt-5"
                  />
                  <v-spacer></v-spacer>
                </v-form>
              </ValidationObserver>
            </v-container>
          </v-card>
        </v-card>
        <v-card elevation="0" v-if="selected == 'register'" class="card">
          <v-container class="text-center">
            <ValidationObserver
              ref="regObs"
              v-slot="{ handleSubmit }"
              mode="eager"
            >
              <v-form @submit.prevent="handleSubmit(onRegister)">
                <v-card-subtitle
                  style="padding: 0px 0px 0px 10px"
                  class="text-left label"
                  >{{
                    login[selectedCountry.shortName].firstName
                  }}</v-card-subtitle
                >
                <VTextFieldWithValidation
                  rules="required"
                  v-model="firstName"
                  outlined
                  dense
                />
                <v-card-subtitle
                  style="padding: 0px 0px 0px 10px"
                  class="text-left label"
                  >{{
                    login[selectedCountry.shortName].lastName
                  }}</v-card-subtitle
                >
                <VTextFieldWithValidation
                  rules="required"
                  v-model="lastName"
                  outlined
                  dense
                />
                <v-card-subtitle
                  style="padding: 0px 0px 0px 10px"
                  class="text-left label"
                  >{{ login[selectedCountry.shortName].email }}</v-card-subtitle
                >
                <VTextFieldWithValidation
                  type="email"
                  rules="email|required"
                  v-model="registerEmail"
                  color="indigo"
                  outlined
                  dense
                />
                <v-card-subtitle
                  style="padding: 0px 0px 0px 10px"
                  class="text-left label"
                  >{{ login[selectedCountry.shortName].phone }}</v-card-subtitle
                >
                <v-text-field
                  v-model="phone"
                  outlined
                  color="black"
                  background-color="white"
                  rounded
                  dense
                />
                <v-card-subtitle
                  style="padding: 0px 0px 0px 10px"
                  class="text-left label"
                  >{{ login[selectedCountry.shortName].fbo }}</v-card-subtitle
                >
                <v-text-field
                  v-model="fbo"
                  outlined
                  color="black"
                  background-color="white"
                  rounded
                  dense
                />
                <v-card-subtitle
                  style="padding: 0px 0px 0px 10px"
                  class="text-left label"
                  >{{
                    login[selectedCountry.shortName].password
                  }}</v-card-subtitle
                >
                <VTextFieldWithValidation
                  type="password"
                  rules="required"
                  vid="password1"
                  v-model="registerPassword"
                  outlined
                  dense
                />
                <v-card-subtitle
                  style="padding: 0px 0px 0px 10px"
                  class="text-left label"
                  >{{
                    login[selectedCountry.shortName].repeatPassword
                  }}</v-card-subtitle
                >
                <VTextFieldWithValidation
                  type="password"
                  rules="required|password:@password1"
                  v-model="passwordConfirmation"
                  outlined
                  dense
                />
                <v-card-subtitle
                  style="padding: 0px 0px 0px 10px"
                  class="text-left label"
                  >{{ login[selectedCountry.shortName].role }}</v-card-subtitle
                >
                <VSelectWithValidation
                  rules="required"
                  v-model="position"
                  outlined
                  :items="roles"
                  item-value="id"
                  :menu-props="{
                    top: true,
                    offsetY: true,
                  }"
                  dense
                >
                  <template v-slot:selection="{ item }">{{
                    item.name
                  }}</template>
                  <template v-slot:item="{ item }">{{
                    formatRole(item.name)
                  }}</template>
                </VSelectWithValidation>

                <v-card-subtitle
                  style="padding: 0px 0px 0px 10px"
                  class="text-left label"
                  >Jak dowiedziałaś/łeś się o aplikacji?</v-card-subtitle
                >
                <VTextFieldWithValidation
                  type="text"
                  rules="required|max:1024"
                  v-model="howFoundApp"
                  outlined
                  dense
                />

                <div class="d-flex flex-column">
                  <VCheckboxWithValidation
                    rules="checkbox"
                    color="black"
                    v-model="rulesCheckbox"
                    regulation
                  />
                  <VCheckboxWithValidation
                    rules="checkbox"
                    color="black"
                    v-model="privacyCheckbox"
                    privacy
                  />

                  <VCheckboxWithValidation
                    color="black"
                    v-model="newsletterCheckbox"
                    label="Dołączam do newslettera, aby otrzymywać informacje o aktualizacjach funkcji w aplikacji ForMyever Office."
                    required="false"
                  />

                  <!-- <v-checkbox
                    class="mt-0 mr-auto"
                    color="black"
                    hide-details
                    v-model="rules"
                  >
                    <template v-slot:label>
                      <div>
                        Akceptuję
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="/regulations"
                              @click.stop
                              v-on="on"
                            >
                              regulamin
                            </a>
                          </template>
                          Wyświetl regulamin
                        </v-tooltip>
                        aplikacji
                      </div>
                    </template>
                  </v-checkbox> -->
                  <!-- <v-checkbox
                    class="mr-auto"
                    color="black"
                    hide-details
                    v-model="optional"
                    label="Zgadzam się na przesyłanie materiałów
reklamowych drogą elektroniczną (opcjonalne)."
                  ></v-checkbox> -->
                </div>
                <BlackButton
                  width="100%"
                  type="submit"
                  :text="login[selectedCountry.shortName].register"
                  class="mt-5"
                />
              </v-form>
            </ValidationObserver>
          </v-container>
        </v-card>
        <v-card class="card d-flex justify-center" elevation="0">
          <v-btn
            color="black"
            text
            to="reset-password"
            :ripple="false"
            class="small-text-button"
            style=""
          >
            {{ login[selectedCountry.shortName].forget }}
          </v-btn>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { IMAGE_URL } from "../config";
import { login } from "../languages/login";
import VTextFieldWithValidation from "../components/inputs/VTextFieldWithValidation";
import VCheckboxWithValidation from "../components/inputs/VCheckboxWithValidation";
import VSelectWithValidation from "../components/inputs/VSelectWithValidation";
import { ValidationObserver } from "vee-validate";
import BlackButton from "../components/buttons/BlackButton";
import shared from "../shared";
export default {
  name: "EntryPage",
  components: {
    VTextFieldWithValidation,
    VSelectWithValidation,
    ValidationObserver,
    BlackButton,
    VCheckboxWithValidation,
  },

  data: () => ({
    defferedPrompt: "",
    selectedCountry: {
      id: "d936b4ef-2046-44b6-86ec-8aa2b7ae3b2f",
      imagePath: "pl-flag.png",
      name: "Polska",
      shortName: "PL",
    },
    selected: "login",
    loginEmail: "",
    loginPassword: "",
    firstName: "",
    lastName: "",
    registerEmail: "",
    phone: "",
    fbo: "",
    registerPassword: "",
    passwordConfirmation: "",
    position: "",
    login,
    loginLoading: false,
    registerLoading: false,
    rememberMe: false,
    rules: false,
    rulesCheckbox: false,
    privacyCheckbox: false,
    newsletterCheckbox: false,
    // optional: false,
  }),

  methods: {
    formatRole(role) {
      return shared.formatRole(role);
    },
    getImageURL() {
      return IMAGE_URL;
    },
    async onLogin() {
      this.loginLoading = true;
      const email = this.loginEmail;
      const password = this.loginPassword;

      await this.$store.dispatch("auth/login", { email, password });
      this.loading = false;
      if (this.$route.name === "entry") this.$refs.logObs.reset();
    },
    onRegister() {
      this.registerLoading = true;
      const email = this.registerEmail;
      const password = this.registerPassword;
      const passwordConfirmation = this.passwordConfirmation;
      const fullname = this.firstName + " " + this.lastName;
      const phone = this.phone;
      const fbo = this.fbo;
      const roleId = this.position;
      const languageId = this.selectedCountry.id;
      const newsletter = this.newsletterCheckbox;
      const howFoundApp = this.howFoundApp;
      this.$store
        .dispatch("auth/register", {
          email,
          password,
          passwordConfirmation,
          fullname,
          phone,
          fbo,
          languageId,
          roleId,
          newsletter,
          howFoundApp,
        })
        .then((response) => {
          if (response) {
            this.registerEmail = "";
            this.registerPassword = "";
            this.passwordConfirmation = "";
            this.phone = "";
            this.fbo = "";
            this.position = "";
            this.firstName = "";
            this.lastName = "";
            this.rulesCheckbox = false;
            this.privacyCheckbox = false;
            this.newsletterCheckbox = false;
            this.howFoundApp = "";
          }

          this.$refs.regObs.reset();
          this.selected = "login";
        });
    },
  },

  created() {
    let lastRoute = localStorage.getItem("lastRoute");
    if (
      this.token &&
      !this.$route.query.register &&
      lastRoute === "landing-page"
    ) {
      this.$router.push("/home");
    }
    localStorage.removeItem("contactsCounter");
    localStorage.removeItem("subscriptionCounter");
    this.$store.dispatch("publics/fetchRoles");
    this.$store.dispatch("publics/fetchLanguages");
    if (this.$route.query.register) {
      this.selected = "register";
    }
  },
  computed: {
    token() {
      return this.$store.getters["auth/getToken"];
    },
    roles() {
      return this.$store.getters["publics/getRoles"];
    },
    languages() {
      return this.$store.getters["publics/getLanguages"];
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  word-break: break-word;
}
.text-button::before {
  background-color: transparent;
}
.text-button {
  font-size: 2em !important;
  font-weight: bold;
  font-family: "BebasNeue", sans-serif !important;
}
.textColor {
  color: rgba($color: #000000, $alpha: 0.3) !important;
}
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .text-button {
    font-size: 1.6em !important;
  }
}
.small-text-button {
  text-decoration: underline;
  font-size: 0.8em;
}
.small-text-button::before {
  background-color: transparent;
}
.selected-button {
  text-decoration: underline;
  color: black !important;
}
.card {
  background-color: #fbc501;
}
.label {
  font-size: 0.8rem;
  color: black !important;
}
.logo {
  height: 150px;
}
::v-deep .theme--light.v-label {
  color: black !important;
}
@media (max-width: 346px) {
  .textSize {
    font-size: 23px !important;
  }
}
</style>
