<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card :elevation="hover ? 10 : 0" tile :to="to">
        <v-img
          v-ripple
          :height="
            $vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'
              ? '39.5vh'
              : ''
          "
          class="black--text align-end "
          :aspect-ratio="
            $vuetify.breakpoint.name === 'xs' ||
            $vuetify.breakpoint.name === 'sm'
              ? '1'
              : ''
          "
          :src="getPhoto()"
          gradient="to bottom, rgba(255,255,255, .01) 70%, rgba(112, 112, 112, 1) 100%"
        >
          <v-card-title
            class="cardTitle smallPadding noSelect white--text text-uppercase"
          >
            {{ text }}
          </v-card-title></v-img
        >
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import { URL } from "../config";
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: null,
    },
    src: {
      type: String,
      default: "",
    },
  },
  methods: {
    getImageUrl() {
      return URL;
    },
    getPhoto() {
      let path = "/uploads/";
      return this.getImageUrl() + path + this.src;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .cardTitle {
    font-weight: bold;
    font-size: 16px;
    word-break: break-word;
  }
  .smallPadding {
    padding-bottom: 5px;
  }
  .officeCard {
    font-size: 30px;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .cardTitle {
    font-weight: bold;
    font-size: 28px;
    word-break: break-word;
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .cardTitle {
    font-weight: bold;
    font-size: 30px;
  }
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
@media (max-width: 374px) {
  .cardTitle {
    font-size: 12px;
    line-height: 120%;
  }
}
</style>
