var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"section-payment grey-background",attrs:{"id":"section-payment"}},[_c('div',{staticClass:"section-payment__title"},[_c('h1',[_vm._v("plany")]),_c('h5',[_vm._v("aplikacji")]),_c('h3',[_vm._v("Subskrypcja wygasa")]),_c('h3',[_vm._v(_vm._s(_vm.formatDate(_vm.user.subscriptionExpirationDate)))])]),_c('v-container',[_c('v-row',{staticClass:"responsive-4"},_vm._l((_vm.payments),function(item,index){return _c('v-col',{key:index,staticClass:"section-payment__item",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('div',{staticClass:"item",class:item.yellow_background ? 'bg-yellow' : 'bg-white'},[(item.discount)?_c('small',{staticClass:"item__promotion"},[_vm._v("PROMOCJA")]):_vm._e(),_c('p',{staticClass:"item__desc",class:item.yellow_background
                  ? 'item__desc--uppercase'
                  : 'item__desc--capitalize'},[_vm._v(" "+_vm._s(item.desc)+" ")]),(item.discount)?_c('div',{staticClass:"item__price strikethrough"},[(item.month)?_c('span',{staticClass:"item__price--small"},[_vm._v(_vm._s(item.price)+" / MIESIĄC")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"item__price"},[_c('span',{staticClass:"item__price--big",class:item.yellow_background
                    ? 'item__price--black'
                    : 'item__price--yellow'},[_vm._v(_vm._s(item.discount ? item.discount : item.price)+" ")]),(item.month)?_c('span',{staticClass:"item__price--small"},[_vm._v("/ MIESIĄC")]):_vm._e()]),(!item.yellow_background)?_c('div',{staticClass:"item__price--summary"},[_vm._v(" Całkowita kwota do zapłaty "+_vm._s(item.discount ? item.totalDiscount : item.totalPrice)+" zł "),(item.discount)?_c('span',[_vm._v("/ zamiast "+_vm._s(item.totalPrice)+" zł")]):_vm._e()]):_vm._e(),_c('button',{staticClass:"item__button button",class:item.yellow_background
                  ? 'button--white-shadow'
                  : 'button--white',on:{"click":function($event){return _vm.onClick(
                  item.id,
                  item.discount ? item.totalDiscount : item.totalPrice
                )}}},[_vm._v(" "+_vm._s(item.button)+" ")])])])}),1)],1),_c('PaymentModal',{attrs:{"isVisible":_vm.modalVisible,"paymentBody":_vm.paymentBody,"price":_vm.selectedPrice},on:{"closeModal":function($event){_vm.modalVisible = false}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }