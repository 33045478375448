<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="400px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title v-if="!calculation"
            ><div style="font-size: 0.9rem">
              Dodaj produkty do listy zakupów
            </div></v-toolbar-title
          >
          <v-toolbar-title v-else
            ><div style="font-size: 0.9rem">
              Dodaj produkty do kalkulacji
            </div></v-toolbar-title
          >
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />

        <!-- <font-awesome-icon
          @click="closeModal()"
          style="cursor:pointer;"
          color="black"
          icon="times-circle"
          class="fa-2x close"
        /> -->
        <v-card flat tile elevation="0">
          <v-card-actions class="px-10 pt-10">
            <v-menu offset-y v-model="openMenu">
              <template v-slot:activator="{ on }">
                <v-text-field
                  hide-details
                  filled
                  color="black"
                  v-model="filter"
                  dense
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-list style="cursor: pointer" dense>
                <v-list-item v-if="products < 1" filled>
                  <v-list-item-title>Brak produktów</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="addToSelected(product)"
                  v-for="product in products"
                  :key="product.id"
                >
                  <v-list-item-title>{{ product.name }}</v-list-item-title>
                  <v-list-item-icon>
                    <font-awesome-icon color="green" icon="plus" class="mr-1" />
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <v-autocomplete
              dense
              filled
              multiple
              hide-selected
              chips
              deletable-chips
              no-filter
              value="dsadsa"
              @keydown="keyHandler($event)"
              v-model="selectedProducts"
              :search-input.sync="filter"
              :items="products"
              item-text="name"
              :clearable="false"
              return-object
              label="Wyszukaj produkt"
              :menu-props="{
                bottom: true,
                offsetY: true,
                closeOnClick: true,
              }"
            >
              <template slot="append">
                <font-awesome-icon icon="search" class="mt-1" />
              </template>
            </v-autocomplete> -->
          </v-card-actions>
          <v-card-actions class="d-flex flex-column">
            <v-simple-table v-if="selectedProducts.length > 0">
              <thead>
                <tr>
                  <th class="text-left">Produkt</th>
                  <th class="text-left">Ilość</th>
                  <th class="text-left">Cena klienta</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(product, index) in selectedProducts"
                  :key="product.id"
                >
                  <td>{{ product.name }}</td>
                  <td>
                    <span
                      class="noSelect"
                      style="cursor: pointer"
                      @click="decreaseAmount(product)"
                      >-</span
                    >
                    <span class="mx-5 noSelect">{{ product.amount }}</span>
                    <span
                      class="noSelect"
                      style="cursor: pointer"
                      @click="product.amount++"
                      >+</span
                    >
                  </td>
                  <td>
                    {{
                      percentDiscount && !product.hasBlockedDiscounts
                        ? roundNumber(
                            product.priceDetal *
                              (1 - percentDiscount / 100) *
                              product.amount,
                            2
                          )
                        : roundNumber(product.priceDetal * product.amount, 2) +
                          "zł"
                    }}
                  </td>

                  <td>
                    <font-awesome-icon
                      @click="removeProduct(index)"
                      style="cursor: pointer"
                      color="red"
                      icon="times"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-actions>
          <v-card-actions class="flex-column py-10 px-10"
            ><YellowButton
              width="100%"
              :disabled="selectedProducts.length < 1"
              text="Dodaj do listy"
              @click.native="addToCart"
              icon="plus"
              class="ml-0"
            />
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import shared from "../../shared";
import YellowButton from "../buttons/YellowButton";
import BlackButton from "../buttons/BlackButton";
export default {
  name: "AddProductModal",
  props: {
    calculation: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    getAllPrices: {
      type: Boolean,
      default: false,
    },
    percentDiscount: {
      type: Number,
      default: null,
    },
  },

  components: {
    YellowButton,
    BlackButton,
  },
  created() {
    this.$store.commit("catalogue/SET_PRODUCTS", []);
  },

  data: () => ({
    selectedProducts: [],
    filter: "",
    openMenu: false,
  }),
  computed: {
    products() {
      return this.$store.getters["catalogue/getProducts"];
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
  },
  watch: {
    filter() {
      if (this.filter.length > 0) {
        this.openMenu = true;
      } else {
        this.openMenu = false;
      }

      this.fetchFilteredProducts();
    },
  },
  methods: {
    roundNumber(number, precision) {
      return shared.roundNumber(number, precision);
    },
    addToCart() {
      if (!this.calculation) {
        let products = [];
        for (let i = 0; i < this.selectedProducts.length; i++) {
          let product = {
            productId: this.selectedProducts[i].id,
            amount: this.selectedProducts[i].amount,
          };
          products.push(product);
        }

        this.$store.dispatch("shoppingCart/addToShoppingCart", products);
        this.$emit("fetchShoppingCart");
        this.closeModal();
      } else {
        this.$emit("addToCalculation", this.selectedProducts);
        this.closeModal();
      }
    },
    decreaseAmount(product) {
      if (product.amount > 1) {
        product.amount--;
      } else {
        return;
      }
    },
    addToSelected(item) {
      if (this.selectedProducts.length > 0) {
        var i;
        for (i = 0; i < this.selectedProducts.length; i++) {
          if (this.selectedProducts[i].id === item.id) {
            this.selectedProducts[i].amount++;
            return;
          }
        }
      }
      let product = {
        name: item.name,
        id: item.id,
        userPrice: this.getAllPrices
          ? item.product_prices.find((x) => x.roleId === this.user.roleId).value
          : item.product_prices[0].value,
        prices: item.product_prices,
        priceDetal: item.priceDetal,
        cc: item.cc,
        amount: 1,
        hasBlockedDiscounts: item.hasBlockedDiscounts,
      };
      this.selectedProducts.push(product);
      this.filter = "";
      this.$store.commit("catalogue/SET_PRODUCTS", null);
    },
    removeProduct(index) {
      this.selectedProducts.splice(index, 1);
    },
    closeModal() {
      this.selectedProducts = [];
      this.filter = "";
      this.$emit("closeModal");
    },
    fetchFilteredProducts() {
      if (this.filter) {
        if (this.filter.length >= 3) {
          let object = {
            filter: this.filter,
          };
          if (this.getAllPrices) {
            object.showAllPrices = true;
          }
          this.$store.dispatch("catalogue/fetchProducts", object);
        }
      }
      if (this.filter === "") {
        this.$store.commit("catalogue/SET_PRODUCTS", null);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10000000;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
</style>
