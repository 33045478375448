<template>
  <v-row justify="center" style="z-index:9999;">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="400px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Filtruj zamówienia</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <v-card flat tile elevation="0">
          <v-card-title>Filtrowanie</v-card-title>
          <v-card-actions class="flex-column">
            <v-select
              style="width:90%"
              dense
              outlined
              clearable
              label="Wybierz status"
              item-value="value"
              item-text="name"
              v-model="selectedStatus"
              :items="paymentStatuses"
              :menu-props="{ bottom: true, offsetY: true }"
            >
              <template v-slot:selection="{ item }">
                <span>{{ getStatusName(item.name) }}</span>
              </template>
              <template v-slot:item="slotProps">
                <span>{{ getStatusName(slotProps.item.name) }}</span>
              </template>
            </v-select>
          </v-card-actions>

          <v-card-actions class="flex-column  py-10 px-10"
            ><WhiteButton
              width="100%"
              text="Filtruj"
              border
              class="mt-3 ml-0"
              @click.native="filterProducts()"
            />
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BlackButton from "../buttons/BlackButton";
import WhiteButton from "../buttons/WhiteButton";
import shared from "../../shared";
export default {
  name: "FilterProductsModal",
  props: ["isVisible"],

  components: {
    BlackButton,
    WhiteButton,
  },
  created() {},

  data: () => ({
    selectedStatus: "ALL",
    paymentStatuses: [
      { name: "Opłacone", value: "PAID" },
      { name: "Nieopłacone", value: "NOT_PAID" },
      { name: "Wszystkie", value: "ALL" },
    ],
  }),

  methods: {
    getStatusName(status) {
      return shared.formatRole(status);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    filterProducts() {
      const filters = {
        paymentStatus: this.selectedStatus,
      };
      this.$emit("fetchOrders", filters);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10000000;
}
</style>
