<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="320px"
      v-model="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card>
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Przenieś zadanie na inny dzień</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-text class="mb-0 pb-0">
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-date-picker
                    class="mt-0 pt-0 mb-0 pb-0"
                    v-model="date"
                    first-day-of-week="1"
                    color="yellow"
                    full-width
                    no-title
                    style="height: 260px"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="mt-0 pt-0">
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="confirm()"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Przenieś zadanie
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import moment from "moment";

export default {
  name: "MoveTaskToAnotherDayModal",
  props: ["taskId", "selectedDate", "isVisible"],

  components: {
    ValidationObserver,
  },
  updated() {
    if (this.selectedDate) {
      this.date = this.selectedDate;
    } else {
      this.date = moment().format("YYYY-MM-DD");
    }
  },
  data: () => ({
    date: "",
  }),
  mounted() {
    if (this.selectedDate) {
      this.date = this.selectedDate;
    } else {
      this.date = moment().format("YYYY-MM-DD");
    }
  },
  computed: {},
  watch: {
    selectedDate() {
      if (this.selectedDate) {
        this.date = this.selectedDate;
      } else {
        this.date = moment().format("YYYY-MM-DD");
      }
    },
  },
  methods: {
    async closeModal() {
      this.$emit("closeModal");
    },
    async confirm() {
      let object = {
        id: this.taskId,
        data: {
          date: this.date,
        },
        withoutNotification: true,
      };
      await this.$store.dispatch("userTask/editUserTask", object);
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "success",
          icon: "check",
          message: "Zadanie zostało przeniesione na wybrany dzień",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });

      this.closeModal();
      this.$emit("fetch");
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
.close {
  position: absolute;
  top: 1%;
  right: 3%;
  z-index: 10000000;
}
</style>
