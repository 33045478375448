<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="400px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title></v-toolbar-title>
        </v-toolbar>
        <!-- <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        /> -->

        <v-card flat tile elevation="0">
          <v-card-actions class="cards">
            <YellowButton
              :text="`Przejdź do karty kontaktu`"
              small
              @click.native="moveToContactCard"
            />
            <YellowButton
              icon="calendar-alt"
              small
              text="Zaplanuj datę"
              @click.native="planContact"
            />
          </v-card-actions>
          <v-card-actions>
            <div class="close-label">
              <v-btn color="warning" text v-on:click="closeModal">
                <font-awesome-icon icon="times" class="mr-1" />
                Wyjdź
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import YellowButton from "../buttons/YellowButton.vue";
// import BlackButton from "../buttons/BlackButton";
export default {
  components: {
    YellowButton,
    // BlackButton
  },
  name: "NewCustomerAddedModal",
  props: ["isVisible"],

  created() {},

  data: () => ({}),
  computed: {},
  methods: {
    moveToContactCard() {
      this.$emit("moveToContactCard");
      this.closeModal();
    },
    planContact() {
      this.$emit("planContact");
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.close {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10000000;
}
.cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10px;
  margin-top: 1rem;
}
.close-label {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
