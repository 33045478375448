<template>
  <v-row justify="center" dense>
    <v-col>
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-center pb-5"
      >
        <v-card-title class="black--text justify-center display-1"
          ><span class="font">Panel Administratora</span></v-card-title
        >
      </v-card>
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-space-between"
      >
      </v-card>

      <v-card tile elevation="0" class="d-flex justify-space-around flex-wrap">
        <v-card-text
          v-for="item in cards"
          :key="item.value"
          style="cursor: pointer; width: 100px"
          class="text-center fontSize px-1 my-1"
          :class="{
            selectedTab: selectedTab === item.value,
            background: selectedTab === item.value,
            unselectedTab: selectedTab != item.value,
          }"
          @click="selectedTab = item.value"
          >{{ item.text }}</v-card-text
        >
      </v-card>
      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'gadgets'"
      >
        <v-card-actions class="nav-tabs">
          <YellowButton
            text="Dodaj INFO"
            icon="plus"
            small
            @click.native="addGadget()"
            class="mr-2"
          />

          <v-text-area-with-validation
            v-model="gadgetsInformation"
            label="Informacje"
            color="indigo"
            outlined
            dense
            class="gadgetInput"
            @input="saveGadgetsInformation"
            rules="max:2048"
          />
          <!-- <YellowButton
            text="Zapisz"
            icon="plus"
            small
            @click.native="saveGadgetsInformation()"
          /> -->
        </v-card-actions>
        <v-card-title>
          <font-awesome-icon class="mr-2" icon="tshirt" />
          Info
          <v-spacer></v-spacer>
          <v-text-field
            v-model="gadgetSearch"
            label="Szukaj"
            single-line
            hide-details
            ><font-awesome-icon
              icon="search"
              slot="prepend-inner"
              class="mr-1 mt-1"
          /></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="gadgets.length === 0"
          loading-text="Brak info"
          :headers="gadgetsHeaders"
          :items="gadgets"
          :search="gadgetSearch"
        >
          <template v-slot:[`item.description`]="{ item }">
            <p v-html="item.description"></p>
          </template>

          <template v-slot:[`item.image`]="{ item }">
            <v-img
              width="120px"
              contain
              height="50px"
              :src="getPhoto(item.imgPath)"
              click
            ></v-img>
          </template>
          <template v-slot:[`item.visible`]="{ item }">
            {{ item.visible ? "Tak" : "Nie" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-on:click="editGadget(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="edit" />
                </v-btn>
              </template>
              <span>Edytuj</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red"
                  @click="selectGadgetForDeletion(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="times" />
                </v-btn>
              </template>
              <span>Usuń</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <!-- <v-card-actions class="justify-center"
          ><YellowButton text="Wszystkie gadżety" icon="plus" small
        /></v-card-actions> -->
        <GadgetModal
          :isVisible="gadgetModalVisible"
          @closeModal="closeGadgetModal()"
          :isEdit="isEditGadget"
        />
      </v-card>
      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'products'"
      >
        <v-card-actions class="justify-space-between">
          <YellowButton
            text="Dodaj produkt"
            icon="plus"
            small
            @click.native="addProduct()"
        /></v-card-actions>
        <v-card-title>
          <font-awesome-icon class="mr-2" icon="box-open" />
          Produkty
          <v-spacer></v-spacer>
          <v-text-field
            v-model="productSearch"
            label="Szukaj"
            single-line
            hide-details
            ><font-awesome-icon
              icon="search"
              slot="prepend-inner"
              class="mr-1 mt-1"
          /></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="products.length === 0"
          loading-text="Brak produktów"
          :headers="productsHeaders"
          :items="products"
          :search="productSearch"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img
              width="120px"
              contain
              height="50px"
              :src="getPhoto(item.imgPath)"
              click
            ></v-img>
          </template>
          <template v-slot:[`item.visible`]="{ item }">
            {{ item.visible ? "Tak" : "Nie" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-on:click="editProduct(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="edit" />
                </v-btn>
              </template>
              <span>Edytuj</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red"
                  @click="selectProductForDeletion(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="times" />
                </v-btn>
              </template>
              <span>Usuń</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <ProductModal
          :isVisible="productModalVisible"
          @closeModal="closeProductModal()"
          :isEdit="isEditProduct"
        />
      </v-card>
      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'codes'"
      >
        <v-card-actions class="justify-space-between">
          <YellowButton
            text="Dodaj kod rabatowy"
            icon="plus"
            small
            @click.native="addPromotionalCode()"
          />
          <YellowButton
            text="Pobierz raport"
            icon="download"
            small
            @click.native="openPromotionalsCodeReportModal()"
          />
        </v-card-actions>
        <v-card-title>
          <font-awesome-icon class="mr-2" icon="users" />
          Kody rabatowe
          <v-spacer></v-spacer>
          <v-text-field
            v-model="promotionalCodeSearch"
            label="Szukaj"
            single-line
            hide-details
            ><font-awesome-icon
              icon="search"
              slot="prepend-inner"
              class="mr-1 mt-1"
          /></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="promotionalCodes.length === 0"
          loading-text="Brak kodów promocyjnych"
          :headers="promotionalCodesHeaders"
          :items="promotionalCodes"
          :search="promotionalCodeSearch"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ formatDate(item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-on:click="editPromotionalCode(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="edit" />
                </v-btn>
              </template>
              <span>Edytuj kod</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red"
                  @click="selectPromotionalCodeToRemove(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="times" />
                </v-btn>
              </template>
              <span>Usuń</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <span v-if="item.isActive">
              <font-awesome-icon icon="check" color="green" />
            </span>
            <span v-else>
              <font-awesome-icon icon="times" color="red" />
            </span>
          </template>
        </v-data-table>

        <PromotionalCodeModal
          :isVisible="promotionalCodeModalVisible"
          @closeModal="closePromotionalCodeModal()"
          :isEdit="isEditProduct"
          :item="selectedCode"
        />
        <PromotionalCodesReportModal
          :isVisible="promotionalCodesReportModalVisible"
          @closeModal="closePromotionalCodesReportModal()"
        />
      </v-card>
      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'prices'"
      >
        <PriceAdminCard />
      </v-card>
      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'users'"
      >
        <v-card-title>
          <font-awesome-icon class="mr-2" icon="users" />
          Użytkownicy
          <v-spacer></v-spacer>
          <v-text-field
            v-model="userSearch"
            label="Szukaj"
            single-line
            hide-details
            ><font-awesome-icon
              icon="search"
              slot="prepend-inner"
              class="mr-1 mt-1"
          /></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="users.length === 0"
          loading-text="Brak użytkowników"
          :headers="usersHeaders"
          :items="users"
          :search="userSearch"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.emailConfirm`]="{ item }">
            <div>
              <font-awesome-icon
                v-if="item.emailConfirm"
                icon="check"
                color="green"
              />
              <font-awesome-icon v-else icon="times" color="red" />
            </div>
          </template>
          <template v-slot:[`item.newsletter`]="{ item }">
            <div>
              <font-awesome-icon
                v-if="item.newsletter"
                icon="check"
                color="green"
              />
              <font-awesome-icon v-else icon="times" color="red" />
            </div>
          </template>
          <template v-slot:[`item.activeAccount`]="{ item }">
            <div>
              <font-awesome-icon
                v-if="item.activeAccount"
                icon="check"
                color="green"
              />
              <font-awesome-icon v-else icon="times" color="red" />
            </div>
          </template>
          <template v-slot:[`item.isDeleted`]="{ item }">
            <div>
              <font-awesome-icon
                v-if="item.isDeleted"
                icon="check"
                color="green"
              />
              <font-awesome-icon v-else icon="times" color="red" />
            </div>
          </template>
          <template v-slot:[`item.role.name`]="{ item }">
            <span>{{ formatRole(item.role.name) }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ formatDate(item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.subscriptionExpirationDate`]="{ item }">
            <span>{{ formatDate(item.subscriptionExpirationDate) }}</span>
          </template>
          <template v-slot:[`item.adminNote`]="{ item }">
            <p v-html="item.adminNote" class="note-format"></p>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-on:click="editAdminNote(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="edit" />
                </v-btn>
              </template>
              <span>Edytuj notatkę</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.lastActivityDate`]="{ item }">
            <p>
              {{
                item.lastActivityDate
                  ? formattedDateTime(item.lastActivityDate)
                  : "-"
              }}
            </p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-on:click="editSubscription(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="edit" />
                </v-btn>
              </template>
              <span>Edytuj Subskrypcję</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-on:click="removeUser(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="times" color="red" />
                </v-btn>
              </template>
              <span>Usuń użytkownika</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>

      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'orders'"
      >
        <OrderListAdminCard />
      </v-card>

      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'categories'"
      >
        <v-card-title>
          <font-awesome-icon class="mr-2" icon="box-open" />
          Kategorie produktów
          <v-spacer></v-spacer>
          <v-text-field
            v-model="categorySearch"
            label="Szukaj"
            single-line
            hide-details
            ><font-awesome-icon
              icon="search"
              slot="prepend-inner"
              class="mr-1 mt-1"
          /></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="categories.length === 0"
          loading-text="Brak produktów"
          :headers="categoriesHeaders"
          :items="categories"
          :search="categorySearch"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img
              width="120px"
              contain
              height="50px"
              :src="getPhoto(item.imagePath)"
              click
            ></v-img>
          </template>
          <template v-slot:[`item.visible`]="{ item }">
            {{ item.visible ? "Tak" : "Nie" }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-on:click="editCategory(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="edit" />
                </v-btn>
              </template>
              <span>Edytuj</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <CategoryModal
          :isVisible="categoryModalVisible"
          @closeModal="closeCategoryModal()"
          :isEdit="isEditCategory"
        />
      </v-card>

      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'tabs'"
      >
        <!-- <v-card-actions class="justify-space-between">
          <YellowButton
            text="Dodaj zakładkę"
            icon="plus"
            small
            @click.native="addTab()"
        /></v-card-actions> -->
        <v-card-title>
          <font-awesome-icon class="mr-2" icon="box-open" />
          Zakładki na stronie głównej
          <v-spacer></v-spacer>
          <v-text-field
            v-model="tabsSearch"
            label="Szukaj"
            single-line
            hide-details
            ><font-awesome-icon
              icon="search"
              slot="prepend-inner"
              class="mr-1 mt-1"
          /></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="tabs.length === 0"
          loading-text="Brak zakładek"
          :headers="tabsHeaders"
          :items="tabs"
          :search="tabsSearch"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img
              width="120px"
              contain
              height="50px"
              :src="getPhoto(item.imagePath)"
              click
            ></v-img>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-on:click="editTab(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="edit" />
                </v-btn>
              </template>
              <span>Edytuj</span>
            </v-tooltip>
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red"
                  @click="selectTabForDeletion(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="times" />
                </v-btn>
              </template>
              <span>Usuń</span>
            </v-tooltip> -->
          </template>
        </v-data-table>
        <TabModal
          :isVisible="tabModalVisible"
          @closeModal="closeTabModal()"
          :isEdit="isEditTab"
        />
      </v-card>

      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'faq'"
      >
        <v-card-actions class="justify-space-between">
          <YellowButton
            text="Dodaj pytanie"
            icon="plus"
            small
            @click.native="addFaq()"
        /></v-card-actions>
        <v-card-title>
          <font-awesome-icon class="mr-2" icon="box-open" />
          FAQ
          <v-spacer></v-spacer>
          <v-text-field
            v-model="faqSearch"
            label="Szukaj"
            single-line
            hide-details
            ><font-awesome-icon
              icon="search"
              slot="prepend-inner"
              class="mr-1 mt-1"
          /></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="!faqs"
          loading-text="Brak pytań"
          :headers="faqHeaders"
          :items="faqs"
          :search="faqSearch"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-on:click="editFaq(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="edit" />
                </v-btn>
              </template>
              <span>Edytuj</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red"
                  @click="selectFaqForDeletion(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="times" />
                </v-btn>
              </template>
              <span>Usuń</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <FaqModal
          :isVisible="faqModalVisible"
          @closeModal="closeFaqModal()"
          :isEdit="isEditFaq"
        />
      </v-card>

      <v-card
        elevation="0"
        tile
        class="selectedTabItem"
        v-if="selectedTab === 'instructionalVideos'"
      >
        <v-card-actions class="nav-tabs">
          <YellowButton
            text="Dodaj film"
            icon="plus"
            small
            @click.native="addInstructionalVideo()"
            class="mr-2"
          />
          <v-text-area-with-validation
            v-model="instructionalVideoInformation"
            label="Informacje"
            color="indigo"
            outlined
            dense
            class="gadgetInput"
            @input="saveInstructionalVideoInformation"
            rules="max:2048"
          />
        </v-card-actions>
        <v-card-title>
          <font-awesome-icon class="mr-2" icon="box-open" />
          Filmy instruktażowe
          <v-spacer></v-spacer>
          <v-text-field
            v-model="instructionalVideoSearch"
            label="Szukaj"
            single-line
            hide-details
            ><font-awesome-icon
              icon="search"
              slot="prepend-inner"
              class="mr-1 mt-1"
          /></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="!instructionalVideos"
          loading-text="Brak pytań"
          :headers="instructionalVideosHeaders"
          :items="instructionalVideos"
          :search="instructionalVideoSearch"
        >
          <template v-slot:[`item.description`]="{ item }">
            <p v-html="item.description"></p>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="indigo"
                  v-on:click="editInstructionalVideo(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="edit" />
                </v-btn>
              </template>
              <span>Edytuj</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red"
                  @click="selectInstructionalVideoForDeletion(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <font-awesome-icon icon="times" />
                </v-btn>
              </template>
              <span>Usuń</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <InstructionalVideoModal
          :isVisible="instructionalVideoModalVisible"
          @closeModal="closeInstructionalVideoModal()"
          :isEdit="isEditInstructionalVideo"
        />
      </v-card>

      <ConfirmDeleteModal
        :isVisible="confirmDeleteModalVisible"
        @closeModal="closeConfirmDeleteModal()"
        :product="selectedProduct"
        :gadget="selectedGadget"
        :tab="selectedHomeTab"
        :promotionalCode="selectedPromotionalCodeToRemove"
        :user="selectedUser"
        :faq="selectedFaq"
        :instructionalVideo="selectedInstructionalVideo"
      />

      <SetDateTimeModal
        :isVisible="dateTimeModal"
        @closeModal="(dateTimeModal = false), (selectedUser = '')"
        :datetime="dateTime"
        :userId="selectedUser"
      />

      <EditTextFieldModal
        v-if="selectedUser"
        :isVisible="editNoteModal"
        @closeModal="editNoteModal = false"
        :initValue="selectedUser.adminNote"
        :title="'Edytuj notatkę'"
        :label="'Notatka'"
        @confirm="editNote"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from "../axios";
import { URL } from "../config";
import YellowButton from "../components/buttons/YellowButton";
import ProductModal from "../components/modals/ProductModal";
import PromotionalCodeModal from "../components/modals/PromotionalCodeModal";
import SetDateTimeModal from "../components/modals/SetDateTimeModal";
import ConfirmDeleteModal from "../components/modals/ConfirmDeleteModal";
import GadgetModal from "../components/modals/GadgetModal";
import shared from "../shared";
import PriceAdminCard from "../components/cards/admin/PriceAdminCard";
import OrderListAdminCard from "../components/cards/admin/OrderListAdminCard.vue";
import PromotionalCodesReportModal from "../components/modals/PromotionalCodesReportModal.vue";
import CategoryModal from "../components/modals/CategoryModal.vue";
import TabModal from "../components/modals/TabModal.vue";
import VTextAreaWithValidation from "../components/inputs/VTextAreaWithValidation.vue";
import FaqModal from "../components/modals/FaqModal.vue";
import InstructionalVideoModal from "../components/modals/InstructionalVideoModal.vue";
import EditTextFieldModal from "../components/modals/EditTextFieldModal.vue";
export default {
  name: "AdminPage",
  components: {
    YellowButton,
    ProductModal,
    GadgetModal,
    ConfirmDeleteModal,
    SetDateTimeModal,
    PriceAdminCard,
    OrderListAdminCard,
    PromotionalCodeModal,
    PromotionalCodesReportModal,
    CategoryModal,
    TabModal,
    VTextAreaWithValidation,
    FaqModal,
    InstructionalVideoModal,
    EditTextFieldModal,
  },
  async created() {
    this.$store.dispatch("catalogue/fetchProductsForAdmin");
    this.$store.dispatch("gadgets/fetchGadgetsForAdmin");
    this.$store.dispatch("user/fetchUsers");
    this.$store.dispatch("promotionalCodes/fetchAllPromotionalCodes");
    this.$store.dispatch("catalogue/fetchCategories", {
      showInvisibleCategories: true,
    });
    this.$store.dispatch("catalogue/fetchTabs");
    this.$store.dispatch("faq/fetchFaqs");
    this.$store.dispatch("instructionalVideo/fetchInstructionalVideos");
    const gadgetsInformationResponse = await axios.get(
      `custom-text/gadgetShopInfo`
    );
    this.gadgetsInformation = gadgetsInformationResponse.data;
    const instructionalVideoInformationResponse = await axios.get(
      `custom-text/instructionalVideosInfo`
    );
    console.log(instructionalVideoInformationResponse);
    this.instructionalVideoInformation =
      instructionalVideoInformationResponse.data;
  },

  data: () => ({
    promotionalCodesReportModalVisible: false,
    selectedPromotionalCodeToRemove: null,
    tab: "",
    selectedTab: "gadgets",
    editModal: false,
    dateTimeModal: false,
    selectedUser: "",
    dateTime: "",
    selectedCode: null,
    cards: [
      { text: "Info", value: "gadgets" },
      { text: "Produkty", value: "products" },
      { text: "Użytkownicy", value: "users" },
      { text: "Ceny", value: "prices" },
      { text: "Kody rabatowe", value: "codes" },
      { text: "Zamówienia", value: "orders" },
      { text: "Kategorie", value: "categories" },
      { text: "Zakładki", value: "tabs" },
      { text: "FAQ", value: "faq" },
      { text: "Filmy instruktażowe", value: "instructionalVideos" },
    ],
    tabsHeaders: [
      { text: "Nazwa", value: "name" },
      { text: "Zdjęcie", value: "image", sortable: false, width: "125px" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    categoriesHeaders: [
      { text: "Nazwa", value: "name" },
      { text: "Widoczny", value: "visible" },
      { text: "Zdjęcie", value: "image", sortable: false, width: "125px" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    productsHeaders: [
      { text: "Nazwa", value: "name" },
      // { text: "Opis", value: "description" },
      { text: "Cena", value: "priceDetal" },
      { text: "Widoczny", value: "visible" },
      { text: "Zdjęcie", value: "image", sortable: false, width: "125px" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    gadgetsHeaders: [
      { text: "Nazwa", value: "name" },
      { text: "Opis", value: "description" },
      { text: "Cena", value: "note" },
      { text: "Link", value: "link" },
      { text: "Widoczny", value: "visible" },
      { text: "Zdjęcie", value: "image", sortable: false, width: "125px" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    usersHeaders: [
      { text: "Imię", value: "fullname" },
      { text: "Id", value: "id" },
      { text: "Email", value: "email" },
      { text: "Telefon", value: "phone" },
      { text: "Email potwierdzony", value: "emailConfirm", width: "170px" },
      { text: "Newsletter", value: "newsletter" },
      { text: "Subskrybuje", value: "activeAccount", width: "170px" },
      { text: "Rola", value: "role.name" },
      { text: "Data rejestracji", value: "createdAt", width: "200px" },
      {
        text: "Ważność konta",
        value: "subscriptionExpirationDate",
        width: "200px",
      },
      { text: "Język", value: "language.shortName" },
      { text: "Jak dowiedziałaś/łeś się o aplikacji?", value: "howFoundApp" },
      { text: "Notatka (widzi tylko administrator)", value: "adminNote" },
      { text: "Ostatni ruch w aplikacji", value: "lastActivityDate" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    promotionalCodesHeaders: [
      { text: "Data utworzenia", value: "createdAt" },
      { text: "Kod", value: "code" },
      { text: "Opis", value: "description" },
      { text: "Wartość", value: "discountInPercent" },
      { text: "Czy jest aktywny?", value: "isActive" },
      { text: "Akcje", value: "actions", sortable: true },
    ],
    faqHeaders: [
      { text: "Pytanie", value: "question" },
      { text: "Odpowiedź", value: "answer" },
      { text: "Akcje", value: "actions", sortable: true },
    ],
    instructionalVideosHeaders: [
      { text: "Tytuł", value: "title" },
      { text: "Opis", value: "description" },
      { text: "Link YT", value: "link" },
      { text: "Akcje", value: "actions", sortable: true },
    ],
    productSearch: "",
    gadgetSearch: "",
    userSearch: "",
    promotionalCodeSearch: "",
    productModalVisible: false,
    promotionalCodeModalVisible: false,
    confirmDeleteModalVisible: false,
    gadgetModalVisible: false,
    isEditProduct: false,
    isEditGadget: false,
    selectedProduct: "",
    selectedGadget: "",
    categorySearch: "",
    categoryModalVisible: false,
    tabModalVisible: false,
    isEditCategory: false,
    isEditTab: false,
    selectedCategory: "",
    tabsSearch: "",
    selectedHomeTab: "",
    gadgetsInformation: "",
    gadgetsInformationTimeout: null,
    instructionalVideoTimeout: null,
    isEditFaq: false,
    faqModalVisible: false,
    faqSearch: "",
    selectedFaq: "",
    isEditInstructionalVideo: false,
    instructionalVideoModalVisible: false,
    instructionalVideoSearch: "",
    selectedInstructionalVideo: "",
    instructionalVideoInformation: "",
    editNoteModal: false,
  }),

  watch: {
    selectedTab() {
      if (this.selectedTab === "categories") {
        this.$store.dispatch("catalogue/fetchCategories", {
          showInvisibleCategories: true,
        });
      }
    },
  },
  computed: {
    promotionalCodes() {
      return this.$store.getters["promotionalCodes/getAllPromotionalCodes"];
    },
    products() {
      return this.$store.getters["catalogue/getProducts"];
    },
    categories() {
      return this.$store.getters["catalogue/getCategories"];
    },
    gadgets() {
      return this.$store.getters["gadgets/getGadgets"];
    },
    users() {
      return this.$store.getters["user/getUsers"];
    },
    tabs() {
      return this.$store.getters["catalogue/getTabs"];
    },
    faqs() {
      return this.$store.getters["faq/getFaqs"];
    },
    instructionalVideos() {
      return this.$store.getters["instructionalVideo/getInstructionalVideos"];
    },
  },
  methods: {
    editAdminNote(user) {
      this.selectedUser = user;
      this.editNoteModal = true;
    },
    async editNote(value) {
      await this.$store.dispatch("user/updateUserDetails", {
        id: this.selectedUser.id,
        data: { adminNote: value },
      });
      this.editNoteModal = false;
      this.selectedUser = "";
    },
    async saveInstructionalVideoInformation(value) {
      if (this.instructionalVideoTimeout) {
        clearTimeout(this.instructionalVideoTimeout);
      }
      if (value && value.length > 2048) {
        return;
      }

      this.instructionalVideoTimeout = setTimeout(async () => {
        const url = `admin/custom-text/instructionalVideosInfo`;
        await axios.put(url, { value: value });
      }, 1000);
    },
    async saveGadgetsInformation(value) {
      if (this.gadgetsInformationTimeout) {
        clearTimeout(this.gadgetsInformationTimeout);
      }
      if (value && value.length > 2048) {
        return;
      }

      this.gadgetsInformationTimeout = setTimeout(async () => {
        const url = `admin/custom-text/gadgetShopInfo`;
        await axios.put(url, { value: value });
      }, 1000);
    },
    formatRole(role) {
      return shared.formatRole(role);
    },
    formatDate(date) {
      return shared.formattedDateHour(date);
    },
    formatDays(date, hours) {
      return shared.formattedDays(date, hours);
    },
    formattedDateTime(date) {
      return shared.formattedDateTimeWithSeconds(date);
    },
    getImageUrl() {
      return URL;
    },
    removeUser(item) {
      this.selectedUser = item;
      this.confirmDeleteModalVisible = true;
    },
    getPhoto(src) {
      let path = "/uploads/";
      if (src && src === "no-photo.png") {
        path = "/assets/";
      }
      return this.getImageUrl() + path + src;
    },
    openPromotionalsCodeReportModal() {
      this.promotionalCodesReportModalVisible = true;
    },
    closePromotionalCodesReportModal() {
      this.promotionalCodesReportModalVisible = false;
    },
    addProduct() {
      this.productModalVisible = true;
    },
    addTab() {
      this.tabModalVisible = true;
    },
    addFaq() {
      this.$store.dispatch("faq/setFaq", null);
      this.faqModalVisible = true;
    },
    addInstructionalVideo() {
      this.$store.dispatch("instructionalVideo/setInstructionalVideo", null);
      this.instructionalVideoModalVisible = true;
    },
    addCategory() {
      this.categoryModalVisible = true;
    },

    addPromotionalCode() {
      this.promotionalCodeModalVisible = true;
    },

    async editProduct(id) {
      await this.$store.dispatch("catalogue/fetchProductForAdmin", id);
      this.isEditProduct = true;
      this.productModalVisible = true;
    },

    async editCategory(id) {
      await this.$store.dispatch("catalogue/fetchCategoryForAdmin", id);
      this.isEditCategory = true;
      this.categoryModalVisible = true;
    },

    async editTab(id) {
      await this.$store.dispatch("catalogue/fetchTabForAdmin", id);
      this.isEditTab = true;
      this.tabModalVisible = true;
    },

    async editFaq(faq) {
      await this.$store.dispatch("faq/setFaq", faq);
      this.isEditFaq = true;
      this.faqModalVisible = true;
    },

    async editInstructionalVideo(instructionalVideo) {
      await this.$store.dispatch(
        "instructionalVideo/setInstructionalVideo",
        instructionalVideo
      );
      this.isEditInstructionalVideo = true;
      this.instructionalVideoModalVisible = true;
    },

    async editPromotionalCode(promotionalCode) {
      this.selectedCode = promotionalCode;
      this.isEditProduct = true;
      this.promotionalCodeModalVisible = true;
    },
    closeProductModal() {
      this.productModalVisible = false;
      this.isEditProduct = false;
    },
    closeCategoryModal() {
      this.categoryModalVisible = false;
      this.isEditCategory = false;
      this.$store.dispatch("catalogue/fetchCategories", {
        showInvisibleCategories: true,
      });
    },
    closeTabModal() {
      this.tabModalVisible = false;
      this.isEditTab = false;
    },
    closeFaqModal() {
      this.faqModalVisible = false;
      this.isEditFaq = false;
    },
    closeInstructionalVideoModal() {
      this.instructionalVideoModalVisible = false;
      this.isEditInstructionalVideo = false;
    },
    closePromotionalCodeModal() {
      this.promotionalCodeModalVisible = false;
      this.isEditProduct = false;
    },
    addGadget() {
      this.gadgetModalVisible = true;
    },
    async editGadget(id) {
      await this.$store.dispatch("gadgets/fetchGadget", id);
      this.isEditGadget = true;
      this.gadgetModalVisible = true;
    },
    closeGadgetModal() {
      this.gadgetModalVisible = false;
      this.isEditGadget = false;
    },
    selectProductForDeletion(item) {
      this.selectedProduct = item;
      this.confirmDeleteModalVisible = true;
    },
    selectTabForDeletion(item) {
      this.selectedHomeTab = item;
      this.confirmDeleteModalVisible = true;
    },
    selectFaqForDeletion(item) {
      this.selectedFaq = item;
      this.confirmDeleteModalVisible = true;
    },
    selectInstructionalVideoForDeletion(item) {
      this.selectedInstructionalVideo = item;
      this.confirmDeleteModalVisible = true;
    },
    selectGadgetForDeletion(item) {
      this.selectedGadget = item;
      this.confirmDeleteModalVisible = true;
    },
    selectPromotionalCodeToRemove(item) {
      this.selectedPromotionalCodeToRemove = item;
      this.confirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.confirmDeleteModalVisible = false;
      this.selectedProduct = "";
      this.selectedGadget = "";
      this.selectedPromotionalCodeToRemove = "";
      this.selectedHomeTab = "";
      this.selectedUser = "";
      this.selectedFaq = "";
      this.selectedInstructionalVideo = "";
    },
    editSubscription(item) {
      this.selectedUser = item.id;
      this.dateTime = item.subscriptionExpirationDate;
      this.dateTimeModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.gadgetInput {
  margin-top: 20px;
  width: 50%;
}
.selectedTab {
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
  border-radius: 10px 10px 0px 0px !important;
  font-weight: bold;
}
.unselectedTab {
  border-bottom: 1px solid grey;
  border-radius: 0px;
}
.selectedTabItem {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
  border-radius: 0px 0px 10px 10px !important;
  overflow: hidden;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}
.theme--light.v-data-table
  .v-data-table__wrapper
  table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  .v-data-table__wrapper
  table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .fontSize {
    font-size: 0.67rem;
  }
}
text {
  color: black !important;
}
.background {
  background-color: #fbc501;
  color: black !important;
}
::v-deep th {
  vertical-align: top;
}
.note-format {
  white-space: pre-wrap;
}
</style>
