export const login = {
  PL: {
    country: "Wybierz swój kraj",
    welcome: "Witaj w naszej aplikacji, zaloguj się, aby móc z niej korzystać!",
    logIn: "ZALOGUJ SIĘ",
    register: "ZAREJESTRUJ SIĘ",
    email: "Twój adres e-mail:",
    password: "Twoje hasło:",
    repeatPassword: "Powtórz hasło:",
    forget: "Zapomniałem Hasła",
    firstName: "Twoje imię:",
    lastName: "Twoje nazwisko:",
    phone: "Twój nr tel.: (możesz uzupełnić później) ",
    fbo: "Twój numer FBO: (możesz uzupełnić później) ",
    role: "Twoja aktualna pozycja: ",
  },
  EN: {
    country: "Choose your country",
    welcome: "Welcome in our application, log in to be able to use it!",
    logIn: "LOG IN",
    register: "REGISTER",
    email: "Your e-mail address",
    password: "Your password",
    repeatPassword: "Repeat your password",
    forget: "Forgot password",
    firstName: "First name",
    lastName: "Last name",
    phone: "Phone number",
    fbo: "Your FBO number",
    role: "Your current role",
  },
};
