<template>
  <v-row
    justify="center"
    style="max-width: 1000px; padding: 10px; margin: auto"
  >
    <h1 class="black--text py-3 display-2">
      <span class="font">najczęściej zadawane pytania</span>
    </h1>
    <v-expansion-panels accordion>
      <v-expansion-panel v-for="(item, i) in questions" :key="i">
        <v-expansion-panel-header
          >{{ i + 1 }}. {{ item.question }}</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          {{ item.answer }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  created() {
    this.$store.dispatch("faq/fetchFaqs");
  },
  computed: {
    questions() {
      return this.$store.getters["faq/getFaqs"];
    },
  },
};
</script>

<style scoped></style>
