import axios from "../../axios";
import router from "../../router";
import jwt_decode from "jwt-decode";
import errorHandler from "../../errorHandler";
import { showSuccessAlert, showErrorAlert } from "../../utils/alert";

const auth = {
  namespaced: true,
  state: () => ({
    token: window.localStorage.getItem("token"),
    emailVerified: false,
  }),

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_IS_VERIFIED(state, data) {
      state.emailVerified = data;
    },
  },

  actions: {
    async login({ commit, dispatch }, object) {

      try {
        const response = await axios.post(
          "auth/signin",
          {
            password: object.password,
            email: object.email,
          },
          { skipAuthRefresh: true }
        );
        const { accessToken, refreshToken } = response.data;
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        commit("SET_TOKEN", response.data.accessToken);
        if (localStorage.getItem("subscription")) {
          localStorage.removeItem("subscription");
          router.push({ name: "subscription" });
        } else {
          router.push({ name: "home" });
        }

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Zalogowano pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        commit("SET_TOKEN", null);

        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        delete axios.defaults.headers.common["Authorization"];

        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async logout({ commit, dispatch }) {
      try {
        await axios.delete("auth/logout");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        delete axios.defaults.headers.common["Authorization"];
        router.push("/entry");
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Wylogowano pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });

        commit("SET_TOKEN", null);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async register({ dispatch }, object) {
      try {
        await axios.post("auth/signup", {
          password: object.password,
          password_confirmation: object.passwordConfirmation,
          email: object.email,
          fullname: object.fullname,
          fbo: object.fbo,
          phone: object.phone,
          languageId: object.languageId,
          roleId: object.roleId,
          newsletter: object.newsletter,
          howFoundApp: object.howFoundApp
        });

        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Konto zostało utworzone!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        let routeData = router.resolve({ path: "/email-sent" });
        window.open(routeData.href, "_blank");
        return true;
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return false;
      }
    },
    async resetPassword({ dispatch }, object) {
      try {
        await axios.post("auth/reset-password", {
          email: object.email,
        });
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Link do zresetowania hasła został wysłany na email!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async resendVerificationCode({ dispatch }, email) {
      try {
        await axios.post("auth/resend-verification-code", email);
        showSuccessAlert(dispatch, "Pomyślnie wysłano kod weryfikacyjny!");
      } catch (error) {
        const { message, code } = error.response.data;
        if (message && code) {
          showErrorAlert(dispatch, message, code);
        }
      }
    },
    async setNewPassword({ dispatch }, data) {
      try {
        await axios.post("auth/set-new-password", {
          email: data.object.email,
          code: data.object.code,
          password: data.object.password,
          password_confirmation: data.object.passwordConfirmation,
        });
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Hasło zostało zmienione!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        router.push("/entry");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async verifyEmail({ commit, dispatch }, object) {
      try {
        await axios.post("auth/verify-email", {
          email: object.email,
          code: object.code,
        });
        commit("SET_IS_VERIFIED", true);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie zweryfikowano email!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        setTimeout(() => {
          router.push("/entry");
        }, 1500);
      } catch (error) {
        commit("SET_IS_VERIFIED", false);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    forceLogout({ commit }) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      delete axios.defaults.headers.common["Authorization"];
      commit("SET_TOKEN", null);
      router.push("/welcome");
    },
    redirectBack({ dispatch }, error) {
      router.back();
      if (error?.response?.data) {
        const { message, code } = error.response.data;
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(message, code),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    getToken: (state) => {
      if (state.token) {
        return jwt_decode(state.token);
      } else {
        return null;
      }
    },
    getIsVerified: (state) => state.emailVerified,
  },
};
export default auth;
