<template>
  <v-row justify="center" dense style="margin-top: -28px" no-gutters>
    <v-col cols="12" sm="11" md="9" lg="8" xl="7">
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-space-between"
      >
        <v-img
          src="../assets/calculatex2.png"
          aspect-ratio="2"
          class="white--text align-end"
          gradient="to bottom, rgba(255,255,255, .2)40%, rgba(0,0,0,1.99) 120%"
          max-height="300px"
        >
          <v-card-title class="cardTitle pb-10 noSelect justify-center bold">
            KALKULATOR CEN
          </v-card-title></v-img
        >
      </v-card>
      <v-card-actions
        style="background-color: rgba(112, 112, 112, 0.05)"
        v-if="calculation"
        class="px-5 pt-3"
      >
        <span class="align-self-end pt-0 headline break">Edycja Kalkulacji</span
        ><span
          style="color: #fbc501"
          class="align-self-end pt-0 display-1 break"
          >{{ calculation.identifier }}</span
        ></v-card-actions
      >
      <!-- <v-card tile elevation="0" class="d-flex align-center px-3">
        <div>
          <v-card-title class="py-0 pl-2 display-1">{{
            user.fullname
          }}</v-card-title>
        </div>
      </v-card> -->
      <v-card
        tile
        elevation="0"
        class="px-3"
        :class="{ 'pt-3': !calculation }"
        style="background-color: rgba(112, 112, 112, 0.05)"
      >
        <!-- <v-card-actions class="pt-0 d-flex justify-space-between">
          <span v-if="user">{{ formatRole(user.role.name) }}</span>
          <span class="text-right" style="width:90%; color:green;"
            >Zniżka: -{{ getTotalDiscount() }}%</span
          >
        </v-card-actions> -->
        <!-- <v-card-actions class="flex-column align-start">
          <div class="bold break" style="font-size:30px">
            {{ user.fullname }}
          </div>
          <div>{{ formatRole(user.role.name) }}</div>
        </v-card-actions> -->
        <v-card tile elevation="0">
          <v-card-actions
            class="py-0 d-flex justify-space-between"
            style="background-color: rgba(112, 112, 112, 0.05)"
          >
            <div style="width: 57%">
              <span class="inputLabel">Próg zniżkowy klienta</span>
              <v-select
                outlined
                color="black"
                background-color="white"
                v-model="selectedRole"
                hide-details
                single-line
                item-disabled="disabled"
                dense
                rounded
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                :items="selectableRoles"
                item-value="id"
                return-object
              >
                <template v-slot:selection="{ item }">
                  <span class="bold selectText"
                    >{{ formatRole(item.name) }} (-{{
                      item.discountTreshold
                    }}%)</span
                  >
                </template>
                <template v-slot:item="slotProps">
                  <span
                    >{{ formatRole(slotProps.item.name) }} (-{{
                      slotProps.item.discountTreshold
                    }}%)</span
                  >
                </template></v-select
              >
            </div>
            <div class="align-self-start" style="width: 38%">
              <span
                style="width: 100%; display: inline-block"
                class="text-center inputLabel"
                >Rabat dla klienta</span
              >
              <div class="d-flex justify-space-between">
                <WhiteButton
                  @click.native="decreaseCounter()"
                  xsmall
                  :disabled="selectedRole.name != 'Rabat własny'"
                  :height="$vuetify.breakpoint.smAndDown ? '30px' : '40px'"
                  :width="$vuetify.breakpoint.smAndDown ? '30px' : '40px'"
                  fab
                  text="-"
                  borderYellow
                />
                <span
                  class="align-self-center"
                  :class="{ 'font-size': counter > 99 }"
                  >{{ counter }} <span>%</span></span
                >
                <WhiteButton
                  @click.native="increaseCounter()"
                  xsmall
                  :disabled="
                    counter > 100 || selectedRole.name != 'Rabat własny'
                  "
                  :height="$vuetify.breakpoint.smAndDown ? '30px' : '40px'"
                  :width="$vuetify.breakpoint.smAndDown ? '30px' : '40px'"
                  fab
                  text="+"
                  borderYellow
                />
              </div>
            </div>
          </v-card-actions>
        </v-card>
        <v-card-actions class="flex-column">
          <div style="width: 100%" class="d-flex flex-row align-center">
            <span style="width: 100%">
              <span class="inputLabel">Klient</span>
              <span style="width: 100%">
                <v-autocomplete
                  dense
                  filled
                  clearable
                  full-width
                  color="#fbc501"
                  hide-details
                  v-model="selectedCustomer"
                  :search-input.sync="filter"
                  :items="getItems()"
                  return-object
                  item-text="fullname"
                  item-value="id"
                  placeholder="Wyszukaj klienta"
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                >
                  <template slot="append">
                    <font-awesome-icon icon="search" class="mt-1" />
                  </template>
                </v-autocomplete>
              </span>
            </span>
            <WhiteButton
              border-yellow
              small
              @click.native="addCustomerModal = true"
              icon="plus"
              :class="{
                'align-self-end': $vuetify.breakpoint.smAndDown,
                'mt-6': !$vuetify.breakpoint.smAndDown,
              }"
              class="ml-3"
              text="Nowy klient"
            />
          </div>
        </v-card-actions>
      </v-card>
      <v-card elevation="0" tile class="px-5">
        <v-row>
          <v-col cols="6">
            <div class="time-picker">
              <div class="inputLabel">Data oferty</div>
              <v-text-field
                class="time p-0 m-0 mt-0 pt-1"
                v-model="offerDate"
                single-line
                type="date"
                color="#fbc501"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="time-picker">
              <div class="inputLabel">Data zamówienia</div>
              <v-text-field
                class="time p-0 m-0 mt-0 pt-1"
                v-model="orderDate"
                single-line
                type="date"
                color="#fbc501"
              ></v-text-field>
            </div>
          </v-col>
          <!-- <v-col cols="6">
            <div class="inputLabel"><b>Data zamówienia</b></div>
            <p class="inputLabel">
              {{ getFormattedDate(calculation.createdAt) }}
            </p>
          </v-col> -->
        </v-row>
      </v-card>
      <v-card elevation="0" tile class="px-3">
        <v-card-actions class="justify-center">
          <div class="bold title">Lista produktów</div>
          <!-- <YellowButton
            text="Dodaj produkt"
            icon="plus"
            @click.native="addProductsModal = true"
            xsmall
        /> -->
        </v-card-actions>
        <v-simple-table dense>
          <template>
            <thead>
              <tr>
                <th class="text-left">Produkt</th>
                <th
                  class="text-left"
                  :class="{ bigThFont: !$vuetify.breakpoint.smAndDown }"
                >
                  Ilość
                </th>
                <!-- <th class="text-left" style="width:20%">
                  CC:
                </th> -->
                <th
                  :class="{ bigThFont: !$vuetify.breakpoint.smAndDown }"
                  class="text-left"
                >
                  Cena klienta
                </th>
                <th
                  :class="{ bigThFont: !$vuetify.breakpoint.smAndDown }"
                  class="text-left"
                >
                  Twoja cena
                </th>
                <th class="text-left">Rabat</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(product, index) in products"
                :key="product.id"
                :class="{ tableColor: index % 2 === 0 }"
              >
                <td class="bold px-0 pr-2">{{ product.name }}</td>
                <td class="px-0 pr-2">
                  <span
                    v-if="!product.status || product.status === 'NOT_ORDERED'"
                    class="noSelect"
                    style="cursor: pointer"
                    @click="decreaseAmount(product)"
                    >-</span
                  >
                  <span class="mx-2 noSelect">{{ product.amount }}</span>
                  <span
                    v-if="!product.status || product.status === 'NOT_ORDERED'"
                    class="noSelect"
                    style="cursor: pointer"
                    @click="increaseAmount(product)"
                    >+</span
                  >
                </td>
                <!-- <td>
                  {{ product.cc }}
                </td> -->

                <td class="bold px-0">
                  {{
                    roundNumber(product.amount * getTotalDiscount(product), 2) +
                    "zł"
                  }}
                </td>
                <td class="px-0">
                  {{
                    roundNumber(product.amount * product.userPrice, 2) + "zł"
                  }}
                </td>

                <td v-if="!product.hasBlockedDiscounts" class="px-0">
                  <span
                    v-if="selectedRole.name === 'Rabat własny'"
                    class="align-self-center"
                    :class="{ 'font-size': counter > 99 }"
                    >- {{ counter }} <span>%</span></span
                  >

                  <span
                    v-else
                    class="align-self-center"
                    :class="{ 'font-size': selectedRole.discountTreshold > 99 }"
                    >- {{ selectedRole.discountTreshold }} <span>%</span></span
                  >
                </td>
                <td v-else class="px-0">
                  <span
                    class="align-self-center"
                    :class="{ 'font-size': selectedRole.discountTreshold > 99 }"
                    >- 0 <span>%</span></span
                  >
                </td>
                <td class="px-0">
                  <v-btn
                    v-if="!product.status || product.status === 'NOT_ORDERED'"
                    icon
                    color="red"
                    @click="deleteProduct(product, index)"
                  >
                    <font-awesome-icon icon="times" />
                  </v-btn>
                </td>
              </tr>
              <tr
                v-if="
                  deliveryPriceToSet != null && deliveryPriceToSet != undefined
                "
              >
                <td class="bold px-0 pr-2">Dostawa</td>
                <td class="px-0 pr-2">
                  <span class="mr-3"></span>
                  <span>1</span>
                </td>
                <td class="px-0 pr-2 bold">
                  {{ roundNumber(deliveryPriceToSet, 2) }}zł
                </td>
                <td class="px-0 pr-2">
                  {{ roundNumber(deliveryPriceToSet, 2) }}zł
                </td>
                <td>
                  <span
                    class="align-self-center"
                    :class="{ 'font-size': selectedRole.discountTreshold > 99 }"
                    >- 0 <span>%</span></span
                  >
                </td>
                <!-- <td></td> -->
                <td class="px-0">
                  <v-btn icon color="red" @click="deleteDelivery()">
                    <font-awesome-icon icon="times" />
                  </v-btn>
                </td>
              </tr>
              <tr v-if="products.length != 0">
                <td class="noBorder"></td>
                <td class="noBorder"></td>
                <td class="noBorder">
                  <span class="" style="color: #f2be02; font-weight: 500">{{
                    getTotalPriceForCustomer() + "zł"
                  }}</span>
                </td>
                <td class="noBorder">
                  <span class="bold">{{ getTotalPriceForUser() + "zł" }}</span>
                </td>
                <td class="noBorder"></td>
              </tr>
              <tr v-if="products.length != 0">
                <td class="noBorder"></td>
                <td class="noBorder"></td>
                <td class="noBorder"><span class="bold">Twój zysk:</span></td>
                <td class="noBorder">
                  <span class="green--text bold"
                    >{{
                      roundNumber(
                        getTotalPriceForCustomer() - getTotalPriceForUser(),
                        2
                      ) + "zł"
                    }}
                  </span>
                </td>
                <td class="noBorder"></td>
              </tr>
              <tr v-if="products.length != 0">
                <td class="noBorder"></td>
                <td class="noBorder"></td>
                <td class="noBorder">
                  <span class="bold">Ilość punktów cc:</span>
                </td>
                <td class="noBorder">
                  <span class="bold green--text">{{
                    getTotalCcForUser() + "pkt"
                  }}</span>
                </td>
                <td class="noBorder"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-if="products.length === 0" class="text-center">
          Brak produktów
        </div>
        <div class="text-center my-3">
          <YellowButton
            width="auto"
            large
            padding
            text="Dodaj produkt"
            @click.native="addProductsModal = true"
            class="mx-2"
          />
          <YellowButton
            width="auto"
            large
            padding
            text="Dodaj dostawę"
            @click.native="addDeliveryModal = true"
            class="mx-2"
          />
        </div>

        <!-- <v-btn
          small
          class="text-none"
          color="#fbc501"
          text
          @click.native="addProductsModal = true"
        >
          <span class="text-decoration-underline">
            +Dodaj produkt
          </span>
        </v-btn> -->
        <!-- <div class="d-flex flex-row" style="font-size:0.875rem;">
          <div style="width:70%" class="d-flex align-end flex-column">
            <div
              style="width:100%"
              class=" d-flex flex-row justify-space-between"
            >
              <v-btn
                small
                class="text-none"
                color="#fbc501"
                text
                @click.native="addProductsModal = true"
              >
                <span class="text-decoration-underline">
                  +Dodaj produkt
                </span>
              </v-btn>

              <span class="pl-1 headline" style="color:#F2BE02">{{
                getTotalPriceForCustomer() + "zł"
              }}</span>
            </div>
            <div>
              <span class="bold">Twój zysk:</span>
            </div>
            <div>
              <span class="bold">Ilość punktów cc:</span>
            </div>
          </div>
          <div style="width:20%" class="d-flex align-start flex-column pl-10">
            <div class="pt-1">
              <span class="bold">{{ getTotalPriceForUser() + "zł" }}</span>
            </div>
            <div style="padding-top:10px">
              <span class="green--text bold"
                >{{
                  roundNumber(
                    getTotalPriceForCustomer() - getTotalPriceForUser(),
                    2
                  ) + "zł"
                }}
              </span>
            </div>
            <div>
              <span class="bold green--text">{{
                getTotalCcForUser() + "pkt"
              }}</span>
            </div>
          </div>
        </div> -->
        <div style="width: 100%">
          <span class="inputLabel">Moja notatka (zobaczysz je tylko Ty):</span>
          <v-text-field
            v-model="myNote"
            dense
            filled
            placeholder="Wpisz swoje uwagi"
          ></v-text-field>
        </div>
        <div style="width: 100%">
          <span class="inputLabel"
            >Uwagi dla klienta (zobaczy je Twój klient):</span
          >
          <v-text-field
            v-model="customerNote"
            dense
            filled
            placeholder="Wpisz treść notatki"
          ></v-text-field>
        </div>
      </v-card>
      <!-- <v-card-actions class="pt-3 px-3"
        ><div style="width:100%;">
          <span style="font-size:0.7rem;"
            >Uwagi dla klienta (zobaczy je Twój klient):</span
          >
          <v-text-field
            v-model="customerNote"
            dense
            filled
            placeholder="Wpisz treść notatki"
          ></v-text-field></div
      ></v-card-actions> -->
      <v-card-actions class="justify-center pt-10">
        <!-- <YellowButton
          width="49%"
          text="Zapisz kalkulację"
          @click.native="createCalculation()"
        /> -->
        <YellowButton
          width="auto"
          xlarge
          padding
          text="Zapisz Kalkulację"
          @click.native="createCalculationForCustomer()"
        />
      </v-card-actions>
      <CustomerModal
        :isVisible="addCustomerModal"
        :add="true"
        @closeModal="addCustomerModal = false"
      />
      <AddProductsModal
        :isVisible="addProductsModal"
        :calculation="true"
        :getAllPrices="true"
        @closeModal="addProductsModal = false"
        @addToCalculation="addProductsToCalculation"
        :percentDiscount="selectedDiscount"
      />
      <AddDeliveryModal
        :isVisible="addDeliveryModal"
        @closeModal="addDeliveryModal = false"
        @deliverySelected="handleSelectDelivery"
      />
    </v-col>
  </v-row>
</template>

<script>
import shared from "../shared";
import YellowButton from "../components/buttons/YellowButton";
import CustomerModal from "../components/modals/CustomerModal";
import AddProductsModal from "../components/modals/AddProductsModal";
import AddDeliveryModal from "../components/modals/AddDeliveryModal";
import WhiteButton from "../components/buttons/WhiteButton";
import { dateWithTime } from "../utils/dateFormatting";
import moment from "moment";
export default {
  name: "CalculatorPage",
  components: {
    YellowButton,
    WhiteButton,
    CustomerModal,
    AddProductsModal,
    AddDeliveryModal,
  },

  data: () => ({
    selectableRoles: [],
    filter: "",
    myNote: "",
    customerNote: "",
    products: [],
    updatedProducts: [],
    deletedProducts: [],
    addCustomerModal: false,
    addProductsModal: false,
    addDeliveryModal: false,
    calculationModal: false,
    selectedRole: "",
    selectedCustomer: "",
    counter: 0,
    deliveryPriceToSet: null,
    offerDate: null,
    orderDate: null,
  }),

  methods: {
    deleteDelivery() {
      this.deliveryPriceToSet = null;
    },
    async saveDeliveryPrice(calculationId) {
      await this.$store.dispatch("calculation/updateCalculationDeliveryPrice", {
        calculationId: calculationId ? calculationId : this.calculation.id,
        deliveryPrice: this.deliveryPriceToSet,
      });
    },
    async handleSelectDelivery(selectedPrice) {
      this.deliveryPriceToSet = selectedPrice;
    },
    getFormattedDate(date) {
      return shared.formattedDatePolish(date);
    },
    roundNumber(number, precision) {
      return shared.roundNumber(number, precision);
    },
    getTotalCcForUser() {
      let total = 0;
      for (let i = 0; i < this.products.length; i++) {
        let amount = this.products[i].cc * this.products[i].amount;

        total = total + amount;
      }
      return this.roundNumber(total, 3);
    },
    getTotalPriceForUser() {
      let total = 0;
      for (let i = 0; i < this.products.length; i++) {
        let amount = this.products[i].amount * this.products[i].userPrice;
        total = total + amount;
      }
      if (this.deliveryPriceToSet) {
        total = total + Number(this.deliveryPriceToSet);
      }
      return this.roundNumber(total, 2);
    },
    getTotalPriceForCustomer() {
      let total = 0;
      for (let i = 0; i < this.products.length; i++) {
        if (!this.products[i].hasBlockedDiscounts) {
          if (!this.selectedRole.id) {
            let amount =
              this.products[i].amount *
              (1 - this.counter / 100) *
              this.products[i].priceDetal;
            total = total + amount;
          } else {
            let amount =
              this.products[i].amount *
              this.products[i].prices.find(
                (x) => x.roleId === this.selectedRole.id
              ).value;
            total = total + amount;
          }
        } else {
          total = total + this.products[i].priceDetal * this.products[i].amount;
        }
      }
      if (this.deliveryPriceToSet) {
        total = total + Number(this.deliveryPriceToSet);
      }
      return this.roundNumber(total, 2);
    },
    dataForCustomer() {
      var data = {
        customer: this.selectedCustomer,
        discount: this.getTotalDiscount(),
        note: this.customerNote,
      };
      return data;
    },
    // createCalculation() {
    //   var selectedProducts = [];
    //   for (let i = 0; i < this.products.length; i++) {
    //     var product = {
    //       productId: this.products[i].id,
    //       amount: this.products[i].amount,
    //     };
    //     selectedProducts.push(product);
    //   }
    //   let body = {
    //     newCustomer: false,
    //     note: this.myNote,
    //     clientNote: this.customerNote,
    //     customerId: this.selectedCustomer.id,
    //     discount: this.selectedRole.discountTreshold,
    //     clientDiscount: this.counter,
    //     roleId: this.selectedRole.id,
    //     products: selectedProducts,
    //   };
    //   if (this.calculation) {
    //     let data = {
    //       calculationId: this.calculation.id,
    //       newCustomer: false,
    //       note: this.myNote,
    //       clientNote: this.customerNote,
    //       customerId: this.selectedCustomer.id,
    //       discount: this.selectedRole.discountTreshold,
    //       clientDiscount: this.counter,
    //       roleId: this.selectedRole.id,
    //       updatedProducts:
    //         this.updatedProducts.length > 0 ? this.updatedProducts : [],
    //       deletedProducts:
    //         this.deletedProducts.length > 0 ? this.deletedProducts : [],
    //     };

    //     this.$store.dispatch("calculation/editCalculation", data);
    //   } else {
    //     this.$store.dispatch("calculation/createCalculation", body);
    //   }
    // },
    async createCalculationForCustomer() {
      if (!this.offerDate) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Wymagane podanie daty oferty!",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        return;
      }
      var selectedProducts = [];
      for (let i = 0; i < this.products.length; i++) {
        var product = {
          productId: this.products[i].id,
          amount: this.products[i].amount,
        };
        selectedProducts.push(product);
      }
      let body = {
        newCustomer: false,
        note: this.myNote,
        clientNote: this.customerNote,
        customerId: this.selectedCustomer ? this.selectedCustomer.id : "",
        discount: this.counter,
        roleId: this.selectedRole.id,
        products: selectedProducts,
        offerDate: this.offerDate ? this.offerDate : null,
        orderDate: this.orderDate ? this.orderDate : null,
      };
      if (this.calculation) {
        let data = {
          calculationId: this.calculation.id,
          newCustomer: false,
          note: this.myNote,
          clientNote: this.customerNote,
          customerId: this.selectedCustomer ? this.selectedCustomer.id : "",
          discount: this.counter,
          roleId: this.selectedRole.id,
          updatedProducts:
            this.updatedProducts.length > 0 ? this.updatedProducts : [],
          deletedProducts:
            this.deletedProducts.length > 0 ? this.deletedProducts : [],
          offerDate: this.offerDate ? this.offerDate : null,
          orderDate: this.orderDate ? this.orderDate : null,
        };
        await this.$store.dispatch("calculation/editCalculation", data);
        await this.saveDeliveryPrice(this.calculation.id);
        this.$router.push(`/calculation-offer/${this.calculation.id}`);
      } else {
        await this.$store.dispatch("calculation/createCalculation", body);
        await this.saveDeliveryPrice(this.calculationId);
        this.$router.push(`/calculation-offer/${this.calculationId}`);
      }
    },
    getTotalDiscount(product) {
      if (product.hasBlockedDiscounts) {
        return product.priceDetal;
      }
      if (!this.selectedRole.id) {
        return (1 - this.counter / 100) * product.priceDetal;
      } else {
        return (
          product.prices.find((x) => x.roleId === this.selectedRole.id).value *
          1
        );
      }
    },
    async addProductsToCalculation(item) {
      if (this.calculation) {
        var products = [];
        for (let i = 0; i < item.length; i++) {
          var temp = {
            productId: item[i].id,
            amount: item[i].amount,
          };
          products.push(temp);
        }
        var object = {
          products: products,
          calculation: this.calculation.id,
        };
        await this.$store.dispatch("calculation/addProduct", object);
        this.fetchCalculation();
      } else {
        var found;
        for (let i = 0; i < item.length; i++) {
          found = false;
          if (this.products.length > 0) {
            for (let j = 0; j < this.products.length; j++) {
              if (this.products[j].id === item[i].id) {
                this.products[j].amount += item[i].amount;
                found = true;
                break;
              }
            }
          }
          if (!found) {
            this.products.push(item[i]);
          }
        }
      }
    },
    formatRole(role) {
      return shared.formatRole(role);
    },
    increaseCounter() {
      if (this.counter < this.user.role.discountTreshold - 1) {
        this.counter++;
        this.selectedRole.discountTreshold = this.counter;
      }
    },
    decreaseCounter() {
      if (this.counter > 0) {
        this.counter--;
        this.selectedRole.discountTreshold = this.counter;
      } else {
        return;
      }
    },
    increaseAmount(item) {
      item.amount++;
      this.addToUpdated(item);
    },
    decreaseAmount(item) {
      if (item.amount > 1) {
        item.amount--;
        this.addToUpdated(item);
      } else {
        return;
      }
    },
    getLastContact(date) {
      return dateWithTime(date);
    },
    async fetchData() {
      await this.$store.dispatch("user/fetchUser");
      await this.fetchRoles();
      if (this.$route.query.id) {
        await this.fetchCalculation();
      }
      if (this.$route.query.customerId) {
        await this.fetchCustomer();
      }
    },
    fetchFilteredCustomers() {
      if (this.filter) {
        if (this.filter.length >= 3) {
          let object = {
            filter: this.filter,
          };
          this.$store.dispatch("customers/fetchCustomers", object);
        }
      }
    },
    async fetchRoles() {
      await this.$store.dispatch("publics/fetchRoles");

      this.selectableRoles = JSON.parse(JSON.stringify(this.roles));
      for (let i = 0; i < this.selectableRoles.length; i++) {
        if (
          this.selectableRoles[i].discountTreshold >
          this.user.role.discountTreshold
        ) {
          this.selectableRoles[i].disabled = true;
        } else {
          this.selectableRoles[i].disabled = false;
        }
      }
      let item = {
        id: "",
        disabled: false,
        name: "Rabat własny",
        discountTreshold: 0,
      };
      this.selectableRoles.push(item);
      this.selectedRole = item;
    },
    async fetchCalculation() {
      this.products = [];
      await this.$store.dispatch(
        "calculation/fetchCalculationById",
        this.$route.query.id
      );

      this.myNote = this.calculation.note;
      this.customerNote = this.calculation.clientNote
        ? this.calculation.clientNote
        : "";
      for (let i = 0; i < this.calculation.calculation_products.length; i++) {
        let temp = {
          amount: this.calculation.calculation_products[i].amount,
          cc: this.calculation.calculation_products[i].product.cc,
          id: this.calculation.calculation_products[i].id,
          productId: this.calculation.calculation_products[i].productId,
          name: this.calculation.calculation_products[i].product.name,
          userPrice: this.calculation.calculation_products[
            i
          ].product.product_prices.find((x) => x.roleId === this.user.roleId)
            .value,
          priceDetal:
            this.calculation.calculation_products[i].product.priceDetal,
          prices:
            this.calculation.calculation_products[i].product.product_prices,
          status: this.calculation.calculation_products[i].status,
          hasBlockedDiscounts:
            this.calculation.calculation_products[i].hasBlockedDiscounts ||
            this.calculation.calculation_products[i].product
              ?.hasBlockedDiscounts,
        };
        this.products.push(temp);
      }
      if (!this.calculation.clientDiscount) {
        for (let j = 0; j < this.selectableRoles.length; j++) {
          if (this.selectableRoles[j].id === this.calculation.discountId) {
            this.selectedRole = this.selectableRoles[j];
            break;
          }
        }
      } else {
        this.counter = this.calculation.discount;
        this.selectedRole.discountTreshold = this.calculation.discount;
      }
      this.selectedCustomer = this.calculation.customer;
    },
    async fetchCustomer() {
      let object = {
        id: this.$route.query.customerId,
      };
      await this.$store.dispatch("customers/fetchCustomer", object);
      this.selectedCustomer = this.customer;
    },
    addToUpdated(item) {
      let product = {
        productId: item.productId,
        id: item.id,
        amount: item.amount,
        status: item.status,
      };
      var i;
      if (this.updatedProducts.length < 1) {
        this.updatedProducts.push(product);
      } else {
        for (i = 0; i < this.updatedProducts.length; i++) {
          if (
            this.updatedProducts[i].productId === product.productId &&
            this.calculation
          ) {
            this.updatedProducts.splice(i, 1);
            var result = this.calculation.calculation_products.filter((obj) => {
              return obj.productId === product.productId;
            });
            if (
              result[0].amount === product.amount &&
              result[0].status === product.status
            ) {
              return;
            } else {
              this.updatedProducts.push(product);
              return;
            }
          }
        }
        this.updatedProducts.push(product);
      }
    },
    deleteProduct(item, index) {
      this.products.splice(index, 1);
      if (this.calculation) {
        this.deletedProducts.push(item.id);
        this.deleteFromUpdated(item);
      }
    },
    deleteFromUpdated(item) {
      if (this.updatedProducts < 1) {
        return;
      } else {
        for (let i = 0; i < this.updatedProducts.length; i++) {
          if (this.updatedProducts[i].productId === item.productId) {
            this.updatedProducts.splice(i, 1);
          }
        }
      }
    },
    getItems() {
      var items = [];
      if (this.calculation.customerId && !this.customer && !this.customers) {
        items.push(this.calculation.customer);
      } else if (this.customer && !this.customers) {
        items.push(this.customer);
      } else {
        items = this.customers;
      }

      return items;
    },
  },
  watch: {
    filter() {
      this.fetchFilteredCustomers();
    },
    selectedRole() {
      if (this.selectedRole.name != "Rabat własny") {
        this.counter = 0;
        this.selectableRoles[5].discountTreshold = 0;
      }
    },
    customer() {
      this.selectedCustomer = this.customer;
    },
  },

  async created() {
    this.$store.commit("calculation/SET_CALCULATION", "");
    this.$store.commit("customers/SET_CUSTOMER", "");
    this.$store.commit("customers/SET_CUSTOMERS", "");
    await this.fetchData();
    const { deliveryPrice, offerDate, orderDate } = this.calculation;
    if (deliveryPrice) {
      this.deliveryPriceToSet = deliveryPrice;
    }
    if (offerDate) {
      this.offerDate = moment(offerDate).format("YYYY-MM-DD");
    }
    if (orderDate) {
      this.orderDate = moment(orderDate).format("YYYY-MM-DD");
    }
  },
  computed: {
    customers() {
      return this.$store.getters["customers/getCustomers"].data;
    },
    customer() {
      return this.$store.getters["customers/getCustomer"];
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
    calculation() {
      return this.$store.getters["calculation/getCalculation"];
    },
    roles() {
      return this.$store.getters["publics/getRoles"];
    },
    calculationId() {
      return this.$store.getters["calculation/getCalculationId"];
    },
    selectedDiscount() {
      if (this.selectedRole.name === "Rabat własny") {
        return this.counter;
      } else {
        return this.selectedRole.discountTreshold;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .fontSize {
    font-size: 0.8rem;
  }
  .smallFont {
    font-size: 0.6rem;
  }
  .mediumFont {
    font-size: 0.7rem;
  }
  .smallPadding {
    padding-bottom: 5px;
  }
}
.category {
  word-wrap: none;
}
a {
  color: black !important;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.cardTitle {
  font-weight: bold;
  font-size: 30px;
  word-break: break-word;
}
.inputLabel {
  font-size: 0.8rem;
  text-align: center;
  width: 100%;
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .cardTitle {
    font-weight: bold;
    font-size: 48px;
    word-break: break-word;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  ::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
    padding-left: 24px;
    padding-right: 13px;
  }
  ::v-deep .v-input__append-inner {
    margin-top: 3px !important;
  }
  .inputLabel {
    font-size: 0.7rem;
  }
  ::v-deep .v-input__slot {
    min-height: 30px !important;
    display: flex !important;
    align-items: center !important;
  }
  ::v-deep .v-input__slot.white {
    max-height: 30px !important;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 0.75rem;
  }
  ::v-deep tr th {
    font-size: 0.75rem;
    font-weight: 400;
  }
  .selectText {
    font-size: 0.63rem;
  }
}

::v-deep.v-text-field--outlined fieldset {
  border-color: #fcc502 !important;
}
::v-deep tr th {
  font-weight: 400;
}
.bigThFont {
  font-size: 14px !important;
}
.noBorder {
  border: 0px !important;
}
td,
th {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
</style>
