<template>
  <v-row justify="center">
    <v-dialog
      @keydown.esc="closeModal()"
      max-width="500px"
      :value="isVisible"
      v-on:click:outside="closeModal"
      overlay-opacity="1"
      transition="slide-y-transition"
    >
      <v-card flat tile elevation="0">
        <v-toolbar
          class="black--text d-flex justify-center"
          color="#fbc501"
          tile
        >
          <v-toolbar-title>Dzisiejsze kontakty</v-toolbar-title>
        </v-toolbar>
        <BlackButton
          fab
          small
          icon="times"
          iconMargin=""
          class="close"
          @click.native="closeModal()"
        />
        <v-card flat tile elevation="0">
          <v-card-actions class="d-flex flex-column">
            <v-simple-table v-if="contacts.length > 0">
              <thead>
                <tr>
                  <th class="text-left">Kontakt</th>
                  <th class="text-left">Godzina</th>
                  <th class="text-left">Temat</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="contact in contacts" :key="contact.id">
                  <td>
                    <span
                      style="cursor: pointer"
                      @click="
                        $router.push(
                          `/my-office/customer/${contact.customerId}`
                        )
                      "
                      >{{
                        contact.customer ? contact.customer.fullname : ""
                      }}</span
                    >
                  </td>
                  <td>
                    {{ getTime(contact.date) }}
                  </td>
                  <td>
                    {{ contact.topic }}
                  </td>
                  <td>
                    <font-awesome-icon
                      @click="markAsDone(contact)"
                      style="cursor: pointer"
                      color="green"
                      icon="check"
                    />
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-card-title v-else class="break body-1"
              >Brak zaplanowanych kontaktów na dzisiaj</v-card-title
            >
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BlackButton from "../buttons/BlackButton";
import shared from "../../shared";
export default {
  name: "TodayContactsModal",
  props: ["isVisible"],

  components: {
    BlackButton,
  },
  data: () => ({}),
  computed: {
    contacts() {
      return this.$store.getters["user/getContacts"].filter(
        (contact) => contact.customer
      );
    },
  },
  methods: {
    getTime(date) {
      return shared.formattedTimeWithoutSeconds(date);
    },
    markAsDone(item) {
      let object = {
        customerId: item.customer.id,
        id: item.id,
      };
      this.$store.dispatch("customers/markContactAsDone", object);
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
// .v-overlay__scrim {
//   backdrop-filter: blur(5px) !important;
//   background-color: transparent !important;
// }
.close {
  position: absolute;
  top: 2%;
  right: 2%;
  z-index: 10000000;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.break {
  word-break: break-word;
}
</style>
